import { useEffect, useRef, useState } from "react";

export const CampaignName = ({ name, onChange }) => {

    const [isEdit, setIsEdit] = useState(false);
    const inputRef = useRef(null);
    const [inputValue, setInputValue] = useState(name);

    useEffect(() => {
        if (isEdit) {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    }, [isEdit]);

    const updateCheck = () => {
        if (name !== inputValue) {
            onChange(inputValue);
        }
    }

    const keyup = (e) => {
        if (e.key === 'Enter') {
            updateCheck();
            setIsEdit(false)
        }
    }

    return (
        <div className="headr-text purple-black" onClick={() => {
            setIsEdit((editState) => !editState);
        }}>
            {
                !isEdit && <div>{name}</div>
            }
            {
                isEdit ?
                    <div className="input-bg">
                        <input
                            type="textbox"
                            name="textbox"
                            value={inputValue}
                            onChange={(e) => { setInputValue(e.target.value) }}
                            onBlur={() => { updateCheck(); setIsEdit(false) }}
                            onKeyUp={keyup}
                            ref={inputRef}
                            className="textbox-input-body-table"
                        />
                    </div> : null
            }
        </div>
    )
}
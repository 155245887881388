import { yupResolver } from "@hookform/resolvers/yup";
import { ApiPost } from "helper/API/ApiData";
import { FileBaseUrl, UserBaseUrl } from "helper/API/BaseUrl";
import React, { useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import reload from "../assets/img/getting_started.png";
import FAQ_icon from "../assets/img/FAQ_icon.png";
import Contact_by_email from "../assets/img/Contact_by_email.png";
import { ReactComponent as EmailIcon } from "../assets/img/email_icon.svg";
import { ReactComponent as EmailIconBlack } from "../assets/img/email_icon_black.svg";
import Error from "./Error";
import TextBoxWrapper from "./TextBoxWrapper";
import { getUserDataAPI } from "redux/features/authentication/authSlice";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useCallback } from "react";

const HelpModal = ({ show, onHide }) => {
  const inputraf = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userData, setuserData] = useState();
  const [logoImage, setLogoImage] = useState({ logoFormData: null, url: "" });
  const [img, steImg] = useState("");
  const [selectedFileName, setSelectedFileName] = useState("");
  const [editInputFiels, setEditInputFiels] = useState(true);
  const [contactByEmail, setContactByEmail] = useState(false);

  const Help = yup.object({
    email: yup.string().email().required("Enter your email "),
    body: yup.string().required("Please describe your issue"),
  });

  const {
    register,
    handleSubmit,
    reset,
    values,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Help),
  });

  const onHandleSubmit = async (data) => {
    try {
      let logoURL = img;
      if (logoImage?.logoFormData) {
        const uploadRes = await ApiPost(
          `${FileBaseUrl}file/upload`,
          logoImage?.logoFormData
        );
        const {
          response: { url },
        } = uploadRes;
        logoURL = url;
        steImg(url);
      }
      let body = {
        body: data.body,
        email: data.email,
        title: "Help",
      };

      if (logoURL) {
        body.file = logoURL;
      }
      await ApiPost(`${UserBaseUrl}help`, body).then((res) => {
        toast.success("Submitted successfully.", {
          closeOnClick: true,
          pauseOnHover: true,
        });
        onCancel();
      });
    } catch (e) {
      console.log("e", e);
    }
  };

  const onCancel = () => {
    reset({
      email: "",
      body: "",
    });
    onHide();
  };

  const postUploadfile = (file) => {
    const form_data = new FormData();
    form_data.append("file", file?.target.files[0]);
    form_data.append("group", "help");
    form_data.append("subgroup", "");
    form_data.append("filename", file?.target.files[0].name);
    form_data.append("url", URL.createObjectURL(file?.target.files[0]));
    if (file?.target.files[0]) {
      setSelectedFileName(file.target.files[0].name);
    }
    setLogoImage({
      logoFormData: form_data,
      url: URL.createObjectURL(file?.target.files[0]),
    });
  };

  const setUserProfileData = useCallback(async () => {
    await dispatch(getUserDataAPI())
      .then(unwrapResult)
      .then((result) => {
        setuserData(result?.response);
        reset({
          email: result?.response?.email,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, [show]);

  useEffect(() => {
    if (show) setUserProfileData();
    setContactByEmail(false);
  }, [show]);

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      // centered
      show={show}
      onHide={onCancel}
      backdrop="static"
    >
      <Modal.Header
        className="close"
        style={{ borderBottom: "none" }}
        closeButton
      >
        {" "}
        Help{" "}
      </Modal.Header>
      <Modal.Body className="SegmentModal">
        <div className="help_getting_started">
          <div className="getting_started_heade">
            <img src={reload} alt="" />
            <p>Getting started</p>
          </div>
          <p style={{ marginBottom: "0px" }}>My first campaign!</p>
        </div>
        <div
          className="help_detail"
          onClick={() => {
            onCancel();
            navigate("/settings", { state: { id: "QA" } });
          }}
        >
          <div className={`${contactByEmail && "active"} faq_main`}>
            <img src={FAQ_icon} alt="" />
            <p>FAQ</p>
          </div>
        </div>
        <div
          className="help_detail contact_by_email mt-0"
          onClick={() => setContactByEmail(!contactByEmail)}
        >
          <div
            className={`${contactByEmail ? "subscription_notification" : ""}`}
          >
            <div className={`${contactByEmail && "active"} faq_main`}>
              <img src={Contact_by_email} alt="" />
              <p>Contact by email</p>
            </div>
          </div>
        </div>
        {contactByEmail && (
          <div className="contact_by_email_main">
            <div className="contact_by_email_input">
              <TextBoxWrapper
                placeholderImage={<EmailIcon />}
                focusPlaceholderImage={<EmailIconBlack />}
              >
                <input
                  type="text"
                  placeholder="enter your email"
                  name="email"
                  autoFocus
                  defaultValue={userData?.email}
                  disabled={editInputFiels}
                  {...register("email")}
                />
              </TextBoxWrapper>
              {errors?.email && <Error message={errors?.email?.message} />}
              <button onClick={() => setEditInputFiels(!editInputFiels)}>
                {" "}
                <u>Edit</u>
              </button>
            </div>
            <div>
              <TextBoxWrapper>
                <textarea
                  rows={5}
                  style={{ height: "auto" }}
                  className="model-input"
                  name="body"
                  placeholder="Describe your issue..."
                  {...register("body")}
                />
              </TextBoxWrapper>
              {errors?.body && <Error message={errors?.body?.message} />}
            </div>
            <div className="contact_by_email_draganddrop">
              <input
                type="file"
                ref={inputraf}
                onChange={(e) => {
                  postUploadfile(e);
                }}
              />
              <p>
                Drag and drop <span>or upload file</span>
              </p>
              <p>{selectedFileName}</p>
            </div>
            <div
              className="start-promotional-campaign-footer gap-2"
              style={{ border: "none" }}
            >
              <Button
                onClick={handleSubmit((data) => {
                  onHandleSubmit(data);
                })}
                style={{ width: "100px" }}
                className="button-8559F3 boomio-white"
              >
                Send
              </Button>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default HelpModal;

import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const DeleteModal = (props) => {
    const { modalBy, onDelete, onHide, ...modalProps } = props;

    return (
        <Modal
            {...modalProps}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='create-coupons-modals delete-modal'
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='delete-img-bg'>
                    <img src="./assets/img/delete-popup-img.png" alt="" />
                </div>
                {
                    modalBy === 'campaigns' ?
                        <h4>Once you delete the campaign, it will no longer appear in your campaign listing and can't be recovered. <br /> <b> Are you sure you want to permanently delete the selected campaign? </b></h4>
                        :
                        <h4>By deleting , This will be permanently <br />
                            removed from the listing</h4>
                }
            </Modal.Body>
            <Modal.Footer>
                <a className='templat-img'></a>
                <div className=''>
                    <Button onClick={onHide} className='cancel-btn'>No, Cancel</Button>
                    <Button onClick={() => {
                        onDelete()
                        onHide()
                    }} className='submit-btn'>Yes, Delete</Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteModal;

import React, { useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { changePasswordAPI } from 'redux/features/changePassword/changePasswordSlice';

const ChangePasswordModal = ({ show, onHide }) => {

    const dispatch = useDispatch()
    const { t } = useTranslation();

    const EditSchema = yup.object({
        newpassword: yup.string().required('Enter new password '),
        oldpassword: yup.string().required('Enter old password'),
        confirmPassword: yup.string()
            .oneOf([yup.ref('newpassword'), null], 'Passwords must match')
            .required('Enter confirm password'),
    });

    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(EditSchema),
    });

    const onHandleSubmit = async (data) => {
        await dispatch(changePasswordAPI(data)).then(unwrapResult).then((result) => {
            if (result.status === 200) {
                onHide()
            }
        })
    }

    return (
        <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={onHide}
            className="change_password"
        >
            <Modal.Header className="close" closeButton></Modal.Header>
            <Modal.Body className="SegmentModal">
                <form action="" style={{ padding: '20px 0px 0px' }}>
                    <div className=' mb-3'>
                        <p className='title-lable'>Password
                        </p>
                        <input
                            type="text"
                            className='model-input'
                            name="password"
                            placeholder="Password"
                            {...register("oldpassword")}
                        />
                        {errors?.oldpassword && <Form.Text className="color">{errors?.oldpassword?.message}</Form.Text>}
                    </div>
                    <div className=' mb-3'>
                        <p className='title-lable'>
                            New Password
                        </p>
                        <input
                            type="text"
                            className='model-input'
                            name="newpassword"
                            placeholder="New Password"
                            {...register("newpassword")}
                        />
                        {errors?.newpassword && <Form.Text className="color">{errors?.newpassword?.message}</Form.Text>}
                    </div>
                    <div className=' mb-3'>
                        <p className='title-lable'>
                            Confirm Password
                        </p>
                        <input
                            type="text"
                            className='model-input'
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            {...register("confirmPassword")}
                        />
                        {errors.confirmPassword && <Form.Text className="color">{errors?.confirmPassword?.message}</Form.Text>}
                    </div>
                    <div className='start-promotional-campaign-footer mt-3'>
                        <button
                            type="submit"
                            className='authActionButton'
                            onClick={handleSubmit((data) => {
                                onHandleSubmit(data)
                            })}>
                            <div className="gradientBG">
                                <div className="button">
                                    <span className="continueText">{t("EditProfile.Edit_btn")}</span>
                                </div>
                            </div>
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default ChangePasswordModal
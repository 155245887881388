import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Info from "../../../assets/img/info.png";
import datepicker from "../../../assets/img/date-picker.png";
import RadioButton from "../../../common/RadioButton";
import CheckboxButton from "../../../common/CheckboxButton";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createCampaigns,
  editCampaigns,
  getCampaignsById,
} from "redux/features/campaigns/campaignSlice";
import queryString from "query-string";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import moment from "moment";
import DiscountModal from "../widgets/DiscountModal";
const Reward = () => {
  const [open, setOpen] = React.useState(false);
  const [customerType, setCustomerType] = useState("all");
  const [sendType, setSendType] = useState(["app"]);
  const [launchType, setLaunchType] = useState("now");
  const [campaignData, setCampaignData] = useState();
  const [dateRange, setDateRange] = useState({});
  const [couponData, setCouponData] = useState();

  const query = queryString.parse(window.location.search);
  const id = query?.id;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggle = () => {
    setOpen(!open);
  };

  const productRewardSchema = yup.object({});
  const { register, handleSubmit, setValue, reset } = useForm({
    resolver: yupResolver(productRewardSchema),
  });

  const handleCampaignData = (payload) => {
    const returnData = {
      name: payload?.campaignName,
      description: payload?.description,
      title: payload?.campaignTitle,
      camp_sent_to_customer: payload?.what_way_camp_sent_to_customer,
      when_to_launch: payload?.when_to_launch,
      start_date: payload?.campaignStartDate,
      end_date: payload?.campaignEndDate,
      params: {
        selectRewardType1: payload?.rewardType && payload?.rewardType,
      },
      campaign_type: "automatic_campaign_reward",
    };
    dispatch(
      id ? editCampaigns({ id, ...returnData }) : createCampaigns(returnData)
    )
      .then((res) => {
        navigate("/campaigns");
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getCampaignsData = async () => {
    await dispatch(getCampaignsById(id))
      .then(unwrapResult)
      .then((res) => {
        setDateRange({
          startDate: moment(res?.data?.start_date).format("YYYY/MM/DD"),
          endDate: moment(res?.data?.end_date).format("YYYY/MM/DD"),
        });
        setCampaignData(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCustomersToSend = (val) => {
    if (sendType.find((findVal) => findVal === val)) {
      setSendType(sendType.filter((filterVal) => filterVal !== val));
      setValue(
        "what_way_camp_sent_to_customer",
        sendType.filter((filterVal) => filterVal !== val)
      );
    } else {
      setSendType([...sendType, val]);
      setValue("what_way_camp_sent_to_customer", [...sendType, val]);
    }
  };

  useEffect(() => {
    var defaultResetValues = {};
    reset(defaultResetValues);
  }, [campaignData]);

  useEffect(() => {
    if (id) {
      getCampaignsData();
    }
  }, [id]);

  return (
    <>
      <div className="campaigns-heade">
        <h1 className="page-header">Campaign details</h1>
      </div>
      <div className="d-xl-flex">
        <div className="product-design-bg">
          <h2>Campaign details</h2>
          <h3>Enter campaign details</h3>
          <div className="d-sm-flex flex-column justify-content-between">
            <div className="w-100 d-flex justify-content-between">
              <div className="w-40">
                <div className="mt-4 mb-2">
                  <label htmlFor="" className="input-bold">
                    Campaign Name *
                  </label>
                </div>
                <input
                  className="form-control boomio-input"
                  type="text"
                  {...register("campaignName")}
                  placeholder="My first timer 20% discount campaign"
                  aria-label="default input example"
                />
                <div className="d-flex align-items-center mt-2">
                  <img src={Info} alt="" />
                  <span className="boomio-gray boomio-small">
                    &nbsp;Campaign Title is public.
                  </span>
                </div>
              </div>
              <div className="set-date-picker first-time-reward w-40">
                <label htmlFor="" className="label-text">
                  Campaign Period *
                </label>
                <div
                  className="date-picker mt-7 boomio-datepicker-border"
                  style={{ position: "relative" }}
                  onClick={() => setOpen(!open)}
                  {...register("campaignStartDate")}
                  {...register("campaignEndDate")}
                >
                  {`${
                    dateRange?.startDate ? dateRange?.startDate : "Start Date"
                  } - ${dateRange?.endDate ? dateRange?.endDate : "End Date"} `}
                  <img src={datepicker} alt="" onClick={() => setOpen(!open)} />
                </div>
                {/* <DateRangePicker
                  open={open}
                  onChange={(range) => {
                    setValue('campaignStartDate', moment(range?.startDate).format("YYYY/MM/DD"))
                    setValue('campaignEndDate', moment(range?.endDate).format("YYYY/MM/DD"))
                    setDateRange({ startDate: moment(range?.startDate).format("YYYY/MM/DD"), endDate: moment(range?.endDate).format("YYYY/MM/DD") })
                  }}
                  toggle={toggle} /> */}
              </div>
            </div>
            <div className="w-40">
              <div className="mt-4 mb-2">
                <label htmlFor="" className="input-bold">
                  Campaign Title *
                </label>
              </div>
              <input
                className="form-control boomio-input"
                type="text"
                {...register("campaignTitle")}
                placeholder="Get your 20% discount!"
                aria-label="default input example"
              />
              <div className="d-flex align-items-center mt-2">
                <img src={Info} alt="" />
                <span className="boomio-gray boomio-small">
                  &nbsp;Campaign Title is public.
                </span>
              </div>
            </div>
            <div className="w-100">
              <div className="mt-4 mb-2">
                <label htmlFor="" className="input-bold">
                  Description *
                </label>
              </div>
              <textarea
                className="form-control w-100 boomio-input"
                id="exampleFormControlTextarea1"
                rows="3"
                {...register("description")}
                placeholder="With this coupon you can get 20% discount of a fresh orange juice size of 0,33L"
              ></textarea>
              <div className="d-flex align-items-center mt-2">
                <img src={Info} alt="" />
                <span className="boomio-gray boomio-small">
                  &nbsp;Campaign Title is public.
                </span>
              </div>
            </div>
            <div className="w-50">
              <div className="mt-4 mb-2">
                <label htmlFor="" className="input-bold me-4">
                  Notification Image
                </label>
                <Button className="button-8559F3 boomio-white me-2">
                  View
                </Button>
              </div>
            </div>
            <div className="w-51">
              <div className="mt-4 mb-2">
                <label htmlFor="" className="input-bold">
                  Select a reward type *
                </label>
              </div>
              <div>
                <RadioButton
                  checked={customerType === "all"}
                  text={"All"}
                  id={"option1"}
                  value={"all"}
                  {...register("rewardType")}
                  onChange={() => {
                    setValue("rewardType", "all");
                    setCustomerType("all");
                  }}
                />
                <RadioButton
                  checked={customerType === "new"}
                  text={"New"}
                  id={"option2"}
                  value={"new"}
                  {...register("rewardType")}
                  onChange={() => {
                    setValue("rewardType", "new");
                    setCustomerType("new");
                  }}
                />
                <RadioButton
                  checked={customerType === "existing"}
                  text={"Existing"}
                  id={"option3"}
                  value={"existing"}
                  {...register("rewardType")}
                  onChange={() => {
                    setValue("rewardType", "existing");
                    setCustomerType("existing");
                  }}
                />
              </div>
              <div className="d-flex align-items-center mt-2">
                <img src={Info} alt="" />
                <span className="boomio-gray boomio-small">
                  &nbsp;Campaign Title is public.
                </span>
              </div>
            </div>
            <div className="w-51">
              <div className="mt-4 mb-2">
                <label htmlFor="" className="input-bold">
                  In what ways this campaign should be sent to customers?
                </label>
              </div>
              <div>
                <CheckboxButton
                  checked={sendType.includes("app")}
                  text={"App"}
                  id={"option1"}
                  value={"app"}
                  {...register("what_way_camp_sent_to_customer")}
                  onChange={() => {
                    handleCustomersToSend("app");
                  }}
                />
                <CheckboxButton
                  checked={sendType.includes("sms")}
                  text={"SMS"}
                  id={"option2"}
                  value={"sms"}
                  {...register("what_way_camp_sent_to_customer")}
                  onChange={() => {
                    handleCustomersToSend("sms");
                  }}
                />
                <CheckboxButton
                  checked={sendType.includes("email")}
                  text={"Email"}
                  id={"option3"}
                  value={"email"}
                  {...register("what_way_camp_sent_to_customer")}
                  onChange={() => {
                    handleCustomersToSend("email");
                  }}
                />
              </div>
              <div className="d-flex align-items-center mt-2">
                <img src={Info} alt="" />
                <span className="boomio-gray boomio-small">
                  &nbsp;Can choose multiple options.
                </span>
              </div>
            </div>
            <div className="w-51">
              <div className="mt-4 mb-2">
                <label htmlFor="" className="input-bold">
                  When to launch this?
                </label>
              </div>
              <div>
                <RadioButton
                  checked={launchType === "now"}
                  text={"Send it Now"}
                  {...register("when_to_launch")}
                  id={"option1"}
                  value={"now"}
                  onChange={() => {
                    setValue("when_to_launch", "now");
                    setLaunchType("now");
                  }}
                />
                <RadioButton
                  checked={launchType === "date"}
                  text={"Select a Specific Date"}
                  {...register("when_to_launch")}
                  id={"option2"}
                  value={"date"}
                  onChange={() => {
                    setValue("when_to_launch", "date");
                    setLaunchType("date");
                  }}
                />
                <RadioButton
                  checked={launchType === "feedback"}
                  text={"Get a Feedback"}
                  {...register("when_to_launch")}
                  id={"option3"}
                  value={"feedback"}
                  onChange={() => {
                    setValue("when_to_launch", "feedback");
                    setLaunchType("feedback");
                  }}
                />
              </div>
              <div className="d-flex align-items-center mt-2">
                <img src={Info} alt="" />
                <span className="boomio-gray boomio-small">
                  &nbsp;Customers will receive notifications immediately after
                  launch.
                </span>
              </div>
            </div>
            <div className="mt-5">
              <Button className="button-white purple-black me-2">Back</Button>
              <Button
                className="button-8559F3 boomio-white me-2"
                onClick={handleSubmit((data) => {
                  handleCampaignData(data);
                })}
              >
                Launch
              </Button>
            </div>
          </div>
        </div>
        <DiscountModal couponData={couponData} />
      </div>
    </>
  );
};

export default Reward;

import { useEffect, useState } from "react";

export function useDocumentClickListener() {
    const [event, setEvent] = useState();
    useEffect(() => {
        document.addEventListener('click', (e) => setEvent(e));
        return () => document.removeEventListener('click', (e) => {});
    })

    return event;
}
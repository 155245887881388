import React from "react";
import DiscountModal from "../widgets/DiscountModal";
import RevealTheReward from "../widgets/RevealTheReward";
import UserGetsHint from "../widgets/UserGetsHint";

const UserJourney = ({
  couponData,
  title1,
  title2,
  title3,
  from,
  discountModalClass,
  gameSelect,
}) => {
  const getUI = () => {
    switch (from) {
      case "BoostProduct":
        return (
          <div
            className="user_journey_body"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <div className="journey-steps">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "20px",
                }}
              >
                <div className="round-background">
                  <p style={{ color: "#FF3183", margin: 0, fontWeight: 600 }}>
                    1
                  </p>
                </div>
              </div>
              <h3 className="lable">{title1}</h3>
              <UserGetsHint
                title={"You Got Lucky!"}
                description={"Find a game and unlock your reward"}
                couponData={couponData}
              />
            </div>
            <div className="journey-steps" style={{ margin: "0px 50px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "20px",
                }}
              >
                <div className="round-background">
                  <p style={{ color: "#FF3183", margin: 0, fontWeight: 600 }}>
                    2
                  </p>
                </div>
              </div>
              <h3 className="lable">{title2}</h3>
              <RevealTheReward
                puzzle={false}
                couponData={couponData}
                gameSelect={gameSelect}
              />
            </div>
            <div className="journey-steps">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "20px",
                }}
              >
                <div className="round-background">
                  <p style={{ color: "#FF3183", margin: 0, fontWeight: 600 }}>
                    3
                  </p>
                </div>
              </div>
              <h3 className="lable">{title3}</h3>
              <DiscountModal
                className={discountModalClass}
                couponData={couponData}
              />
            </div>
          </div>
        );

      case "LeadToPurchase":
        return (
          <div className="user_journey_body">
            <h3 className="lable">{title1}</h3>
            <RevealTheReward
              from="LeadToPurchase"
              puzzle={false}
              couponData={couponData}
            />
            <h3 className="lable">{title2}</h3>
            <DiscountModal
              className={discountModalClass}
              couponData={couponData}
            />
          </div>
        );

      case "SpecificProductReward":
        return (
          <div className="user_journey_body">
            <h3 className="lable">{title1}</h3>
            <RevealTheReward puzzle={true} />
            <h3 className="lable mt-3">{title2}</h3>
            <UserGetsHint
              title={"YOU’RE ALMOST THERE! FIND THE LAST PIECE!"}
              description={"Hint for another piece:Adidas Stan Smith J FX7519"}
              puzzle={true}
              couponData={couponData}
            />
            <h3 className="lable">{title3}</h3>
            <DiscountModal couponData={couponData} />
          </div>
        );
      case "IncreaseBrowsingTime":
        return (
          <div className="user_journey_body">
            <h3 className="lable">{title1}</h3>
            <RevealTheReward puzzle={true} />
            <h3 className="lable mt-3">{title2}</h3>
            <UserGetsHint
              title={"YOU’RE ALMOST THERE! FIND THE LAST PIECE!"}
              description={"Hint for another piece:Adidas Stan Smith J FX7519"}
              puzzle={true}
              couponData={couponData}
            />
            <h3 className="lable">{title3}</h3>
            <DiscountModal couponData={couponData} />
          </div>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      <div
        className="campaign-header"
        style={{ fontSize: "24px", marginBottom: "30px" }}
      >
        USER JOURNEY PREVIEW
      </div>
      {getUI()}
    </>
  );
};

export default UserJourney;

import React, { useMemo, useState } from 'react'

const TextBoxWrapper = ({ children, placeholderImage, focusPlaceholderImage }) => {
    const [isFocus, setIsFocus] = useState(false);

    const renderPlaceHolderImage = useMemo(() => () => {
        return isFocus ? focusPlaceholderImage : placeholderImage;
    }, [isFocus]);

    return (
        <div
            className={`authTextWrapper ${isFocus ? 'active' : ''}`}
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
        >
            {/* <div className="margin" /> */}
            <div className="gradientBG">
                <div className="placeholderImage">{renderPlaceHolderImage()}</div>
                {children}
            </div>
        </div>
    )
}

export default TextBoxWrapper
import React from "react";
import editButton from "../../../assets/img/editButton.png";

const CampaignAccordion = ({
  rewardType,
  discountAmount,
  selectedDay,
  dateRange,
  gameSelect,
  campaignName,
  selectedGameValue,
  campaignAccessibility,
  setStep,
}) => {
  return (
    <>
      <div>
        <div style={{ fontSize: "32px", fontWeight: "700" }}>
          Review before publishing
        </div>
        <div>Review changes before before publishing</div>

        <div
          style={{
            width: "100%",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: 24,
            display: "inline-flex",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              paddingTop: 16,
              paddingBottom: 24,
              paddingLeft: 24,
              paddingRight: 24,
              borderRadius: 16,
              border: "1px #D6D6D6 solid",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 32,
              display: "flex",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 4,
                display: "inline-flex",
              }}
            >
              <div
                style={{
                  flex: "1 1 0",
                  color: "#7A7A7A",
                  fontSize: 16,
                  fontFamily: "Montserrat",
                  fontWeight: "700",

                  wordWrap: "break-word",
                }}
              >
                Main information
              </div>

              <img
                src={editButton}
                alt=""
                style={{
                  width: "132px",
                  height: "26px",
                  cursor: "pointer",
                }}
                onClick={() => console.log(setStep(1))}
              />
            </div>
            <div
              style={{
                alignSelf: "stretch",
                height: 152,
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 16,
                display: "flex",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  gap: 16,
                  display: "inline-flex",
                }}
              >
                <div
                  style={{
                    flex: "1 1 0",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: 16,
                    display: "inline-flex",
                  }}
                >
                  <div
                    style={{
                      alignSelf: "stretch",
                      height: 40,
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        alignSelf: "stretch",
                        color: "#585666",
                        fontSize: 14,
                        fontFamily: "Montserrat",
                        fontWeight: "700",

                        wordWrap: "break-word",
                      }}
                    >
                      Campaign name
                    </div>
                    <div
                      style={{
                        alignSelf: "stretch",
                        justifyContent: "flex-start",
                        alignItems: "flex-end",
                        gap: 8,
                        display: "inline-flex",
                      }}
                    >
                      <div
                        style={{
                          flex: "1 1 0",
                          color: "#141414",
                          fontSize: 16,
                          fontFamily: "Montserrat",
                          fontWeight: "700",

                          wordWrap: "break-word",
                        }}
                      >
                        {campaignName}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      alignSelf: "stretch",
                      height: 40,
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        alignSelf: "stretch",
                        color: "#585666",
                        fontSize: 14,
                        fontFamily: "Montserrat",
                        fontWeight: "700",

                        wordWrap: "break-word",
                      }}
                    >
                      Selected game
                    </div>
                    <div
                      style={{
                        alignSelf: "stretch",
                        justifyContent: "flex-start",
                        alignItems: "flex-end",
                        gap: 8,
                        display: "inline-flex",
                      }}
                    >
                      <div
                        style={{
                          flex: "1 1 0",
                          color: "#141414",
                          fontSize: 16,
                          fontFamily: "Montserrat",
                          fontWeight: "700",

                          wordWrap: "break-word",
                        }}
                      >
                        {gameSelect.label}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      alignSelf: "stretch",
                      height: 40,
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        alignSelf: "stretch",
                        color: "#585666",
                        fontSize: 14,
                        fontFamily: "Montserrat",
                        fontWeight: "700",

                        wordWrap: "break-word",
                      }}
                    >
                      Game type
                    </div>
                    <div
                      style={{
                        alignSelf: "stretch",
                        justifyContent: "flex-start",
                        alignItems: "flex-end",
                        gap: 8,
                        display: "inline-flex",
                      }}
                    >
                      <div
                        style={{
                          flex: "1 1 0",
                          color: "#141414",
                          fontSize: 16,
                          fontFamily: "Montserrat",
                          fontWeight: "700",

                          wordWrap: "break-word",
                        }}
                      >
                        {selectedGameValue.charAt(0).toUpperCase() +
                          selectedGameValue.slice(1)}{" "}
                        Based
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              height: 200,
              alignSelf: "stretch",
              paddingTop: 16,
              paddingBottom: 24,
              paddingLeft: 24,
              paddingRight: 24,
              borderRadius: 16,
              border: "1px #D6D6D6 solid",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 32,
              display: "flex",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 4,
                display: "inline-flex",
              }}
            >
              <div
                style={{
                  flex: "1 1 0",
                  color: "#7A7A7A",
                  fontSize: 16,
                  fontFamily: "Montserrat",
                  fontWeight: "700",

                  wordWrap: "break-word",
                }}
              >
                Registration
              </div>
              <img
                src={editButton}
                alt=""
                style={{
                  width: "132px",
                  height: "26px",
                  cursor: "pointer",
                }}
                onClick={() => console.log(setStep(2))}
              />
            </div>
            <div
              style={{
                alignSelf: "stretch",
                height: 44,
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "flex-start",
                gap: 16,
                display: "flex",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  height: 44,
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    flex: "1 1 0",
                    color: "#141414",
                    fontSize: 16,
                    fontFamily: "Montserrat",
                    fontWeight: "700",

                    wordWrap: "break-word",
                  }}
                >
                  Nickname
                </div>
                <div
                  style={{
                    flex: "1 1 0",
                    color: "#141414",
                    fontSize: 16,
                    fontFamily: "Montserrat",
                    fontWeight: "700",
                    marginTop: "20px",
                    wordWrap: "break-word",
                  }}
                >
                  Name
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              height: 260,
              alignSelf: "stretch",
              paddingTop: 16,
              paddingBottom: 24,
              paddingLeft: 24,
              paddingRight: 24,
              borderRadius: 16,
              border: "1px #D6D6D6 solid",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 32,
              display: "flex",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 4,
                display: "inline-flex",
              }}
            >
              <div
                style={{
                  flex: "1 1 0",
                  color: "#7A7A7A",
                  fontSize: 16,
                  fontFamily: "Montserrat",
                  fontWeight: "700",

                  wordWrap: "break-word",
                }}
              >
                Rules
              </div>
              <img
                src={editButton}
                alt=""
                style={{
                  width: "132px",
                  height: "26px",
                  cursor: "pointer",
                }}
                onClick={() => console.log(setStep(3))}
              />
            </div>

            <div
              style={{
                height: 40,
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                display: "flex",
              }}
            >
              {" "}
              <div
                style={{
                  flex: "1 1 0",
                  color: "#141414",
                  fontSize: 16,
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  wordWrap: "break-word",
                }}
              >
                1. Spausk kad skristum
              </div>
              <div
                style={{
                  flex: "1 1 0",
                  color: "#141414",
                  fontSize: 16,
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  marginTop: "20px",

                  wordWrap: "break-word",
                }}
              >
                2. Kartok dėl geresnio resultato
              </div>
              <div
                style={{
                  flex: "1 1 0",
                  color: "#141414",
                  fontSize: 16,
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  marginTop: "20px",
                  wordWrap: "break-word",
                }}
              >
                3. Laimėk prizus kas savaitę!
              </div>
            </div>
          </div>

          <div
            style={{
              alignSelf: "stretch",
              height: 141,
              paddingTop: 16,
              paddingBottom: 24,
              paddingLeft: 24,
              paddingRight: 24,
              borderRadius: 16,
              border: "1px #D6D6D6 solid",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 32,
              display: "flex",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 4,
                display: "inline-flex",
              }}
            >
              <div
                style={{
                  flex: "1 1 0",
                  color: "#7A7A7A",
                  fontSize: 16,
                  fontFamily: "Montserrat",
                  fontWeight: "700",

                  wordWrap: "break-word",
                }}
              >
                Personalization
              </div>
              <img
                src={editButton}
                alt=""
                style={{
                  width: "132px",
                  height: "26px",
                  cursor: "pointer",
                }}
                onClick={() => console.log(setStep(4))}
              />
            </div>
            <div
              style={{
                alignSelf: "stretch",
                height: 44,
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "flex-start",
                gap: 16,
                display: "flex",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  height: 44,
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    color: "#585666",
                    fontSize: 14,
                    fontFamily: "Montserrat",
                    fontWeight: "700",

                    wordWrap: "break-word",
                  }}
                >
                  Personalize game
                </div>
                <div
                  style={{
                    width: 1005,
                    justifyContent: "flex-start",
                    alignItems: "flex-end",
                    gap: 8,
                    display: "inline-flex",
                  }}
                >
                  <div
                    style={{
                      width: 1005,
                      color: "#141414",
                      fontSize: 16,
                      fontFamily: "Montserrat",
                      fontWeight: "700",

                      wordWrap: "break-word",
                    }}
                  >
                    Personalized to XX brand.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              height: 141,
              paddingTop: 16,
              paddingBottom: 24,
              paddingLeft: 24,
              paddingRight: 24,
              borderRadius: 8,
              border: "1px #D6D6D6 solid",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 32,
              display: "flex",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 4,
                display: "inline-flex",
              }}
            >
              <div
                style={{
                  flex: "1 1 0",
                  color: "#9C9C9D",
                  fontSize: 16,
                  fontFamily: "Montserrat",
                  fontWeight: "700",

                  wordWrap: "break-word",
                }}
              >
                Outcome
              </div>
              <img
                src={editButton}
                alt=""
                style={{
                  width: "132px",
                  height: "26px",
                  cursor: "pointer",
                }}
                onClick={() => console.log(setStep(5))}
              />
            </div>
            <div
              style={{
                alignSelf: "stretch",
                height: 44,
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "flex-start",
                gap: 16,
                display: "flex",
              }}
            >
              aa
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              height: 161,
              paddingTop: 16,
              paddingBottom: 24,
              paddingLeft: 24,
              paddingRight: 24,
              borderRadius: 16,
              border: "1px #D6D6D6 solid",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 32,
              display: "flex",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 4,
                display: "inline-flex",
              }}
            >
              <div
                style={{
                  flex: "1 1 0",
                  color: "#7A7A7A",
                  fontSize: 16,
                  fontFamily: "Montserrat",
                  fontWeight: "700",

                  wordWrap: "break-word",
                }}
              >
                Duration
              </div>
              <img
                src={editButton}
                alt=""
                style={{
                  width: "132px",
                  height: "26px",
                  cursor: "pointer",
                }}
                onClick={() => console.log(setStep(6))}
              />
            </div>
            <div
              style={{
                alignSelf: "stretch",
                height: 64,
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "flex-start",
                gap: 16,
                display: "flex",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  height: 64,
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  gap: 4,
                  display: "flex",
                }}
              >
                <div
                  style={{
                    color: "#585666",
                    fontSize: 14,
                    fontFamily: "Montserrat",
                    fontWeight: "700",

                    wordWrap: "break-word",
                  }}
                >
                  Duration of campaign
                </div>
                <div
                  style={{
                    width: 600,
                    height: 40,
                    justifyContent: "flex-start",
                    alignItems: "flex-end",
                    display: "inline-flex",
                  }}
                >
                  <div
                    style={{
                      width: 228,
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      display: "inline-flex",
                    }}
                  >
                    <div
                      style={{
                        alignSelf: "stretch",
                        color: "#585666",
                        fontSize: 14,
                        fontFamily: "Montserrat",
                        fontWeight: "500",

                        wordWrap: "break-word",
                      }}
                    >
                      Start date
                    </div>
                    <div
                      style={{
                        justifyContent: "flex-start",
                        alignItems: "flex-end",
                        gap: 8,
                        display: "inline-flex",
                      }}
                    >
                      <div
                        style={{
                          color: "#141414",
                          fontSize: 16,
                          fontFamily: "Montserrat",
                          fontWeight: "700",

                          wordWrap: "break-word",
                        }}
                      >
                        {dateRange.startDate && dateRange.startDate}
                      </div>
                    </div>
                  </div>
                  {dateRange.endDate && (
                    <div
                      style={{
                        flex: "1 1 0",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        display: "inline-flex",
                      }}
                    >
                      <div
                        style={{
                          alignSelf: "stretch",
                          color: "#585666",
                          fontSize: 14,
                          fontFamily: "Montserrat",
                          fontWeight: "500",

                          wordWrap: "break-word",
                        }}
                      >
                        End date
                      </div>
                      <div
                        style={{
                          justifyContent: "flex-start",
                          alignItems: "flex-end",
                          gap: 8,
                          display: "inline-flex",
                        }}
                      >
                        <div
                          style={{
                            color: "#141414",
                            fontSize: 16,
                            fontFamily: "Montserrat",
                            fontWeight: "700",

                            wordWrap: "break-word",
                          }}
                        >
                          {dateRange.endDate && dateRange.endDate}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              height: 141,
              paddingTop: 16,
              paddingBottom: 24,
              paddingLeft: 24,
              paddingRight: 24,
              borderRadius: 8,
              border: "1px #D6D6D6 solid",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: 32,
              display: "flex",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 4,
                display: "inline-flex",
              }}
            >
              <div
                style={{
                  flex: "1 1 0",
                  color: "#9C9C9D",
                  fontSize: 16,
                  fontFamily: "Montserrat",
                  fontWeight: "700",

                  wordWrap: "break-word",
                }}
              >
                Set Up
              </div>
              <img
                src={editButton}
                alt=""
                style={{
                  width: "132px",
                  height: "26px",
                  cursor: "pointer",
                }}
                onClick={() => console.log(setStep(7))}
              />
            </div>
            <div
              style={{
                width: 1005,
                color: "#141414",
                fontSize: 16,
                fontFamily: "Montserrat",
                fontWeight: "700",

                wordWrap: "break-word",
              }}
            >
              Verified
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CampaignAccordion;

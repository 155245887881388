import React from 'react';
import { Button } from 'react-bootstrap';
import Ellipse from '../../../assets/img/ellipse.png'
import EllipseBold from '../../../assets/img/ellipse-bold.png'
import Watermelon from '../../../assets/img/watermelon.png'
import Icecream from '../../../assets/img/icecream.png'
import Coffee from '../../../assets/img/coffee.png'
import Burger from '../../../assets/img/burger.png'
import Cutlery from '../../../assets/img/cutlery.png'
import Gift from '../../../assets/img/gift.png'
import Info from '../../../assets/img/info.png'

const WelcomeReward = () => {
    return (
        <>
            <div className='campaigns-heade'>
                <h1 className='page-header'>Welcome Reward</h1>
            </div>
            <div className='d-xl-flex'>
                <div className='product-design-bg'>
                    <h2>Campaign details</h2>
                    <h3>Enter campaign details</h3>
                    <div className='d-sm-flex flex-column justify-content-between'>
                        <div className="w-51">
                            <div className='mt-4 mb-2'>
                                <label htmlFor="" className='input-bold'>XSelect a reward type *</label>
                            </div>
                            <input type="radio" className="btn-check" name="options" id="option1" autoComplete="off"
                                checked />
                            <label className="btn btn-secondary boomio-light-gradient boomio-campaign-active-btn" htmlFor="option1">
                                <img src={EllipseBold} alt="" />
                                &nbsp;Free Gift
                            </label>
                            <input type="radio" className="btn-check" name="options" id="option2"
                                autoComplete="off" />
                            <label className="btn btn-secondary boomio-white-gradient boomio-campaign-btn" htmlFor="option2">
                                <img src={Ellipse} alt="" />
                                &nbsp;Discount
                            </label>
                        </div>
                        <div className="w-51">
                            <div className='mt-4 mb-2'>
                                <label htmlFor="" className='input-bold'>YSelect an icon to illustrate your gift</label>
                            </div>
                            <input type="radio" className="btn-check" name="options" id="option1" autoComplete="off"
                                checked />
                            <label className="btn btn-secondary boomio-light-gradient boomio-campaign-active-btn" htmlFor="option1">
                                <img src={Coffee} alt="" />
                            </label>
                            <input type="radio" className="btn-check" name="options" id="option2"
                                autoComplete="off" />
                            <label className="btn btn-secondary boomio-white-gradient boomio-campaign-btn" htmlFor="option2">
                                <img src={Watermelon} alt="" />
                            </label>
                            <input type="radio" className="btn-check" name="options" id="option3"
                                autoComplete="off" />
                            <label className="btn btn-secondary boomio-white-gradient boomio-campaign-btn" htmlFor="option3">
                                <img src={Icecream} alt="" />
                            </label>
                            <input type="radio" className="btn-check" name="options" id="option4"
                                autoComplete="off" />
                            <label className="btn btn-secondary boomio-white-gradient boomio-campaign-btn" htmlFor="option4">
                                <img src={Burger} alt="" />
                            </label>
                            <input type="radio" className="btn-check" name="options" id="option5"
                                autoComplete="off" />
                            <label className="btn btn-secondary boomio-white-gradient boomio-campaign-btn" htmlFor="option5">
                                <img src={Cutlery} alt="" />
                            </label>
                            <input type="radio" className="btn-check" name="options" id="option6"
                                autoComplete="off" />
                            <label className="btn btn-secondary boomio-white-gradient boomio-campaign-btn" htmlFor="option6">
                                <img src={Gift} alt="" />
                            </label>
                        </div>
                        <div className="w-100">
                            <div className='mt-4 mb-2'>
                                <label htmlFor="" className='input-bold'>ZEnter a gift</label>
                            </div>
                            <input className="form-control boomio-input" type="text" placeholder="Mufin"
                                aria-label="default input example" />
                        </div>
                        <div className="w-100">
                            <div className='mt-4 mb-2'>
                                <label htmlFor="" className='input-bold'>Coupon Title * (čia ir campaign pavadinimas)</label>
                            </div>
                            <input className="form-control boomio-input w-25" type="text" placeholder="XFree ZMuffin"
                                aria-label="default input example" />
                            <div className='d-flex align-items-center mt-2'>
                                <img src={Info} alt="" />
                                <span className='boomio-gray boomio-small'>&nbsp;Campaign Title is public.</span>
                            </div>
                        </div>
                        <div className="w-100">
                            <div className='mt-4 mb-2'>
                                <label htmlFor="" className='input-bold'>Description (optional) su rodykle (box issikleidzia tik ja paspaudus)</label>
                            </div>
                            <textarea className="form-control w-100 boomio-input" id="exampleFormControlTextarea1" rows="3" placeholder='Join our shop in Boomio app and enjoy Free Muffin!'></textarea>
                            <div className='d-flex align-items-center mt-2'>
                                <img src={Info} alt="" />
                                <span className='boomio-gray boomio-small'>&nbsp;Description</span>
                            </div>
                        </div>
                        <div className="w-100">
                            <div className='mt-4 mb-2'>
                                <label htmlFor="" className='input-bold'>Campaign period *</label>
                            </div>
                            <select className="form-select boomio-input w-25" aria-label="Default select example">
                                <option selected>3 months</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select>
                        </div>
                        <div className='mt-5'>
                            <Button className='button-white purple-black me-2'>Back</Button>
                            <Button className='button-8559F3 boomio-white me-2'>Launch</Button>
                            <span className='boomio-gray boomio-small'>&nbsp;*You can cancel this campaign at anytime in “Campaigns” section.</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WelcomeReward;

import React, { useEffect } from "react";

const Support = () => {
  useEffect(() => {
    const element = document.createElement("a");
    element.href = "https://helpdesk.sun.boomio.com/";
    element.click();
  }, []);

  return <>Loading...</>;
};

export default Support;

import { useState } from "react";
import "./indexButton.css";

const ContinueButton = ({
  onSubmit,
  text = "Continue",
  arrowVisible = true,
  disabled = false,
  className,
}) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <button
      type="submit"
      className={`authActionButton ${isHover ? "active" : ""}`}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={onSubmit}
      disabled={disabled}
    >
      <div className="margin" />
      <div className="gradientBG">
        <div className={className ? className : "button"} >
          <span className="continueText">{text}</span>
          {/* {arrowVisible && (!isHover ? <RightArrow /> : <LinearRight />)} */}
        </div>
      </div>
    </button>
  );
};

export default ContinueButton;

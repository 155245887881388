import React, { useState } from "react";
import { Button } from "react-bootstrap";
import RadioButton from "../../common/RadioButton";
import Info from "../../assets/img/info.png";

const NewCustomerCampaign = () => {
  const [announcementType, setAnnouncementType] = useState("campaign");
  const [campaignType, setCampaignType] = useState("reward");

  return (
    <>
      <div className="campaigns-heade">
        <h1 className="page-header">Welcome Reward</h1>
      </div>
      <div className="d-xl-flex">
        <div className="product-design-bg">
          <h2>Campaign Type</h2>
          <h3>Select campaign type</h3>
          <div className="d-sm-flex flex-column justify-content-between">
            <div className="w-51">
              <div className="mt-4 mb-2">
                <label htmlFor="" className="input-bold">
                  What kind of announcement you are looking to create?
                </label>
              </div>
              <div>
                <RadioButton
                  checked={announcementType === "campaign"}
                  text={"New Customer Campaign"}
                  id={"option1"}
                  value={"campaign"}
                  onChange={() => setAnnouncementType("campaign")}
                />
                <RadioButton
                  checked={announcementType === "account"}
                  text={"Create an Account"}
                  id={"option2"}
                  value={"account"}
                  onChange={() => setAnnouncementType("account")}
                />
                <RadioButton
                  checked={announcementType === "profile"}
                  text={"Complete the Profile"}
                  id={"option3"}
                  value={"profile"}
                  onChange={() => setAnnouncementType("profile")}
                />
                <RadioButton
                  checked={announcementType === "download"}
                  text={"Download an App"}
                  id={"option4"}
                  value={"download"}
                  onChange={() => setAnnouncementType("download")}
                />
                <RadioButton
                  checked={announcementType === "birthday"}
                  text={"Congratulate 7 days before birthday"}
                  id={"option5"}
                  value={"birthday"}
                  onChange={() => setAnnouncementType("birthday")}
                />
              </div>
              <div className="d-flex align-items-center mt-2">
                <img src={Info} alt="" />
                <span className="boomio-gray boomio-small">
                  &nbsp;When a new customer joins your place in the Boomio app,
                  they will automatically receive a gift.
                </span>
              </div>
            </div>
            <div className="w-51">
              <div className="mt-4 mb-2">
                <label htmlFor="" className="input-bold">
                  What kind of campaign you are looking to create?
                </label>
              </div>
              <div>
                <RadioButton
                  checked={campaignType === "reward"}
                  text={"Discount / Reward"}
                  id={"campaignOption1"}
                  value={"reward"}
                  onChange={() => setCampaignType("reward")}
                />
                <RadioButton
                  checked={campaignType === "shop"}
                  text={"Visit My Shop"}
                  id={"campaignOption2"}
                  value={"shop"}
                  onChange={() => setCampaignType("shop")}
                />
                <RadioButton
                  checked={campaignType === "feedback"}
                  text={"Get a Feedback"}
                  id={"campaignOption3"}
                  value={"feedback"}
                  onChange={() => setCampaignType("feedback")}
                />
              </div>
              <div className="d-flex align-items-center mt-2">
                <img src={Info} alt="" />
                <span className="boomio-gray boomio-small">
                  &nbsp;This is a discount or reward ........
                </span>
              </div>
            </div>
            <div className="mt-5 d-flex align-items-center">
              <Button className="button-white purple-black me-2">Back</Button>
              <Button className="button-purple boomio-white me-2">Next</Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewCustomerCampaign;

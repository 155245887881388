import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router";

const AddStore = ({ show, onHide }) => {
  const navigate = useNavigate();

  const handleTryPreviewClick = () => {
    localStorage.setItem("initialChoice", JSON.stringify("preview"));
    navigate("/campaigns/boost-product");
  };

  const handleStartCampaignClick = () => {
    localStorage.setItem("initialChoice", JSON.stringify("campaign"));
    navigate("/campaigns/boost-product");
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      centered
      aria-labelledby="example-custom-modal-styling-title"
      className="initial_add_store_modal"
      style={{ width: "100% !important" }}
    >
      <Modal.Body className="plansModal">
        <img
          onClick={onHide}
          src="./assets/img/closeIcon.png"
          className="closeIcon icon-size"
          alt="close"
        />
        <div className="initial_add_store_main">
          <h1 className="header">Welcome to Boomio!</h1>
          <p style={{ marginBottom: "20px" }}>
            Here's a quick guide on how to start using Boomio:
          </p>
          <ul style={{ marginBottom: "20px" }}>
            <li>
              {" "}
              <p className="initial_add_store_bold_text">
                Connect your e-store
              </p>
            </li>
            <li>
              <p>
                Click on{" "}
                <b className="initial_add_store_bold_text">
                  'Start a campaign'
                </b>
              </p>
            </li>
            <li>
              <p>
                Follow easy steps to{" "}
                <b className="initial_add_store_bold_text">launch</b> campaign
              </p>
            </li>
            <li>
              <p>Head over to the dashboard to see results.</p>
            </li>
          </ul>
          <p style={{ marginBottom: "40px" }}>
            Curious about how gamification will look on your e-shop?
          </p>
          <div style={{ flexDirection: "row", width: "100%" }}>
            <Button
              onClick={handleTryPreviewClick}
              className="button-8559F3 boomio_input boomio_input_main mx-3"
            >
              Try Preview
            </Button>
            <Button onClick={handleStartCampaignClick} className="">
              Start campaign
            </Button>
            <Button className="button-8559F3 boomio-white mx-3"></Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddStore;

import React, { useState, useEffect, useRef } from "react";

const Slider = ({
  type,
  setValue1,
  setDiscountAmount,
  discountAmount,
  setCouponData,
}) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const itemHeight = 34; // Adjust the height of each number item
  const discountList = [5, 10, 15, 20, 30, 40, 50, 60, 70, 80, 90];

  const itemCount = discountList.length;

  const containerRef = useRef(null);

  useEffect(() => {
    const containerElement = containerRef.current;
    if (containerElement) {
      const containerHeight = containerElement.clientHeight;
      const visibleItems = Math.floor(containerHeight / itemHeight);
      const middleIndex = Math.floor(visibleItems / 2);
      const selectedIndex = discountList.indexOf(discountAmount);
      let scrollToIndex = selectedIndex - middleIndex;

      if (scrollToIndex < 0) {
        scrollToIndex = 0;
      } else if (scrollToIndex > itemCount - visibleItems) {
        scrollToIndex = itemCount - visibleItems;
      }

      scrollToPosition(containerElement, scrollToIndex * itemHeight, 300); // Smooth scroll to the position

      setScrollPosition(scrollToIndex);
    }
  }, [discountAmount]);

  useEffect(() => {
    const containerElement = containerRef.current;
    if (containerElement) {
      containerElement.scrollTop = scrollPosition * itemHeight;
    }
  }, [scrollPosition]);

  const handleScroll = (e) => {
    const scrollTop = e.target.scrollTop;
    let newPosition = Math.floor(scrollTop / itemHeight);

    if (newPosition < 0) {
      newPosition = 0;
    } else if (newPosition >= itemCount) {
      newPosition = itemCount - 1;
    }

    setScrollPosition(newPosition);
  };

  const handleNumberClick = (number) => {
    setDiscountAmount(number);
    setCouponData({
      params: {
        topTitle: `YOU GOT A ${number} ${type} DISCOUNT!`,
        couponCodeDiscount: ` ${number} ${type} `,
      },
    });
  };

  const generateNumberItems = () => {
    const items = [];
    const distanceThreshold = 2;
    const selectedDiscountIndex = discountList.indexOf(discountAmount);

    for (let i = 0; i < itemCount; i++) {
      const distance = Math.abs(i - selectedDiscountIndex);
      let opacity;

      if (distance <= distanceThreshold) {
        // Apply linear interpolation for opacity
        opacity = 1 - 0.2 * distance;
      } else if (
        i === selectedDiscountIndex - 1 ||
        i === selectedDiscountIndex + 1
      ) {
        // Opacity for the items right before and right after the selected item
        opacity = 0.9;
      } else {
        // Opacity for the items far away from the selected item
        opacity = 0.4;
      }

      items.push(
        <div
          key={i}
          className={`number ${
            discountAmount === discountList[i] ? "selected" : ""
          }`}
          style={{ top: `${i * itemHeight}px` }}
          onClick={() => {
            setValue1("rewardValue", discountList[i]);
            handleNumberClick(discountList[i]);
          }}
        >
          <div
            className={discountAmount === discountList[i] ? "selected-box" : ""}
          ></div>
          <div
            className={`number ${
              discountAmount === discountList[i] ? "selected" : ""
            }`}
            style={{ opacity, fontSize: "12px !important" }}
          >
            {discountList[i]}
            {type}
          </div>
        </div>
      );
    }
    return items;
  };

  const scrollToPosition = (element, to, duration) => {
    const start = element.scrollTop;
    const change = to - start;
    const increment = 20;

    let currentTime = 0;

    const animateScroll = function () {
      currentTime += increment;
      const val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };

    animateScroll();
  };

  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  return (
    <div className="selector_border">
      <div className="selector" onScroll={handleScroll} ref={containerRef}>
        <div
          className="scroller"
          style={{ height: `${itemHeight * itemCount}px` }}
        >
          {generateNumberItems()}
        </div>
      </div>
    </div>
  );
};

export default Slider;

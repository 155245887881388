import React from 'react';
import { Button, Modal } from 'react-bootstrap';


const ConfirmModal = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='create-coupons-modals confirm-modal'
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.children}
            </Modal.Body>
            <Modal.Footer>
                <a className='templat-img'></a>
                <div className=''>
                    <Button onClick={props.onHide} className='cancel-btn'>No, Cancel</Button>
                    <Button onClick={() => {
                        props.onConfirm()
                        props.onHide()
                    }} className='submit-btn'>Yes</Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmModal;

import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import Select from 'react-select'

const SetkpiPopup = (props) => {

    const colourOptions = [
        { value: 'SMS', label: 'SMS' },
        { value: 'Email', label: 'Email' },
        { value: 'App', label: 'App' }
    ]

    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='create-coupons-modals setkpipopu'
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Set KPI’s
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="setkpipopu-card">
                    <div className='d-lg-flex justify-content-between align-items-center'>
                        <div className='activeinput w-25 mt-3'>
                            <label htmlFor="" className='label-text mt-0'>Name <span style={{ color: "#FF3183" }}>*</span></label>
                            <input type="text" placeholder='Customers' />
                        </div>
                        <div className='activeinput w-25 mt-3'>
                            <label htmlFor="" className='label-text mt-0'>Quantity <span style={{ color: "#FF3183" }}>*</span> </label>
                            <input type="text" placeholder='1500' />
                        </div>
                        <div className='activeinput w-25 mt-3'>
                            <label htmlFor="" className='label-text mt-0'>Period  <span style={{ color: "#FF3183" }}>*</span> </label>
                            <Select
                                name="colors"
                                options={colourOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Daily"
                            />
                        </div>
                        <div className='switch-main mt-3'>
                            <label className="switch me-3">
                                <input type="checkbox" />
                                <span className="slider round"></span>
                            </label>
                            <p> KPI <br /> Enabled</p>
                        </div>
                    </div>
                </div>
                <div className="setkpipopu-card">
                    <div className='d-lg-flex justify-content-between align-items-center'>
                        <div className='activeinput w-25 mt-3'>
                            <label htmlFor="" className='label-text mt-0'>Name <span style={{ color: "#FF3183" }}>*</span></label>
                            <input type="text" placeholder='Repeat Purchase Rate' />
                        </div>
                        <div className='activeinput w-25 mt-3'>
                            <label htmlFor="" className='label-text mt-0'>Quantity <span style={{ color: "#FF3183" }}>*</span> </label>
                            <input type="text" placeholder='3500' />
                        </div>
                        <div className='activeinput w-25 mt-3'>
                            <label htmlFor="" className='label-text mt-0'>Period  <span style={{ color: "#FF3183" }}>*</span> </label>
                            <Select
                                name="colors"
                                options={colourOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Weekly"
                            />
                        </div>
                        <div className='switch-main mt-3'>
                            <label className="switch me-3">
                                <input type="checkbox" />
                                <span className="slider round"></span>
                            </label>
                            <p> KPI <br /> Enabled</p>
                        </div>
                    </div>
                </div>
                <div className="setkpipopu-card">
                    <div className='d-lg-flex justify-content-between align-items-center'>
                        <div className='activeinput w-25 mt-3'>
                            <label htmlFor="" className='label-text mt-0'>Name <span style={{ color: "#FF3183" }}>*</span></label>
                            <input type="text" placeholder='Repeat Customer Revenue' />
                        </div>
                        <div className='activeinput w-25 mt-3'>
                            <label htmlFor="" className='label-text mt-0'>Quantity <span style={{ color: "#FF3183" }}>*</span> </label>
                            <input type="text" placeholder='2500' />
                        </div>
                        <div className='activeinput w-25 mt-3'>
                            <label htmlFor="" className='label-text mt-0'>Period  <span style={{ color: "#FF3183" }}>*</span> </label>
                            <Select
                                name="colors"
                                options={colourOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Monthly"
                            />
                        </div>
                        <div className='switch-main mt-3'>
                            <label className="switch me-3">
                                <input type="checkbox" />
                                <span className="slider round"></span>
                            </label>
                            <p> KPI <br /> Enabled</p>
                        </div>
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <a className='templat-img'></a>
                <div className=''>
                    <Button onClick={props.onHide} className='cancel-btn'>Cancel</Button>
                    <Button onClick={() => { }} className='submit-btn'>Submit</Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default SetkpiPopup;

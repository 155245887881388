import Header from "layout/header/Header";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import headerIconNew from "../../assets/img/header-icon-new.png";
import { Divider, IconButton, Menu, MenuItem } from "@mui/material";
import { useDispatch } from "react-redux";
import { deleteTeaser, getTeaser } from "redux/features/teser/teaserSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import DeleteModal from "pages/componets/modals/DeleteModal";

const Teaser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [apiOrder, setApiOrder] = useState(-1);
  const [isCheck, setIsCheck] = useState([]);
  const [teserData, setTeserData] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [setonhoverCampaign] = useState(false);
  const [dropdownmanu, setdropdownmanu] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setdeleteId] = useState();
  const open = Boolean(anchorEl);

  const openMenu = (i) => {
    if (i === dropdownmanu) {
      setdropdownmanu();
    } else {
      setdropdownmanu(i);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickK = (event, i) => {
    setAnchorEl(event.currentTarget);
    openMenu(i);
  };

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(teserData.map((li) => li.cam_id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== parseInt(id)));
    } else {
      setIsCheck([...isCheck, parseInt(id)]);
    }
  };

  const getTeaserData = () => {
    dispatch(getTeaser())
      .then(unwrapResult)
      .then((res) => {
        setTeserData(res.response);
      });
  };

  useEffect(() => {
    getTeaserData();
  }, []);

  return (
    <>
      <Header title={"Teser"} />
      <div className="customer-table">
        <div className="table-bg">
          <div className="table-bg-inner">
            <div className="table-scroll">
              <div>
                <table>
                  <thead className="thead-bg">
                    <tr>
                      <th scope="col">
                        <div className="d-flex align-items-center">
                          <div className="d-flex">
                            <input
                              type="checkbox"
                              name="checkbox"
                              onChange={(e) => {
                                handleSelectAll(e);
                              }}
                              className="checkbox-input"
                              checked={isCheckAll}
                            />
                          </div>
                          <span
                            onClick={() => {
                              setApiOrder(apiOrder === -1 ? 0 : -1);
                            }}
                            className="headr-text"
                          >
                            Title
                          </span>
                          <img
                            src={headerIconNew}
                            alt=""
                            onClick={() => {
                              setApiOrder(apiOrder === -1 ? 0 : -1);
                            }}
                            className="icon-6"
                          />
                        </div>
                      </th>
                      <th scope="col">
                        <div
                          className="d-flex align-items-center"
                          onClick={() => {
                            setApiOrder(apiOrder === -1 ? 0 : -1);
                          }}
                        >
                          <span className="headr-text">Description</span>
                          <img src={headerIconNew} className="icon-6" alt="" />
                        </div>
                      </th>
                      <th scope="col">
                        <div
                          onClick={() => {
                            setApiOrder(apiOrder === -1 ? 0 : -1);
                          }}
                        >
                          <span className="headr-text">Offer Type</span>
                          <img src={headerIconNew} className="icon-6" alt="" />
                        </div>
                      </th>
                      <th scope="col">
                        <div
                          className="d-flex align-items-center"
                          onClick={() => {
                            setApiOrder(apiOrder === -1 ? 0 : -1);
                          }}
                        >
                          <span className="headr-text">Status</span>
                          <img src={headerIconNew} className="icon-6" alt="" />
                        </div>
                      </th>
                      <th scope="col">
                        <div
                          className="d-flex align-items-center"
                          onClick={() => {
                            setApiOrder(apiOrder === -1 ? 0 : -1);
                          }}
                        >
                          <span className="headr-text">Sort Order</span>
                          <img src={headerIconNew} className="icon-6" alt="" />
                        </div>
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {teserData?.length > 0 ? (
                      teserData.map((item, i) => {
                        return (
                          <tr>
                            <td>
                              <div className="d-flex align-items-center">
                                <input
                                  type="checkbox"
                                  name="checkbox"
                                  id={item.id}
                                  onChange={(e) => {
                                    handleClick(e);
                                  }}
                                  className="checkbox-input-body"
                                  checked={isCheck.includes(item.id)}
                                />
                                <div
                                  className="headr-text purple-black"
                                  onClick={() => {
                                    setonhoverCampaign(true);
                                  }}
                                >
                                  {item.title}
                                </div>
                              </div>
                            </td>
                            <td style={{ width: "" }}>
                              <span className="headr-text purple-black">
                                {item?.description}
                              </span>
                            </td>
                            <td style={{ width: "" }}>
                              <span className="headr-text purple-black">
                                {item?.offer_type}
                              </span>
                            </td>
                            <td>
                              <span className="headr-text purple-black">
                                {item?.status}
                              </span>
                            </td>
                            <td>
                              <span className="headr-text purple-black">
                                {item?.sort_order}
                              </span>
                            </td>
                            <td>
                              <IconButton
                                onClick={(e) => {
                                  handleClickK(e, i);
                                }}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={
                                  open ? "account-menu" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                              >
                                <img
                                  src="./assets/img/three-dot.png"
                                  alt=""
                                  height="18px"
                                />
                              </IconButton>
                              {dropdownmanu === i && (
                                <Menu
                                  anchorEl={anchorEl}
                                  id="account-menu"
                                  open={open}
                                  onClose={handleClose}
                                  onClick={handleClose}
                                  PaperProps={{
                                    elevation: 0,
                                    sx: {
                                      overflow: "visible",
                                      filter:
                                        "drop-shadow(0px 0px 1px #E1E1E1)",
                                      mt: 1.5,
                                      "& .MuiAvatar-root": {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                      },
                                      "&:before": {
                                        content: '""',
                                        display: "block",
                                        position: "absolute",
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: "background.paper",
                                        transform:
                                          "translateY(-50%) rotate(45deg)",
                                        zIndex: 0,
                                      },
                                    },
                                  }}
                                  transformOrigin={{
                                    horizontal: "right",
                                    vertical: "top",
                                  }}
                                  anchorOrigin={{
                                    horizontal: "right",
                                    vertical: "bottom",
                                  }}
                                >
                                  <MenuItem
                                    onClick={(e) => {
                                      navigate(`/teaser/add?id=${item.id}`);
                                    }}
                                  >
                                    <img
                                      src="./assets/img/Edit.png"
                                      alt=""
                                      className="me-2"
                                    />
                                    Edit
                                  </MenuItem>
                                  <Divider />
                                  <MenuItem
                                    onClick={(e) => {
                                      setDeleteModal(true);
                                      setdropdownmanu();
                                      setdeleteId(item?.id);
                                    }}
                                  >
                                    <img
                                      src="./assets/img/delete-icon.png"
                                      alt=""
                                      className="me-2"
                                    />
                                    delete
                                  </MenuItem>
                                </Menu>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="7">noDataFound</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {deleteModal && (
        <DeleteModal
          title={"Delete Teaser"}
          show={deleteModal}
          onHide={() => setDeleteModal(false)}
          onDelete={() => {
            dispatch(deleteTeaser(deleteId))
              .then(unwrapResult)
              .then((result) => {
                getTeaserData();
              })
              .catch((error) => {
                console.error(error);
              });
          }}
        />
      )}
    </>
  );
};

export default Teaser;

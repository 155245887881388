import React from 'react';
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";

const ConfirmDialog = (props) => {
    const { open,  onClose, img, boldText, text, cancelText, onCancel, actionText, onAction} = props;

    return (
      <Dialog onClose={onClose} open={open}>
      {/* <DialogTitle >  You can be anything you want to be  
      Just turn yourself into anything you think that you could ever be 
     Be free with your tempo, be free, be free
     Surrender your ego, be free, be free to yourself
          </DialogTitle> */}
      <img
        src={img}
        alt=""
        className="dialog-image"
            />
      <p className="dialog-text" style={{fontWeight: '600'}} >{boldText}</p>
      <p className="dialog-text" >{text}</p>
      <div className="dialog-buttons" >
        <button
          style={{ width: "150px", height: '50px'}}
          className={"outline_btn_dark btn btn-primary"}
          onClick={onCancel}
        >
          <span> {cancelText || 'Cancel' }</span>
        </button>
        <button
          style={{ width: "150px", height: '50px' }}
          className={"outline_btn_dark btn btn-primary"}
          onClick={onAction}
        >
          <span> {actionText || 'Continue'} </span>
        </button>
      </div>
    </Dialog>
          
    );
};

export default ConfirmDialog;
import React, { useState } from 'react'
import Sidebar from './sidebar/Sidebar'
import { Box } from '@mui/material';

const AuthLayout = ({ children }) => {
    return (
        <div className="sidebar_main">
            <Box sx={{ display: 'flex' }}>
                <Sidebar />
                <div className="children-bg">
                    {children}
                </div>
            </Box>
        </div >
    )
}

export default AuthLayout

import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import datepicker from "../../../assets/img/date-picker.png";
import moment from "moment/moment";
import RadioButton from "../../../common/RadioButton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import queryString from "query-string";
import { unwrapResult } from "@reduxjs/toolkit";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  createCampaigns,
  checkProductUrl,
  editCampaigns,
  getCampaignsById,
} from "redux/features/campaigns/campaignSlice";
import UserJourney from "../userJourney/UserJourney";
import select_arrow from "../../../assets/img/boomio_select_icon.png";
import { toast } from "react-toastify";
import NumberInputField from "common/NumberInputField";
import DateRangeSelect from "common/DateRangeSelect/DateRangeSelect";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import ReactGA from "react-ga4";

const BoostProduct = () => {
  const [open, setOpen] = React.useState(false);
  const [rewardType, setRewardType] = useState("FREE_SHIPPING");
  const [discountPercent, setDiscountPercent] = useState(50);
  const [discountAmount, setDiscountAmount] = useState(50);
  const [days, setDays] = useState("");
  const [selectedDay, setSelectedDay] = useState(1);
  const [dateRange, setDateRange] = useState({
    startDate: moment(new Date()).format("YYYY/MM/DD"),
    endDate: undefined,
  });
  const [campaignData, setCampaignData] = useState();
  const [isActive, setIsActive] = useState(true);
  const data = useSelector(
    (state) => state.authState.dashboardData?.shopify_params?.url
  );
  const [couponData, setCouponData] = useState({
    params: {
      topTitle: "YOU GOT A FREE SHIPPING!",
      couponCodeDiscount: `Free`,
      couponCodediscountText: "SHIPPING",
    },
  });
  const shopName = useSelector(
    (state) => state.authState.dashboardData?.shopify_params?.name
  );
  const query = queryString.parse(window.location.search);
  const id = query?.id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [focusedRange, setFocusedRange] = React.useState(null);

  const productRewardSchema = yup.object({
    productUrl1: yup
      .string()
      .required("Product URL is required.")
      .matches(
        /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
        "Enter valid product URL"
      ),
    productUrl2: yup
      .string()
      .required("Product URL is required.")
      .matches(
        /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
        "Enter valid product URL"
      ),
    productUrl3: yup
      .string()
      .required("Product URL is required.")
      .matches(
        /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
        "Enter valid product URL"
      ),
    productUrl4: yup
      .string()
      .required("Product URL is required.")
      .matches(
        /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
        "Enter valid product URL"
      ),
    campaignEndDate: yup.string().required("campaign duration is required"),
    discountDollar:
      rewardType === "DISCOUNT_CURRENCY" &&
      yup.string().required("Discount is required"),
    discountPercentage:
      rewardType === "DISCOUNT_PERCENTAGE" &&
      yup.string().required("Discount is required"),
  });
  const productRewardSchema1 = yup.object({});

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(productRewardSchema),
  });

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    setValue: setValue1,
    reset: reset1,
    formState: { errors: errors1 },
    clearErrors: clearErrors1,
  } = useForm({
    resolver: yupResolver(productRewardSchema1),
  });

  const toggle = (range) => {
    setFocusedRange(range);
    setOpen(!open);
  };

  const handlePreview = () => {
    if (data) {
      window.open(`${data}?demo=1`, "_blank");
    }
  };
  const getCampaignsData = async () => {
    await dispatch(getCampaignsById(id))
      .then(unwrapResult)
      .then((res) => {
        setIsActive(res?.response?.status === "inactive" ? false : true);
        setCouponData((prev) => {
          if (res?.response?.params?.selectRewardType1 === "FREE_SHIPPING") {
            return {
              ...prev,
              params: {
                ...prev?.params,
                topTitle: "YOU GOT A FREE SHIPPING!",
                couponCodeDiscount: `Free`,
                couponCodediscountText: "SHIPPING",
              },
            };
          } else if (
            res?.response?.params?.selectRewardType1 === "DISCOUNT_PERCENTAGE"
          ) {
            return {
              ...prev,
              params: {
                ...prev?.params,
                topTitle: `YOU GOT A ${
                  res?.response?.params?.rewardValue
                    ? res?.response?.params?.rewardValue
                    : 50
                }% DISCOUNT!`,
                couponCodeDiscount: `${
                  res?.response?.params?.rewardValue
                    ? res?.response?.params?.rewardValue
                    : 50
                }%`,
                couponCodediscountText: "Discount",
              },
            };
          } else if (
            res?.response?.params?.selectRewardType1 === "DISCOUNT_CURRENCY"
          ) {
            return {
              ...prev,
              params: {
                ...prev?.params,
                topTitle: `YOU GOT A $${
                  res?.response?.params?.rewardValue
                    ? res?.response?.params?.rewardValue
                    : 50
                } DISCOUNT!`,
                couponCodeDiscount: `$${
                  res?.response?.params?.rewardValue
                    ? res?.response?.params?.rewardValue
                    : 50
                }`,
                couponCodediscountText: "Discount",
              },
            };
          }
        });
        setRewardType(res?.response?.params?.rewardType);
        setDiscountPercent(
          res?.response?.params?.rewardValue
            ? res?.response?.params?.rewardValue
            : 50
        );
        setDiscountAmount(
          res?.response?.params?.rewardValue
            ? res?.response?.params?.rewardValue
            : 50
        );
        setDateRange({
          startDate: moment(res?.response?.campaign_start).format("YYYY/MM/DD"),
          endDate: moment(res?.response?.campaign_end).format("YYYY/MM/DD"),
        });
        setCampaignData(res?.response);
        if (res?.response?.params?.rewardExpirationDate >= 6) {
          setDays(res?.response?.params?.rewardExpirationDate);
          setSelectedDay(6);
        } else {
          setSelectedDay(res?.response?.params?.rewardExpirationDate);
        }
      })
      .catch((err) => {
        console.log("=====", err);
      });
  };

  const handleCampaignData = (payload, status) => {
    const productUrl =
      payload?.Specific_product_URL && payload?.Specific_product_URL;

    const returnData = {
      campaign_start:
        payload?.campaignStartDate !== ""
          ? payload?.campaignStartDate
          : undefined,
      campaign_end:
        payload?.campaignEndDate !== "" ? payload?.campaignEndDate : undefined,
      params: {
        campaignPeriod:
          payload?.campaignPeriod && parseInt(payload?.campaignPeriod),
        rewardExpirationDate: selectedDay <= 5 ? selectedDay : days ? days : 1,
        numberOfDays: payload?.numberOfDays && parseInt(payload?.numberOfDays),
        productUrl1: payload?.productUrl1 && payload?.productUrl1,
        productUrl2: payload?.productUrl2 && payload?.productUrl2,
        productUrl3: payload?.productUrl3 && payload?.productUrl3,
        productUrl4: payload?.productUrl4 && payload?.productUrl4,
        rewardType: rewardType,
        rewardValue: discountAmount,
        campaignLaunchDate: payload?.campaignLaunchDate,
      },
      id: id && id,
      campaign_type: "boost_specific_product",
      status,
    };
    const productUrls = [
      returnData.params.productUrl1,
      returnData.params.productUrl2,
      returnData.params.productUrl3,
      returnData.params.productUrl4,
    ];

    let urlErrorOccurred = false;

    const checkUrls = async () => {
      for (const url of productUrls) {
        if (!urlErrorOccurred) {
          const urlCheckData = {
            url: url,
          };
          const { payload } = await dispatch(checkProductUrl(urlCheckData));
          if (payload.isError) {
            urlErrorOccurred = true;
            toast.error(`Campaign already exists with provided url: ${url}`, {
              closeOnClick: true,
              pauseOnHover: true,
            });
            break;
          }
        }
      }

      if (!urlErrorOccurred) {
        startCampaigns();
      }
    };

    checkUrls();
    async function startCampaigns() {
      try {
        const action = id
          ? editCampaigns({ id, ...returnData })
          : createCampaigns(returnData);
        const response = await dispatch(action);
        if (response.payload?.isError) {
          throw response.payload;
        }
        ReactGA.event({
          action: "Launched campaign",
          category: "General, on selfservice",
          value: "",
        });
        navigate("/campaigns");
      } catch (error) {
        console.log("Error:", error);
      }
    }
  };

  useEffect(() => {
    var defaultResetValues = {};
    defaultResetValues = {
      ...defaultResetValues,
      selectRewardType1: campaignData?.params?.selectRewardType1,
    };
    defaultResetValues = {
      ...defaultResetValues,
      rewardValue: campaignData?.params?.rewardValue,
    };
    defaultResetValues = {
      ...defaultResetValues,
      rewardValue: campaignData?.params?.rewardValue,
    };
    defaultResetValues = {
      ...defaultResetValues,
      productUrl1: campaignData?.params?.productUrl1,
    };
    defaultResetValues = {
      ...defaultResetValues,
      productUrl2: campaignData?.params?.productUrl2,
    };
    defaultResetValues = {
      ...defaultResetValues,
      productUrl3: campaignData?.params?.productUrl3,
    };
    defaultResetValues = {
      ...defaultResetValues,
      productUrl4: campaignData?.params?.productUrl4,
    };
    defaultResetValues = {
      ...defaultResetValues,
      numberOfDays: campaignData?.params?.numberOfDays,
    };
    defaultResetValues = {
      ...defaultResetValues,
      rewardExpirationDate:
        campaignData?.params?.rewardExpirationDate ?? selectedDay,
    };
    defaultResetValues = {
      ...defaultResetValues,
      rewardExpirationDay: campaignData?.params?.rewardExpirationDay ?? days,
    };
    defaultResetValues = {
      ...defaultResetValues,
      campaignPeriod: campaignData?.params?.campaignPeriod,
    };
    defaultResetValues = {
      ...defaultResetValues,
      campaignLaunchDate: campaignData?.params?.campaignLaunchDate,
    };
    defaultResetValues = {
      ...defaultResetValues,
      campaignStartDate: campaignData?.campaign_start,
    };
    defaultResetValues = {
      ...defaultResetValues,
      campaignEndDate: campaignData?.campaign_end,
    };
    reset(defaultResetValues);
    reset1(defaultResetValues);
  }, [campaignData]);

  useEffect(() => {
    var defaultResetValues = {};
    if (rewardType === "DISCOUNT_PERCENTAGE") {
      defaultResetValues = {
        ...defaultResetValues,
        rewardValue: discountPercent,
      };
    }
    if (rewardType === "DISCOUNT_CURRENCY") {
      defaultResetValues = {
        ...defaultResetValues,
        rewardValue: discountAmount,
      };
    }
    defaultResetValues = {
      ...defaultResetValues,
      selectRewardType1: rewardType,
    };
    setValue("rewardValue", discountPercent);
    setValue1("rewardValue", discountPercent);
    setValue("rewardValue", discountAmount);
    setValue1("rewardValue", discountAmount);
    setValue("selectRewardType1", rewardType);
    setValue1("selectRewardType1", rewardType);
  }, [rewardType, discountPercent, discountAmount]);

  useEffect(() => {
    if (id) {
      getCampaignsData();
    }
  }, [id]);

  const dateRangeChange = (ranges) => {
    if (ranges && ranges.range1.startDate && ranges.range1.endDate) {
      const startDate = moment(ranges.range1?.startDate).format("YYYY/MM/DD");
      const endDate = moment(ranges.range1?.endDate).format("YYYY/MM/DD");
      setValue("campaignStartDate", startDate);
      setValue1("campaignStartDate", startDate);
      setValue("campaignEndDate", endDate);
      setValue1("campaignEndDate", endDate);
      ranges.range1?.endDate && clearErrors("campaignEndDate");
      ranges.range1?.endDate && clearErrors1("campaignEndDate");
      setDateRange({ startDate, endDate });
    }
  };

  return (
    <>
      <div className="campaigns-heade" style={{ justifyContent: "start" }}>
        {" "}
        <IconButton
          onClick={() => {
            id
              ? navigate("/campaigns")
              : navigate("/campaigns/start-first-campaign");
          }}
          style={{
            marginRight: "12px",
            border: "1px solid #b0afbe",
            padding: "4px",
            marginBottom: "16px",
          }}
        >
          <ArrowBackIcon
            style={{ width: "30px", height: "30px", marginLeft: "3px" }}
          />
        </IconButton>
        <div>
          <h1 className="page-header">Boost specific product discovery</h1>
          <p className="sub_title">
            User finds a reward next to the product you want to draw the user's
            attention to
          </p>
        </div>
      </div>
      <Row className="mt-3">
        <Col xxl={8} xl={7} lg={12}>
          <div className="product-design-bg">
            <h2 style={{ marginBottom: "20px" }}>Campaign details</h2>
            <div className="d-sm-flex flex-column justify-content-between">
              <div className="w-51">
                <div className="mt-4 mb-2">
                  <label htmlFor="" className="input-bold">
                    Select a reward type *
                  </label>
                </div>
                <div>
                  <RadioButton
                    checked={rewardType === "FREE_SHIPPING"}
                    text={"Free shipping"}
                    id={"option1"}
                    value={"FREE_SHIPPING"}
                    {...register("selectRewardType1")}
                    {...register1("selectRewardType1")}
                    ref={null}
                    onChange={() => {
                      setCouponData((prev) => ({
                        ...prev,
                        params: {
                          ...prev?.params,
                          topTitle: "YOU GOT A FREE SHIPPING!",
                          couponCodeDiscount: `Free`,
                          couponCodediscountText: "SHIPPING",
                        },
                      }));
                      setValue("selectRewardType1", "FREE_SHIPPING");
                      setValue1("selectRewardType1", "FREE_SHIPPING");
                      setRewardType("FREE_SHIPPING");
                    }}
                  />
                  <RadioButton
                    checked={rewardType === "DISCOUNT_PERCENTAGE"}
                    text={"Discount %"}
                    id={"option2"}
                    value={"DISCOUNT_PERCENTAGE"}
                    {...register("selectRewardType1")}
                    {...register1("selectRewardType1")}
                    ref={null}
                    onChange={() => {
                      setCouponData((prev) => ({
                        ...prev,
                        params: {
                          ...prev?.params,
                          topTitle: `YOU GOT A ${discountPercent}% DISCOUNT!`,
                          couponCodeDiscount: `${discountPercent}%`,
                          couponCodediscountText: "Discount",
                        },
                      }));
                      setValue("selectRewardType1", "DISCOUNT_PERCENTAGE");
                      setValue1("selectRewardType1", "DISCOUNT_PERCENTAGE");
                      setRewardType("DISCOUNT_PERCENTAGE");
                    }}
                  />
                  <RadioButton
                    checked={rewardType === "DISCOUNT_CURRENCY"}
                    text={"Discount $"}
                    id={"option3"}
                    value={"DISCOUNT_CURRENCY"}
                    {...register("selectRewardType1")}
                    {...register1("selectRewardType1")}
                    ref={null}
                    onChange={() => {
                      setCouponData((prev) => ({
                        ...prev,
                        params: {
                          ...prev?.params,
                          topTitle: `YOU GOT A $${discountAmount} DISCOUNT!`,
                          couponCodeDiscount: `$${discountAmount}`,
                          couponCodediscountText: "Discount",
                        },
                      }));
                      setValue("selectRewardType1", "DISCOUNT_CURRENCY");
                      setValue1("selectRewardType1", "DISCOUNT_CURRENCY");
                      setRewardType("DISCOUNT_CURRENCY");
                    }}
                  />
                </div>
              </div>
              {rewardType === "DISCOUNT_PERCENTAGE" && (
                <Row>
                  <Col lg={8} md={8} sm={12}>
                    <div className="">
                      <div className="mt-4 mb-2 w-100">
                        <label htmlFor="" className="input-bold">
                          Select a reward type *
                        </label>
                      </div>
                      <div>
                        <RadioButton
                          checked={discountPercent === 50}
                          text={"50%"}
                          id={"option1"}
                          value={50}
                          {...register("rewardValue")}
                          {...register1("rewardValue")}
                          ref={null}
                          onChange={() => {
                            setCouponData((prev) => ({
                              ...prev,
                              params: {
                                ...prev?.params,
                                topTitle: "YOU GOT A 50% DISCOUNT!",
                                couponCodeDiscount: `50%`,
                              },
                            }));
                            setValue("rewardValue", 50);
                            setValue1("rewardValue", 50);
                            setDiscountPercent(50);
                          }}
                          img={null}
                        />
                        <RadioButton
                          checked={discountPercent === 30}
                          text={"30%"}
                          id={"option2"}
                          value={30}
                          {...register("rewardValue")}
                          {...register1("rewardValue")}
                          ref={null}
                          onChange={() => {
                            setCouponData((prev) => ({
                              ...prev,
                              params: {
                                ...prev?.params,
                                topTitle: "YOU GOT A 30% DISCOUNT!",
                                couponCodeDiscount: `30%`,
                              },
                            }));
                            setValue("rewardValue", 30);
                            setValue1("rewardValue", 30);
                            setDiscountPercent(30);
                          }}
                          img={null}
                        />
                        <RadioButton
                          checked={discountPercent === 20}
                          text={"20%"}
                          id={"option3"}
                          value={20}
                          {...register("rewardValue")}
                          {...register1("rewardValue")}
                          ref={null}
                          onChange={() => {
                            setCouponData((prev) => ({
                              ...prev,
                              params: {
                                ...prev?.params,
                                topTitle: "YOU GOT A 20% DISCOUNT!",
                                couponCodeDiscount: `20%`,
                              },
                            }));
                            setValue("rewardValue", 20);
                            setValue1("rewardValue", 20);
                            setDiscountPercent(20);
                          }}
                          img={null}
                        />
                        <RadioButton
                          checked={discountPercent === 15}
                          text={"15%"}
                          id={"option4"}
                          value={15}
                          {...register("rewardValue")}
                          {...register1("rewardValue")}
                          ref={null}
                          onChange={() => {
                            setCouponData((prev) => ({
                              ...prev,
                              params: {
                                ...prev?.params,
                                topTitle: "YOU GOT A 15% DISCOUNT!",
                                couponCodeDiscount: `15%`,
                              },
                            }));
                            setValue("rewardValue", 15);
                            setValue1("rewardValue", 15);
                            setDiscountPercent(15);
                          }}
                          img={null}
                        />
                        <RadioButton
                          checked={discountPercent === 10}
                          text={"10%"}
                          id={"option5"}
                          value={10}
                          {...register("rewardValue")}
                          {...register1("rewardValue")}
                          ref={null}
                          onChange={() => {
                            setCouponData((prev) => ({
                              ...prev,
                              params: {
                                ...prev?.params,
                                topTitle: "YOU GOT A 10% DISCOUNT!",
                                couponCodeDiscount: `10%`,
                              },
                            }));
                            setValue("rewardValue", 10);
                            setValue1("rewardValue", 10);
                            setDiscountPercent(10);
                          }}
                          img={null}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} md={4} sm={12}>
                    <div className="">
                      <div className="mt-4 mb-2">
                        <label htmlFor="" className="input-bold">
                          or enter a discount
                        </label>
                      </div>
                      <div className="boomio_input_main">
                        <input
                          className="boomio_input"
                          type="number"
                          placeholder="33%"
                          aria-label="default input example"
                          value={discountPercent >= 0 ? discountPercent : 0}
                          {...register("discountPercentage")}
                          // {...register1('discountPercentage')}
                          onChange={(e) => {
                            setCouponData((prev) => ({
                              ...prev,
                              params: {
                                ...prev?.params,
                                topTitle: `YOU GOT A $${e?.target?.value} DISCOUNT!`,
                                couponCodeDiscount: `$${e?.target?.value}`,
                              },
                            }));
                            setDiscountPercent(e?.target?.value);
                          }}
                        />
                        <input
                          type={"hidden"}
                          value={discountPercent >= 0 ? discountPercent : 0}
                          {...register1("discountPercentage")}
                        />
                      </div>
                      {errors?.discountPercentage && (
                        <span className="campaings_error">
                          {errors?.discountPercentage?.message}
                        </span>
                      )}
                    </div>
                  </Col>
                </Row>
              )}
              {rewardType === "DISCOUNT_CURRENCY" && (
                <Row>
                  <Col lg={8} md={8} sm={12}>
                    <div className="">
                      <div className="mt-4 mb-2 w-100">
                        <label htmlFor="" className="input-bold">
                          Select a reward type *
                        </label>
                      </div>
                      <div>
                        <RadioButton
                          checked={discountAmount === 50}
                          text={"$50"}
                          id={"option1"}
                          value={50}
                          {...register("rewardValue")}
                          {...register1("rewardValue")}
                          ref={null}
                          onChange={() => {
                            setCouponData((prev) => ({
                              ...prev,
                              params: {
                                ...prev?.params,
                                topTitle: "YOU GOT A $50 DISCOUNT!",
                                couponCodeDiscount: `$50`,
                              },
                            }));
                            setDiscountAmount(50);
                          }}
                          img={null}
                        />
                        <RadioButton
                          checked={discountAmount === 30}
                          text={"$30"}
                          id={"option2"}
                          value={30}
                          {...register("rewardValue")}
                          {...register1("rewardValue")}
                          ref={null}
                          onChange={() => {
                            setCouponData((prev) => ({
                              ...prev,
                              params: {
                                ...prev?.params,
                                topTitle: "YOU GOT A $30 DISCOUNT!",
                                couponCodeDiscount: `$30`,
                              },
                            }));
                            setDiscountAmount(30);
                          }}
                          img={null}
                        />
                        <RadioButton
                          checked={discountAmount === 20}
                          text={"$20"}
                          id={"option3"}
                          value={20}
                          {...register("rewardValue")}
                          {...register1("rewardValue")}
                          ref={null}
                          onChange={() => {
                            setCouponData((prev) => ({
                              ...prev,
                              params: {
                                ...prev?.params,
                                topTitle: "YOU GOT A $20 DISCOUNT!",
                                couponCodeDiscount: `$20`,
                              },
                            }));
                            setDiscountAmount(20);
                          }}
                          img={null}
                        />
                        <RadioButton
                          checked={discountAmount === 15}
                          text={"$15"}
                          id={"option4"}
                          value={15}
                          {...register("rewardValue")}
                          {...register1("rewardValue")}
                          ref={null}
                          onChange={() => {
                            setCouponData((prev) => ({
                              ...prev,
                              params: {
                                ...prev?.params,
                                topTitle: "YOU GOT A $15 DISCOUNT!",
                                couponCodeDiscount: `$15`,
                              },
                            }));
                            setDiscountAmount(15);
                          }}
                          img={null}
                        />
                        <RadioButton
                          checked={discountAmount === 10}
                          text={"$10"}
                          id={"option5"}
                          value={10}
                          {...register("rewardValue")}
                          {...register1("rewardValue")}
                          ref={null}
                          onChange={() => {
                            setCouponData((prev) => ({
                              ...prev,
                              params: {
                                ...prev?.params,
                                topTitle: "YOU GOT A $10 DISCOUNT!",
                                couponCodeDiscount: `$10`,
                              },
                            }));
                            setDiscountAmount(10);
                          }}
                          img={null}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} md={4} sm={12}>
                    <div className="">
                      <div className="mt-4 mb-2">
                        <label htmlFor="" className="input-bold">
                          or enter a discount
                        </label>
                      </div>
                      <div className="boomio_input_main">
                        <input
                          className="boomio_input"
                          type="number"
                          placeholder="13"
                          aria-label="default input example"
                          value={discountAmount >= 0 ? discountAmount : 0}
                          {...register("discountDollar")}
                          // {...register1('discountDollar')}
                          onChange={(e) => {
                            setCouponData((prev) => ({
                              ...prev,
                              params: {
                                ...prev?.params,
                                topTitle: `YOU GOT A $${e?.target?.value} DISCOUNT!`,
                                couponCodeDiscount: `$${e?.target?.value}`,
                              },
                            }));
                            setDiscountAmount(e?.target?.value);
                          }}
                        />
                        <input
                          type={"hidden"}
                          value={discountPercent >= 0 ? discountPercent : 0}
                          {...register1("discountDollar")}
                        />
                      </div>
                      {errors?.discountDollar && (
                        <span className="campaings_error">
                          {errors?.discountDollar?.message}
                        </span>
                      )}
                    </div>
                  </Col>
                </Row>
              )}
              <div className="w-100 d-flex flex-wrap justify-content-between align-items-end">
                <div className="w-45">
                  <div className="mt-4 mb-2">
                    <label htmlFor="" className="input-bold">
                      1st product URL
                    </label>
                    <h3>This is where the 1st puzzle piece will appear</h3>
                  </div>
                  <div className="boomio_input_main">
                    <input
                      className="boomio_input"
                      type="text"
                      {...register("productUrl1")}
                      {...register1("productUrl1")}
                      placeholder="https://"
                      onChange={(e) => {
                        setValue("productUrl1", e.target.value);
                        setValue1("productUrl1", e.target.value);
                      }}
                      defaultValue={campaignData?.params?.productUrl1}
                      aria-label="default input example"
                    />
                  </div>
                  {errors.productUrl1 && (
                    <span className="campaings_error">
                      {errors?.productUrl1?.message}
                    </span>
                  )}
                </div>
                <div className="w-45">
                  <div className="mt-4 mb-2">
                    <label htmlFor="" className="input-bold">
                      2nd product URL
                    </label>
                  </div>
                  <div className="boomio_input_main">
                    <input
                      className="boomio_input"
                      type="text"
                      {...register("productUrl2")}
                      {...register1("productUrl2")}
                      placeholder="https://"
                      onChange={(e) => {
                        setValue("productUrl2", e.target.value);
                        setValue1("productUrl2", e.target.value);
                      }}
                      defaultValue={campaignData?.params?.productUrl2}
                      aria-label="default input example"
                    />
                  </div>
                  {errors.productUrl2 && (
                    <span className="campaings_error">
                      {errors?.productUrl2?.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="w-100 d-flex flex-wrap justify-content-between align-items-end">
                <div className="w-45">
                  <div className="mt-4 mb-2">
                    <label htmlFor="" className="input-bold">
                      3rd product URL
                    </label>
                    <h3>This is where the 3st puzzle piece will appear</h3>
                  </div>
                  <div className="boomio_input_main">
                    <input
                      className="boomio_input"
                      type="text"
                      {...register("productUrl3")}
                      {...register1("productUrl3")}
                      placeholder="https://"
                      onChange={(e) => {
                        setValue("productUrl3", e.target.value);
                        setValue1("productUrl3", e.target.value);
                      }}
                      defaultValue={campaignData?.params?.productUrl3}
                      aria-label="default input example"
                    />
                  </div>
                  {errors.productUrl3 && (
                    <span className="campaings_error">
                      {errors?.productUrl3?.message}
                    </span>
                  )}
                </div>
                <div className="w-45">
                  <div className="mt-4 mb-2">
                    <label htmlFor="" className="input-bold">
                      4th product URL
                    </label>
                  </div>
                  <div className="boomio_input_main">
                    <input
                      className="boomio_input"
                      type="text"
                      {...register("productUrl4")}
                      {...register1("productUrl4")}
                      placeholder="https://"
                      onChange={(e) => {
                        setValue("productUrl4", e.target.value);
                        setValue1("productUrl4", e.target.value);
                      }}
                      defaultValue={campaignData?.params?.productUrl4}
                      aria-label="default input example"
                    />
                  </div>
                  {errors.productUrl4 && (
                    <span className="campaings_error">
                      {errors?.productUrl4?.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="w-100 d-flex flex-wrap justify-content-between">
                <div className="w-40">
                  <div className="mt-4 mb-2">
                    <label htmlFor="" className="input-bold">
                      Select a reward expiration date *
                    </label>
                  </div>
                  <div className="boomio_input_main w-50">
                    <img src={select_arrow} alt="" className="select_img" />
                    <select
                      className="boomio_input boomio_select"
                      aria-label="Default select example"
                      {...register("rewardExpirationDate")}
                      {...register1("rewardExpirationDate")}
                      onChange={(e) => {
                        setSelectedDay(parseInt(e.target.value));
                        if (parseInt(e.target.value))
                          setValue("rewardExpirationDate", e.target.value);
                        setValue1("rewardExpirationDate", e.target.value);
                      }}
                      value={selectedDay}
                    >
                      <option value={1}>1 Day</option>
                      <option value={2}>2 Day</option>
                      <option value={3}>3 Day</option>
                      <option value={4}>4 Day</option>
                      <option value={5}>5 Day</option>
                      <option value={6}>Custom</option>
                    </select>
                  </div>
                </div>
                <div className="w-40">
                  <div className="mt-4 mb-2">
                    <label htmlFor="" className="input-bold">
                      or enter a number of days
                    </label>
                  </div>
                  <div className="boomio_input_main">
                    <NumberInputField
                      {...register("numberOfDays")}
                      {...register1("numberOfDays")}
                      disabled={selectedDay <= 5}
                      ref={null}
                      value={days}
                      setdays={setDays}
                    />
                  </div>
                </div>
              </div>
              <div className="w-100 d-flex flex-wrap justify-content-between">
                <div>
                  <div className="set-date-picker first-time-reward mb-2">
                    <label htmlFor="" className="label-text input-bold">
                      Campaign duration *
                    </label>

                    <div className="d-flex flex-wrap gap-2 mt-7">
                      <div className="boomio_input_main w-md-100">
                        <div
                          className="boomio_input boomio_datepicker"
                          style={{ position: "relative" }}
                          onClick={() => toggle([0, 0])}
                          {...register("campaignStartDate")}
                          {...register1("campaignStartDate")}
                          // {...register('campaignEndDate')}
                        >
                          <input
                            type={"hidden"}
                            {...register1("campaignStartDate")}
                          />
                          {`${
                            dateRange?.startDate
                              ? dateRange?.startDate
                              : "Start Date"
                          }
                        `}
                          {/* - ${dateRange?.endDate ? dateRange?.endDate : "End Date"}  */}
                          <img
                            src={datepicker}
                            alt=""
                            onClick={() => toggle([0, 0])}
                          />
                        </div>
                      </div>
                      <div className="boomio_input_main w-md-100">
                        <div
                          className="boomio_input boomio_datepicker"
                          style={{ position: "relative" }}
                          onClick={() => toggle([0, 1])}
                          // {...register('campaignStartDate')}
                          {...register("campaignEndDate")}
                          // {...register1('campaignEndDate')}
                        >
                          <input
                            type={"hidden"}
                            {...register1("campaignEndDate")}
                          />
                          {`
                        ${
                          dateRange?.endDate ? dateRange?.endDate : "End Date"
                        } `}
                          {/* ${dateRange?.startDate ? dateRange?.startDate : "Start Date"} -  */}
                          <img
                            src={datepicker}
                            alt=""
                            onClick={() => toggle([0, 1])}
                          />
                        </div>
                      </div>
                    </div>
                    <DateRangeSelect
                      startDate={dateRange?.startDate}
                      endDate={dateRange?.endDate}
                      open={open}
                      setOpen={setOpen}
                      dateRangeChange={dateRangeChange}
                      focusedRange={focusedRange}
                      setFocusedRange={setFocusedRange}
                    />
                  </div>
                  {errors?.campaignEndDate && (
                    <span className="campaings_error">
                      {errors?.campaignEndDate?.message}
                    </span>
                  )}
                </div>
                {campaignData?.status !== "draft" && id && (
                  <div className="status_switch w-40">
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label="Status"
                      checked={isActive}
                      onChange={() => setIsActive(!isActive)}
                    />
                  </div>
                )}
              </div>
              <div className="mt-5 d-flex flex-wrap gap-2 align-items-center">
                <Button
                  className="outline_btn_dark me-2"
                  onClick={() => {
                    handlePreview();
                  }}
                >
                  <span>Preview</span>
                </Button>
                {(!id || campaignData?.status === "draft") && (
                  <Button
                    className="outline_btn_dark me-2"
                    onClick={handleSubmit1((data) => {
                      handleCampaignData(data, "draft");
                    })}
                  >
                    <span>Save draft</span>
                  </Button>
                )}
                {id && campaignData?.status !== "draft" ? (
                  <Button
                    className="outline_btn_dark me-2"
                    onClick={handleSubmit((data) => {
                      handleCampaignData(
                        data,
                        isActive ? "active" : "inactive"
                      );
                    })}
                  >
                    <span>Save</span>
                  </Button>
                ) : (
                  <Button
                    className="outline_btn_dark me-2"
                    onClick={handleSubmit((data) => {
                      if (shopName) {
                        handleCampaignData(data, "active");
                      } else {
                        toast.error("Connect your shop, please", {
                          closeOnClick: true,
                          pauseOnHover: true,
                        });
                      }
                    })}
                  >
                    <span>Launch</span>
                  </Button>
                )}
                <span className="boomio-gray boomio-small">
                  &nbsp;*You can cancel this campaign at anytime in “Campaigns”
                  section.
                </span>
              </div>
            </div>
          </div>
        </Col>
        <Col xxl={4} xl={5} lg={12}>
          <UserJourney
            from="SpecificProductReward"
            title1={
              "1. After 10 sec. of e-shop browsing, the first piece of puzzle appears on the screen which user is viewing. User starts playing a game by clicking on it."
            }
            title2={
              "2. After receiving each puzzle piece, user gets hints about the location of the remaining pieces. Puzzle pieces are located next to your selected products."
            }
            title3={
              "3. After the game's completion a pop-up with a reward appears."
            }
            couponData={couponData}
          />
        </Col>
      </Row>
    </>
  );
};

export default BoostProduct;

import React, { useEffect, useState } from "react";
import ContinueButton from "common/Button";
import Error from "../../../assets/img/error-icon.svg";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { patchBusinessData } from "redux/features/business/businessSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

const Customers = () => {
  const dispatch = useDispatch();
  const businessState = useSelector((state) => state.businessState.data);

  const initialErrorVal = {
    new: false,
    loyal: false,
    cold: false,
    active: false,
  };
  const [errorNew, setErrorNew] = useState(initialErrorVal);
  const [editColor, setEditColor] = useState(initialErrorVal);
  const [initialSegment, setInitialSegment] = useState({
    new: 10,
    loyal: 2,
    cold: 90,
    active: 90
  });
  const defaultSegment = {
    new: 10,
    loyal: 2,
    cold: 90,
    active: 90
  };
  useEffect(() => {
    if (businessState && businessState.params && businessState.params.segmentation) {
      setInitialSegment(businessState.params.segmentation)
    }
  }, [businessState]);

  const checkErrors = (e) => {
    const name = e.target.name;
    const value = parseInt(e.target.value);
    switch (name) {
      case "new":
        if (7 <= value && value <= 14) {
          setErrorNew({ ...errorNew, new: false });
        } else {
          setErrorNew({ ...errorNew, new: true });
        }
        break;
      case "active":
        if (30 <= value && value <= 150) {
          setErrorNew({ ...errorNew, active: false });
        } else {
          setErrorNew({ ...errorNew, active: true });
        }
        break;
      case "loyal":
        if (2 <= value && value <= 5) {
          setErrorNew({ ...errorNew, loyal: false });
        } else {
          setErrorNew({ ...errorNew, loyal: true });
        }
        break;
      case "cold":
        if (30 <= value && value <= 150) {
          setErrorNew({ ...errorNew, cold: false });
        } else {
          setErrorNew({ ...errorNew, cold: true });
        }
        break;
      default:
        setErrorNew({ ...errorNew });
        break;
    }
  };

  const resetFormValues = () => {
    formik.setValues(defaultSegment);
    setErrorNew(initialErrorVal);
  };

  const formik = useFormik({
    initialValues: initialSegment,
    validationSchema: Yup.object().shape({
      new: Yup.string().required("new is required"),
      loyal: Yup.string().required("loyal is required"),
      cold: Yup.string().required("cold is required"),
      active: Yup.string().required("active is required"),
    }),
    enableReinitialize: true,
    onSubmit: async (val) => {
      const body = {
        params: {
          ...businessState.params,
          segmentation: val,
        },
      };

      dispatch(patchBusinessData(body)).then(unwrapResult);
    },
  });

  const { errors, touched, values } = formik;

  const editColorChange = (e) => {
    const name = e.target.name;
    const value = parseInt(e.target.value);
    switch (name) {
      case "new":
        if (value === initialSegment.new) {
          setEditColor({ ...editColor, new: false });
        } else {
          setEditColor({ ...editColor, new: true });
        }
        break;
      case "active":
        if (value === initialSegment.active) {
          setEditColor({ ...editColor, active: false });
        } else {
          setEditColor({ ...editColor, active: true });
        }
        break;
      case "loyal":
        if (value === initialSegment.loyal) {
          setEditColor({ ...editColor, loyal: false });
        } else {
          setEditColor({ ...editColor, loyal: true });
        }
        break;
      case "cold":
        if (value === initialSegment.cold) {
          setEditColor({ ...editColor, cold: false });
        } else {
          setEditColor({ ...editColor, cold: true });
        }
        break
      default: // do nothing
    }
  }

  const checkChangedVal = () => {
    if (values?.active !== initialSegment.active) {
      return true;
    } else if (values?.cold !== initialSegment.cold) {
      return true;
    } else if (values?.loyal !== initialSegment.loyal) {
      return true;
    } else if (values?.new !== initialSegment.new) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <div className="customer-new">
          <h1>Segment descriptions</h1>
          <h6>
            Edit the customer segment descriptions which will appear in your
            customers’ tab
          </h6>
          <div className="Segment-details">
            <div className="segment-tag segment-new">
              <div className="segment-tag-bg">
                <span>New</span>
              </div>
            </div>
            <div className="d-flex align-items-center">
              Joined in past
              <div className="gredianBorder">
                <Form.Control
                  type="number"
                  name="new"
                  className={`input-value-text ${editColor.new ? "newEDitColor" : ""}`}
                  value={formik?.values?.new}
                  onChange={(e) => {
                    formik.handleChange(e);
                    editColorChange(e)
                    checkErrors(e);
                  }}
                  onBlur={formik.handleBlur}
                />
              </div>
              days
            </div>
            {errors.new ? (
              <div className="modal-text-mute">
                {touched.new && <img src={Error} alt="error" />}
                <span>{touched.new && errors.new}</span>
              </div>
            ) : errorNew.new ? (
              <div className="modal-text-mute">
                <img src={Error} alt="error" />
                <span>The number must be between 7 and 14.</span>
              </div>
            ) : null}
          </div>
          <div className="Segment-details">
            <div className="segment-tag segment-active">
              <div className="segment-tag-bg">
                <span>Active</span>
              </div>
            </div>
            <div className="d-flex align-items-center">
              Have made a purchase (used a reward) in the past
              <Form.Control
                type="number"
                name="active"
                className={`input-value-text ${editColor.active ? "activeEDitColor" : ""}`}
                value={formik.values?.active}
                onChange={(e) => {
                  formik.handleChange(e);
                  editColorChange(e)
                  checkErrors(e);
                }}
                onBlur={formik.handleBlur}
              />
              days
            </div>

            {errors.active ? (
              <div className="modal-text-mute">
                {touched.active && <img src={Error} alt="error" />}
                <span>{touched.active && errors.active}</span>
              </div>
            ) : errorNew.active ? (
              <div className="modal-text-mute">
                <img src={Error} alt="error" />
                <span>The number must be between 30 and 150.</span>
              </div>
            ) : null}
          </div>

          <div className="Segment-details">
            <div className="segment-tag segment-loyal">
              <div className="segment-tag-bg">
                <span>Loyal</span>
              </div>
            </div>
            <div className="d-flex align-items-center">
              Received and used at least
              <Form.Control
                type="number"
                name="loyal"
                className={`input-value-text ${editColor.loyal ? "loyalEDitColor" : ""}`}
                value={formik.values?.loyal}
                onChange={(e) => {
                  formik.handleChange(e);
                  editColorChange(e)
                  checkErrors(e);
                }}
                onBlur={formik.handleBlur}
              />
              rewards
            </div>

            {errors.loyal ? (
              <div className="modal-text-mute">
                {touched.loyal && <img src={Error} alt="error" />}
                <span>{touched.loyal && errors.loyal}</span>
              </div>
            ) : errorNew.loyal ? (
              <div className="modal-text-mute">
                <img src={Error} alt="error" />
                <span>The number must be between 2 and 5.</span>
              </div>
            ) : null}
          </div>

          <div className="Segment-details">
            <div className="segment-tag segment-cold">
              <div className="segment-tag-bg">
                <span>Cold</span>
              </div>
            </div>
            <div className="d-flex align-items-center">
              Have NOT made a purchase (used a reward) in the past
              <Form.Control
                type="number"
                name="cold"
                className={`input-value-text ${editColor.cold ? "coldEDitColor" : ""}`}
                value={formik.values?.cold}
                onChange={(e) => {
                  formik.handleChange(e);
                  editColorChange(e)
                  checkErrors(e);
                }}
                onBlur={formik.handleBlur}
              />
              days
            </div>

            {errors.cold ? (
              <div className="modal-text-mute">
                {touched.cold && <img src={Error} alt="error" />}
                <span>{touched.cold && errors.cold}</span>
              </div>
            ) : errorNew.cold ? (
              <div className="modal-text-mute">
                <img src={Error} alt="error" />
                <span>
                  The number must be between 30 and 150 and can’t be lower than
                  number in “active” segment.
                </span>
              </div>
            ) : null}
          </div>

          <ContinueButton
            text="Save"
            className={!checkChangedVal() ? "savebtnDisable" : ""}
            type="submit"
            disabled={!checkChangedVal()}
          />
          <button
            className="outline-btnCustomers"
            type="button"
            onClick={resetFormValues}
          >
            <span className="outline-btnCustomersText">Restore Default</span>
          </button>
        </div>
      </Form>
      {/* <ContinueButton
          text="Edit"
          arrowVisible={false}
          onSubmit={() => setModalShow(true)}
        /> */}
    </>
  );
};
export default Customers;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiDelete, ApiGet, ApiPatch, ApiPost } from "helper/API/ApiData";
import { TeaserBaseUrl } from "helper/API/BaseUrl";

export const getTeaser = createAsyncThunk(
  "teser/getTeaser",
  async (data, thunkAPI) => {
    return await ApiGet(`${TeaserBaseUrl}offer`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        err = { isError: true };
        return err;
      });
  }
);

export const createTeaser = createAsyncThunk(
  "teaser/createTeaser",
  (data, thunkAPI) => {
    return ApiPost(`${TeaserBaseUrl}offer`, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        err = { isError: true };
        return err;
      });
  }
);

export const getMyTeaser = createAsyncThunk("teaser/getTeaser", async () => {
  return await ApiGet(`${TeaserBaseUrl}offer/my_offers`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      err = { isError: true };
      return err;
    });
});

export const getTeaserById = createAsyncThunk(
  "teaser/getTeaser",
  async (id, thunkAPI) => {
    return await ApiGet(`${TeaserBaseUrl}offer/${id}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        err = { isError: true };
        return err;
      });
  }
);

export const editTeaser = createAsyncThunk(
  "teaser/editTeaser",
  (data, thunkAPI) => {
    const id = data.id;
    delete data.id;
    return ApiPatch(`${TeaserBaseUrl}offer/${id}`, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        err = { isError: true };
        return err;
      });
  }
);

export const deleteTeaser = createAsyncThunk(
  "campaigns/getCampaigns",
  async (id, thunkAPI) => {
    return await ApiDelete(`${TeaserBaseUrl}offer/${id}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        err = { isError: true };
        return err;
      });
  }
);

export const teaserSlice = createSlice({
  name: "teaserSlice",
  initialState: { entities: [], loading: false },
  reducers: {},
  extraReducers: {
    [getTeaser.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.value += action.payload;
    },
    [getTeaser.reject]: (state, action) => {
      state.isLoading = false;
    },
    [getTeaser.pending]: (state, action) => {
      state.isLoading = true;
    },
  },
});

export default teaserSlice.reducer;

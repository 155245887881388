import Header from 'layout/header/Header'
import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { useLocation } from 'react-router'
import Customers from './tabs/Customers'
import MyAccount from './tabs/MyAccount'
import PaymentMethods from './tabs/PaymentMethods'
import PrivacyPolicy from './tabs/PrivacyPolicy'
import QA from './tabs/QA'
import Team from './tabs/Team'
const Settings = () => {

    const location = useLocation()
    const id = location.state?.id
    const [activeTab, setActiveTab] = useState(id ?? "app")
    //    console.log("🚀 ~ file: Settings.js:16 ~ Settings ~ activeTab:", activeTab)

    const changeTab = () => {
        ///console.log('here')
        if (id === 'payments') {
            document.getElementById('settings-tab-payments').click();
        } else {
            document.getElementById('settings-tab-QA').click();
        }
    }

    useEffect(() => {
        if (id)
            setActiveTab(id)
        changeTab()
    }, [id])


    return (
        <>
            <Header func={changeTab} title={'Settings'} />
            <div className='setting-tab'>
                <Tabs
                    defaultActiveKey={activeTab}
                    id="settings"
                    className=""
                >
                    <Tab eventKey="payments" title="Plans & Payment Methods">
                        <PaymentMethods />
                    </Tab>
                    <Tab eventKey="app" title="Customers">
                        <Customers />
                    </Tab>
                    <Tab eventKey="sms" title="My Profile">
                        <MyAccount />
                    </Tab>
                    {/* <Tab eventKey="email" title="Members">
                        <Members />
                    </Tab>
                    <Tab eventKey="Language" title="Language">
                        <Language />
                    </Tab> */}
                    {/* <Tab eventKey="BillingInvoices" title="Billing & Invoices" id='bg-remove'>
                        <BillingInvoices />
                    </Tab> */}
                    <Tab eventKey="Team" title="Team">
                        <Team />
                    </Tab>
                    <Tab eventKey="PrivacyPolicy" title="Privacy & Policy">
                        <PrivacyPolicy />
                    </Tab>
                    <Tab eventKey="QA" title="QA" style={{ width: '100%' }}>
                        <QA />
                    </Tab>
                </Tabs>
            </div>
        </>
    )
}

export default Settings

import STORAGEKEY from "../config/APP/app.config";
import Cookie from 'js-cookie'
import { appSettings } from "./appSettings";

class AuthStorage {

    static setStorageData(key, data) {
        Cookie.set(key, data, { domain: appSettings.AUTH_COOKIE_DOMAIN })
    }

    static setStorageJsonData(key, data) {
        Cookie.set(key, JSON.stringify(data), { domain: appSettings.AUTH_COOKIE_DOMAIN })
    }

    static getStorageData(key) {
        return Cookie.get(key)
    }

    static getStorageJsonData(key) {
        const data = Cookie.get(key)
        if (data) return JSON.parse(data);
    }

    static getToken() {
        return Cookie.get(STORAGEKEY.token)
    }

    static setToken(value) {
        Cookie.set(STORAGEKEY.token, value, { domain: appSettings.AUTH_COOKIE_DOMAIN })
    }

    static isUserAuthenticated() {
        return Cookie.get(STORAGEKEY.token)
    }

    static deauthenticateUser() {
        Cookie.remove(STORAGEKEY.token, { domain: appSettings.AUTH_COOKIE_DOMAIN });
        Cookie.remove(STORAGEKEY.refreshToken, { domain: appSettings.AUTH_COOKIE_DOMAIN });
        Cookie.remove(STORAGEKEY.businessId, { domain: appSettings.AUTH_COOKIE_DOMAIN });
        Cookie.remove(STORAGEKEY.userId, { domain: appSettings.AUTH_COOKIE_DOMAIN });
        Cookie.remove(STORAGEKEY.userName, { domain: appSettings.AUTH_COOKIE_DOMAIN });
        Cookie.remove(STORAGEKEY.userData, { domain: appSettings.AUTH_COOKIE_DOMAIN });
        window.location = appSettings.AUTH_APP_URL;
    }

    static deleteKey(key) {
        Cookie.remove(key);
    }

    static setCookie(key, data) {
        Cookie.set(key, data, { domain: appSettings.AUTH_COOKIE_DOMAIN });
    }
}

export default AuthStorage;

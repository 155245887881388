const STORAGEKEY = {
    token: 'token',
    refreshToken: 'refresh-token',
    businessId: 'business_id',
    userData: 'userData',
    userId: 'userId',
    layoutData: 'layoutData',
    roles: 'roles',
    email: 'email',
    lang: 'i18nextLng',
    assumedShop: 'assumed_shop'
};

export default STORAGEKEY

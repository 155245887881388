import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";

const CampaignCard = ({ disabled, img, header, text, navigateTo }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="start-campaign start-campaign-item">
        {disabled ? (
          <>
            <div className="boomio-disabled">
              <img src={img} alt="" />
              <h5 className="boomio-card-header-min-height">{header}</h5>
              <p className="boomio-gray boomio-gray-min-height">{text}</p>
              <Button className="button-8559F3 mt-4">Get Started</Button>
            </div>
            <Button className="coming-soon-btn">Coming Soon</Button>
          </>
        ) : (
          <>
            <img src={img} alt="" />
            <h5 className="boomio-card-header-min-height">{header}</h5>
            <p className="boomio-gray boomio-gray-min-height">{text}</p>
            <Button
              className="button-8559F3"
              onClick={() => navigate(navigateTo)}
            >
              Get Started
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default CampaignCard;

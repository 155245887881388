import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { ApiDelete, ApiGet, ApiPatch, ApiPost } from "helper/API/ApiData"
import { UserBaseUrl, BusinessBaseUrl } from "helper/API/BaseUrl"
import { toast } from "react-toastify"

export const getTeams = createAsyncThunk(
    'settings/getTeams',
    async (data, thunkAPI) => {
        return await ApiGet(`${UserBaseUrl}portal-users/business`).then((res) => {
            return res
        }).catch((err) => {
            err = { isError: true }
            return err
        })
    }
)

export const InviteMembers = createAsyncThunk(
    'settings/InviteMembers',
    async (data) => {
        return await ApiPost(`${BusinessBaseUrl}members/invite`, data)
            .then((res) => {
                toast.success("Email sent successfully", {
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                return res
            })
            .catch((err) => {
                console.log("err", err);
                err = { isError: true }
                return err
            });
    }
)

export const editUserRole = createAsyncThunk(
    'settings/editUserRole',
    (data, thunkAPI) => {
        return ApiPatch(`${UserBaseUrl}portal-users/roles/${data.ur_id}`, { r_id: data?.r_id }).then((res) => {
            return res?.data?.response;
        }).catch((err) => {
            err = { isError: true }
            return err
        })
    }
)

export const deleteUserRole = createAsyncThunk(
    'campaigns/deleteUserRole',
    async (data, thunkAPI) => {
        return await ApiDelete(`${UserBaseUrl}portal-users/roles/${data.ur_id}`).then((res) => {
            return res
        }).catch((err) => {
            err = { isError: true }
            return err
        })

    }
)


export const teamSlice = createSlice({
    name: "campaignSlice",
    initialState: { entities: [], loading: false },
    reducers: {
    },
    extraReducers: {
        [getTeams.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.value += action.payload;
        },
        [getTeams.reject]: (state, action) => {
            state.isLoading = false;
        },
        [getTeams.pending]: (state, action) => {
            state.isLoading = true;
        },
    },

})

export default teamSlice.reducer;
export const xwwwFormUrlencoded = (formData) => {
  var formBody = [];
  for (var property in formData) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(formData[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");

  return formBody
}

export const hasClass = (element, className) => {
  return element.classList.contains(className);
};

export const findParent = (element, className) => {
  if (!element.parentNode) {
    return false;
  }

  if (hasClass(element, className)) {
    return element;
  }

  return findParent(element.parentNode, className)
};

export const POPUP_TIMER = 3000
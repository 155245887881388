import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Card from "./components/Card";
import "react-datepicker/dist/react-datepicker.css";
import DateRangeSelect from "common/DateRangeSelect/DateRangeSelect";
import Splinegraph from "./components/Splinegraph";
import SetkpiPopup from "../componets/modals/SetkpiPopup";
import { useNavigate } from "react-router";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { getCampaignByBusiness } from "redux/features/authentication/authSlice";
import BigCard from "./components/BigCard";
import ButtonCard from "./components/ButtonCard";
import { getDashboard } from "redux/features/dashboard/dashboardSlice";
import Header from "layout/header/Header";
import AddStore from "pages/componets/modals/AddStore";
import { useSearchParams } from "react-router-dom";
import AddStoreResponse from "pages/componets/modals/AddStoreResponse";
import { ApiGet } from "helper/API/ApiData";
import { toast } from "react-toastify";
import AddStoreInitial from "pages/componets/modals/AddStoreInitial";
import Cookie from "js-cookie";
import AddStoreOne from "pages/componets/modals/AddStoreInitialOne";
import AddStoreTwo from "pages/componets/modals/AddStoreInitialTwo";
import ReactGA from "react-ga4";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [addStoreModal, setAddStoreModal] = useState(false);
  const [addStoreResModal, setAddStoreResModal] = useState({
    open: false,
    type: "",
  });
  const [notification, setNotification] = useState(false);
  const [dashboardNotification, setDashboardNotification] = useState(false);
  const [connectStore, setConnectStore] = useState(false);
  const [startCampaignCard, setStartCampaignCard] = useState(false);
  const [estoreLink, setEstoreLink] = useState(false);
  const [selectPlanCard, setSelectPlanCard] = useState(false);
  const [dashBoardData, setDashBoardData] = useState();
  const [dashboardCustomerGrowth, setDashboardCustomerGrowth] = useState([]);
  const [dashboardReturningCustomer, setDashboardReturningCustomer] = useState(
    []
  );
  const [revenueGrowth, setRevenueGrowth] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState({
    start_date: moment().startOf("month").format("YYYY/MM/DD"),
    end_date: moment().format("YYYY/MM/DD"),
  });
  let [searchParams] = useSearchParams();
  const dashboardData = useSelector((state) => state.authState.dashboardData);
  const queryParamData = JSON.parse(searchParams.get("data"));
  const [focusedRange, setFocusedRange] = React.useState(null);
  const [initialModal, setInitialModal] = useState(false);
  const [initialModalTwo, setInitialModalTwo] = useState(false);

  useEffect(() => {
    if (addStoreResModal.open) {
      window.close();
    }
  }, [addStoreResModal.open]);

  useEffect(() => {
    dispatch(getCampaignByBusiness())
      .then(unwrapResult)
      .then((res) => {
        if (!res?.response?.length) {
          setDashboardNotification(true);
          setSelectPlanCard(true);
          setStartCampaignCard(true);
          setEstoreLink(true);
        }
      });
    return () => {
      setDashboardNotification(false);
      setSelectPlanCard(false);
      setStartCampaignCard(false);
      setEstoreLink(false);
    };
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (
        !connectStore &&
        startCampaignCard &&
        addStoreResModal.open === false &&
        Cookie.get("firstLogin") === "true"
      ) {
        setInitialModal(true);
      }
    }, 3000);
    return () => clearTimeout(timeout);
  }, [startCampaignCard]);
  useEffect(() => {
    if (queryParamData && queryParamData.isFirstTime) {
      ApiGet(
        `https://trade1.sun.boomio.com/verify-signature${window.location.search}`
      ).then((res) => {
        setAddStoreResModal({ open: true, type: "success" });
      });
    }
    if (queryParamData && queryParamData.error) {
      setAddStoreResModal({ open: true, type: "error" });
    }
  }, []);

  const formatDate = (date) => {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  };

  const series1 = [
    {
      name: "Amount",
      data: dashboardCustomerGrowth.map((val) => parseInt(val.ammount)),
    },
  ];
  const options1 = {
    chart: {
      height: 350,
      type: "area",
    },
    stroke: {
      curve: "smooth",
    },
    fill: {
      type: "gradient",
      gradient: {
        type: "horizontal",
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        gradientToColors: ["#FF3183", "#FF6E6D"],
        opacityFrom: 1,
        opacityTo: 1,
        stops: [30, 100],
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: dashboardCustomerGrowth.map((val) => {
        const date = new Date(val.dt_when);
        return formatDate(date);
      }),
      labels: {
        datetimeUTC: false,
      },
    },
    tooltip: {
      x: {
        format: "dd/MM/yy",
      },
    },
  };

  const series2 = [
    {
      name: "Amount",
      data: dashboardReturningCustomer.map((val) => val?.ammount),
    },
  ];

  const options2 = {
    chart: {
      height: 350,
      type: "area",
    },
    colors: ["#746bee"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: dashboardReturningCustomer.map((val) => {
        const date = new Date(val.dt_when);
        return formatDate(date);
      }),
      labels: {
        datetimeUTC: false,
      },
    },
    tooltip_detail: {
      x: {
        format: "dd/MM/yy",
      },
    },
  };
  const series3 = [
    {
      name: "Amount",
      data: revenueGrowth && revenueGrowth.map((val) => val?.ammount),
    },
  ];

  const options3 = {
    chart: {
      height: 350,
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    fill: {
      type: "gradient",
      gradient: {
        type: "horizontal",
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        gradientToColors: ["#8559F3", "#09DDD0"],
        stops: [0, 60, 100],
        opacityFrom: 1,
        opacityTo: 1,
      },
    },
    xaxis: {
      type: "datetime",
      categories: revenueGrowth.map((val) => {
        const date = new Date(val.dt_when);
        return formatDate(date);
      }),
      labels: {
        datetimeUTC: false,
      },
    },
    tooltip_detail: {
      x: {
        format: "dd/MM/yy",
      },
    },
  };

  useEffect(() => {
    if (dashboardData?.shopify_params?.name) {
      setConnectStore(true);
    }
  }, [dashboardData]);

  const setDashboardData = async (short_title) => {
    let body = {
      short_title: short_title,
      parameters: {
        date_from: formatDate(new Date(data.start_date)),
        date_to: formatDate(new Date(data.end_date)),
      },
    };
    await dispatch(getDashboard(body))
      .then(unwrapResult)
      .then((result) => {
        if (result?.payload?.isError) {
          throw result;
        }
        short_title === "DASHBOARD" && setDashBoardData(result?.response[0]);
        short_title === "DASH_CUST_GROWTH" &&
          setDashboardCustomerGrowth(result?.response);
        short_title === "DASH_RET_CUST" &&
          setDashboardReturningCustomer(result?.response);
        short_title === "DASH_REV_GROWTH" && setRevenueGrowth(result?.response);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    setDahboard();
  }, [data.start_date, data.end_date]);

  const setDahboard = () => {
    setDashboardData("DASHBOARD");
    setDashboardData("DASH_CUST_GROWTH");
    setDashboardData("DASH_RET_CUST");
    setDashboardData("DASH_REV_GROWTH");
  };

  const customersRewarded = [
    {
      text: "Rewards winners",
      total: dashBoardData?.customers_rewarded,
      percent: dashBoardData?.customers_rewarded_perc,
    },
    {
      text: "Total customers",
      total: dashBoardData?.customers_purchased,
      percent: dashBoardData?.customers_purchased_perc,
    },
    {
      text: "Total new customers",
      total: dashBoardData?.new_customers_purchased,
      percent: dashBoardData?.new_customers_purchased_perc,
    },
  ];
  const givenrewards = [
    {
      text: "Given rewards",
      total: dashBoardData?.given_rewards,
      percent: dashBoardData?.given_rewards_perc,
    },
    {
      text: "Total revenue ",
      total: `$ ${dashBoardData?.total_revenue}`,
      percent: dashBoardData?.total_revenue_perc,
    },
    {
      text: "New customer revenue ",
      total: `$ ${dashBoardData?.new_cust_revenue}`,
      percent: dashBoardData?.new_cust_revenue_perc,
    },
  ];

  const getBodyText = () => {
    if (dashboardData) {
      if (dashboardData?.subscription_plan !== "trial") {
        return ` You are on ${dashboardData?.subscription_plan} plan.`;
      } else if (
        dashboardData?.subscription_plan === "trial" &&
        dashboardData?.subscription_trial_rem_days > 0
      ) {
        return `${dashboardData?.subscription_trial_rem_days} days left of a free trial.`;
      } else if (
        dashboardData?.subscription_plan === "trial" &&
        dashboardData?.subscription_trial_rem_days <= 0
      ) {
        return "Your free trial has ended.";
      }
    }
  };
  const dateRangeChange = (ranges) => {
    setData({
      ...data,
      start_date: moment(ranges.range1?.startDate).format("YYYY/MM/DD"),
      end_date: moment(ranges.range1?.endDate).format("YYYY/MM/DD"),
    });
  };

  const toggle = (range) => {
    setFocusedRange(range);
    setOpen(!open);
  };
  return (
    <>
      <Header title={"Home / Dashboard"} onRefresh={setDahboard} />
      {/* <hr className="hr" /> */}
      <hr className="invisible" />

      <div className="campaigns-heade" style={{ rowGap: "10px" }}>
        <h1 className="page-header mb-0 gradient-color-font">{"DASHBOARD"}</h1>

        {/* <h1 className="page-header mb-0 gradient-color-font">
          {t("Dashboard.header")}{" "}
          {dashboardData?.name ? dashboardData?.name : ""}
        </h1> */}
        {/* <div className="set-date-picker first-time-reward">
          <div style={{ display: "flex", gap: "15px" }}>
            <div className="date-picker">
              <div
                className="boomio_input boomio_datepicker"
                style={{ position: "relative", paddingRight: "50px" }}
                onClick={() => toggle([0, 0])}
              >
                {`${data?.start_date ? data?.start_date : "Start Date"} - ${
                  data?.end_date ? data?.end_date : "End Date"
                } `}
                <img
                  src="../../../../assets/img/date-picker.png"
                  alt=""
                  style={{ marginLeft: "20px" }}
                  onClick={() => toggle([0, 0])}
                />
              </div>
            </div>
            <DateRangeSelect
              startDate={data?.start_date}
              endDate={data?.end_date}
              minDate={0}
              open={open}
              setOpen={setOpen}
              dateRangeChange={dateRangeChange}
              focusedRange={focusedRange}
              setFocusedRange={setFocusedRange}
            />
          </div>
        </div> */}
        {/* <div>
          <Button
            onClick={() => {
              ReactGA.event({
                action: "Clicked start campaign",
                category: "General, on selfservice",
                value: "",
              });
              navigate("/campaigns/boost-product");
            }}
            className="button-8559F3 boomio-white"
          >
            Start Campaign
          </Button>
        </div> */}
      </div>
      {/* <span className="dashboard-coheader"> This is the place for dashboard co-header</span> */}
      {dashboardNotification && (
        <div
          className={`dashboard-coheader ${
            notification ? "d-none" : "dashboard-coheader"
          }`}
          // className={`dashboard_notification ${
          //   notification ? "d-none" : "dashboard_notification"
          // }`}
        >
          <p>
            All data you see in dashboard and tables are sample data, i.e. what
            you would be able to see after creating the campaign. All data will
            be reset after creating your first campaign.
          </p>
          <img
            src="./assets/img/white-close.png"
            alt=""
            width="12px"
            height="14px"
            onClick={() => setNotification(true)}
          />
        </div>
      )}
      <Row style={{ rowGap: "20px", marginTop: "30px" }}>
        {startCampaignCard && (
          <Col xxl={4} xl={4} lg={12} md={12} sm={12}>
            <ButtonCard
              img={connectStore ? "connect_e_store.png" : "added_e_store.png"}
              title={
                connectStore ? "Your e-shop is added" : "Connect your e-shop"
              }
              bodytext={
                connectStore
                  ? "Well done, your e-shop is successfully added and connected with Boomio !"
                  : "You’re almost ready. Add your e-shop and connect it with  Boomio App."
              }
              bodytext2={"It takes only few minutes!"}
              buttontext={connectStore ? "E-shop Added" : "Connect e-shop"}
              cardmainclass={connectStore ? "verify-email" : "connenct_store"}
              link={estoreLink}
              onBtnClick={() => navigate("/campaigns/boost-product")}
            />
          </Col>
        )}
        {startCampaignCard && (
          <Col xxl={4} xl={4} lg={6} md={12} sm={12}>
            <ButtonCard
              img="ButtonCard_2.png"
              title={"Start a campaign!"}
              bodytext="Start your first campaign to boom your business!"
              cardmainclass="start__campaign"
              buttontext="Start Campaign"
              onBtnClick={() => {
                navigate("/campaigns/boost-product");
              }}
            />
          </Col>
        )}
        {selectPlanCard && (
          <Col xxl={4} xl={4} lg={6} md={12} sm={12}>
            <ButtonCard
              img="ButtonCard_3.png"
              title={"Select a plan"}
              bodytext={getBodyText()}
              cardmainclass="selectplan"
              buttontext={`${
                dashboardData?.subscription_plan === "trial"
                  ? "Select"
                  : "Upgrade"
              }`}
              onBtnClick={() => {
                ReactGA.event({
                  action: "Interested in upgrading plan",
                  category: "General, on selfservice",
                  value: "",
                });
                navigate("/settings", { state: { id: "payments" } });
              }}
            />
          </Col>
        )}
      </Row>
      <Row style={{ rowGap: "20px", marginTop: "30px" }}>
        <Col lg={6} md={12} sm={12}>
          <BigCard
            img="color-customers_new.png"
            positionimg="Light-info.png"
            data={customersRewarded}
            big
            boldtext="Rewards winners"
            text=" - number of people who have received at least one reward during selected time period."
            boldtext2="Total customers"
            text2=" - number of people who have used a reward to complete the purchase (once or more) during selected time period."
            boldtext3="Total new customers"
            text3=" - number of people who have used a reward to complete the purchase for the first time during selected time period."
          />
        </Col>
        <Col lg={6} md={12} sm={12}>
          <BigCard
            img="profile-visits.png"
            positionimg="Dark-info.png"
            data={givenrewards}
            percent={+12}
            boldtext="Given rewards"
            text="- number of rewards given during this period. "
            boldtext2="Total revenue"
            text2="- total revenue generated from completed purchases when using a reward during this period. "
            boldtext3="Total new revenue"
            text3=" - revenue generated from completed purchases by new customers when using a reward during this period. "
          />
        </Col>
        <Col lg={4} md={6} sm={12}>
          <Card
            img="loyalty-prog-participants-icon.png"
            positionimg="Dark-info.png"
            text={"Loyalty program participants"}
            total={dashBoardData?.loyal_prog_partcp}
            percent={
              Number.isInteger(dashBoardData?.loyal_prog_partcp_perc)
                ? dashBoardData?.loyal_prog_partcp_perc
                : +dashBoardData?.loyal_prog_partcp_perc?.toFixed(2)
            }
            boldtext="Loyalty program participants"
            tultiptext="- number of people who have joined your loyalty program through Boomio App"
          />
        </Col>
        <Col lg={4} md={6} sm={12}>
          <Card
            img="purchase-rate-icon.png"
            positionimg="Dark-info.png"
            text={"Purchase rate"}
            total={dashBoardData?.purchase_rate}
            percent={
              Number.isInteger(dashBoardData?.purchase_rate_perc)
                ? dashBoardData?.purchase_rate_perc
                : +dashBoardData?.purchase_rate_perc?.toFixed(2)
            }
            boldtext="Purchase rate"
            tultiptext=" - a percentage of reward winners who made a purchase"
          />
        </Col>
        <Col lg={4} md={6} sm={12}>
          <Card
            img="average-spending-icon.png"
            positionimg="Dark-info.png"
            text={"Average spending"}
            total={dashBoardData?.average_spending}
            percent={
              Number.isInteger(dashBoardData?.average_spending_perc)
                ? dashBoardData?.average_spending_perc
                : +dashBoardData?.average_spending_perc?.toFixed(2)
            }
            boldtext="Average spending"
            tultiptext="- the total revenue divided by the total number of customers who purchased."
          />
        </Col>
        <Col xl={6} lg={12} md={12}>
          <Splinegraph
            options={options1}
            series={series1}
            height={350}
            subTitle="Shows the total customer growth per selected period of time."
            title="Customer Growth"
            className="chart1"
          />
        </Col>
        <Col xl={6} lg={12} md={12}>
          <Splinegraph
            options={options2}
            series={series2}
            height={350}
            title="Returning Customer"
            subTitle="Shows total customers who used their rewards more than once."
          />
        </Col>
        <Col lg={12}>
          <Splinegraph
            options={options3}
            series={series3}
            height={350}
            title="Revenue Growth"
            subTitle="Shows a change in total revenue over a selected period of time."
          />
        </Col>
      </Row>
      {
        <AddStoreOne
          show={initialModal}
          onHide={() => {
            setInitialModal(false);
            Cookie.set("firstLogin", false, { domain: ".boomio.com" });
          }}
          onProceed={() => {
            setInitialModal(false);
            Cookie.set("firstLogin", false, { domain: ".boomio.com" });
            navigate("/campaigns/boost-product");
          }}
          name={dashboardData?.name}
        />
      }
      {/* {
        <AddStoreTwo
          show={initialModalTwo}
          onHide={() => setInitialModalTwo(false)}
        />
      } */}
      {<SetkpiPopup show={modalShow} onHide={() => setModalShow(false)} />}
      {<AddStore show={addStoreModal} onHide={() => setAddStoreModal(false)} />}
      {/* {
        <AddStoreResponse
          url={dashboardData?.shopify_params?.url}
          addStoreResModal={addStoreResModal}
          onHide={() => setAddStoreResModal({ open: false, type: "" })}
        />
      } */}
    </>
  );
};

export default Dashboard;

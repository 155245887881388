import { ApiGet } from 'helper/API/ApiData';
import Header from 'layout/header/Header'
import React, { useEffect } from 'react'
import Loader_img from '../../assets/img/notification-logo.png'
import moment from 'moment';
import { NotificationBaseUrl } from 'helper/API/BaseUrl';

const Notifications = () => {

    const [allNotification, setAllNotification] = React.useState([]);

    useEffect(() => {
        ApiGet(`${NotificationBaseUrl}notifications`).then((res) => {
            const arr = res.response.notifications.sort((a, b) => a.isRead - b.isRead)
            setAllNotification(arr)
        })
    }, [])

    return (
        <>
            <Header title={"Notifications"} />
            {
                allNotification.map((item, index) => (
                    <div className={`${item.isRead ? 'readed_notification_main' : 'notification_main'}`}>
                        <img src={item.logo ? item.logo : Loader_img} alt='' height='32px' width="32px" style={{ marginRight: '8px' }} />
                        <div className="notification_detail" style={{}}>
                            <h5>{item.body}</h5>
                            <h6>{moment(item.created_at).fromNow()}</h6>
                        </div>
                    </div>
                )
                )
            }
        </>
    )
}

export default Notifications
import { appSettings } from "helper/appSettings";

export const BaseUrl = appSettings.API_BASE_URL;
export const UserBaseUrl = `${BaseUrl}user-service/`;
export const BusinessBaseUrl = `${BaseUrl}business-service/`;
export const NotificationBaseUrl = `${BaseUrl}notify-service/`;
export const CampaignBaseUrl = `${BaseUrl}campaign-service/`;
export const FileBaseUrl = `${BaseUrl}file-service/`;
export const InfoBaseUrl = `${BaseUrl}info-service/`;
export const CouponsBaseUrl = `${BaseUrl}coupon-service/`
export const ReportBaseUrl = `${BaseUrl}report-service/`
export const TeaserBaseUrl = `${BaseUrl}teaser-service/`
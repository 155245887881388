import React from 'react';
import DiscountModal from 'pages/campaigns/widgets/DiscountModal';

function getCouponCode() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let couponData = {
        params: {
            topTitle: params.get('topTitle'),
            couponCodeDiscount: params.get('couponCodeDiscount'),
            couponCodediscountText: params.get('couponCodediscountText'),
            couponCode: params.get('couponCode'),
        }
    }

    return <DiscountModal couponData={couponData} />
}

export default getCouponCode
import React, { useEffect, useMemo, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { apiClient, ApiGet, ApiPost } from "../../../helper/API/ApiData";
import { useSelector } from "react-redux";
import { findParent } from "helper/utils";
import { useDocumentClickListener } from "helper/documentClickListener";
import ConfirmModal from "pages/componets/modals/modalConfirm";
import { toast } from "react-toastify";
import { BusinessBaseUrl } from "helper/API/BaseUrl";

const PaymentMethods = () => {
  const docuementClickListener = useDocumentClickListener();
  const [show, setShow] = useState(false);
  const [additionalPlans, setAdditionalPlans] = useState([]);
  const [currentPlan, setCurrentPlan] = React.useState("trial");
  const [freeTrialDays, setFreeTrialDays] = React.useState(0);
  const dashboardData = useSelector((state) => state.authState.dashboardData);
  const [allPlans, setAllPlans] = useState([
    {
      planType: "FREE",
      planDescription:
        "Try out Boomio main features for FREE! Try the functionality and see the benefits firsthand.",
      planPrice: "$0",
      planPriceDetails: "per month + $0.1 per used coupons by customers",
      buttonText: "Let's talk",
      buttonText: "Select",
      planBenefits: [
        "7 days free trial",
        "Unlimited games",
        "Unlimited campaigns",
        "Integration with ecommerce platform",
        "Gamified campaigns",
        "Reward options",
        "Reward expiration reminders",
        "Listing on Boomio app",
        "Real-time campaign analytics",
        "Customer analytics",
      ],
      includes: "Plan includes:",
      button: true,
      details: (
        <p className="plan_detail_text">
          billed monthly
          <div>
            <p style={{ fontWeight: "600", display: "inline" }}>+ $0.50 </p>
            per used coupon
          </div>
        </p>
      ),
    },
    {
      planType: "Standard",
      planDescription:
        "Unlock more benefits without exceeding your budget. This plan is designed to cater businesses with 150+ customers. Enhance your loyalty program and keep your customers engaged.",
      planPrice: "$49",
      planPriceDetails: "per month + $0.1 per used coupons by customers",
      buttonText: "Select",
      planBenefits: [
        "Integration with ecommerce platform",

        "Unlimited games",
        "Unlimited campaigns",
        "Gamified campaigns",
        "Reward options",
        "Reward expiration reminders",
        "Listing on Boomio app",
        "Real-time campaign analytics",
        "Customer analytics",
      ],
      includes: "Plan includes:",
      button: true,
      details: (
        <p className="plan_detail_text">
          billed monthly
          <div>
            <p style={{ fontWeight: "600", display: "inline" }}>+ $0.15 </p>
            per used coupon
          </div>
        </p>
      ),
    },
    {
      planType: "Premium",
      planDescription:
        "Ready to take your loyalty system to new heights? With advanced features, you can effectively scale  loyalty program and exceed customer expectations. Great for when you have 1000+ customers.",
      planPrice: "$99",
      planPriceDetails: "per month + $0.1 per used coupons by customers",
      buttonText: "Select",
      planBenefits: [
        "Integration with ecommerce platform",
        "Unlimited games",
        "Unlimited campaigns",
        "Gamified campaigns",
        "Reward options",
        "Reward expiration reminders",
        "Listing on Boomio app",
        "Real-time campaign analytics",
        "Customer analytics",
        "Ad space on Boomio App",
      ],
      includes: "Plan includes:",
      button: true,
      additional: "popular",
      details: (
        <p className="plan_detail_text">
          billed monthly
          <div>
            <p style={{ fontWeight: "600", display: "inline" }}>+ $0.10 </p>
            per used coupon
          </div>
        </p>
      ),
    },
    {
      planType: "Enterprise",
      planDescription:
        "Enterprise Plan is tailor-made to elevate your success even further. This plan caters to 2000+ customers, offering limitless possibilities, top ROI, and personalized solutions.",
      planPrice: "CUSTOM",
      planPriceDetails: "per month + $0.1 per used coupons by customers",
      buttonText: "Let's talk",
      planBenefits: [
        "Custom integration",
        "Dedicated account manager",
        "24/7 customer service",
        "Custom notifications to customers",
        "Custom built features",
        "Performance summary reports",
      ],
      includes: "Everything in Standard, plus:",
      button: "custom",
      details: (
        <p className="plan_detail_text">
          Contact us to get the price on request
        </p>
      ),
    },
  ]);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [subscriptions, setSubscriptions] = useState(null);
  const [planType, setPlanType] = useState("monthly");
  const [showDowngradeConfirmation, setShowDowngradeConfirmation] =
    useState(false);
  const shopName = useSelector(
    (state) => state.authState.dashboardData?.shopify_params?.name
  );

  useEffect(() => {
    getAllPlans();
    getCurrentSubscription();
    return () => {
      setAdditionalPlans([]);
      setPaymentMethods(null);
      setSubscriptions(null);
    };
  }, []);

  useEffect(() => {
    if (docuementClickListener) updatePlanSelection(docuementClickListener);
  }, [docuementClickListener]);

  useEffect(() => {
    if (dashboardData) {
      setCurrentPlan(dashboardData?.subscription_plan);
      setFreeTrialDays(dashboardData?.subscription_trial_rem_days);
    }
  }, [dashboardData]);

  const updatePlan = (name) => {
    name = name.toLowerCase();
    if (name) {
      if (shopName) {
        ApiPost(
          `${BusinessBaseUrl}plan/shopify/subscribe?interval=${
            planType === "yearly" ? "ANNUAL" : "MONTHLY"
          }`,
          { name }
        ).then((data) => {
          if (data.response.appSubscriptionCreate.confirmationUrl) {
            window.open(
              data.response.appSubscriptionCreate.confirmationUrl,
              "_blank"
            );
          }
        });
      } else {
        toast.error("Connect your shop, please", {
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    }
  };

  const getAllPlans = () => {
    ApiGet(`${BusinessBaseUrl}plan/shopify`).then((res) => {
      if (res && res.response && res.response.plans) {
        setAdditionalPlans(res.response.plans);
      }
    });
  };

  const getCurrentSubscription = async () => {
    const res = await apiClient({
      url: `${BusinessBaseUrl}stripe/active-subscription`,
      method: "GET",
    });
    if (res && res.data && res.data.response) {
      setPaymentMethods(res.data.response.paymentMethods);
      setSubscriptions(res.data.response.subscriptions);
    }
  };

  const updatePlanSelection = (e) => {
    const classes = e.target.classList;
    let target;
    if (classes.contains("plan_select_button")) {
      target = e.target;
    } else {
      target = findParent(e.target, "plan_select_button");
    }

    if (target) {
      if (
        target?.name === "free" &&
        getActivePlanName() !== "free" &&
        getActivePlanName() !== "trial"
      ) {
        setShowDowngradeConfirmation(true);
      } else {
        updatePlan(target.name);
      }
    }
  };

  const getActivePlanName = () => {
    return dashboardData?.subscription_plan;
  };

  const getActivePlan = () => {
    return additionalPlans?.find((plan) => plan.name === currentPlan);
  };

  // This function is in used and working on memo based
  const injectSticker = useMemo(() => {
    if (show) {
      setTimeout(() => {
        allPlans?.map((plan, i) => {
          const appendSticker = (stickerVal) => {
            const planElem = document.querySelector(
              `#subsPlan${i} .plan_content`
            );
            var iDiv = document.createElement("div");
            iDiv.className = "off_lable";
            iDiv.innerHTML = `-${stickerVal}%`;
            planElem.appendChild(iDiv);
          };

          const updateAmount = () => {
            const priceElem = document.querySelector(
              `#subsPlan${i} .plan_name`
            );
            priceElem.innerHTML =
              planType == "monthly"
                ? plan.planPrice
                : planType == "yearly"
                ? plan?.planPrice
                  ? plan.planPrice
                  : 0
                : 0;
          };

          if (planType == "monthly") {
            if (plan?.discount) {
              appendSticker(plan.discount);
            }
          }

          if (planType == "yearly") {
            if (plan?.discount_anual) {
              appendSticker(plan.discount_anual);
            }
          }

          updateAmount();

          let mainContainerElem = document.querySelector(
            `#subsPlan${i} .card_main_inner`
          );
          if (!mainContainerElem) {
            mainContainerElem = document.querySelector(
              `#subsPlan${i} .second_card_inner`
            );
          }
          mainContainerElem.classList.add(`${plan.name}Plan`);

          // / updating button /
          // let buttonElem = document.querySelector(
          //   `#subsPlan${i} .plan_select_button`
          // );
          // if (plan.name != "standard" && buttonElem) {
          //   buttonElem.remove();

          //   buttonElem = document.createElement("button");
          //   buttonElem.className = "outline-ModalBtnCustomers btn-select-plan";
          //   buttonElem.setAttribute('name', plan.name);
          //   // buttonElem.setAttribute('data-name', plan.name);
          //   const spanElem = document.createElement("span");
          //   spanElem.className = "outline-ModalBtnCustomersText";
          //   spanElem.innerHTML = "Select";
          //   buttonElem.appendChild(spanElem);
          //   const planContentElem = document.querySelector(
          //     `#subsPlan${i} .plan_content`
          //   );
          //   planContentElem.appendChild(buttonElem);
          // } else if (plan.name == 'standard' && buttonElem) {
          //   buttonElem.classList.add("btn-select-plan");
          // }
        });
      }, 50);
    }
  }, [show, planType, allPlans]);

  if (!paymentMethods === null) {
    return <p>Loading</p>;
  }

  const combinedPlans = allPlans.map((plan, index) => ({
    ...plan,
    ...additionalPlans[index],
  }));

  return (
    <React.Fragment>
      <div className="paymentMethods" id="top">
        <div className="mx-auto">
          <h1 className="planTitle">My Plan</h1>
          <div className="planCard">
            <img src="./assets/img/plan-img.png" className="topImg" alt="" />
            <div className="planCardBg">
              {currentPlan !== "trial" && <h2>{currentPlan}</h2>}
              {currentPlan === "trial" && freeTrialDays > 0 && (
                <h2> {freeTrialDays} days left of a free trial.</h2>
              )}
              {currentPlan === "trial" && freeTrialDays <= 0 && (
                <h2> Your free trial has ended. </h2>
              )}
              {currentPlan !== "trial" && (
                <p>Order limit: {getActivePlan()?.order_limit}</p>
              )}
            </div>
          </div>
          <div
            className="d-flex gap-2 align-items-center cursor-pointer"
            style={{ marginTop: "12px" }}
            onClick={() => setShow(true)}
          >
            <img
              src="./assets/img/gredian-setting.png"
              className="icon-size"
              alt=""
            />
            <h2 className="managePlan">Manage plan</h2>
          </div>
        </div>
      </div>

      <ConfirmModal
        title="Downgrade to free"
        onHide={() => setShowDowngradeConfirmation(false)}
        onConfirm={() => updatePlan("free")}
        show={showDowngradeConfirmation}
      >
        <div>
          <span style={{ paddingLeft: "16px" }}>
            Are you sure you want to downgrade to Free from {getActivePlanName}?
          </span>
        </div>
      </ConfirmModal>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="xl"
        aria-labelledby="example-custom-modal-styling-title"
        className="example-custom-modal-styling-title"
        style={{ zIndex: "9999999" }}
      >
        <Modal.Body className="planModalBody">
          <div className="relative">
            <div className="d-flex steper d-none">
              <p className="active">1. Select a Plan</p>
              <img
                src="./assets/img/rightStep.png"
                className="rightIcon"
                alt=""
              />
              <p>2. Enter Your Payment Details</p>
              <img
                src="./assets/img/rightStep.png"
                className="rightIcon"
                alt=""
              />
              <p>3. Complete Your Purchase</p>
            </div>

            <img
              onClick={() => setShow(false)}
              src="./assets/img/closeIcon.png"
              className="closeIcon icon-size"
              alt="close"
            />
            {/* <div className="d-flex mainText">
              <h4 className="gredianBgText">Save up to 50% </h4>&nbsp;
              <h4> by paying annually</h4>
            </div> */}
            {/* <div className="d-flex lightBg cursor-pointer">
              <div
                className={`tab ${planType === "monthly" ? "active" : ""}`}
                onClick={() => {
                  setPlanType("monthly");
                }}
              >
                Monthly
              </div>
              <div
                className={`tab ${planType === "yearly" ? "active" : ""}`}
                onClick={() => {
                  setPlanType("yearly");
                }}
              >
                Annually
              </div>
            </div> */}
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              rowGap: "10px",
              marginTop: "100px",
              columnGap: "10px",
            }}
          >
            {combinedPlans?.map((plan, i) => {
              return (
                <div
                  // className="card_main"
                  key={i}
                  id={"subsPlan" + i}
                  className={
                    plan?.active
                      ? "current_plan"
                      : plan.additional === "popular"
                      ? "popular_plan"
                      : ""
                  }
                >
                  <div
                    className="card_main_inner"
                    style={{
                      borderRadius:
                        plan?.active || plan.additional === "popular"
                          ? "0px 0px 40px 40px"
                          : "40px",
                    }}
                  >
                    <div className="plan_content">
                      <h1 className="plan_type">{plan.planType}</h1>
                      <p className="plan_description">{plan.planDescription}</p>
                      {/* <div>
                        <div className="plan_name">{plan.planPrice}</div>
                        {plan.details}
                      </div> */}
                      {plan?.active ? (
                        <div style={{ height: "30px" }}></div>
                      ) : plan?.button === "custom" ? (
                        <button
                          className="outline-btnCustomers plan_select_button"
                          type="button"
                          name={plan?.name}
                          id="planSelector"
                          onClick={() =>
                            window.open(
                              "https://www.boomio.com/contact-us",
                              "_blank"
                            )
                          }
                        >
                          <span className="outline-btnCustomersText">
                            {plan.buttonText}
                          </span>
                        </button>
                      ) : (
                        plan.button && (
                          <button
                            className="outline-btnCustomers plan_select_button"
                            type="button"
                            name={plan?.name}
                            id="planSelector"
                            // onClick={() => updatePlan(plan.planType)}
                          >
                            <span className="outline-btnCustomersText">
                              {plan.buttonText}
                            </span>
                          </button>
                        )
                      )}
                    </div>
                    <div className="plan_benefits">
                      <p className="plan_includes">{plan.includes}</p>
                      <ul>
                        {plan.planBenefits.map((benefit, j) => (
                          <li className="plan_list" key={j}>
                            <img src="../assets/img/check_mark.png" alt="" />
                            <span className="plan_list_text">{benefit}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default PaymentMethods;

import React from "react";
import stoppedIcon from "../../assets/img/octicon_stop-16.png";
import activeIcon from "../../assets/img/Union.png";
import inDraftIcon from "../../assets/img/icon-review.png";
import finishedIcon from "../../assets/img/icon-archive.png";
import moment from "moment";
const StatusBar = ({ status, end}) => {
 const currentDate = new Date();
 const endDate = moment(end).toDate();
 let nextDate = new Date();
 nextDate.setTime(endDate.getTime() + 86400000);
 const color =
  status === "draft"
     ? "#757575" // in draft
     : currentDate > nextDate
     ? "#376FFF" //finished
     : status === "active"
     ? "#48BB78" // active
     : "#E93940"; //stopped
 const icon =
  status === "draft"
     ? inDraftIcon 
     : currentDate > nextDate
     ? finishedIcon 
     : status === "active"
     ? activeIcon 
     : stoppedIcon; 
 const txt =
   status === "draft"
     ? 'In Draft' 
     : currentDate > nextDate
     ? 'Finished' 
     : status === "active"
     ? 'Active' 
     : 'Stopped'; 
  return (
       <div className="campaign-list-status-bar" style={{ background: color }}>
        <span className="headr-text align-self-center "> {txt} </span>
        <img  className="align-self-center "src={icon} alt=""/>
    </div>
  );
};

export default StatusBar;
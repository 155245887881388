import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiGet } from "helper/API/ApiData";
import { BusinessBaseUrl } from "helper/API/BaseUrl";

export const getShopify = createAsyncThunk(
    'paymentmethods/getShopify',
    async () => {
        return await ApiGet(`${BusinessBaseUrl}plan/shopify`)
            .then((res) => {
                return res
            })
            .catch((err) => {
                err = { isError: true }
                return err
            });
    }
)



export const paymentSlice = createSlice({
    name: "paymentSlice",
    initialState: { entities: [], loading: false, dashboardData: [] },
    extraReducers: {
        [getShopify.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.value += action.payload;
            state.shopifyData = JSON.parse(JSON.stringify(action.payload.response));
        },
        [getShopify.reject]: (state, action) => {
            state.isLoading = false;
        },
        [getShopify.pending]: (state, action) => {
            state.isLoading = true;
        },
    },
})

export default paymentSlice.reducer;
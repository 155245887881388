import STORAGEKEY from "config/APP/app.config";
import { appSettings } from "helper/appSettings";
import axios from "axios";
import AuthStorage from "helper/AuthStorage";
import { UserBaseUrl } from "helper/API/BaseUrl";

export const API = axios.create();

API.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response.status === 401) {
        const headers = {
            'refresh-token': AuthStorage.getStorageData(STORAGEKEY.refreshToken),
            'Content-Type': 'application/json',
        }
        axios.post(`${UserBaseUrl}auth/refresh-token`, {}, { headers }).then((res) => {
            AuthStorage.setToken(res.data.response.token);
            window.location = appSettings.PORTAL_APP_URL;
        })
            .catch((e) => {
                AuthStorage.deauthenticateUser()
            })
    } else {
        return Promise.reject(error);
    }
});


import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DateRangeSelect from "common/DateRangeSelect/DateRangeSelect";
import moment from "moment/moment";
import datepicker from "../../../assets/img/date-picker.png";
import queryString from "query-string";
import RewardPaper from "./RewardPaper";
import CampaignReview from "./CampaignReview";
import { Button } from "react-bootstrap";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { ApiPost } from "helper/API/ApiData";
import { UserBaseUrl } from "helper/API/BaseUrl";
import { toast } from "react-toastify";
import { Checkbox } from "@material-ui/core";
import { checkProductUrl } from "redux/features/campaigns/campaignSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import upload from "../../../assets/img/upload.svg";
import { BusinessBaseUrl } from "helper/API/BaseUrl";
import AuthStorage from "helper/AuthStorage";

const CampaignAccordion = ({
  rewardType,
  setRewardType,
  couponData,
  setCouponData,
  discountAmount,
  setDiscountAmount,
  selectedDay,
  setSelectedDay,
  days,
  setDays,
  dateRange,
  setDateRange,
  gameSelect,
  setExpanded,
  setStep,
  step,
  campaignName,
  selectedGameValue,
  campaignAccessibility,
  setCampaignAccessibility,
  outcome,
  setOutcome,
  setSelectUrl,
  selectUrl,
  urlAvailable,
  setUrlAvailable,
  privacyData,
  setPrivacyData,
  setRulesData,
  rulesData,
  setScoreboardData,
  scoreboardData,
  setPersonalizationData,
  personalizationData,
}) => {
  const query = queryString.parse(window.location.search);
  const dispatch = useDispatch();

  const id = query?.id;

  const [open, setOpen] = React.useState(false);
  const [focusedRange, setFocusedRange] = React.useState(null);
  const [moreGames, setMoreGames] = React.useState(false);
  const [showEndDate, setShowEndDate] = React.useState(false);
  const [logoImage, setLogoImage] = useState({ logoFormData: null, url: "" });
  const businessState = useSelector((state) => state.businessState.data);

  const [customizaitonEmailDetails, setCustomizaitonEmailDetails] =
    React.useState({
      company: "",
      email: "",
      firstName: "",
    });

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const productRewardSchema = yup.object({
    Specific_product_URL: yup
      .array()
      .of(
        yup
          .string()
          .required("Product URL is required.")
          .matches(
            /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
            "Enter valid product URL"
          )
      )
      .required("Product URL is required."),
  });
  const {
    register: register1,
    handleSubmit: handleSubmit1,
    setValue: setValue1,
    reset: reset1,
    formState: { errors },
    clearErrors: clearErrors1,
  } = useForm({
    resolver: yupResolver(productRewardSchema),
  });

  const dateRangeChange = (ranges) => {
    if (ranges && ranges.range1.startDate && ranges.range1.endDate) {
      const startDate = moment(ranges.range1?.startDate).format("YYYY/MM/DD");
      const endDate = moment(ranges.range1?.endDate).format("YYYY/MM/DD");
      setValue1("campaignStartDate", startDate);
      setValue1("campaignEndDate", endDate);
      ranges.range1?.endDate && clearErrors1("campaignEndDate");
      setDateRange({ startDate, endDate });
    }
  };
  const toggle = (range) => {
    setFocusedRange(range);
    setOpen(!open);
  };

  const handleConfirm = async (data) => {
    let body = {
      body: JSON.stringify(customizaitonEmailDetails),
      email: customizaitonEmailDetails.email,
      title: "Game Customization",
    };

    await ApiPost(`${UserBaseUrl}help`, body).then((res) => {
      toast.success("Thank you for shaping the future of our platform!", {
        closeOnClick: true,
        pauseOnHover: true,
      });
    });
  };

  const handleCampaignAccessibility = (type, isChecked) => {
    if (isChecked) {
      // If the checkbox is checked, add the type to the list
      setCampaignAccessibility([...campaignAccessibility, type]);
    } else {
      // If the checkbox is unchecked, remove the type from the list
      setCampaignAccessibility(
        campaignAccessibility.filter((item) => item !== type)
      );
    }
  };
  let urlErrorOccurred = false;

  const checkUrls = async () => {
    if (!urlErrorOccurred) {
      const urlCheckData = {
        id: id,
        url: selectUrl,
      };
      await dispatch(checkProductUrl(urlCheckData))
        .then(unwrapResult)
        .then((res) => {
          if (res.isError) {
            toast.error(`Url does not have boomio scripts: ${selectUrl}`, {
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else {
            setUrlAvailable(true);
          }
        })
        .catch((err) => {
          toast.error(`Url does not have boomio scripts: ${selectUrl}`, {
            closeOnClick: true,
            pauseOnHover: true,
          });
        });
    }
  };

  const handlePrivacyChange = (event) => {
    const { name, value } = event.target;
    setPrivacyData({
      ...privacyData,
      [name]: value,
    });
  };

  const handleRulesChange = (event) => {
    const { name, value } = event.target;
    setRulesData({
      ...rulesData,
      [name]: value,
    });
  };
  const inputrafIntro = useRef(null);
  const inputrafGame = useRef(null);

  const postUploadfile = async (e, type) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    const accessToken = AuthStorage.getToken();
    try {
      const response = await fetch(`${BusinessBaseUrl}games/upload`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `${accessToken}`,
        },
      });
      if (response.ok) {
        const responseData = await response.json();
        setPersonalizationData((prevData) => ({
          ...prevData,
          [type]: responseData.response.file_name,
        }));
      } else {
        console.error("Upload failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };
  return (
    <>
      <DateRangeSelect
        startDate={dateRange?.startDate}
        endDate={dateRange?.endDate}
        open={open}
        setOpen={setOpen}
        dateRangeChange={dateRangeChange}
        focusedRange={focusedRange}
        setFocusedRange={setFocusedRange}
      />
      {step === 2 && (
        <div>
          {/* <p
            className="campaign-header"
            style={{ fontSize: step > 1 ? 14 : 32 }}
          >
            Coming soon
          </p> */}
          <p style={{ fontSize: "18px", fontWeight: 600, marginBottom: "0px" }}>
            Game registration
          </p>
          <p style={{ fontSize: "14px", color: "#585666" }}>
            The fields that will be required to fill during game registration
          </p>

          <FormControl
            style={{
              marginLeft: "40px",
              marginTop: "4px",
            }}
          >
            <FormControlLabel
              style={{ alignItems: "start" }}
              control={
                <Checkbox
                  style={{
                    color: campaignAccessibility.includes("nickname")
                      ? "#EB5894"
                      : "black",
                    padding: "6px",
                  }}
                  checked={campaignAccessibility.includes("nickname")}
                  disabled
                />
              }
              label={
                <div
                  style={{
                    marginTop: "8px",
                    fontWeight: "600",
                    fontSize: "16px",
                    color: "black",
                  }}
                >
                  Nickname *
                </div>
              }
            />
            <FormControlLabel
              style={{ alignItems: "start" }}
              control={
                <Checkbox
                  style={{
                    color: campaignAccessibility.includes("email")
                      ? "#EB5894"
                      : "black",
                    padding: "6px",
                  }}
                  checked={campaignAccessibility.includes("email")}
                  disabled
                />
              }
              label={
                <div
                  style={{
                    marginTop: "8px",
                    fontWeight: "600",
                    fontSize: "16px",
                    color: "black",
                  }}
                >
                  Email *
                </div>
              }
            />
            <FormControlLabel
              style={{ alignItems: "start" }}
              control={
                <Checkbox
                  style={{
                    color: campaignAccessibility.includes("name")
                      ? "#EB5894"
                      : "black",
                    padding: "6px",
                  }}
                  checked={campaignAccessibility.includes("name")}
                  onChange={(event) =>
                    handleCampaignAccessibility("name", event.target.checked)
                  }
                />
              }
              label={
                <div
                  style={{
                    marginTop: "8px",
                    fontWeight: "600",
                    fontSize: "16px",
                    color: "black",
                  }}
                >
                  Name
                </div>
              }
            />
            <FormControlLabel
              style={{ alignItems: "start" }}
              control={
                <Checkbox
                  style={{
                    color: campaignAccessibility.includes("last_name")
                      ? "#EB5894"
                      : "black",
                    padding: "6px",
                  }}
                  checked={campaignAccessibility.includes("last_name")}
                  onChange={(event) =>
                    handleCampaignAccessibility(
                      "last_name",
                      event.target.checked
                    )
                  }
                />
              }
              label={
                <div
                  style={{
                    marginTop: "8px",
                    fontWeight: "600",
                    fontSize: "16px",
                    color: "black",
                  }}
                >
                  Last name
                </div>
              }
            />
            <FormControlLabel
              style={{ alignItems: "start" }}
              control={
                <Checkbox
                  style={{
                    color: campaignAccessibility.includes("phone_number")
                      ? "#EB5894"
                      : "black",
                    padding: "6px",
                  }}
                  checked={campaignAccessibility.includes("phone_number")}
                  onChange={(event) =>
                    handleCampaignAccessibility(
                      "phone_number",
                      event.target.checked
                    )
                  }
                />
              }
              label={
                <div
                  style={{
                    marginTop: "8px",
                    fontWeight: "600",
                    fontSize: "16px",
                    color: "black",
                  }}
                >
                  Phone number
                </div>
              }
            />
            <FormControlLabel
              style={{ alignItems: "start" }}
              control={
                <Checkbox
                  style={{
                    color: campaignAccessibility.includes("whatsApp_number")
                      ? "#EB5894"
                      : "black",
                    padding: "6px",
                  }}
                  checked={campaignAccessibility.includes("whatsApp_number")}
                  onChange={(event) =>
                    handleCampaignAccessibility(
                      "whatsApp_number",
                      event.target.checked
                    )
                  }
                />
              }
              label={
                <div
                  style={{
                    marginTop: "8px",
                    fontWeight: "600",
                    fontSize: "16px",
                    color: "black",
                  }}
                >
                  WhatsApp number
                </div>
              }
            />
          </FormControl>
          <p
            style={{
              fontSize: "18px",
              fontWeight: 600,
              marginTop: "40px",
              marginBottom: "0px",
            }}
          >
            Set terms and conditions
          </p>
          <p style={{ fontSize: "14px", color: "#585666" }}>
            Fill the information regarding customers privacy policies, that fits
            GDPR requirements.{" "}
          </p>
          <p>Write text and add URL of companies privacy policy</p>
          <input
            style={{ maxWidth: "450px" }}
            type="text"
            className="model-input"
            placeholder="Sutinku su įmonės privatumo politika."
            name="privacyPolicy"
            onChange={handlePrivacyChange}
            value={privacyData.privacyPolicy}
          />
          <input
            style={{ maxWidth: "450px" }}
            type="text"
            className="model-input"
            placeholder="http://...."
            name="websiteUrl"
            onChange={handlePrivacyChange}
            value={privacyData.websiteUrl}
          />
          <p style={{ marginTop: "10px" }}>
            Write text regarding customers confirmation to get newsletters.
          </p>
          <input
            style={{ maxWidth: "450px" }}
            type="text"
            className="model-input"
            placeholder="Sutinku gauti naujienlaiškius."
            name="newsletter"
            onChange={handlePrivacyChange}
            value={privacyData.newsletter}
          />
        </div>
      )}

      {step === 3 && (
        <div>
          <p style={{ fontSize: "18px", fontWeight: 600, marginBottom: "0px" }}>
            Set game rules
          </p>
          <p style={{ fontSize: "14px", color: "#585666" }}>
            Download full rules, add missing information and place full rules on
            your website
          </p>
          <p>Write text regarding full game rules</p>
          <input
            style={{ maxWidth: "450px" }}
            type="text"
            className="model-input"
            placeholder="Skaityti pilnas taisykles"
            name="rulesText"
            onChange={handleRulesChange}
            value={rulesData.rulesText}
          />
          <p style={{ margin: "10px 0px 10px 0px" }}>
            Write link where full game rules are placed on your website
          </p>
          <input
            style={{ maxWidth: "450px" }}
            type="text"
            className="model-input"
            placeholder="http://...."
            name="rulesLink"
            onChange={handleRulesChange}
            value={rulesData.rulesLink}
          />
          <p style={{ margin: "10px 0px 10px 0px" }}>
            Short game rules that will appear in the game
          </p>
          <input
            style={{ margin: "10px 0px 10px 0px", maxWidth: "450px" }}
            type="text"
            className="model-input"
            placeholder="SPAUSK  kad skristum "
            name="gameRules_1"
            value={rulesData.gameRules_1}
            onChange={handleRulesChange}
          />
          <input
            style={{ margin: "10px 0px 10px 0px", maxWidth: "450px" }}
            type="text"
            className="model-input"
            placeholder="KARTOK dėl geresnio rezultato"
            name="gameRules_2"
            value={rulesData.gameRules_2}
            onChange={handleRulesChange}
          />
          <input
            style={{ margin: "10px 0px 0px 0px", maxWidth: "450px" }}
            type="text"
            className="model-input"
            placeholder="LAIMĖK  prizus!"
            name="gameRules_3"
            value={rulesData.gameRules_3}
            onChange={handleRulesChange}
          />
        </div>
      )}
      {step === 4 && (
        <div>
          <p style={{ fontSize: "18px", fontWeight: 600, marginBottom: "0px" }}>
            Personalize game
          </p>
          <p style={{ fontSize: "14px", color: "#585666" }}>
            Personalize your game appearance
          </p>{" "}
          <p style={{ fontSize: "18px", fontWeight: 600, marginBottom: "0px" }}>
            Intro
          </p>
          <p style={{ fontSize: "14px", color: "#585666" }}>
            Upload game intro
          </p>
          <div
            className="col-12"
            style={{ flexDirection: "row", display: "flex", cursor: "pointer" }}
          >
            <div className="set-profile d-flex">
              <img
                src={personalizationData?.intro_picture_url}
                alt=""
                width={80}
                height={80}
                onClick={(e) => {
                  inputrafIntro?.current?.click();
                }}
              />
              <input
                type="file"
                style={{ display: "none" }}
                ref={inputrafIntro}
                onChange={(e) => {
                  postUploadfile(e, "intro_picture_url");
                }}
              />
            </div>
            <div className="d-flex uplopad col-2 cursor-pointer">
              <img src={upload} alt="" />
              <p
                onClick={(e) => {
                  inputrafIntro?.current?.click();
                }}
              >
                Add intro gif
              </p>
            </div>
          </div>
          <p style={{ fontSize: "18px", fontWeight: 600, marginBottom: "0px" }}>
            Game
          </p>
          <p style={{ fontSize: "14px", color: "#585666" }}>
            Upload game images
          </p>
          <div
            className="col-12"
            style={{ flexDirection: "row", display: "flex", cursor: "pointer" }}
          >
            <div className="set-profile d-flex">
              <img
                src={personalizationData?.game_background_url}
                alt=""
                width={80}
                height={80}
                onClick={(e) => {
                  inputrafGame?.current?.click();
                }}
              />
              <input
                type="file"
                style={{ display: "none" }}
                ref={inputrafGame}
                onChange={(e) => {
                  postUploadfile(e, "game_background_url");
                }}
              />
            </div>
            <div className="d-flex uplopad col-2 cursor-pointer">
              <img src={upload} alt="" />
              <p
                onClick={(e) => {
                  inputrafGame?.current?.click();
                }}
              >
                Add intro gif
              </p>
            </div>
          </div>
        </div>
      )}

      {step === 5 && (
        <RewardPaper
          rewardType={rewardType}
          setRewardType={setRewardType}
          couponData={couponData}
          setCouponData={setCouponData}
          discountAmount={discountAmount}
          setDiscountAmount={setDiscountAmount}
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
          days={days}
          setDays={setDays}
          selectedGameValue={selectedGameValue}
          outcome={outcome}
          setOutcome={setOutcome}
          setScoreboardData={setScoreboardData}
          scoreboardData={scoreboardData}
        />
      )}
      {step === 7 && (
        <div>
          {/* <p
            className="campaign-header"
            style={{ fontSize: step > 1 ? 14 : 32 }}
          >
            Coming soon
          </p> */}
          <p style={{ fontSize: "18px", fontWeight: 600, marginBottom: "0px" }}>
            Set Up
          </p>
          <p style={{ fontSize: "14px", color: "#585666" }}>
            The set up that is necessary in order the game would be launched.{" "}
          </p>
          <p style={{ marginBottom: "20px" }}>
            Write link where the game should appear . It’s recomended to create
            a separate landing page for the game.
          </p>
          <input
            style={{ maxWidth: "100%", marginBottom: "20px" }}
            type="text"
            className="model-input"
            placeholder="http://...."
            name="url"
            onChange={(e) =>
              setSelectUrl(e.target.value, setUrlAvailable(false))
            }
            value={selectUrl}
          />
          <p style={{ marginBottom: "30px", maxWidth: "700px" }}>
            Copy the code that is below and paste it in ‘Head’ part of your
            website.
            <p style={{ display: "inline", fontStyle: "italic" }}>
              Website management platform
            </p>
            {" --> "}
            <p style={{ display: "inline", fontStyle: "italic" }}>
              site settings{" --> "}
            </p>
            <p style={{ display: "inline", fontStyle: "italic" }}>
              custom code {" --> "}
            </p>
            <p style={{ display: "inline", fontStyle: "italic" }}>head part</p>
          </p>
          <p style={{ fontSize: "16px", fontWeight: 700 }}>
            Or send instructions via email to your developer!
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
              paddingTop: "10px",
            }}
          >
            <p
              style={{
                margin: "0px",
              }}
            >
              {urlAvailable
                ? "VERIFIED"
                : "I Have pasted the code in the website"}
            </p>
            <Button
              style={{
                minWidth: "120px",
                marginBottom: "12px",
                marginLeft: "24px",
                height: "40px",
              }}
              className="outline_btn_dark"
              onClick={() => {
                checkUrls();
              }}
            >
              <span>{urlAvailable ? "VERIFIED" : "VERIFY"}</span>
            </Button>
          </div>
        </div>
      )}
      {step === 6 && (
        <div>
          <p style={{ fontSize: "18px", fontWeight: 600, marginBottom: "0px" }}>
            Duration of campaign
          </p>
          <p style={{ fontSize: "14px", color: "#585666" }}>
            Set the duration of your campaign. During the selected time the game
            will be active on your website.{" "}
          </p>
          <div className="w-100 d-flex flex-wrap justify-content-between">
            <div>
              <div className="set-date-picker first-time-reward">
                <div className="d-flex flex-wrap gap-2  mt-7">
                  <div
                    className="boomio_input_main"
                    style={{ minWidth: "250px" }}
                  >
                    <div
                      className="boomio_input boomio_datepicker"
                      style={{
                        position: "relative",
                        paddingRight: "20px",
                        minWidth: "250px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      onClick={() => toggle([0, 0])}
                    >
                      <input
                        type={"hidden"}
                        {...register1("campaignStartDate")}
                      />
                      {`${
                        dateRange?.startDate
                          ? dateRange?.startDate
                          : "Set Start Date"
                      } 
                      `}
                      <img
                        src={datepicker}
                        alt=""
                        onClick={() => toggle([0, 0])}
                      />
                    </div>
                  </div>

                  <div
                    className="boomio_input_main"
                    style={{ minWidth: "250px" }}
                  >
                    <div
                      className={
                        dateRange?.endDate
                          ? "boomio_input boomio_datepicker"
                          : "boomio_input_active boomio_datepicker"
                      }
                      style={{
                        position: "relative",
                        paddingRight: "20px",
                        minWidth: "250px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      onClick={() => toggle([0, 1])}
                      // style={{
                      //   background: "none",
                      //   webkitBackgroundClip: "none",
                      //   color: "red",
                      // }}
                    >
                      <input
                        type={"hidden"}
                        {...register1("campaignEndDate")}
                      />

                      {`
    ${dateRange?.endDate ? dateRange?.endDate : "Set End Date"} `}

                      <img
                        src={datepicker}
                        alt=""
                        onClick={() => toggle([0, 1])}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {errors?.campaignEndDate && (
                <span className="campaings_error">
                  {errors?.campaignEndDate?.message}
                </span>
              )}
            </div>
          </div>
        </div>
      )}
      {step === 8 && (
        <CampaignReview
          rewardType={rewardType}
          discountAmount={discountAmount}
          selectedDay={selectedDay}
          dateRange={dateRange}
          gameSelect={gameSelect}
          campaignName={campaignName}
          selectedGameValue={selectedGameValue}
          campaignAccessibility={campaignAccessibility}
          setStep={setStep}
        />
      )}
      {step === 9 && (
        <div>
          <p style={{ fontSize: "18px", fontWeight: 600 }}>
            Reward code generation
          </p>
        </div>
      )}
    </>
  );
};

export default CampaignAccordion;

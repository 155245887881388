import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import Select from 'react-select'

const AddCustomer = (props) => {

    const channelOptions = [
        { value: 'channe1', label: 'channe1' },
        { value: 'channe2', label: 'channe2' },
        { value: 'channe3', label: 'channe3' }
    ]
    const sourceOptions = [
        { value: ' Source1', label: 'Source1' },
        { value: 'Source2', label: 'Source2' },
        { value: 'Source3', label: 'Source3' }
    ]
    const giftOptions = [
        { value: 'Gift1', label: 'Gift1' },
        { value: 'Gift2', label: 'Gift2' },
        { value: 'Gift3', label: 'Gift3' }
    ]

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='create-coupons-modals add-customer'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {props.type === "add" ? "Add New" : "Edit"} Customer
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-lg-flex justify-content-between'>
                        <div className='activeinput w-45'>
                            <label htmlFor="" className='label-text'>Customer Name *</label>
                            <input type="text" placeholder='My first timer 20% discount campaign' />
                        </div>
                        <div className='activeinput w-45'>
                            <label htmlFor="" className='label-text'>Phone Number * </label>
                            <input type="text" placeholder='2021-12-31 - 2022-01-30' />
                        </div>
                    </div>
                    <div className='d-lg-flex justify-content-between'>
                        <div className='activeinput w-45'>
                            <label htmlFor="" className='label-text'>Email *</label>
                            <input type="text" placeholder='My first timer 20% discount campaign' />
                        </div>
                        <div className='activeinput w-45 mt-4'>
                            <label htmlFor="" className='label-text mt-0'>Channel <span style={{ color: "#FF3183" }}>*</span> </label>
                            <Select
                                name="colors"
                                options={channelOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Add Channels"
                            />
                        </div>
                    </div>
                    <div className='d-lg-flex justify-content-between'>
                        <div className='activeinput w-45 mt-4'>
                            <label htmlFor="" className='label-text mt-0'>Source <span style={{ color: "#FF3183" }}>*</span> </label>
                            <Select
                                name="colors"
                                options={sourceOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Select Source"
                            />
                        </div>

                        <div className='activeinput w-45 mt-4'>
                            <label htmlFor="" className='label-text mt-0'>Add Gift / Coupon <span style={{ color: "#FF3183" }}>*</span> </label>
                            <Select
                                name="colors"
                                options={giftOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Select"
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <a className='templat-img'></a>
                    <div className=''>
                        <Button onClick={props.onHide} className='cancel-btn'>Cancel</Button>
                        <Button onClick={() => { }} className='submit-btn'>Submit</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AddCustomer;

import React, { useEffect, useState } from "react";
import { Button, Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import queryString from "query-string";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import datepicker from "assets/img/date-picker.png";
import { useDispatch } from "react-redux";
import {
  createCampaigns,
  editCampaigns,
  getCampaignsById,
} from "redux/features/campaigns/campaignSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { Switch } from "@mui/material";
import SuccessModal from "pages/componets/modals/SuccessModal";
const EventOrganization = () => {
  const [checkbox, setCheckbox] = useState([]);
  const [activeRadioBtn, setActiveRadioBtn] = useState();
  const [activeRadioBtn2, setActiveRadioBtn2] = useState();
  const [activeRadioBtn3, setActiveRadioBtn3] = useState();
  const [campaignData, setCampaignData] = useState();
  const [modalShow, setModalShow] = React.useState(false);
  const [dateRange, setDateRange] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [isActive, setIsActive] = useState(false);
  const [data, setData] = useState({
    name: "",
    start_date: campaignData?.start_date,
    end_date: campaignData?.end_date,
    cg_id: "",
  });

  const query = queryString.parse(window.location.search);
  const id = query?.id;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const campaignSchema = yup.object({
    name: yup.string().required("Name Is required."),
    description: yup.string().required("description Is required."),
    campaignTitle: yup.string().required("Campaign Title Is required."),
    with_ticket: yup.string().nullable().required("This field Is required."),
    customer_to_send: yup
      .string()
      .nullable()
      .required("This field Is required."),
    when_to_launch: yup.string().nullable().required("This field Is required."),
    start_date: yup.string().required("This field Is required."),
    camp_sent_to_customer: yup
      .array()
      .required("This field Is required.")
      .nullable(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(campaignSchema),
  });
  const handleRadioBtn = (id) => {
    setActiveRadioBtn(id);
  };
  const handleRadioBtn2 = (id) => {
    setActiveRadioBtn2(id);
  };
  const handleRadioBtn3 = (id) => {
    setActiveRadioBtn3(id);
  };

  const handlecheckbox = (id) => {
    if (checkbox.find((a) => a === id)) {
      setCheckbox(checkbox.filter((fil) => fil !== id));
    } else {
      setCheckbox([...checkbox, id]);
    }
  };

  const toggle = () => {
    setOpen(!open);
  };
  const radioBtn = ["Yes", "No"];
  const CheckBoxBtn = ["SMS", "Email", "App"];
  const radioBtn2 = ["All", "New", "Active", "Lapsed"];
  const radioBtn3 = ["Send it Now", "Select A Specific Day"];

  const handleCampaignData = (data) => {
    const returnData = {
      name: data?.name,
      description: data?.description,
      title: data?.campaignTitle,
      camp_sent_to_customer: data?.camp_sent_to_customer,
      customer_to_send: data?.customer_to_send,
      when_to_launch: data?.when_to_launch,
      with_ticket: data?.with_ticket,
      code: "abcd",
      status: isActive === true ? "active" : "inactive",
      start_date: data?.start_date,
      end_date: data?.end_date,
      params: "params",
      scan_allowed: "any",
      win_button_action: "winbutn",
      max_scan: 10,
      max_scan_unit: "days",
      business_id: 1,
      author_u_id: 2,
      modified_u_id: 3,
      campaign_payment_type: "CARD_PAYMENTS",
      campaign_type: "eventOrganization",
    };
    dispatch(
      id ? editCampaigns({ id, ...returnData }) : createCampaigns(returnData)
    );
    setModalShow(true);
  };

  const getCampaignsData = async () => {
    await dispatch(getCampaignsById(id))
      .then(unwrapResult)
      .then((res) => {
        setIsActive(res?.data?.status === "active" ? true : false);
        setCheckbox(res?.data?.camp_sent_to_customer);
        setActiveRadioBtn(res?.data?.with_ticket);
        setActiveRadioBtn2(res?.data?.customer_to_send);
        setActiveRadioBtn3(res?.data?.when_to_launch);
        setData((prev) => ({
          ...prev,
          start_date: moment(res?.data?.start_date).format("YYYY/MM/DD"),
          end_date: moment(res?.data?.end_date).format("YYYY/MM/DD"),
        }));
        setCampaignData(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const goToListPage = () => {
    setModalShow(false);
    navigate("/campaigns");
  };

  useEffect(() => {
    if (id) {
      getCampaignsData();
    }
  }, [id]);

  useEffect(() => {
    checkbox.length > 0 && clearErrors("camp_sent_to_customer");
  }, [checkbox]);

  useEffect(() => {
    reset({
      name: campaignData?.name,
      description: campaignData?.description,
      title: campaignData?.title,
      camp_sent_to_customer: campaignData?.camp_sent_to_customer,
      customer_to_send: campaignData?.customer_to_send,
      with_ticket: campaignData?.with_ticket,
      when_to_launch: campaignData?.when_to_launch,
      start_date: campaignData?.start_date,
      end_date: campaignData?.end_date,
    });
  }, [campaignData]);

  useEffect(() => {
    setData({
      ...data,
      start_date: moment(dateRange.startDate).format("YYYY/MM/DD"),
      end_date: moment(dateRange.endDate).format("YYYY/MM/DD"),
    });
  }, [dateRange]);

  return (
    <>
      <div className="campaigns-heade">
        <h1 className="page-header">Event Organization</h1>
      </div>
      <div className="d-xl-flex">
        <div className="product-design-bg">
          <h2>Event Details</h2>
          <h3>Enter event details</h3>
          <div className="product-design-form">
            <div className="d-sm-flex justify-content-between">
              <div className="activeinput w-45">
                <label htmlFor="" className="label-text">
                  Event Name *
                </label>
                <input
                  type="text"
                  defaultValue={campaignData?.name}
                  {...register("name")}
                  placeholder="My first timer 20% discount campaign"
                />
                {errors?.name && (
                  <p className="error_message">{errors?.name?.message}</p>
                )}
              </div>

              <div
                className="set-date-picker first-time-reward w-45 activeinput w-45"
                style={{ position: "relative" }}
              >
                <label htmlFor="" className="label-text">
                  Event Period *
                </label>
                <div
                  className="date-picker mt-7"
                  {...register("start_date")}
                  {...register("end_date")}
                  onClick={() => setOpen(!open)}
                >
                  {data.start_date ? data.start_date : "Start Date"}{" "}
                  {data.end_date ? " - " + data.end_date : " - End Date"}
                </div>

                {/* <DateRangePicker
                                    open={open}
                                    toggle={toggle}
                                    onChange={(range) => {
                                        clearErrors('start_date')
                                        setValue('start_date', moment(range.startDate).format("YYYY/MM/DD"))
                                        setValue('end_date', moment(range.endDate).format("YYYY/MM/DD"))
                                        setDateRange(range)
                                    }}
                                /> */}
                <img src={datepicker} alt="" onClick={() => setOpen(!open)} />

                {errors?.start_date && (
                  <p className="error_message">{errors?.start_date?.message}</p>
                )}
              </div>
            </div>
            <div className="activeinput w-100">
              <label htmlFor="" className="label-text">
                {" "}
                Event Title *{" "}
              </label>
              <input
                type="text"
                {...register("campaignTitle")}
                defaultValue={campaignData?.title}
                placeholder="Get your 20% discount!"
              />
              {errors?.campaignTitle && (
                <p className="error_message">
                  {errors?.campaignTitle?.message}
                </p>
              )}
              <label htmlFor="" className="label-text">
                Description *
              </label>
              <textarea
                type="text"
                {...register("description")}
                defaultValue={campaignData?.description}
                placeholder="With this coupon you can get 20% discount of a fresh orange juice size of 0,33L."
              />
              {errors?.description && (
                <p className="error_message">{errors?.description?.message}</p>
              )}
            </div>
            <div className="img-template">
              <h4>Image Templates</h4>
              <Button className="">View</Button>
            </div>
            <div className="img-template">
              <h4 className="label-text">Active </h4>
              <Switch
                className="check-btn"
                onChange={(e) => setIsActive(!isActive)}
                checked={isActive}
              />
            </div>
            <div>
              <h4 className="radio-lable">Visit event only with tickets?</h4>
              <div className="create-coupons-Select-Type">
                {radioBtn.map((item) => (
                  <>
                    <label
                      className={` ${
                        activeRadioBtn === item && "bg-red"
                      } form-check`}
                      htmlFor={`rediobtn${item}`}
                      id="test1"
                      onClick={() => handleRadioBtn(item)}
                    >
                      <input
                        className="form-check-input"
                        {...register("with_ticket")}
                        value={item}
                        onChange={() => {
                          clearErrors("with_ticket");
                          setValue("with_ticket", item);
                        }}
                        type="radio"
                        name="flexRadioDefault"
                        id={`rediobtn${item}`}
                      />
                      <label
                        className="form-check-label"
                        for={`rediobtn${item}`}
                      >
                        {item}
                      </label>
                    </label>
                  </>
                ))}
                <div className="img-template">
                  <Button className="">View Tickets</Button>
                </div>
              </div>
              {errors?.with_ticket && (
                <p className="error_message">{errors?.with_ticket?.message}</p>
              )}
            </div>

            <div>
              <h4 className="radio-lable">Customers to send?</h4>
              <div className="create-coupons-Select-Type">
                {radioBtn2.map((item) => (
                  <label
                    className={` ${
                      activeRadioBtn2 === item && "bg-red"
                    } form-check`}
                    htmlFor={`rediobtn2${item}`}
                    id={item}
                    onClick={() => handleRadioBtn2(item)}
                  >
                    <input
                      className="form-check-input"
                      {...register("customer_to_send")}
                      value={item}
                      onChange={() => {
                        clearErrors("customer_to_send");
                        setValue("customer_to_send", item);
                      }}
                      type="radio"
                      name="flexRadioDefault2"
                      id={`rediobtn2${item}`}
                    />
                    <label
                      className="form-check-label"
                      for={`rediobtn2${item}`}
                    >
                      {item}
                    </label>
                  </label>
                ))}
              </div>
              {errors?.customer_to_send && (
                <p className="error_message">
                  {errors?.customer_to_send?.message}
                </p>
              )}
            </div>
            <div>
              <h4 className="radio-lable">
                In what ways this campaign should be sent to customers?
              </h4>
              <div className="create-coupons-Select-Type">
                {CheckBoxBtn.map((item) => (
                  <label
                    key={item}
                    className={`${
                      checkbox.includes(item) && "bg-red"
                    } form-check`}
                    htmlFor={`checkbox${item}`}
                    id={`checkbox${item}`}
                    onClick={() => {
                      handlecheckbox(item);
                    }}
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      {...register("camp_sent_to_customer")}
                      onChange={() => {
                        setValue("camp_sent_to_customer", checkbox);
                      }}
                      value={item}
                      name="camp_sent_to_customer"
                      id={`checkbox${item}`}
                      checked={checkbox.includes(item)}
                    />
                    <label className="form-check-label" for={`checkbox${item}`}>
                      {item}
                    </label>
                  </label>
                ))}
              </div>
              {errors?.camp_sent_to_customer && (
                <p className="error_message">
                  {errors?.camp_sent_to_customer?.message}
                </p>
              )}
            </div>

            <div>
              <h4 className="radio-lable">When to launch this?</h4>
              <div className="create-coupons-Select-Type">
                {radioBtn3.map((item) => (
                  <label
                    className={` ${
                      activeRadioBtn3 === item && "bg-red"
                    } form-check`}
                    htmlFor={`rediobtn3${item}`}
                    id={item}
                    onClick={() => handleRadioBtn3(item)}
                  >
                    <input
                      className="form-check-input"
                      {...register("when_to_launch")}
                      value={item}
                      onChange={() => {
                        clearErrors("when_to_launch");
                        setValue("when_to_launch", item);
                      }}
                      type="radio"
                      name="flexRadioDefault3"
                      id={`rediobtn3${item}`}
                    />
                    <label
                      className="form-check-label"
                      for={`rediobtn3${item}`}
                    >
                      {item}
                    </label>
                  </label>
                ))}
              </div>
              {errors?.when_to_launch && (
                <p className="error_message">
                  {errors?.when_to_launch?.message}
                </p>
              )}
            </div>
            <div className="start-promotional-campaign-footer">
              <Button
                onClick={() =>
                  navigate(id ? "/campaigns" : "/campaigns/start-campaign")
                }
                className="cancel-btn"
              >
                Back
              </Button>
              <Button
                onClick={handleSubmit((data) => {
                  handleCampaignData(data);
                })}
                className="submit-btn"
              >
                {id ? "Update" : "Launch"}
              </Button>
            </div>
          </div>
        </div>
        <div className="product-design-bg-2">
          <h4>Preview</h4>
          <Tabs
            defaultActiveKey="app"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="app" title="App">
              <div className="title-info d-flex align-items-center">
                <h4>CN</h4>
                <div className="title-name">
                  <h3 className="mb-1">Company Name</h3>
                  <p>company@example.com</p>
                </div>
              </div>
              <div className="browse">
                <label htmlFor="file">Browse</label>
                <p>or Drag & Drop your reward here</p>
                <input type="file" id="file" className="d-none" />
              </div>
              <div className="Preview-info">
                <h3>
                  Code: <span>49979999997979</span>
                </h3>
                <p>
                  With this coupon you can get 20% discount of a fresh orange
                  juice size of 0,33L.
                </p>
              </div>
              <p className="valid-date">Valid: 2021-12-31</p>
            </Tab>
            <Tab eventKey="sms" title="SMS">
              <h1>SMS</h1>
            </Tab>
            <Tab eventKey="email" title="Email">
              <h1>Email</h1>
            </Tab>
          </Tabs>
        </div>
      </div>
      {modalShow && (
        <SuccessModal show={modalShow} onHide={() => goToListPage()} />
      )}
    </>
  );
};

export default EventOrganization;

import React, { useState } from 'react';
import { Button, Tab, Tabs } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import queryString from 'query-string';
import { createCampaigns, editCampaigns } from 'redux/features/campaigns/campaignSlice';
import { useDispatch } from 'react-redux';
import SuccessModal from 'pages/componets/modals/SuccessModal';
const GetFeedback = () => {
    const dispatch = useDispatch()
    const [checkbox, setCheckbox] = useState([])
    const [activeRadioBtn, setActiveRadioBtn] = useState()
    const [activeRadioBtn2, setActiveRadioBtn2] = useState()
    const [activeRadioBtn3, setActiveRadioBtn3] = useState()
    const [activeRadioBtn4, setActiveRadioBtn4] = useState()
    const [isActive, setIsActive] = useState(false)
    const [modalShow, setModalShow] = React.useState(false);

    const query = queryString.parse(window.location.search);
    const id = query?.id
    const navigate = useNavigate()

    const handleRadioBtn = (id) => {
        setActiveRadioBtn(id)
    }
    const handleRadioBtn2 = (id) => {
        setActiveRadioBtn2(id)
    }
    const handleRadioBtn3 = (id) => {
        setActiveRadioBtn3(id)
    }
    const handleRadioBtn4 = (id) => {
        setActiveRadioBtn4(id)
    }

    const campaignSchema = yup.object({
    });

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(campaignSchema),
    });

    const handlecheckbox = (id) => {
        if (checkbox.find((a) => a === id)) {
            setCheckbox(checkbox.filter((fil) => fil !== id))
        } else {
            setCheckbox([...checkbox, id])
        }
    }

    const handleCampaignData = (data) => {
        const returnData = {
            title: data?.campaignTitle,
            camp_sent_to_customer: data?.camp_sent_to_customer,
            customer_to_send: data?.customer_to_send,
            when_to_launch: data?.when_to_launch,
            status: isActive ? 'active' : 'inactive',
            params: "params",
            scan_allowed: "any",
            win_button_action: "winbutn",
            max_scan_unit: "days",
            business_id: 1,
            author_u_id: 2,
            modified_u_id: 3,
            campaign_type: "getFeedback",
            frequency: data?.frequency
        }
        dispatch(id ? editCampaigns({ id, ...returnData }) : createCampaigns(returnData)).then((result) => {
            setModalShow(true)
            return result
        }).catch((error) => {
            console.error(error);
        });

    }

    const goToListPage = () => {
        setModalShow(false)
        navigate('/campaigns')
    }

    const radioBtn = ["Per Day", "Per Week", "Per Month", "Per Campaign"]
    const radioBtn2 = ["All", "New", "Active", "Lapsed"]
    const CheckBoxBtn = ["SMS", "Email", "App"]
    const radioBtn3 = ["Send it Now", "Select A Specific Day"]
    const radioBtn4 = ["Yes, I did", "No, did not", "Not bad, but will not buy in the future", "Do not have opinion"]
    return (
        <>
            <div className='campaigns-heade'>
                <h1 className='page-header'>Get Feedback</h1>
            </div>
            <div className='d-xl-flex'>
                <div className='product-design-bg'>
                    <h2>Campaign Details</h2>
                    <h3>Enter campaign details</h3>
                    <div className='product-design-form'>
                        <div className='d-flex justify-content-between'>
                            <div className='activeinput w-100'>
                                <label htmlFor="" className='label-text'> Campaign Title *</label>
                                <input type="text" {...register('campaignTitle')} placeholder='Get your 20% discount!' />
                                {errors?.campaignTitle && <p className='error_message'>{errors?.campaignTitle?.message}</p>}
                            </div>
                        </div>
                        <div className='img-template'>
                            <h4>Your Question</h4>
                        </div>
                        <div className='activeinput w-100'>
                            <label htmlFor="" className='label-text'> Question *</label>
                            <input type="text" {...register('question')} placeholder='Did you like the new coffee flavor?' />
                            {errors?.question && <p className='error_message'>{errors?.question?.message}</p>}
                        </div>
                        <div className='d-sm-flex justify-content-between'>
                            <div className='activeinput w-45 position-relative'>
                                <label htmlFor="" className='label-text'>Option 1 *</label>
                                <div className='search'>
                                    <input
                                        type="text"
                                        value=""
                                        name='Search'
                                        onChange={() => { }}
                                        className="search-input"
                                        placeholder='Yes, I did'
                                        {...register('option1')}
                                    />
                                    <img src="./assets/img/minus-round.png" alt="" className='' />
                                </div>
                                {errors?.option1 && <p className='error_message'>{errors?.option1?.message}</p>}
                            </div>
                            <div className='activeinput w-45'>
                                <label htmlFor="" className='label-text'>Option 2 * </label>
                                <div className='search'>
                                    <input
                                        type="text"
                                        value=""
                                        name='Search'
                                        onChange={() => { }}
                                        className="search-input"
                                        placeholder='No, did not'
                                        {...register('option2')}
                                    />
                                    <img src="./assets/img/minus-round.png" alt="" className='' />
                                </div>
                                {errors?.option2 && <p className='error_message'>{errors?.option2?.message}</p>}
                            </div>
                        </div>
                        <div className='d-sm-flex justify-content-between'>
                            <div className='activeinput w-45'>
                                <label htmlFor="" className='label-text'>Option 3 *</label>
                                <div className='search'>
                                    <input
                                        type="text"
                                        value=""
                                        name='Search'
                                        onChange={() => { }}
                                        className="search-input"
                                        placeholder='Not bad, but will not buy in the future'
                                        {...register('option3')}
                                    />
                                    <img src="./assets/img/minus-round.png" alt="" className='' />
                                </div>
                                {errors?.option3 && <p className='error_message'>{errors?.option3?.message}</p>}
                            </div>
                            <div className='activeinput w-45 '>
                                <label htmlFor="" className='label-text'>Option 4 * </label>
                                <div className='search'>
                                    <input
                                        type="text"
                                        value=""
                                        name='Search'
                                        onChange={() => { }}
                                        className="search-input"
                                        placeholder='Do not have opinion'
                                        {...register('option4')}
                                    />
                                    <img src="./assets/img/minus-round.png" alt="" className='' />
                                </div>
                                {errors?.option4 && <p className='error_message'>{errors?.option4?.message}</p>}
                            </div>
                        </div>
                        <div className='mt-4 add-opsition'>
                            <div className='add-option mt-2'>
                                <img src="./assets/img/plus-round.png" alt="" />
                                <h6>Add Another Option</h6>
                            </div>
                            <div className='add-option mt-2'>
                                <img src="./assets/img/plus-round.png" alt="" />
                                <h6>Add Another Question</h6>
                            </div>
                        </div>
                        <h4 className='radio-lable'>Questions suggestions:</h4>
                        <div className='questions-suggestions'>
                            <div className='activeinput w-45'>
                                <input type="text" placeholder='What encourages you shopping with us?' />
                                <input type="text" placeholder='How could we improve our service?' />
                                <input type="text" placeholder='Question #3' />
                            </div>
                            <div className='activeinput w-45'>
                                <input type="text" placeholder='Question #4' />
                                <input type="text" placeholder='Question #5' />
                                <input type="text" placeholder='Question #6' />
                            </div>
                        </div>
                        <div className='img-template'>
                            <h4>Prizes</h4>
                            <Button className=''>Add Prizes</Button>
                        </div>
                        <h4 className='radio-lable'>Frequency? <span>(one time)</span></h4>
                        <div className='create-coupons-Select-Type'>

                            {radioBtn.map((item) => (
                                <label className={` ${activeRadioBtn === item && "bg-red"} form-check`} htmlFor={`rediobtn${item}`} id='test1' onClick={() => handleRadioBtn(item)}>
                                    <input {...register('frequency')} value={item} onChange={() => { setValue('frequency', item) }} className="form-check-input" type="radio" name="flexRadioDefault" id={`rediobtn${item}`} />
                                    <label className="form-check-label" for={`rediobtn${item}`}>
                                        {item}
                                    </label>
                                </label>
                            ))}

                        </div>
                        <h4 className='radio-lable'>Customers to send?</h4>
                        <div className='create-coupons-Select-Type'>

                            {radioBtn2.map((item) => (
                                <label className={` ${activeRadioBtn2 === item && "bg-red"} form-check`} htmlFor={`rediobtn2${item}`} id={item} onClick={() => handleRadioBtn2(item)}>
                                    <input {...register('customer_to_send')} value={item} onChange={() => { setValue('customer_to_send', item) }} className="form-check-input" type="radio" name="flexRadioDefault2" id={`rediobtn2${item}`} />
                                    <label className="form-check-label" for={`rediobtn2${item}`}>
                                        {item}
                                    </label>
                                </label>
                            ))}

                        </div>
                        <h4 className='radio-lable'>In what ways this campaign should be sent to customers?</h4>
                        <div className='create-coupons-Select-Type'>

                            {CheckBoxBtn.map((item) => (
                                <label className={`${checkbox.includes(item) && "bg-red"} form-check`} htmlFor={`checkbox${item}`} id={`checkbox${item}`} onClick={() => handlecheckbox(item)}>
                                    <input {...register('camp_sent_to_customer')} value={item} className="form-check-input" type="checkbox" onChange={setValue('camp_sent_to_customer', checkbox)} name="flexRadioDefault" id={`checkbox${item}`} checked={checkbox.includes(item)} />
                                    <label className="form-check-label" for={`checkbox${item}`} >
                                        {item}
                                    </label>
                                </label>
                            ))}

                        </div>
                        <h4 className='radio-lable'>When to launch this?</h4>
                        <div className='create-coupons-Select-Type'>

                            {radioBtn3.map((item) => (
                                <label className={` ${activeRadioBtn3 === item && "bg-red"} form-check`} htmlFor={`rediobtn3${item}`} id={item} onClick={() => handleRadioBtn3(item)}>
                                    <input {...register('when_to_launch')} value={item} onChange={() => { setValue('when_to_launch', item) }} className="form-check-input" type="radio" name="flexRadioDefault3" id={`rediobtn3${item}`} />
                                    <label className="form-check-label" for={`rediobtn3${item}`}>
                                        {item}
                                    </label>
                                </label>
                            ))}

                        </div>
                    </div>
                    <div className='start-promotional-campaign-footer'>
                        <Button onClick={() => navigate('/campaigns/start-campaign/automatic-campaign')} className='cancel-btn'>Back</Button>
                        <Button onClick={handleSubmit((data) => {
                            handleCampaignData(data)
                        })} className='submit-btn'>{id ? "Update" : "Launch"}</Button>
                    </div>
                </div>
                <div className='product-design-bg-2'>
                    <h4>Preview</h4>
                    <Tabs
                        defaultActiveKey="app"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                    >
                        <Tab eventKey="app" title="App">
                            <div className="title-info d-flex align-items-center">
                                <h4>CN</h4>
                                <div className="title-name" >
                                    <h3 className="mb-1">Company Name</h3>
                                    <p>company@example.com</p>
                                </div>
                            </div>
                            <div className="browse-red">
                                <label htmlFor="file">Browse</label>
                                <p>or Drag & Drop your reward here</p>
                                <input type="file" id="file" className="d-none" />
                                <div className='discription'>
                                    <h5>Did you like the new <br /> coffee flavor?</h5>
                                </div>
                            </div>
                            <div className='create-coupons-Select-Type'>

                                {radioBtn4.map((item) => (
                                    <label className={` ${activeRadioBtn4 === item && "bg-red"} form-check`} htmlFor={`rediobtn4${item}`} id={item} onClick={() => handleRadioBtn4(item)}>
                                        <input className="form-check-input" type="radio" name="flexRadioDefault4" id={`rediobtn4${item}`} />
                                        <label className="form-check-label" for={`rediobtn4${item}`}>
                                            {item}
                                        </label>
                                    </label>
                                ))}

                            </div>
                            <div className='single-btn text-left'>
                                <Button onClick={() => { }} className='submit-btn'>Next</Button>
                            </div>
                        </Tab>
                        <Tab eventKey="sms" title="SMS">
                            <h1>SMS</h1>
                        </Tab>
                        <Tab eventKey="email" title="Email">
                            <h1>Email</h1>
                        </Tab>
                    </Tabs>
                </div>
            </div>
            {
                modalShow && <SuccessModal show={modalShow} onHide={() => goToListPage()} />
            }
        </>
    );
};

export default GetFeedback;

import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import connectArrow from "../../assets/img/connect-your-e-store.png";
import platform_button_img from "../../assets/img/platform_button.png";
import other_platforms from "../../assets/img/other_platforms.png";
import { ApiPost } from "helper/API/ApiData";
import { toast } from "react-toastify";
import AuthStorage from "helper/AuthStorage";
import STORAGEKEY from "config/APP/app.config";
import { BusinessBaseUrl } from "helper/API/BaseUrl";
import { useNavigate } from "react-router";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { UserBaseUrl } from "helper/API/BaseUrl";
import ReactGA from "react-ga4";

const ConnectYourEStore = () => {
  const [tab, setTab] = useState("platform");
  const [storeName, setStoreName] = useState(
    AuthStorage.getStorageData(STORAGEKEY.assumedShop) || ""
  );
  const [storeNameError, setStoreNameError] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectOption, setSelectOption] = useState();
  const data = useSelector((state) => state.authState.dashboardData.params);

  const handleConfirm = async (data) => {
    if (selectOption) {
      let body = {
        body: selectOption?.value ?? selectOption,
        email: data?.extra_fields?.email,
        title: "More Shop integrations",
      };

      await ApiPost(`${UserBaseUrl}help`, body).then((res) => {
        toast.success(
          "Your selection will help us prioritize integrations. Thank you for shaping the future of our platform!",
          {
            closeOnClick: true,
            pauseOnHover: true,
          }
        );
        setOpen(false);
      });
    }
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    ReactGA.event({
      action: "Start connecting others",
      category: "Connect your e-shop",
      value: "",
    });
    setOpen(true);
  };

  const handleSetTab = () => {
    ReactGA.event({
      action: "Start connecting shopify",
      category: "Connect your e-shop",
      value: "",
    });
    setTab("address");
  };

  const navigate = useNavigate();

  const handleSubmit = () => {
    if (storeName !== "" && storeName !== null) {
      const body = {
        webshop_name: storeName,
      };
      ApiPost(`${BusinessBaseUrl}robot/generate-token?robotType=shopify`, body)
        .then((res) => {
          if (res && res.response && res.response.install_link) {
            ReactGA.event({
              action: "Lands on shopify install app",
              category: "Connect your e-shop",
              value: "",
            });
            window.open(res.response.install_link, "_blank");
          }
        })
        .catch((err) => {
          toast.error(err && err.response?.data?.metadata?.res_msg, {
            closeOnClick: true,
            pauseOnHover: true,
          });
        });
    } else {
      setStoreNameError(true);
    }
  };

  const shopOptions = [
    { value: "WooCommerce", label: "WooCommerce" },
    { value: "WixStores", label: "Wix Stores" },
    { value: "Squarespace", label: "Squarespace" },
    { value: "Ecwid", label: "Ecwid" },
  ];
  return (
    <div className="connect-your-e-store-main">
      <Modal
        show={open}
        onHide={handleCancel}
        backdrop="static"
        centered
        aria-labelledby="example-custom-modal-styling-title"
        className="initial_add_store_modal"
        style={{ width: "100% !important" }}
      >
        <Modal.Body className="plansModal">
          <img
            onClick={handleCancel}
            src="./assets/img/closeIcon.png"
            className="closeIcon icon-size"
            alt="close"
          />
          <div
            className="initial_add_store_main"
            style={{ flexDirection: "column" }}
          >
            <h1
              className="header"
              style={{ marginBottom: "40px", textAlign: "center" }}
            >
              Choose Your Platform
            </h1>
            <p
              style={{
                marginBottom: "40px",
                fontWeight: 600,
                textAlign: "start",
              }}
            >
              Exciting news! We're expanding Boomio's integration. Choose from
              the list below or enter another platform:
            </p>
            <p style={{ marginBottom: "20px", textAlign: "start" }}>
              Choose a platform for Boomio integration.
            </p>
            <Select
              name="colors"
              options={shopOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select shop"
              value={selectOption}
              onChange={(value) => setSelectOption(value)}
              styles={{
                valueContainer: (base) => ({
                  ...base,
                  height: "56px",
                  borderRadius: "16px",
                }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
            />
            <p style={{ margin: "20px 0px", textAlign: "start" }}>
              Or enter the name of another platform
            </p>
            <TextField
              onChange={(e) => setSelectOption(e.target.value)}
              placeholder="Shop name"
              style={{
                border: "1px solid hsl(0, 0%, 80%)",
                padding: "0px",
                borderRadius: "4    px",
                width: "100%",
                margin: "0px 0px 40px 0px",
              }}
            />
            <div style={{ flexDirection: "row", width: "100%" }}>
              <Button
                onClick={handleCancel}
                className="button-8559F3 boomio_input boomio_input_main mx-3"
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirm}
                className="button-8559F3 boomio-white mx-3"
              >
                Submit
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className="title">
        <img
          onClick={() => {
            navigate("/campaigns");
          }}
          style={{ cursor: "pointer" }}
          src={connectArrow}
          alt=""
        />
        <h1>Connect your e-shop</h1>
      </div>
      <div className="relative mb-3">
        <div className="d-flex steper justify-content-start m-0">
          <p
            onClick={() => setTab("platform")}
            style={{ cursor: "pointer" }}
            className={`${tab === "platform" && "active"}`}
          >
            1. Select a platform
          </p>
          <img src="./assets/img/rightStep.png" className="rightIcon" alt="" />
          <p className={`${tab === "address" && "active"}`}>
            2. Enter your e-shop address
          </p>
        </div>
      </div>
      {tab === "platform" && (
        <div className="tab_card">
          <p>
            Select a platform of your online e-shop and follow other steps to
            continue.{" "}
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="platform_conntent" onClick={handleSetTab}>
              <img src={platform_button_img} alt="" />
            </div>
            <div
              className="platform_conntent wix_platform"
              onClick={handleOpen}
              style={{
                backgroundImage: `url(${other_platforms})`,
                backgroundSize: "cover",
                height: "141px",
                backgroundPosition: "center",
              }}
            >
              <h1>Other platforms</h1>
            </div>
          </div>
        </div>
      )}
      {tab === "address" && (
        <>
          <div className="tab_card" style={{ maxWidth: "683px" }}>
            <p>Enter your e-shop adress: </p>
            <div style={{ textAlign: "end" }}>
              <div className="mb-5" style={{ textAlign: "start" }}>
                <div className="e_store_address_input_main">
                  <p className="e_store_address_span_start">https://</p>
                  <input
                    type="text"
                    className="e_store_address_input"
                    placeholder="your-e-shop-name"
                    value={storeName}
                    onChange={(e) => setStoreName(e.target.value)}
                    name="storeName"
                  />
                  <p className="e_store_address_span_end">.myshopify.com</p>
                </div>
                {storeNameError && (
                  <Form.Text className="color e_store_address_error ">
                    E-shop name required
                  </Form.Text>
                )}
              </div>
              <Button
                onClick={handleSubmit}
                className="button-8559F3 boomio-white"
              >
                Connect
              </Button>
            </div>
          </div>
          <div className="address_list_main" style={{ maxWidth: "683px" }}>
            <ul>
              <li>
                Create specific mini-game campaigns for e-shop that fit your
                marketing goals.
              </li>
              <li>
                Increase repeat purchases and retention with integrated loyalty
                program.
              </li>
              <li>
                See real-time analytics allowing to make data-driven decisions
                to your strategy.
              </li>
              <li>
                Have your e-shop listing on Boomio mobile app with dedicated ad
                space.
              </li>
              <li>
                Create campaigns with flexible rewards and rewards expiration
                reminders.
              </li>
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default ConnectYourEStore;

import { API } from "config/Webservice/Webservice";
import AuthStorage from "helper/AuthStorage";

const defaultHeaders = {
  isAuth: true,
  AdditionalParams: {},
  isJsonRequest: false,
};

export const ApiGet = (type) => {
  return new Promise((resolve, reject) => {
    API.get(`${type}`, getHttpOptions())
      .then((responseJson) => {
        resolve(responseJson.data);
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error.response.data.error);
        } else {
          reject(error);
        }
      });
  });
};

export const ApiPost = (type, userData) => {
  return new Promise((resolve, reject) => {
    API.post(`${type}`, userData, getHttpOptions())
      .then((responseJson) => {
        resolve(responseJson.data);
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error.response.data.error);
        } else {
          reject(error);
        }
      });
  });
};

export const ApiPatch = (type, userData) => {
  return new Promise((resolve, reject) => {
    API.patch(`${type}`, userData, getHttpOptions())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error.response.data.error);
        } else {
          reject(error);
        }
      });
  });
};

export const ApiDelete = (type) => {
  return new Promise((resolve, reject) => {
    API.delete(`${type}`, getHttpOptions())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error.response.data.error);
        } else {
          reject(error);
        }
      });
  });
};

export const ApiPut = (type, userData) => {
  return new Promise((resolve, reject) => {
    API.put(`${type}`, userData, getHttpOptions())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error.response.data.error);
        } else {
          reject(error);
        }
      });
  });
};

export const getHttpOptions = (options = defaultHeaders) => {
  let headers = {
    Authorization: "",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": null,
    "Access-Control-Allow-credentials": true,
  };
  // "Origin, X-Requested-With, Content-Type, Accept"

  if (options.hasOwnProperty("isAuth") && options.isAuth) {
    headers["Authorization"] = AuthStorage.getToken() ?? "";
  }

  if (options.hasOwnProperty("isJsonRequest") && options.isJsonRequest) {
    headers["Content-Type"] = "application/x-www-form-urlencoded";
  }

  if (options.hasOwnProperty("AdditionalParams") && options.AdditionalParams) {
    headers = { ...headers, ...options.AdditionalParams };
  }

  return { headers };
};

export function apiClient({
  url,
  data = {},
  method = "",
  headers = {},
  noHeaders,
  ...rest
}) {
  const accessToken = AuthStorage.getToken();
  const defaultHeaders = {
    "Access-Control-Allow-Origin": null,
    "Access-Control-Allow-credentials": true,
    Authorization: `${accessToken}`,
    "Content-Type": "application/json;charset=UTF-8",
  };
  return new Promise((resolve, reject) => {
    API({
      method,
      url,
      headers: defaultHeaders,
      data,
      ...rest,
    })
      .then(async (res) => {
        resolve(res);
      })
      .catch(async (error) => {
        reject(error);
      });
  });
}

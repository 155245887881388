import EllipseBold from "../assets/img/ellipse-bold.png";
import React from "react";
import Ellipse from "../assets/img/ellipse.png";

const RadioButton = ({
  id,
  checked,
  img,
  text,
  value,
  onChange,
  width,
  justifyContent,
  textTwo,
  margin,
  color,
}) => {
  return (
    <>
      <input
        type="radio"
        className="btn-check"
        name="options"
        id={id}
        value={value}
        autoComplete="off"
        defaultChecked={checked}
        style={{ width: "100%" }}
      />
      <label
        style={{
          width: width ?? "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: justifyContent ?? "start",
          color: color,
          cursor: "pointer",
        }}
        htmlFor={id}
        onClick={onChange}
      >
        {img === null || (
          <img
            src={img || (checked ? EllipseBold : Ellipse)}
            style={{ marginLeft: "10px" }}
            alt=""
          />
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: justifyContent ? "10px" : "20px",
          }}
        >
          <span style={{ fontWeight: 600 }}>&nbsp;{text}</span>
          {textTwo && <span style={{ fontSize: "12px" }}>&nbsp;{textTwo}</span>}
        </div>
      </label>
    </>
  );
};

export default RadioButton;

import React from "react";

const numberRegex = /^[0-9\b]+$/;

const NumberInputField = (props) => {
  const { setdays, ...inputProps } = props;
  const handleChange = (e) => {
    if (e?.target?.value && numberRegex.test(e.target.value)) {
      props.setdays(e.target.value);
    } else {
      props.setdays("");
    }
  };

  return (
    <input
      className={props.disabled ? "boomio_input_disabled" : "boomio_input"}
      placeholder="Enter Custom"
      aria-label="default input example"
      {...inputProps}
      type="text"
      onChange={handleChange}
    />
  );
};

export default NumberInputField;

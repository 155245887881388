import Checkbox from "../assets/img/checkbox-boomio.png";
import React from "react";
import CheckboxChecked from "../assets/img/checkbox-boomio-checked.png";

const CheckboxButton = ({ id, checked, img, text, value, onChange }) => {
  return (
    <>
      <input
        type="checkbox"
        className="btn-check"
        name="options"
        id={id}
        value={value}
        autoComplete="off"
        checked={checked}
      />
      <label
        className={
          checked
            ? "btn btn-secondary boomio-light-gradient boomio-campaign-active-btn boomio-checkbox mb-2"
            : "btn btn-secondary boomio-white-gradient boomio-campaign-btn boomio-checkbox mb-2"
        }
        htmlFor={id}
        onClick={onChange}
      >
        {img === null || (
          <img src={img || (checked ? CheckboxChecked : Checkbox)} alt="" />
        )}
        &nbsp;{text}
      </label>
    </>
  );
};

export default CheckboxButton;

import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router";
import connect_store_success from "../../../assets/img/connect_store_success.png";
import ReactGA from "react-ga4";

const AddStoreResponse = ({ url, addStoreResModal, onHide }) => {
  const navigate = useNavigate();

  const handleOk = () => {
    navigate("/campaigns");
    onHide();
  };

  const handleSuccess = () => {
    ReactGA.event({
      action: "Connected store",
      category: "Connect your e-shop",
      value: "",
    });
    if (initialChoice === '"preview"') {
      window.open(`${url}?demo=1`, "_blank");
    } else if (initialChoice === '"campaign"') {
      navigate("/campaigns/boost-product");
    } else {
      navigate("/campaigns");
    }
    onHide();
  };

  const initialChoice = localStorage.getItem("initialChoice");
  return (
    <Modal
      show={addStoreResModal.open}
      onHide={onHide}
      backdrop="static"
      centered
      dialogClassName="modal-90w"
      className="add_store_modal"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Body className="plansModal">
        <img
          onClick={handleOk}
          src="./assets/img/closeIcon.png"
          className="closeIcon icon-size"
          alt="close"
        />
        {addStoreResModal.type === "success" && (
          <div className="add_store_main">
            <h3 className="header">You’re all set!</h3>
            <img src={connect_store_success} alt="" />
            <p>
              Well done, your e-shop is successfully added and connected with
              Boomio !
            </p>
            <h4 className="add_store_bottom_text mb-5">
              Don’t forget to enable Boomio app in Shopify.{" "}
              <u
                style={{ cursor: "pointer" }}
                className="add_store_bottom_text_link"
                onClick={() => window.open("/settings", "_blank")}
              >
                A step by step guide is here
              </u>
            </h4>

            <Button
              style={{ width: "fit-content" }}
              onClick={handleSuccess}
              className="button-8559F3 boomio-white"
            >
              {initialChoice === '"preview"'
                ? "See preview"
                : initialChoice === '"campaign"'
                ? "Start Campaign"
                : "Start Exploring Boomio"}
            </Button>
          </div>
        )}
        {addStoreResModal.type === "error" && (
          <div className="add_store_main">
            <h2 className="header color">Could not connect to e-shop!</h2>
            <img src={connect_store_success} alt="" />
            <h4 className="add_store_bottom_text mb-5">
              Don’t forget to enable Boomio app in Shopify.{" "}
              <u
                className="add_store_bottom_text_link"
                onClick={() => window.open("/settings", "_blank")}
              >
                A step by step guide is here
              </u>
            </h4>
            <Button onClick={handleOk} className="button-8559F3 boomio-white">
              Close
            </Button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AddStoreResponse;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiGet, ApiPatch } from "helper/API/ApiData";
import {
  UserBaseUrl,
  BusinessBaseUrl,
  CampaignBaseUrl,
} from "helper/API/BaseUrl";
import { t } from "i18next";
import { toast } from "react-toastify";

export const getUserDataAPI = createAsyncThunk(
  "authentication/loginUserAPI",
  async () => {
    return await ApiGet(`${UserBaseUrl}users/me`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("error getting user data");
      });
  }
);

export const editUserDataAPI = createAsyncThunk(
  "authentication/loginUserAPI",
  async (editData) => {
    const userData = editData[0];
    const data = editData[1];
    const navigate = editData[2];
    delete data?.confirmPassword;
    const body = {
      id: userData?.id,
      name: data?.name,
      surname: data?.surname,
      phone: data?.phone,
      job_title: data?.job_title,
    };
    ApiPatch(`${UserBaseUrl}users/me`, body)
      .then(async (res) => {
        toast.success(t("EditProfile.Toast.success"), {
          closeOnClick: true,
          pauseOnHover: true,
        });
        navigate("/campaigns");
      })
      .catch((err) => {
        toast.error(t("EditProfile.Toast.error"), {
          closeOnClick: true,
          pauseOnHover: true,
        });
        console.log(err);
      });
  }
);

export const getDashboardUserDataAPI = createAsyncThunk(
  "authSlice/getDashboardUserDataAPI",
  async () => {
    return await ApiGet(`${BusinessBaseUrl}business/get/self`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        err = { isError: true };
        return err;
      });
  }
);

export const getCampaignByBusiness = createAsyncThunk(
  "authSlice/getCampaignByBusiness",
  async () => {
    return await ApiGet(`${CampaignBaseUrl}campaign/by-business`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        err = { isError: true };
        return err;
      });
  }
);

export const authSlice = createSlice({
  name: "authSlice",
  initialState: { entities: [], loading: false, dashboardData: [] },
  extraReducers: {
    [getDashboardUserDataAPI.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.value += action.payload;
      state.dashboardData = JSON.parse(JSON.stringify(action.payload.response));
    },
    [getDashboardUserDataAPI.reject]: (state, action) => {
      state.isLoading = false;
    },
    [getDashboardUserDataAPI.pending]: (state, action) => {
      state.isLoading = true;
    },
  },
});

export default authSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

export const initialSlice = createSlice({
    name: 'initialState',
    initialState: { language: "en-us" },
    reducers: {
        setLanguage: (state, action) => {
            state.language.push(action.payload);
        },
    }
});

// this is for dispatch
export const { setLanguage } = initialSlice.actions;

// this is for configureStore
export default initialSlice.reducer;
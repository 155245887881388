import React from "react";
import Puzzle_game_preview from "../../../assets/img/Puzzle_game_preview.png";
import Spin_the_wheel_preview_game from "../../../assets/img/Spin_the_wheel_preview_game.png";
import Memory_card_game_Boomio_preview from "../../../assets/img/Memory_card_game_Boomio_preview.png";
import Whack_a_mole_boomio_game_preview from "../../../assets/img/Whack-a-mole_boomio_game_preview.png";
import clawGame from "../../../assets/img/clawGame.png";

const RevealTheReward = (props) => {
  const { couponData } = props;

  console.log(props);
  return (
    <>
      <div
        className={`card_arrow_after position-relative reveal_the_reward p-2 `}
        style={{ marginTop: "60px" }}
      >
        {props.gameSelect === "puzzle" ? (
          <img src={Puzzle_game_preview} alt="" />
        ) : props.gameSelect === "whack" ? (
          <img
            src={Whack_a_mole_boomio_game_preview}
            alt=""
            style={{ width: "300px", marginLeft: "-20px" }}
          />
        ) : props.gameSelect === "claw" ? (
          <img
            src={clawGame}
            alt=""
            style={{ width: "280px", marginLeft: "10px" }}
          />
        ) : props.gameSelect === "guess" ? (
          <img
            src={Memory_card_game_Boomio_preview}
            alt=""
            style={{
              width: "500px",
              marginLeft: "-110px",
              marginTop: "-150px",
            }}
          />
        ) : props.gameSelect === "wheel" ? (
          <div style={{ position: "relative", margin: "20px" }}>
            <div
              style={{
                position: "absolute",
                top:
                  couponData?.params?.couponCodeDiscount !== "Free"
                    ? "50px"
                    : "50px",
                left:
                  couponData?.params?.couponCodeDiscount !== "Free"
                    ? "95px"
                    : "90px",
                backgroundColor: "#fb9b47",
                height:
                  couponData?.params?.couponCodeDiscount !== "Free"
                    ? "25px"
                    : "25px",
                width: "60px",
                transform: "rotate(270deg)",
              }}
            >
              <div
                style={{
                  color: "white",
                }}
              >
                <div className="coupon_info">
                  <p className="spinner_text">
                    {couponData?.params?.couponCodediscountText !==
                    "Discount" ? (
                      <>
                        {`${
                          couponData?.params?.couponCodeDiscount
                            ? couponData?.params?.couponCodeDiscount
                            : " 0% "
                        }
                    ${
                      couponData?.params?.couponCodediscountText !==
                        "Discount" && couponData?.params?.couponCodediscountText
                        ? couponData?.params?.couponCodediscountText
                        : " OFF"
                    }`}
                      </>
                    ) : (
                      <div>
                        <p style={{ fontSize: "11px" }}>
                          {couponData?.params?.couponCodeDiscount
                            ? couponData?.params?.couponCodeDiscount
                            : " 0% "}{" "}
                          OFF
                        </p>
                      </div>
                    )}
                  </p>
                </div>
              </div>
            </div>
            <img
              src={Spin_the_wheel_preview_game}
              alt=""
              style={{ width: "250px", marginLeft: "-5px" }}
            />
          </div>
        ) : (
          <div className="coupon_preview_card_info">
            <div className="silver_bg hammer_ice">
              <div className="coupon_info" style={{ padding: "12px" }}>
                <h3 className="count">
                  {couponData?.params?.couponCodeDiscount
                    ? couponData?.params?.couponCodeDiscount
                    : " 0 %"}
                </h3>
                <h3 className="count_tow">
                  {couponData?.params?.couponCodediscountText
                    ? couponData?.params?.couponCodediscountText
                    : " Discount"}
                </h3>
                <p>
                  Unique code:{" "}
                  {couponData?.params?.couponCode
                    ? couponData?.params?.couponCode
                    : " XXXXXXX"}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RevealTheReward;

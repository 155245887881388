import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { ApiGet, ApiPost, ApiPut } from "helper/API/ApiData"
import { CouponsBaseUrl, FileBaseUrl } from "helper/API/BaseUrl"

export const logoUpload = createAsyncThunk(
    'coupons/logoUpload',
    async (data) => {
        const type = data[0]
        const file = data[1]
        const formData = new FormData();
        formData.append('group', 'test');
        formData.append('subgroup', 'test');
        if (type === 'mini') {
            formData.append('filename', file.name);
            formData.append('file', file);
            return await ApiPost(`${FileBaseUrl}api/campaign/upload-file`, formData).then((res) => {
                return res.data.url_to_aws
            })
        } else if (type === 'large') {
            formData.append('filename', file.name);
            formData.append('file', file);
            return await ApiPost(`${FileBaseUrl}api/campaign/upload-file`, formData).then((res) => {
                if (res.status === 200) {
                    return res.data.url_to_aws
                }
            })
        }
    }
)

export const createCoupons = createAsyncThunk(
    'campaigns/createCampaigns',
    (data) => {
        return ApiPost(`${CouponsBaseUrl}coupon/visual`, data)
            .then((res) => {
                return res
            })
            .catch((err) => {
                console.log("err", err);
                return err
            });
    }
)
export const updateCoupons = createAsyncThunk(
    'campaigns/createCampaigns',
    (data) => {
        return ApiPut(`${CouponsBaseUrl}coupon/visual`, data)
            .then((res) => {
                return res
            })
            .catch((err) => {
                console.log("err", err);
                return err
            });
    }
)


export const getAllCouponsData = createAsyncThunk(
    'coupons/getAllCoupons',
    async (data, thunkAPI) => {
        return await ApiGet(`${CouponsBaseUrl}coupon/visual`).then((res) => {
            return res
        }).catch((err) => {
            console.log(err);
            return err
        })

    }
)
export const getCouponsDataById = createAsyncThunk(
    'coupons/getCouponsDataById',
    async (data, thunkAPI) => {
        return await ApiGet(`${CouponsBaseUrl}coupon/visual?id=${data}`).then((res) => {
            return res
        }).catch((err) => {
            console.log(err);
            return err
        })

    }
)


export const couponsAndCardsSlice = createSlice({
    name: "couponsAndCardsSlice",
    initialState: { entities: [], loading: false },
    reducers: {
    },
    extraReducers: {
        [getAllCouponsData.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.value += action.payload;
        },
        [getAllCouponsData.reject]: (state, action) => {
            state.isLoading = false;
        },
        [getAllCouponsData.pending]: (state, action) => {
            state.isLoading = true;
        },
    },

})

export default couponsAndCardsSlice.reducer;
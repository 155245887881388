import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import bell from "../../assets/img/bell.png";
import downArrow from "../../assets/img/down-arrow-black.png";
import notifications from "../../assets/img/notification-template.png";
import questions from "../../assets/img/questions.png";
import { useTranslation } from "react-i18next";
import { IconButton, Menu, MenuItem, Tooltip, Divider } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { ApiGet, ApiPost } from "helper/API/ApiData";
import moment from "moment";
import Loader_img from "../../assets/img/notification-logo.png";
import { toast } from "react-toastify";
import { NotificationBaseUrl } from "helper/API/BaseUrl";
import HelpModal from "common/HelpModal";
import AuthStorage from "../../helper/AuthStorage";

const Header = ({ title, onRefresh, func = undefined }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [profileAnchorEl, setProfileAnchorEl] = React.useState(null);
  const [notification, setNotification] = React.useState([]);
  const [unReadCount, setUnReadCount] = React.useState();
  const [currentPlan, setCurrentPlan] = React.useState("trial");
  const [freeTrialDays, setFreeTrialDays] = React.useState(0);
  const [helpModal, setHelpModal] = React.useState(false);
  const opene = Boolean(anchorEl);
  const openProfile = Boolean(profileAnchorEl);
  const shopifyData = useSelector((state) => state.paymentSlice.shopifyData);
  const getDashboard = useSelector((state) => state?.dshboardState?.isLoading);
  const dashboardData = useSelector((state) => state.authState.dashboardData);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleProfileClick = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleProfileClose = () => {
    setProfileAnchorEl(null);
  };

  const readeNotification = (id) => {
    const body = {
      n_id: id,
    };
    ApiPost(`${NotificationBaseUrl}user-read-notifications`, body).then(
      (res) => {
        getNotify();
      }
    );
    setAnchorEl(null);
  };

  const signout = () => {
    AuthStorage.deauthenticateUser();
  };

  const markAllRead = () => {
    ApiPost(`${NotificationBaseUrl}user-read-notifications?markAll=true`).then(
      (res) => {
        getNotify();
      }
    );
    setAnchorEl(null);
  };

  useEffect(() => {
    getNotify();
    return () => {
      setNotification([]);
      setUnReadCount();
    };
  }, []);
  useEffect(() => {
    if (dashboardData) {
      setCurrentPlan(dashboardData?.subscription_plan);
      setFreeTrialDays(dashboardData?.subscription_trial_rem_days);
    }
    // console.log('dashboardData ===', dashboardData);
  }, [dashboardData]);
  // console.log('currentPlan ===', currentPlan);

  const getNotify = () => {
    ApiGet(`${NotificationBaseUrl}notifications`).then((res) => {
      const arr = res.response.notifications.sort(
        (a, b) => a.isRead - b.isRead
      );
      const temp = [...arr].splice(0, 5);
      setNotification(temp);
      setUnReadCount(res.response.unReadCount);
    });
  };

  return (
    <>
      <div className="campaigns-heade">
        <span
          className="gradient-color-font"
          style={{ fontSize: "24px", fontWeight: 600 }}
        >
          {title}
        </span>
        <div
          className="search d-flex flex-wrap gap-4 align-items-center"
          style={{ rowGap: "10px" }}
        >
          <div className="d-flex">
            {currentPlan !== "trial" && (
              <span
                className="boomio-grey"
                style={{ paddingLeft: "5px", paddingRight: "5px" }}
              >
                {" "}
                You are on {currentPlan} plan.{" "}
              </span>
            )}
            {currentPlan === "trial" && freeTrialDays > 0 && (
              <span
                className="boomio-grey"
                style={{ paddingLeft: "5px", paddingRight: "5px" }}
              >
                {" "}
                {freeTrialDays} days left of a free trial.{" "}
              </span>
            )}
            {currentPlan === "trial" && freeTrialDays <= 0 && (
              <span
                className="boomio-grey"
                style={{ paddingLeft: "5px", paddingRight: "5px" }}
              >
                {" "}
                Your free trial has ended.{" "}
              </span>
            )}
            {!(currentPlan === "trial" && freeTrialDays === 0) && (
              <a
                className="gradient-color-font gradient-underline"
                style={{ textTransform: "uppercase", cursor: "pointer" }}
                onClick={() => {
                  if (func) {
                    func();
                  }
                  navigate("/settings", { state: { id: "payments" } });
                }}
              >
                Upgrade
              </a>
            )}
            {currentPlan === "trial" && freeTrialDays === 0 && (
              <a
                className="gradient-color-font gradient-underline"
                style={{ textTransform: "uppercase", cursor: "pointer" }}
                onClick={() => {
                  if (func) {
                    func();
                  }
                  navigate("/settings", { state: { id: "payments" } });
                }}
              >
                Select plan
              </a>
            )}
          </div>

          <Tooltip title="Notifications">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{}}
              aria-controls={opene ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={opene ? "true" : undefined}
            >
              <div
                className={"d-flex justify-content-center align-items-center"}
                style={{ position: "relative" }}
              >
                <img
                  src={bell}
                  alt="notifications"
                  // className="icon-size"
                  style={{ width: "32px" }}
                />
                {unReadCount !== 0 && (
                  <div className="new-notifications">{unReadCount}</div>
                )}
              </div>
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={opene}
            onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                padding: "20px",
                width: "350px",
                overflow: "visible",
                overflow: "auto",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 34,
                  width: 20,
                  height: 20,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <div className="notification_title">
              <h1>Notification</h1>
              <a style={{ cursor: "pointer" }} onClick={() => markAllRead()}>
                Mark all as read
              </a>
            </div>
            {notification.map((item, index) => (
              <MenuItem
                onClick={() => readeNotification(item.n_id)}
                className={`${item.isRead ? "readed_notification" : ""}`}
              >
                <img
                  src={item.logo ? item.logo : Loader_img}
                  alt=""
                  height="32px"
                  width="32px"
                  style={{ marginRight: "8px" }}
                />
                <div className="notification_detail" style={{}}>
                  <h5>{item.body}</h5>
                  <h6>{moment(item.created_at).fromNow()}</h6>
                </div>
              </MenuItem>
            ))}
            <div className="notification_link">
              <a
                onClick={() => {
                  navigate("/notifications");
                }}
              >
                View all
              </a>
            </div>
          </Menu>

          {location.pathname === "/teaser" && (
            <Button
              onClick={() => navigate("add")}
              className="button-8559F3 boomio-white"
            >
              Add Teser
            </Button>
          )}
          <div
            onClick={handleProfileClick}
            className="search d-flex flex-wrap gap-2 align-items-center"
            style={{ cursor: "pointer" }}
          >
            <div className="logo">
              <div
                className="logo-image"
                style={{ backgroundImage: `url(${dashboardData.logo})` }}
              ></div>
            </div>

            <div className="hello-user"> Hello, {dashboardData.name}</div>
            <img src={downArrow} alt="" />
          </div>
          <Menu
            anchorEl={profileAnchorEl}
            id="account-menu"
            open={openProfile}
            onClose={handleProfileClose}
            onClick={handleProfileClose}
            autoFocus={false}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 0px 1px #E1E1E1)",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
            anchorOrigin={{
              horizontal: "right",
              vertical: "bottom",
            }}
          >
            <MenuItem
              className="menu-item"
              onClick={(e) => {
                navigate("/editprofile");
              }}
            >
              My profile
            </MenuItem>
            <Divider />
            <MenuItem
              className="menu-item"
              onClick={(e) => {
                navigate("/settings");
              }}
            >
              Settings
            </MenuItem>
            <Divider />
            <MenuItem
              className="menu-item"
              onClick={(e) => {
                signout();
              }}
            >
              Log out
            </MenuItem>
            <Divider />
            <MenuItem
              className="menu-item"
              onClick={(e) => {
                setHelpModal(true);
              }}
            >
              {" "}
              Need Help?
            </MenuItem>
          </Menu>
        </div>
      </div>
      {helpModal && (
        <HelpModal
          func={func}
          show={helpModal}
          onHide={() => setHelpModal(false)}
        />
      )}
    </>
  );
};

export default Header;

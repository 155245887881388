import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router";
import guy_on_a_rocket_img from "../../../assets/img/rocket.png";
import ReactGA from "react-ga4";

const AddStoreTwo = ({ show, onHide }) => {
  const navigate = useNavigate();

  const handleTryPreviewClick = () => {
    localStorage.setItem("initialChoice", JSON.stringify("preview"));
    ReactGA.event({
      action: "Welcome Pop-up start preview",
      category: "Welcome popup",
      value: "",
    });
    navigate("/campaigns/boost-product");
  };

  const handleStartCampaignClick = () => {
    localStorage.setItem("initialChoice", JSON.stringify("campaign"));
    ReactGA.event({
      action: "Welcome Pop-up start campaign",
      category: "Welcome popup",
      value: "",
    });
    navigate("/campaigns/boost-product");
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      centered
      aria-labelledby="example-custom-modal-styling-title"
      className="initial_add_store_modal"
      style={{ width: "100% !important" }}
    >
      <Modal.Body className="plansModal">
        <p className="stepNumber"> Step 2 out of 2</p>
        <img
          onClick={onHide}
          src="./assets/img/closeIcon.png"
          className="closeIcon icon-size"
          alt="close"
        />
        <div className="initial_add_store_main">
          <div className="image">
            <img
              className="initial_add_store_modal_img"
              src={guy_on_a_rocket_img}
              alt=""
            />
          </div>
          <div className="text">
            <h1
              className="sub_header"
              style={{ fontSize: "1.6rem", marginTop: "7px" }}
            >
              Here's how to start using Boomio
            </h1>
            {/* <p style={{marginBottom:'20px'}}>
                    Here's a quick guide on how to start using Boomio:
                    </p> */}
            <ol style={{ marginBottom: "20px", paddingLeft: "1rem" }}>
              <li> Connect your e-store.</li>
              {/* <li>Click on 'Start a campaign'.</li> */}
              <li>Click on 'Start a campaign'.</li>
              <li>Follow easy steps to launch campaign.</li>
              <li>Head over to the dashboard to see results.</li>
            </ol>
            <p style={{ marginBottom: "40px" }}>
              Start boosting loyalty and growth with Boomio today!
            </p>
            <div style={{ flexDirection: "row", width: "100%" }}>
              {/* <Button style={{ width: '35%' }} onClick={handleTryPreviewClick} className="button-8559F3 boomio_input boomio_input_main mx-3">
                                Try Preview
                            </Button> */}
              <Button
                onClick={handleStartCampaignClick}
                className="button-8559F3 boomio-white mx-3"
              >
                Start campaign
              </Button>
              <p style={{ marginTop: "40px" }}>
                If you're not quite ready to start campaign yet, take a{" "}
                <span onClick={handleTryPreviewClick} className="link">
                  sneak peek at a preview
                </span>{" "}
                of sample campaign on your e-shop.
              </p>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddStoreTwo;

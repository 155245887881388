import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router";
import E_Store from "../../../assets/img/e_store.png";

const AddStore = ({ show, onHide }) => {
  const navigate = useNavigate();
  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      centered
      aria-labelledby="example-custom-modal-styling-title"
      className="add_store_modal"
    >
      <Modal.Body className="plansModal">
        <img
          onClick={onHide}
          src="./assets/img/closeIcon.png"
          className="closeIcon icon-size"
          alt="close"
        />
        <div className="add_store_main">
          <h1 className="header">Welcome to Boomio!</h1>
          <p>
            You’re almost ready. Add your e-shop and connect it with Boomio App.
          </p>
          <img src={E_Store} alt="" />
          <p className="add_store_bottom_text">It only takes a few minutes.</p>
          <Button
            onClick={() => navigate("/campaigns/boost-product")}
            className="button-8559F3 boomio-white"
          >
            Add e-shop
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddStore;

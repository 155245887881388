import React, { useEffect, useState } from "react";
import like from "../../../assets/img/like.svg";
import No from "../../../assets/img/no.svg";
import { Accordion } from "react-bootstrap";
import { ApiGet, ApiPost } from "helper/API/ApiData";
import { toast } from "react-toastify";
import { useLocation } from "react-router";
import { InfoBaseUrl } from "helper/API/BaseUrl";

const QA = () => {
  const location = useLocation()
  const location_Id = location.state?.id
  const [questionsData, setQuestionsData] = useState([]);

  useEffect(() => {
    ApiGet(`${InfoBaseUrl}api/static_info/qa`).then(
      (res) => {
        if (res && res.response && res.response.json_body && res.response.json_body.data) {
          setQuestionsData(res.response.json_body.data);
        }
      }
    );
    return () => setQuestionsData([])
  }, []);

  const Replay = (id, nr, ans) => {
    const body = {
      is_helpful: ans,
      q_id: id,
      q_nr: nr,
    };
    ApiPost(
      `${InfoBaseUrl}api/user-collected-info`,
      body
    )
      .then((res) => {
        toast.success("Thanks for feedback", {
          closeOnClick: true,
          pauseOnHover: true,
        });
      })
      .catch((err) => {
        toast.error("Failed to submit your feedback", {
          closeOnClick: true,
          pauseOnHover: true,
        });
      });
  };

  return (
    <>
      <div className="qa">
        <div className="row">
          <div className="col-12">
            <Accordion defaultActiveKey={location_Id === 'QA' ? 0 : ''} style={{ width: "100%" }}>
              {questionsData.map((item, i) => (
                <Accordion.Item eventKey={i} key={i}>
                  <Accordion.Header className="gradientBG accodianHeader" >
                    <span className="qaHeaderText" style={{paddingRight:'40px'}}>{item.question}</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p dangerouslySetInnerHTML={{ __html: item.answer }}></p>
                    <div className="helpFull">
                      <h6>Was this helpful?</h6>
                      <div className="liksButton d-flex">
                        <button
                          className="like"
                          onClick={() =>
                            Replay(item.question_id, item.question_nr, "yes")
                          }
                        >
                          <div className="whiteBg">
                            <span>
                              Yes
                              <img src={like} alt="like" />
                            </span>
                          </div>
                        </button>
                        <button
                          className="likeNo"
                          onClick={() =>
                            Replay(item.question_id, item.question_nr, "no")
                          }
                        >
                          <div className="whiteBg">
                            <span>
                              No
                              <img src={No} alt="no" />
                            </span>
                          </div>
                        </button>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

export default QA;

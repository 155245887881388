import React from 'react';
import { Modal } from 'react-bootstrap';
import success from '../../../assets/img/success.png'

const SuccessModal = (props) => {
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='sucess-modals'
            >
                <Modal.Header closeButton className='dots' onClick={() => { }}>
                    <Modal.Title id="contained-modal-title-vcenter">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='success-bg'>
                        <img src={success} alt="" />
                    </div>
                    <h2>Success</h2>
                    <h3>{props.message}</h3>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SuccessModal;

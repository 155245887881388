import React, { useEffect, useState } from "react";
import RadioButton from "../../../common/RadioButton";
import Close_button from "../../../assets/img/close_button.png";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Col, Row } from "react-bootstrap";
import Slider from "./Slider";
import select_arrow from "../../../assets/img/boomio_select_icon.png";
import NumberInputField from "common/NumberInputField";
import SliderDays from "./SliderDays";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import DateSelect from "common/DateRangeSelect/DateSelect";
import moment from "moment/moment";
import datepicker from "../../../assets/img/date-picker.png";
import Select from "react-select";

const RewardPaper = ({
  rewardType,
  setRewardType,
  couponData,
  setCouponData,
  discountAmount,
  setDiscountAmount,
  selectedDay,
  setSelectedDay,
  days,
  setDays,
  selectedGameValue,
  outcome,
  setOutcome,
  setScoreboardData,
  scoreboardData,
}) => {
  const productRewardSchema = yup.object({
    Specific_product_URL: yup
      .array()
      .of(
        yup
          .string()
          .required("Product URL is required.")
          .matches(
            /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
            "Enter valid product URL"
          )
      )
      .required("Product URL is required."),
  });

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    setValue: setValue1,
    reset: reset1,
    formState: { errors },
    clearErrors: clearErrors1,
  } = useForm({
    resolver: yupResolver(productRewardSchema),
  });
  const [focusedRange, setFocusedRange] = React.useState(null);

  const [open, setOpen] = React.useState(false);

  const toggle = (range) => {
    setFocusedRange(range);
    setOpen(!open);
  };

  const [dateRange, setDateRange] = useState({
    startDate: moment(new Date()).format("YYYY/MM/DD"),
    endDate: undefined,
  });

  const dateRangeChange = (ranges) => {
    if (ranges && ranges.range1.startDate && ranges.range1.endDate) {
      const startDate = moment(ranges.range1?.startDate).format("YYYY/MM/DD");
      const endDate = moment(ranges.range1?.endDate).format("YYYY/MM/DD");
      setValue1("campaignStartDate", startDate);
      setValue1("campaignEndDate", endDate);
      ranges.range1?.endDate && clearErrors1("campaignEndDate");
      setDateRange({ startDate, endDate });
    }
  };

  const handleDiscountChange = (selectedOption) => {
    setDiscountAmount(selectedOption);
  };
  const optionsPercentage = [
    { value: 0, label: "Custom" },
    { value: 5, label: "5 %" },
    { value: 10, label: "10 %" },
    { value: 15, label: "15 %" },
    { value: 25, label: "25 %" },
  ];
  const optionsPrice = [
    { value: 0, label: "Custom" },
    { value: 5, label: "5 €" },
    { value: 10, label: "10 €" },
    { value: 15, label: "15 €" },
    { value: 25, label: "25 €" },
  ];

  const handleOutcomeChange = (outcome) => {
    setOutcome(outcome);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setScoreboardData({
      ...scoreboardData,
      [name]: value,
    });
  };

  return (
    <div style={{ display: "flex" }}>
      {selectedGameValue === "competition" ? (
        <div className="w-100">
          <p style={{ fontSize: "18px", fontWeight: 600, marginBottom: "0px" }}>
            Outcome of the Game
          </p>{" "}
          <p style={{ fontSize: "14px", color: "#585666", maxWidth: "900px" }}>
            When the game ends the scoreboard with the players nicknames and
            achieved scores will be shown. You can add text under the scoreboard
            to inform players about the winnings and date when winners will be
            rewarded.
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              maxWidth: "600px",
              marginLeft: "40px",
              marginTop: "20px",
            }}
          >
            <FormControlLabel
              style={{ alignItems: "start" }}
              value="public"
              control={
                <Radio
                  style={{
                    color: outcome === "points" ? "#EB5894" : "black",
                  }}
                />
              }
              label={
                <p
                  style={{
                    marginLeft: "10px",
                    fontWeight: 600,
                    color: outcome === "points" ? "#EB5894" : "black",
                  }}
                >
                  Points reached
                </p>
              }
              onChange={() => handleOutcomeChange("points")}
              checked={outcome === "points"}
            />

            <FormControlLabel
              style={{ alignItems: "start" }}
              value="private"
              control={
                <Radio
                  style={{
                    color: outcome === "score" ? "#EB5894" : "black",
                  }}
                />
              }
              label={
                <p
                  style={{
                    marginLeft: "10px",
                    fontWeight: 600,
                    color: outcome === "score" ? "#EB5894" : "black",
                  }}
                >
                  Scoreboard place reached
                </p>
              }
              onChange={() => handleOutcomeChange("score")}
              checked={outcome === "score"}
            />
          </div>
          <p>
            Choose the number of {outcome === "score" ? " score" : " points"},
            that will be a breaking point of what message players will see after
            they reach above or below the
            {outcome === "score" ? " score" : " points"} number.
          </p>
          <input
            style={{ maxWidth: "450px" }}
            type="text"
            className="model-input"
            placeholder={`Breaking point ${
              outcome === "score" ? "score" : "points"
            }`}
            name="breakingPointText"
            onChange={handleInputChange}
            value={scoreboardData.breakingPointText}
          />
          <p
            style={{
              fontSize: "18px",
              fontWeight: 600,
              marginTop: "20px",
              marginBottom: "0px",
            }}
          >
            Above
          </p>
          <p style={{ fontSize: "14px", color: "#585666", maxWidth: "900px" }}>
            The text in the lines below. Text will be shown to players that are
            above selected breaking point.
          </p>
          <input
            style={{ margin: "10px 0px 10px 0px", maxWidth: "450px" }}
            type="text"
            className="model-input"
            placeholder="Pagirk žaidėją"
            name="aboveBreakingPointText"
            onChange={handleInputChange}
            value={scoreboardData.aboveBreakingPointText}
          />
          <input
            style={{ margin: "10px 0px 10px 0px", maxWidth: "450px" }}
            type="text"
            className="model-input"
            placeholder="Informuok apie prizus ir laimėjimo datą"
            name="aboveBreakingPointPrizes"
            onChange={handleInputChange}
            value={scoreboardData.aboveBreakingPointPrizes}
          />
          <input
            style={{ margin: "10px 0px 10px 0px", maxWidth: "450px" }}
            type="text"
            className="model-input"
            placeholder="Informuok kad su laimėtojais bus susisiekta el paštu"
            name="aboveBreakingPointEmail"
            onChange={handleInputChange}
            value={scoreboardData.aboveBreakingPointEmail}
          />
          <input
            style={{ margin: "10px 0px 10px 0px", maxWidth: "450px" }}
            type="text"
            className="model-input"
            placeholder="Informuok apie duodamą nuolaidos kodą"
            name="aboveBreakingPointDiscount"
            onChange={handleInputChange}
            value={scoreboardData.aboveBreakingPointDiscount}
          />
          <div style={{ display: "flex", gap: "20px" }}>
            <input
              style={{ margin: "10px 0px 10px 0px", maxWidth: "450px" }}
              type="text"
              className="model-input"
              placeholder="Įrašyk nuolaidos kodą"
              name="discountCode"
              onChange={handleInputChange}
              value={scoreboardData.discountCode}
            />
            <input
              style={{
                margin: "10px 0px 10px 0px",
                maxWidth: "450px",
                color: scoreboardData.discountColor,
                border: `0px solid ${scoreboardData.discountColor}`,
                outline: `0.5px solid ${scoreboardData.discountColor}`,
              }}
              type="text"
              className="model-input"
              placeholder="Write color code or name"
              name="discountColor"
              onChange={handleInputChange}
              value={scoreboardData.discountColor}
            />
          </div>
          <input
            style={{ margin: "10px 0px 10px 0px", maxWidth: "450px" }}
            type="text"
            className="model-input"
            placeholder="papildomas tekstas apie  nuolaidą"
            name="discountText"
            onChange={handleInputChange}
            value={scoreboardData.discountText}
          />
          <p
            style={{
              fontSize: "18px",
              fontWeight: 600,
              marginTop: "20px",
              marginBottom: "0px",
            }}
          >
            Below
          </p>
          <p style={{ fontSize: "14px", color: "#585666", maxWidth: "900px" }}>
            The text in the lines below. Text will be shown to players that are
            above selected breaking point.
          </p>
          <input
            style={{ margin: "10px 0px 10px 0px", maxWidth: "450px" }}
            type="text"
            className="model-input"
            placeholder="Paskatink žaidėją"
            name="belowBreakingPointText"
            onChange={handleInputChange}
            value={scoreboardData.belowBreakingPointText}
          />
          <input
            style={{ margin: "10px 0px 10px 0px", maxWidth: "450px" }}
            type="text"
            className="model-input"
            placeholder="Informuok apie prizus ir laimėjimo datą"
            name="belowBreakingPointPrizes"
            onChange={handleInputChange}
            value={scoreboardData.belowBreakingPointPrizes}
          />
          <input
            style={{ margin: "10px 0px 10px 0px", maxWidth: "450px" }}
            type="text"
            className="model-input"
            placeholder="Informuok kad su laimėtojais bus susisiekta el paštu"
            name="belowBreakingPointEmail"
            onChange={handleInputChange}
            value={scoreboardData.belowBreakingPointEmail}
          />
          <input
            style={{ margin: "10px 0px 10px 0px", maxWidth: "450px" }}
            type="text"
            className="model-input"
            placeholder="Informuok apie duodamą nuolaidos kodą"
            name="belowBreakingPointDiscount"
            onChange={handleInputChange}
            value={scoreboardData.belowBreakingPointDiscount}
          />
          <input
            style={{ margin: "10px 0px 10px 0px", maxWidth: "450px" }}
            type="text"
            className="model-input"
            placeholder="Įrašyk nuolaidos kodą"
            name="belowDiscountCode"
            onChange={handleInputChange}
            value={scoreboardData.belowDiscountCode}
          />
          <input
            style={{ margin: "10px 0px 10px 0px", maxWidth: "450px" }}
            type="text"
            className="model-input"
            placeholder="papildomas tekstas apie  nuolaidą"
            name="belowDiscountText"
            onChange={handleInputChange}
            value={scoreboardData.belowDiscountText}
          />
        </div>
      ) : (
        <>
          <DateSelect
            selectedDay={selectedDay}
            setSelectedDay={setSelectedDay}
            minDate={0}
            open={open}
            setOpen={setOpen}
          />
          <div className="w-100">
            <p style={{ fontSize: "18px", fontWeight: 600 }}>Set game reward</p>
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
              }}
              className="w-100 time-select discount-select"
            >
              <div style={{ marginBottom: "12px" }}>
                <RadioButton
                  width={"100%"}
                  checked={rewardType === "DISCOUNT_PERCENTAGE"}
                  text={"Highest score converts to % discount "}
                  id={"option2"}
                  value={"DISCOUNT_PERCENTAGE"}
                  {...register1("selectRewardType1")}
                  ref={null}
                  color={
                    rewardType === "DISCOUNT_PERCENTAGE" ? "#EB5894" : "black"
                  }
                  onChange={() => {
                    setCouponData((prev) => ({
                      ...prev,
                      params: {
                        ...prev?.params,
                        topTitle: `YOU GOT A ${discountAmount}% DISCOUNT!`,
                        couponCodeDiscount: `${discountAmount}%`,
                        couponCodediscountText: "Discount",
                      },
                    }));
                    setValue1("selectRewardType1", "DISCOUNT_PERCENTAGE");
                    setRewardType("DISCOUNT_PERCENTAGE");
                  }}
                />
                {rewardType === "DISCOUNT_PERCENTAGE" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "52px",
                      marginBottom: "40px",
                    }}
                  >
                    <label
                      htmlFor=""
                      className="campaign-reward-subtext"
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    >
                      Set max discount amount
                    </label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "20px",
                        justifyContent: "space-between",
                      }}
                      className="radio-Button"
                    >
                      <div>
                        <div className="radio-Button">
                          <div
                            className="boomio_input_main"
                            style={{
                              width: "250px",
                              zIndex: "99999999999999999 !important",
                            }}
                          >
                            <Select
                              className="boomio_input select-react"
                              options={optionsPercentage}
                              value={discountAmount}
                              onChange={handleDiscountChange}
                              placeholder="Choose discount amount"
                              isSearchable={false}
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  border: "none", // Remove the border
                                  borderRadius: "24px",
                                  boxShadow: "0px 4px 64px rgba(0, 0, 0, 0.1)",
                                }),

                                dropdownIndicator: (provided) => ({
                                  ...provided,

                                  color: "#333", // Color of the dropdown indicator arrow
                                }),

                                menu: (provided) => ({
                                  ...provided,
                                  backgroundColor: "#fff", // Background color of the dropdown menu
                                  borderRadius: "14px",
                                  boxShadow: "0px 4px 64px rgba(0, 0, 0, 0.1)",
                                  marginLeft: "-15px",
                                  padding: "4px !important",
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  borderRadius: "14px",
                                  background: state.isSelected
                                    ? "linear-gradient(90deg, #FEE3E9 0%, #FFD7E8 21.87%, #F3DBF0 41.67%, #EADFF7 62.5%, #EADFF7 81.77%, #EED8FF 100%)"
                                    : `#fff`, // Gradient background color
                                  color: state.isSelected
                                    ? "#000000"
                                    : "#000000",
                                  "&:hover": {
                                    background: state.isSelected
                                      ? "#f0f0f0"
                                      : `linear-gradient(90deg, #FEE3E9 0%, #FFD7E8 21.87%, #F3DBF0 41.67%, #EADFF7 62.5%, #EADFF7 81.77%, #EED8FF 100%)`,
                                  },
                                }),
                              }}
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                            />
                          </div>
                        </div>
                        {/* <Slider
                        type={"%"}
                        setValue1={setValue1}
                        setDiscountAmount={setDiscountAmount}
                        discountAmount={discountAmount}
                        setCouponData={setCouponData}
                      /> */}
                      </div>
                      <div className="">
                        <div
                          className="boomio_input_main "
                          style={{ width: "250px" }}
                        >
                          <input
                            className="boomio_input "
                            type="number"
                            placeholder="or enter a discount %"
                            aria-label="default input example"
                            value={
                              discountAmount.value >= 0
                                ? discountAmount.value
                                : 0
                            }
                            onChange={(e) => {
                              setCouponData((prev) => ({
                                ...prev,
                                params: {
                                  ...prev?.params,
                                  topTitle: `YOU GOT A $${e?.target?.value} DISCOUNT!`,
                                  couponCodeDiscount: `$${e?.target?.value}`,
                                },
                              }));
                              setDiscountAmount({
                                value: e?.target?.value,
                                label: "Custom",
                              });
                            }}
                          />
                        </div>
                        {errors?.discountAmountage && (
                          <span className="campaings_error">
                            {errors?.discountAmountage?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <label
                      htmlFor=""
                      className="campaign-reward-subtext"
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    >
                      Select a reward expiration date
                    </label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "20px",
                      }}
                      className="radio-Button"
                    >
                      <div className="radio-Button">
                        <div
                          className="boomio_input_main"
                          style={{ width: "250px" }}
                        >
                          <div
                            className={
                              dateRange?.endDate
                                ? "boomio_input boomio_datepicker"
                                : "boomio_input_active boomio_datepicker"
                            }
                            style={{
                              position: "relative",
                              minWidth: "250px",
                              display: "flex",
                              justifyContent: "space-between",
                              zIndex: 0,
                            }}
                            onClick={() => toggle([0, 1])}
                            // style={{
                            //   background: "none",
                            //   webkitBackgroundClip: "none",
                            //   color: "red",
                            // }}
                          >
                            <input
                              type={"hidden"}
                              {...register1("selectedDay")}
                            />
                            {`
    ${
      selectedDay ? moment(selectedDay).format("YYYY/MM/DD") : "Set End Date"
    } `}

                            <img
                              src={datepicker}
                              alt=""
                              onClick={() => toggle([0, 1])}
                            />
                          </div>
                        </div>
                      </div>
                      <div style={{ width: "250px" }}>
                        <div
                          className={
                            selectedDay !== 0
                              ? "boomio_input_main boomio_input_main_disabled"
                              : "boomio_input_main"
                          }
                          style={{ width: "250px" }}
                        >
                          <NumberInputField
                            {...register1("numberOfDays")}
                            disabled={selectedDay !== 0}
                            ref={null}
                            value={days}
                            setdays={setDays}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div style={{ marginBottom: "12px" }}>
                <RadioButton
                  width={"100%"}
                  checked={rewardType === "DISCOUNT_CURRENCY"}
                  text={"Highest score converts to € discount"}
                  id={"option3"}
                  value={"DISCOUNT_CURRENCY"}
                  {...register1("selectRewardType1")}
                  ref={null}
                  color={
                    rewardType === "DISCOUNT_CURRENCY" ? "#EB5894" : "black"
                  }
                  onChange={() => {
                    setCouponData((prev) => ({
                      ...prev,
                      params: {
                        ...prev?.params,
                        topTitle: `YOU GOT A ${discountAmount.value}$ DISCOUNT!`,
                        couponCodeDiscount: `${discountAmount.value}$`,
                        couponCodediscountText: "Discount",
                      },
                    }));
                    setValue1("selectRewardType1", "DISCOUNT_CURRENCY");
                    setRewardType("DISCOUNT_CURRENCY");
                  }}
                />
                {rewardType === "DISCOUNT_CURRENCY" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "52px",
                      marginBottom: "40px",
                    }}
                  >
                    <label
                      htmlFor=""
                      className="campaign-reward-subtext"
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    >
                      Set max discount amount
                    </label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "20px",
                        justifyContent: "space-between",
                      }}
                      className="radio-Button"
                    >
                      <div>
                        <div className="radio-Button">
                          <div
                            className="boomio_input_main"
                            style={{ width: "250px" }}
                          >
                            <Select
                              className="boomio_input select-react"
                              options={optionsPrice}
                              value={discountAmount}
                              onChange={handleDiscountChange}
                              placeholder="Choose discount amount"
                              isSearchable={false}
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  border: "none", // Remove the border
                                  borderRadius: "24px",
                                  boxShadow: "0px 4px 64px rgba(0, 0, 0, 0.1)",
                                }),

                                dropdownIndicator: (provided) => ({
                                  ...provided,

                                  color: "#333", // Color of the dropdown indicator arrow
                                }),

                                menu: (provided) => ({
                                  ...provided,
                                  backgroundColor: "#fff", // Background color of the dropdown menu
                                  borderRadius: "14px",
                                  boxShadow: "0px 4px 64px rgba(0, 0, 0, 0.1)",
                                  marginLeft: "-15px",
                                  padding: "4px !important",
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  borderRadius: "14px",
                                  background: state.isSelected
                                    ? "linear-gradient(90deg, #FEE3E9 0%, #FFD7E8 21.87%, #F3DBF0 41.67%, #EADFF7 62.5%, #EADFF7 81.77%, #EED8FF 100%)"
                                    : `#fff`, // Gradient background color
                                  color: state.isSelected
                                    ? "#000000"
                                    : "#000000",
                                  "&:hover": {
                                    background: state.isSelected
                                      ? "#f0f0f0"
                                      : `linear-gradient(90deg, #FEE3E9 0%, #FFD7E8 21.87%, #F3DBF0 41.67%, #EADFF7 62.5%, #EADFF7 81.77%, #EED8FF 100%)`,
                                  },
                                }),
                              }}
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                            />
                          </div>
                        </div>
                        {/* <Slider
                        type={"$"}
                        setValue1={setValue1}
                        setDiscountAmount={setDiscountAmount}
                        discountAmount={discountAmount}
                        setCouponData={setCouponData}
                      /> */}
                      </div>

                      <div className="">
                        <div
                          className="boomio_input_main"
                          style={{ width: "250px" }}
                        >
                          <input
                            className="boomio_input"
                            type="number"
                            placeholder="or enter a discount $"
                            aria-label="default input example"
                            value={
                              discountAmount.value >= 0
                                ? discountAmount.value
                                : 0
                            }
                            onChange={(e) => {
                              setCouponData((prev) => ({
                                ...prev,
                                params: {
                                  ...prev?.params,
                                  topTitle: `YOU GOT A $${e?.target?.value} DISCOUNT!`,
                                  couponCodeDiscount: `$${e?.target?.value}`,
                                },
                              }));
                              setDiscountAmount({
                                value: e?.target?.value,
                                label: "Custom",
                              });
                            }}
                          />
                        </div>
                        {errors?.discountDollar && (
                          <span className="campaings_error">
                            {errors?.discountDollar?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <label
                      htmlFor=""
                      className="campaign-reward-subtext"
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    >
                      Select a reward expiration date
                    </label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "20px",
                      }}
                      className="radio-Button"
                    >
                      <div className="radio-Button">
                        <div
                          className="boomio_input_main"
                          style={{ width: "250px" }}
                        >
                          <div
                            className={
                              dateRange?.endDate
                                ? "boomio_input boomio_datepicker"
                                : "boomio_input_active boomio_datepicker"
                            }
                            style={{
                              position: "relative",
                              minWidth: "250px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            onClick={() => toggle([0, 1])}
                            // style={{
                            //   background: "none",
                            //   webkitBackgroundClip: "none",
                            //   color: "red",
                            // }}
                          >
                            <input
                              type={"hidden"}
                              {...register1("selectedDay")}
                            />
                            {`
    ${
      selectedDay ? moment(selectedDay).format("YYYY/MM/DD") : "Set End Date"
    } `}

                            <img
                              src={datepicker}
                              alt=""
                              onClick={() => toggle([0, 1])}
                            />
                          </div>
                        </div>
                        {/* <Slider
                        type={"%"}
                        setValue1={setValue1}
                        setDiscountAmount={setDiscountAmount}
                        discountAmount={discountAmount}
                        setCouponData={setCouponData}
                      /> */}
                      </div>
                      <div>
                        <div
                          className={
                            selectedDay !== 0
                              ? "boomio_input_main boomio_input_main_disabled"
                              : "boomio_input_main"
                          }
                          style={{ width: "250px" }}
                        >
                          <NumberInputField
                            {...register1("numberOfDays")}
                            disabled={selectedDay !== 0}
                            ref={null}
                            value={days}
                            setdays={setDays}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div>
                <RadioButton
                  checked={rewardType === "FREE_SHIPPING"}
                  text={"Free Shipping"}
                  id={"option1"}
                  value={"FREE_SHIPPING"}
                  {...register1("selectRewardType1")}
                  width={"100%"}
                  ref={null}
                  color={rewardType === "FREE_SHIPPING" ? "#EB5894" : "black"}
                  onChange={() => {
                    setCouponData((prev) => ({
                      ...prev,
                      params: {
                        ...prev?.params,
                        topTitle: "YOU GOT A FREE SHIPPING!",
                        couponCodeDiscount: `Free`,
                        couponCodediscountText: "SHIPPING",
                      },
                    }));
                    setValue1("selectRewardType1", "FREE_SHIPPING");
                    setRewardType("FREE_SHIPPING");
                  }}
                />

                {rewardType === "FREE_SHIPPING" && (
                  <div
                    className="d-flex flex-wrap"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "52px",
                    }}
                  >
                    <label
                      htmlFor=""
                      className="campaign-reward-subtext"
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    >
                      Select a reward expiration date
                    </label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "20px",
                      }}
                      className="radio-Button"
                    >
                      <div className="radio-Button">
                        <div
                          className="boomio_input_main"
                          style={{ width: "250px" }}
                        >
                          <div
                            className={
                              dateRange?.endDate
                                ? "boomio_input boomio_datepicker"
                                : "boomio_input_active boomio_datepicker"
                            }
                            style={{
                              position: "relative",
                              paddingRight: "50px",
                              minWidth: "250px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            onClick={() => toggle([0, 1])}
                            // style={{
                            //   background: "none",
                            //   webkitBackgroundClip: "none",
                            //   color: "red",
                            // }}
                          >
                            <input
                              type={"hidden"}
                              {...register1("selectedDay")}
                            />
                            {`
    ${
      selectedDay ? moment(selectedDay).format("YYYY/MM/DD") : "Set End Date"
    } `}

                            <img
                              src={datepicker}
                              alt=""
                              onClick={() => toggle([0, 1])}
                            />
                          </div>
                        </div>
                        {/* <Slider
                        type={"%"}
                        setValue1={setValue1}
                        setDiscountAmount={setDiscountAmount}
                        discountAmount={discountAmount}
                        setCouponData={setCouponData}
                      /> */}
                      </div>
                      <div>
                        <div
                          className={
                            selectedDay !== 0
                              ? "boomio_input_main boomio_input_main_disabled"
                              : "boomio_input_main"
                          }
                          style={{ width: "250px" }}
                        >
                          <NumberInputField
                            {...register1("numberOfDays")}
                            disabled={selectedDay !== 0}
                            ref={null}
                            value={days}
                            setdays={setDays}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RewardPaper;

import React from "react";
import gift_img from "../../../assets/img/gift_img.png";
import full_puzzle from "../../../assets/img/full_puzzle.png";
import Close_button from "../../../assets/img/close_button.png";
import { Button } from "react-bootstrap";

const UserGetsHint = (props) => {
  return (
    <div className="card_arrow_before position-relative Preview-select box-show m-2 mb-5">
      <div className="coupon__preview__body coupon_discount_modal">
        <div className="close_button">
          <img src={Close_button} alt="" />
        </div>
        <div className="coupon__preview__card__header text-center d-block">
          <h2 style={{ fontWeight: "600", fontSize: "18px" }}>{props.title}</h2>
          <h6
            style={{ fontWeight: "500", fontSize: "14px" }}
            className={props.puzzle && "color_text_bold"}
          >
            {props.description}
          </h6>
          <div className="user_gets_hint_img">
            <img src={props.puzzle ? full_puzzle : gift_img} alt="" />
          </div>
          {!props.puzzle && (
            <>
              {/* <h3 className='color_text_bold_h3'>Find a game and unlock your reward.</h3> */}
              <h4 className="color_text_bold_h4" style={{ fontSize: "14px" }}>
                {" "}
                <b>Hint </b> - look at Adidas Stan Smith J FX7519
              </h4>
            </>
          )}
          <Button className="go_button">Go!</Button>
        </div>
      </div>
    </div>
  );
};

export default UserGetsHint;

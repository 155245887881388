import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import {
  getCustomers,
  getDownloadCustomers,
} from "redux/features/customers/customersSlice";
import AddCustomer from "../componets/modals/AddCustomer";
import DeleteModal from "../componets/modals/DeleteModal";
import ImportCustomer from "../componets/modals/ImportCustomer";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { t } from "i18next";
import Header from "layout/header/Header";
import moment from "moment";
import DateRangeSelect from "../../common/DateRangeSelect/DateRangeSelect";
import datepicker from "../../assets/img/date-picker.png";

const Customers = () => {
  const dispatch = useDispatch();

  const [modalShow, setModalShow] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [modelType, setModelType] = useState();
  const [dropdownmanu, setdropdownmanu] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  // const [totalCount, setTotalCount] = useState(0);
  const [customersData, setCustomersData] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [currentCount, setCurrentCount] = useState(1);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [sortDirection, setSortDirection] = useState(1);

  const [order, setOrder] = useState({
    orderBy: "best_score",
    orderDirection: "asc",
  });

  const [checkboxes, setCheckboxes] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const [period, setPeriod] = React.useState(false);
  const [decrypt, setDecrypt] = React.useState(false);

  const [search, setSearch] = React.useState("");

  const [focusedRange, setFocusedRange] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: moment(new Date()).format("YYYY/MM/DD"),
    endDate: undefined,
  });

  const dateRangeChange = (ranges) => {
    if (ranges && ranges.range1.startDate && ranges.range1.endDate) {
      const startDate = moment(ranges.range1?.startDate).format("YYYY/MM/DD");
      const endDate = moment(ranges.range1?.endDate).format("YYYY/MM/DD");
      setDateRange({ startDate, endDate });
    }
  };
  const openMenu = (i) => {
    if (i === dropdownmanu) {
      setdropdownmanu();
    } else {
      setdropdownmanu(i);
    }
  };
  const nextPage = () => {
    const nextpage = pageNumber + 1;
    if (nextpage <= totalPages) {
      getCustomersData(nextpage, perPage, order, period, dateRange, search);
      setPageNumber(nextpage);
    } else {
      getCustomersData(pageNumber, perPage, order, period, dateRange, search);
    }
  };

  const previousPage = () => {
    const nextpage = pageNumber - 1;

    getCustomersData(nextpage, perPage, order, period, dateRange, search);
    setPageNumber(nextpage);
    setCurrentCount(nextpage);
  };

  const getDownloadCustomersData = async (
    period,
    dateRange,
    search,
    decrypt
  ) => {
    await dispatch(getDownloadCustomers([period, dateRange, search, decrypt]))
      .then(unwrapResult)
      .then((result) => {
        if (result && result.response) {
          console.log(result);
        }
        return result?.response;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getCustomersData = async (
    pageNumber,
    limit,
    order,
    period,
    dateRange,
    search
  ) => {
    await dispatch(
      getCustomers([pageNumber, limit, order, period, dateRange, search])
    )
      .then(unwrapResult)
      .then((result) => {
        if (result && result.response) {
          if (result && result.response) {
            setCustomersData(result?.response);
          }
          if (result?.response?.count) {
            setTotalCount(result.response.count);
          }
        }
        // setTotalCount(result?.response?.count);
        return result?.response;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getCustomersData(0, perPage, order, period, dateRange, search);
    return () => setCustomersData([]);
  }, [order, perPage, period, dateRange, search]);

  const handleChange = (ind) => {
    let checkboxesCopy = [...checkboxes];
    checkboxesCopy[ind] = !checkboxes[ind];
    setCheckboxes([...checkboxesCopy]);
  };

  const handleChangeAll = (val) => {
    let checkboxesCopy = [...checkboxes];
    checkboxesCopy.forEach((e, i) => (checkboxesCopy[i] = !val));
    setCheckboxes([...checkboxesCopy]);
  };

  const capFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  useEffect(() => {
    const totalPageRounded = Math.floor(Math.round(totalCount / perPage));

    const totalPage = totalCount / perPage;
    let pages;
    if (totalPage > totalPageRounded) {
      pages = totalPageRounded + 1;
      setTotalPages(pages);
    } else {
      pages = totalPageRounded;
      setTotalPages(pages);
    }
  }, [totalCount, perPage]);

  const toggle = (range) => {
    setFocusedRange(range);
    setOpen(!open);
  };
  return (
    <>
      <>
        <DateRangeSelect
          startDate={dateRange?.startDate}
          endDate={dateRange?.endDate}
          open={open}
          setOpen={setOpen}
          dateRangeChange={dateRangeChange}
          focusedRange={focusedRange}
          setFocusedRange={setFocusedRange}
          minDate={0}
        />
      </>
      <Header title={"Customers"} />
      <div
        style={{
          display: "flex",
          marginTop: "40px",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <input
          style={{ maxWidth: "450px" }}
          type="text"
          className="model-input"
          placeholder="Enter text..."
          name="name"
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "20px",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => setPeriod(!period)}
        >
          <input
            type="checkbox"
            value="above"
            name="checkbox"
            label="Select by date"
            className="checkbox-input-body"
            checked={period}
            id="period"
            key="period"
          />
          <p style={{ margin: "0px" }}>Select by date</p>
        </div>
        <div className="boomio_input_main" style={{ minWidth: "250px" }}>
          <div
            className="boomio_input boomio_datepicker"
            style={{
              position: "relative",
              paddingRight: "20px",
              minWidth: "250px",
              display: "flex",
              justifyContent: "space-between",
            }}
            onClick={() => toggle([0, 0])}
          >
            <input type={"hidden"} />
            {`${dateRange?.startDate ? dateRange?.startDate : "Set Start Date"} 
                      `}
            <img src={datepicker} alt="" onClick={() => toggle([0, 0])} />
          </div>
        </div>
        <div className="boomio_input_main" style={{ minWidth: "250px" }}>
          <div
            className={
              dateRange?.endDate
                ? "boomio_input boomio_datepicker"
                : "boomio_input_active boomio_datepicker"
            }
            style={{
              position: "relative",
              paddingRight: "20px",
              minWidth: "250px",
              display: "flex",
              justifyContent: "space-between",
            }}
            onClick={() => toggle([0, 1])}
          >
            <input type={"hidden"} />

            {`
    ${dateRange?.endDate ? dateRange?.endDate : "Set End Date"} `}

            <img src={datepicker} alt="" onClick={() => toggle([0, 1])} />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => setDecrypt(!decrypt)}
        >
          <input
            type="checkbox"
            value="above"
            name="checkbox"
            label="Decrypt"
            className="checkbox-input-body"
            checked={decrypt}
            id="decrypt"
            key="decrypt"
          />
          <p style={{ margin: "0px" }}>Decrypt</p>
        </div>
      </div>
      <div className="customer-table">
        <div className="table-bg">
          <div className="table-bg-inner">
            <div className="table-scroll">
              <div style={{ width: "2000px" }}>
                <table className=" overflow-x-auto position-relative">
                  <thead className="thead-bg">
                    <tr>
                      <th scope="col" style={{ whiteSpace: "nowrap" }}>
                        <div className="d-flex align-items-center">
                          <input
                            type="checkbox"
                            value="above"
                            name="checkbox"
                            checked={isChecked}
                            onChange={() => {
                              handleChangeAll(isChecked);
                              setIsChecked((prev) => !prev);
                            }}
                            className="checkbox-input"
                          />
                          <div
                            className="d-flex align-items-center"
                            onClick={() => {
                              setOrder({
                                orderBy: "campaign_name",
                                orderDirection:
                                  sortDirection === 1 ? "asc" : "desc",
                              });
                              setSortDirection(sortDirection === 1 ? -1 : 1);
                            }}
                          >
                            <span className="headr-text">Campaign Name</span>
                            <img
                              src="./assets/img/header-icon.png"
                              className="icon-6"
                              alt=""
                            />
                          </div>
                        </div>
                      </th>
                      <th scope="col">
                        <div
                          className="d-flex align-items-center"
                          onClick={() => {
                            setOrder({
                              orderBy: "campaign_type",
                              orderDirection:
                                sortDirection === 1 ? "asc" : "desc",
                            });
                            setSortDirection(sortDirection === 1 ? -1 : 1);
                          }}
                        >
                          <span className="headr-text">Campaign Type</span>
                          <img
                            src="./assets/img/header-icon.png"
                            className="icon-6"
                            alt=""
                          />
                        </div>
                      </th>
                      <th scope="col">
                        <div
                          className="d-flex align-items-center"
                          onClick={() => {
                            setOrder({
                              orderBy: "nickname",
                              orderDirection:
                                sortDirection === 1 ? "asc" : "desc",
                            });
                            setSortDirection(sortDirection === 1 ? -1 : 1);
                          }}
                        >
                          <span className="headr-text">Nickname</span>
                          <img
                            src="./assets/img/header-icon.png"
                            className="icon-6"
                            alt=""
                          />
                        </div>
                      </th>{" "}
                      <th scope="col">
                        <div
                          className="d-flex align-items-center"
                          onClick={() => {
                            setOrder({
                              orderBy: "name",
                              orderDirection:
                                sortDirection === 1 ? "asc" : "desc",
                            });
                            setSortDirection(sortDirection === 1 ? -1 : 1);
                          }}
                        >
                          <span className="headr-text">Name</span>
                          <img
                            src="./assets/img/header-icon.png"
                            className="icon-6"
                            alt=""
                          />
                        </div>
                      </th>{" "}
                      <th scope="col">
                        <div
                          className="d-flex align-items-center"
                          onClick={() => {
                            setOrder({
                              orderBy: "email",
                              orderDirection:
                                sortDirection === 1 ? "asc" : "desc",
                            });
                            setSortDirection(sortDirection === 1 ? -1 : 1);
                          }}
                        >
                          <span className="headr-text">Email</span>
                          <img
                            src="./assets/img/header-icon.png"
                            className="icon-6"
                            alt=""
                          />
                        </div>
                      </th>
                      <th scope="col" style={{ whiteSpace: "nowrap" }}>
                        <div
                          className="d-flex align-items-center"
                          onClick={() => {
                            setOrder({
                              orderBy: "emails_consent",
                              orderDirection:
                                sortDirection === 1 ? "asc" : "desc",
                            });
                            setSortDirection(sortDirection === 1 ? -1 : 1);
                          }}
                        >
                          <span className="headr-text">Emails Consent</span>
                          <img
                            src="./assets/img/header-icon.png"
                            className="icon-6"
                            alt=""
                          />
                        </div>
                      </th>
                      <th scope="col" style={{ whiteSpace: "nowrap" }}>
                        <div
                          className="d-flex align-items-center"
                          onClick={() => {
                            setOrder({
                              orderBy: "best_score",
                              orderDirection:
                                sortDirection === 1 ? "asc" : "desc",
                            });
                            setSortDirection(sortDirection === 1 ? -1 : 1);
                          }}
                        >
                          <span className="headr-text">Max Score</span>
                          <img
                            src="./assets/img/header-icon.png"
                            className="icon-6"
                            alt=""
                          />
                        </div>
                      </th>
                      <th scope="col" style={{ whiteSpace: "nowrap" }}>
                        <div
                          className="d-flex align-items-center"
                          onClick={() => {
                            setOrder({
                              orderBy: "user_since",
                              orderDirection:
                                sortDirection === 1 ? "asc" : "desc",
                            });
                            setSortDirection(sortDirection === 1 ? -1 : 1);
                          }}
                        >
                          <span className="headr-text">
                            User registered date
                          </span>
                          <img
                            src="./assets/img/header-icon.png"
                            className="icon-6"
                            alt=""
                          />
                        </div>
                      </th>
                      <th scope="col" style={{ whiteSpace: "nowrap" }}>
                        <div
                          className="d-flex align-items-center"
                          onClick={() => {
                            setOrder({
                              orderBy: "average_duration_seconds",
                              orderDirection:
                                sortDirection === 1 ? "asc" : "desc",
                            });
                            setSortDirection(sortDirection === 1 ? -1 : 1);
                          }}
                        >
                          <span className="headr-text">
                            Avg. Time spent per game
                          </span>
                          <img
                            src="./assets/img/header-icon.png"
                            className="icon-6"
                            alt=""
                          />
                        </div>
                      </th>
                      <th scope="col" style={{ whiteSpace: "nowrap" }}>
                        <div
                          className="d-flex align-items-center"
                          onClick={() => {
                            setOrder({
                              orderBy: "total_duration_seconds",
                              orderDirection:
                                sortDirection === 1 ? "asc" : "desc",
                            });
                            setSortDirection(sortDirection === 1 ? -1 : 1);
                          }}
                        >
                          <span className="headr-text">
                            Total time spent per game
                          </span>
                          <img
                            src="./assets/img/header-icon.png"
                            className="icon-6"
                            alt=""
                          />
                        </div>
                      </th>{" "}
                      <th scope="col" style={{ whiteSpace: "nowrap" }}>
                        <div
                          className="d-flex align-items-center"
                          onClick={() => {
                            setOrder({
                              orderBy: "rounds_finished",
                              orderDirection:
                                sortDirection === 1 ? "asc" : "desc",
                            });
                            setSortDirection(sortDirection === 1 ? -1 : 1);
                          }}
                        >
                          <span className="headr-text">Game play attempts</span>
                          <img
                            src="./assets/img/header-icon.png"
                            className="icon-6"
                            alt=""
                          />
                        </div>
                      </th>
                      <th scope="col" style={{ whiteSpace: "nowrap" }}>
                        <div
                          className="d-flex align-items-center"
                          onClick={() => {
                            setOrder({
                              orderBy: "registrations_count",
                              orderDirection:
                                sortDirection === 1 ? "asc" : "desc",
                            });
                            setSortDirection(sortDirection === 1 ? -1 : 1);
                          }}
                        >
                          <span className="headr-text">Number of returns</span>
                          <img
                            src="./assets/img/header-icon.png"
                            className="icon-6"
                            alt=""
                          />
                        </div>
                      </th>{" "}
                      <th scope="col" style={{ whiteSpace: "nowrap" }}>
                        <div
                          className="d-flex align-items-center"
                          onClick={() => {
                            setOrder({
                              orderBy: "last_registered_at",
                              orderDirection:
                                sortDirection === 1 ? "asc" : "desc",
                            });
                            setSortDirection(sortDirection === 1 ? -1 : 1);
                          }}
                        >
                          <span className="headr-text">
                            Users last active date
                          </span>
                          <img
                            src="./assets/img/header-icon.png"
                            className="icon-6"
                            alt=""
                          />
                        </div>
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {customersData?.result?.length > 0 ? (
                      customersData?.result
                        // .sort((a, b) =>
                        //   a[sortBy] > b[sortBy]
                        //     ? sortDirection
                        //     : -1 * sortDirection
                        // )
                        .map((item, i) => {
                          return (
                            <tr key={item.id}>
                              <td style={{ whiteSpace: "nowrap" }}>
                                <input
                                  type="checkbox"
                                  value="above"
                                  name="checkbox"
                                  className="checkbox-input-body"
                                  onChange={() => handleChange(i)}
                                  checked={checkboxes[i]}
                                  id={i}
                                />
                                <span className="headr-text">
                                  {item.campaign_name}
                                </span>
                              </td>
                              <td>
                                <span className="headr-text status">
                                  {item?.campaign_type}
                                </span>
                              </td>{" "}
                              <td>
                                <span className="headr-text">
                                  {item.nickname}
                                </span>
                              </td>{" "}
                              <td>
                                <span className="headr-text">{item.name}</span>
                              </td>
                              <td>
                                <span className="headr-text">{item.email}</span>
                              </td>{" "}
                              <td>
                                <span className="headr-text">
                                  {item.emails_consent ? "true" : "false"}
                                </span>
                              </td>{" "}
                              <td>
                                <span className="headr-text">
                                  {item.best_score}
                                </span>
                              </td>{" "}
                              <td>
                                <span className="headr-text">
                                  {moment(item.user_since).format("YYYY/MM/DD")}
                                </span>
                              </td>
                              <td>
                                <span className="headr-text">
                                  {item.average_duration_seconds} s.
                                </span>
                              </td>
                              <td>
                                <span className="headr-text">
                                  {item.total_duration_seconds} s.
                                </span>
                              </td>{" "}
                              <td>
                                <span className="headr-text">
                                  {item.rounds_finished}
                                </span>
                              </td>
                              <td>
                                <span className="headr-text">
                                  {item.registrations_count}
                                </span>
                              </td>
                              <td>
                                <span className="headr-text">
                                  {moment(item.last_registered_at).format(
                                    "YYYY/MM/DD"
                                  )}
                                </span>
                              </td>
                              <td>
                                <div className="d-flex justify-content-between position-relative">
                                  <button
                                    onClick={() => {
                                      openMenu(i);
                                    }}
                                    className="three-dot-button"
                                  >
                                    <img
                                      src="./assets/img/three-dot.png"
                                      alt=""
                                      height="18px"
                                    />
                                  </button>
                                  {dropdownmanu === i && (
                                    <div className="dropdownmanu">
                                      <Button
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          setModalShow(true);
                                          setModelType("edit");
                                          setdropdownmanu();
                                        }}
                                      >
                                        {" "}
                                        <img
                                          src="./assets/img/Edit.png"
                                          alt=""
                                          className="me-2"
                                        />
                                        Edit
                                      </Button>
                                      <Button
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          setDeleteModal(true);
                                          setdropdownmanu();
                                        }}
                                      >
                                        <img
                                          src="./assets/img/delete-icon.png"
                                          alt=""
                                          className="me-2"
                                        />
                                        Delete
                                      </Button>
                                      <Button>
                                        <img
                                          src="./assets/img/downlaod-icon.png"
                                          alt=""
                                          className="me-2"
                                          onClick={() => {}}
                                        />
                                        Download
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })
                    ) : (
                      <tr>
                        <td colSpan="7">{t("noDataFound")}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="pagination mt-4 d-flex justify-content-between">
            <div className="records-per-page">
              <span className="boomio-bold purple-black">
                {capFirst(t("campaign.table.show"))}
              </span>
              <select
                className="perpage"
                name="perpage"
                id=""
                value={perPage}
                onChange={(e) => {
                  setPerPage(e.target.value);
                  setCurrentCount(1);
                  // setPageNumber(0);
                }}
              >
                <option value={10}>{t("campaign.table.10")}</option>
                <option value={20}>{t("campaign.table.20")}</option>
                <option value={50}>{t("campaign.table.50")}</option>
                <option value={100}>{t("campaign.table.100")}</option>
              </select>
              <span className="boomio-bold purple-black">
                {t("campaign.table.entries")}
              </span>
            </div>

            <div className="go-to-page">
              <Button
                style={{
                  minWidth: "120px",
                  width: "120px",
                }}
                className="outline_btn_dark me-2 campaign_first_button"
                onClick={() =>
                  getDownloadCustomersData(period, dateRange, search, decrypt)
                }
              >
                <span>Download</span>
              </Button>
              <span className="boomio-bold purple-black">
                {pageNumber + 1} {t("campaign.table.of")} {totalPages}
              </span>
              <button
                disabled={pageNumber + 1 === 1}
                style={{ opacity: pageNumber + 1 === 1 ? "0.5" : "1" }}
                className="paginationBtn"
                onClick={previousPage}
              >
                <img src="./assets/img/table-left-arrow.png" alt="" />
              </button>
              <button
                className="paginationBtn"
                style={{ opacity: pageNumber === totalPages - 1 ? "0.5" : "1" }}
                disabled={pageNumber === totalPages - 1}
                onClick={nextPage}
              >
                <img src="./assets/img/table-right-arrow.png" alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {modalShow && (
        <AddCustomer
          type={modelType}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      )}
      {importModal && (
        <ImportCustomer
          show={importModal}
          onHide={() => setImportModal(false)}
        />
      )}
    </>
  );
};

export default Customers;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Continue Button Component CSS */

.authActionButton {
  font-weight: 600;

  font-size: 16px;
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;
  /* width: 100%; */
}

.margin {
  margin-top: 40px;
}

.gradientBG {
  background: linear-gradient(
    3deg,
    #ff6e6d 0%,
    #ff3183 5.48%,
    #8559f3 88.77%,
    #657bea 95.23%,
    #34b0dc 101.49%
  );
  border-radius: 44px;
  position: relative;
}

.button {
  background: transparent;
  border-radius: 44px;
  padding: 16px 61px;
}

.continueText {
  color: white;
  -webkit-user-select: none;
  /* Safari */
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.authActionButton:active .gradientBG {
  padding: 2px;
}

.authActionButton:active .button {
  background: linear-gradient(
    90deg,
    #fee3e9 0%,
    #ffd7e8 21.87%,
    #f3dbf0 41.67%,
    #eadff7 62.5%,
    #eadff7 81.77%,
    #eed8ff 100%
  );
  padding: 14px 59px;
}

.authActionButton:active .continueText {
  background: linear-gradient(
    3deg,
    #ff6e6d 0%,
    #ff3183 5.48%,
    #8559f3 88.77%,
    #657bea 95.23%,
    #34b0dc 101.49%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* -------- Continue Button Component CSS ---------- */
`, "",{"version":3,"sources":["webpack://./src/common/Button/indexButton.css"],"names":[],"mappings":"AAAA,kCAAkC;;AAElC;EACE,gBAAgB;;EAEhB,eAAe;EACf,eAAe;EACf,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;;;;;;;GAOC;EACD,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,WAAW;EAEX,oBAAoB;EACpB,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;;;;;;;;GAQC;EACD,kBAAkB;AACpB;;AAEA;EACE;;;;;;;GAOC;EACD,6BAA6B;EAC7B,oCAAoC;AACtC;;AAEA,sDAAsD","sourcesContent":["/* Continue Button Component CSS */\n\n.authActionButton {\n  font-weight: 600;\n\n  font-size: 16px;\n  cursor: pointer;\n  border: none;\n  outline: none;\n  background: transparent;\n  /* width: 100%; */\n}\n\n.margin {\n  margin-top: 40px;\n}\n\n.gradientBG {\n  background: linear-gradient(\n    3deg,\n    #ff6e6d 0%,\n    #ff3183 5.48%,\n    #8559f3 88.77%,\n    #657bea 95.23%,\n    #34b0dc 101.49%\n  );\n  border-radius: 44px;\n  position: relative;\n}\n\n.button {\n  background: transparent;\n  border-radius: 44px;\n  padding: 16px 61px;\n}\n\n.continueText {\n  color: white;\n  -webkit-user-select: none;\n  /* Safari */\n  -ms-user-select: none;\n  /* IE 10 and IE 11 */\n  user-select: none;\n  /* Standard syntax */\n}\n\n.authActionButton:active .gradientBG {\n  padding: 2px;\n}\n\n.authActionButton:active .button {\n  background: linear-gradient(\n    90deg,\n    #fee3e9 0%,\n    #ffd7e8 21.87%,\n    #f3dbf0 41.67%,\n    #eadff7 62.5%,\n    #eadff7 81.77%,\n    #eed8ff 100%\n  );\n  padding: 14px 59px;\n}\n\n.authActionButton:active .continueText {\n  background: linear-gradient(\n    3deg,\n    #ff6e6d 0%,\n    #ff3183 5.48%,\n    #8559f3 88.77%,\n    #657bea 95.23%,\n    #34b0dc 101.49%\n  );\n  -webkit-background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n\n/* -------- Continue Button Component CSS ---------- */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

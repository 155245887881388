import React from "react";
import { Col, Row } from "react-bootstrap";

const Card = ({
  img,
  text,
  total,
  big,
  percent,
  positionimg,
  boldtext,
  tultiptext,
}) => {
  return (
    <div
      className={big ? "dashboard-card dashboard-card-big" : "dashboard-card"}
    >
      <Row style={{ height: "100%" }}>
        <Col lg={2} md={2} sm={2} xs={2} className="p-0 text-center">
          <div className="tooltip_main">
            <img
              src={`./assets/img/${positionimg}`}
              alt=""
              className="position__img icon-size"
            />
            <div className="tooltip_detail">
              <p>
                {" "}
                <span>{boldtext}</span> {tultiptext}
              </p>
            </div>
          </div>
          <img src={`./assets/img/${img}`} alt="" className="icon-40" />
        </Col>
        <Col lg={10} md={10} sm={10} xs={10} className="">
          <div className="dashboard_card_detail_main">
            <div className="card-title">
              <h2>{text}</h2>
            </div>
            <div className="card-title">
              <h1>{`${total} ${text !== "Average spending" ? "%" : ""}`}</h1>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Card;

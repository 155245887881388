import {
  Autocomplete,
  Chip,
  FormControl,
  Menu,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import ContinueButton from "common/Button";
import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  deleteUserRole,
  editUserRole,
  getTeams,
  InviteMembers,
} from "redux/features/settings/teamSlice";
import Change_manager from "../../../assets/img/Change_manager.png";
import Delete from "../../../assets/img/Delete.png";

let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;

const Team = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [manulist, setmanulist] = useState("");
  const [role, setRole] = useState("BSUP");
  const [mail, setMail] = useState([]);
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState();
  const [error, setError] = useState("");
  const [data, setData] = useState([]);
  const [emailInputValue, setEmailInputValue] = useState();

  const handleClick = (event, item, i) => {
    setAnchorEl(event.currentTarget);
    setmanulist(item);
  };

  const getTeamsData = async (pageNumber, limit, orderBy) => {
    await dispatch(getTeams([pageNumber, limit, orderBy]))
      .then(unwrapResult)
      .then((result) => {
        if (result?.payload?.isError) {
          throw result;
        }
        if (result && result.response) {
          setData(result?.response);
        }
        return result?.data?.data;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getTeamsData();
    return () => setData([])
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeuserRole = (userData) => {
    const body = {
      ur_id: userData.ur_id,
      r_id: userData.r_id === "BSUP" ? "SADM" : "BSUP",
    };
    dispatch(editUserRole(body))
      .then(unwrapResult)
      .then((result) => {
        getTeamsData();
      })
      .catch((error) => {
        console.error(error);
      });
    setAnchorEl(null);
  };

  const deleteTeamUser = (userData) => {
    const body = {
      ur_id: userData.ur_id,
    };
    dispatch(deleteUserRole(body))
      .then(unwrapResult)
      .then((result) => {
        getTeamsData();
      })
      .catch((error) => {
        console.error(error);
      });
    setAnchorEl(null);
  };

  const onSave = async () => {
    if (mail.length < 1) {
      if (emailInputValue && emailInputValue.length) {
        setError("PRESS ENTER to add Email");
      } else {
        setError("Email required!");
      }
    } else {
      const invalidMail = mail.find((e) => !emailRegex.test(e));
      if (invalidMail) {
        setError(`${invalidMail} is not a valid email`);
      } else {
        const body = {
          emails: mail.toString(),
          role: role,
        };
        await dispatch(InviteMembers(body))
          .then(unwrapResult)
          .then((res) => {
            setError("");
            const successResults = res?.response?.successResults
              ? Object.keys(res.response.successResults).map((item) => ({
                email: item,
                status: res?.response?.successResults[item].message,
              }))
              : [];
            const errorResults = res?.response?.errors
              ? Object.values(res.response.errors).map((item) => ({
                email: item.email,
                status: item.message,
              }))
              : [];
            setModalData(successResults.concat(errorResults));
            setShowModal(true);
            if (res?.payload?.isError) {
              throw res;
            }
          })
          .catch((e) => {
            console.log("e", e);
          });
      }
      setEmailInputValue("");
    }
  };

  const handleChange = (event) => {
    setRole(event.target.value);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleCloseSelect = () => {
    setOpen(false);
  };


  const onChangeMail = (e, value) => {
    setMail(value);
    setError("");
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setMail([]);
  };

  return (
    <>
      <div className="billinginvoices members-table p-0">
        <div className="members-header">
          <h2 className="members_title">Invite members</h2>
        </div>
        <Row className="invite_members gy-2">
          <Col xl={8} lg={7}>
            <Autocomplete
              multiple
              onChange={onChangeMail}
              options={[]}
              value={mail}
              freeSolo
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    className="chip"
                    variant="outlined"
                    label={option}
                    key={index}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <>
                  <label>Invite by Email</label>
                  <TextField
                    onChange={(e) => setEmailInputValue(e.target.value)}
                    {...params}
                    placeholder="Enter email"
                    style={{
                      border: "1px solid #B0AFBE",
                      padding: "0px",
                      borderRadius: "16px",
                    }}
                  />
                </>
              )}
            />
            {error && (
              <label style={{ color: "red", margin: "5px" }}>{error}</label>
            )}
          </Col>
          <Col xl={4} lg={5}>
            <FormControl
              className=""
              style={{
                marginTop:'28px',
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "start",
              }}
            >
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                open={open}
                size="small"
                style={{
                  border: "1px solid #B0AFBE",
                  padding: "0px",
                  borderRadius: "16px",
                  width:'100%'
                }}
                onClose={handleCloseSelect}
                onOpen={handleOpen}
                value={role}
                onChange={handleChange}
                inputProps={{ onBlurCapture: () => true }}
              >
                <MenuItem
                  value={"BSUP"}
                  style={{ display: "flex", justifyContent: "space-between"}}
                >
                  <span>
                    <b>Member&nbsp;</b>
                  </span>
                  <span>Can view</span>
                </MenuItem>
                <MenuItem
                  value={"SADM"}
                  style={{ display: "flex", justifyContent: "space-between"}} 
                
                >
                  <span >
                    <b>Manager&nbsp;</b>
                  </span>
                  <span>Can Manage</span>
                </MenuItem>
              </Select>
            </FormControl>
          </Col>
        </Row>
        <ContinueButton
          text="Send"
          arrowVisible={false}
          onSubmit={() => onSave()}
        />
        <div className="members-header" style={{ marginTop: "30px" }}>
          <h2 className="members_title">Team members</h2>
        </div>
        <div style={{ width: "100%", overflow: "auto" }}>
          <table style={{ minWidth: "1100px" }}>
            <thead className="thead-bg">
              <tr>
                <th scope="col">
                  <div className="d-flex align-items-center">
                    <input
                      type="checkbox"
                      value="above"
                      name="checkbox"
                      onChange={() => { }}
                      className="checkbox-input"
                    />
                    <span className="headr-text">ID</span>
                    <img
                      src="./assets/img/header-icon.png"
                      className="icon-6"
                      alt=""
                    />
                  </div>
                </th>
                <th scope="col">
                  <span className="headr-text">Name</span>
                  <img
                    src="./assets/img/header-icon.png"
                    className="icon-6"
                    alt=""
                  />
                </th>
                <th scope="col">
                  <span className="headr-text">Phone Number</span>
                  <img
                    src="./assets/img/header-icon.png"
                    className="icon-6"
                    alt=""
                  />
                </th>
                <th scope="col">
                  <span className="headr-text">Email</span>
                  <img
                    src="./assets/img/header-icon.png"
                    className="icon-6"
                    alt=""
                  />
                </th>
                <th scope="col">
                  <span className="headr-text">Role</span>
                  <img
                    src="./assets/img/header-icon.png"
                    className="icon-6"
                    alt=""
                  />
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, i) => (
                <tr key={i}>
                  <td>
                    <div className="d-flex align-items-center">
                      <input
                        type="checkbox"
                        value="above"
                        name="checkbox"
                        onChange={() => { }}
                        className="checkbox-input-body"
                      />
                      <span className="headr-text">{item?.u_id}</span>
                    </div>
                  </td>
                  <td>
                    <span className="headr-text">{item?.name}</span>
                  </td>
                  <td>
                    <span className="headr-text">{item?.phoneNumber}</span>
                  </td>
                  <td>
                    <span className="headr-text">{item?.email}</span>
                  </td>
                  <td>
                    <span
                      className="headr-text status"
                      style={{
                        border: "1px solid #473F4E",
                        width: "110px",
                        padding: "4px 30px",
                      }}
                    >
                      {item?.roles[0]?.r_id === "SADM" ? "Manager" : "Member"}
                    </span>
                  </td>
                  <td className="team_action">
                    <div className="d-flex justify-content-between">
                      <span className="headr-text">{item.end_date}</span>
                      <img
                        src="./assets/img/three-dot.png"
                        alt=""
                        onClick={(e) => handleClick(e, item?.roles[0], i)}
                        height="18px"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Menu
          id={`simple-menu`}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => changeuserRole(manulist)}
            style={{ gap: "10px" }}
          >
            <img src={Change_manager} alt="" />
            {manulist?.r_id === "SADM"
              ? `Change to member`
              : `Change to manager`}
          </MenuItem>
          <MenuItem
            onClick={() => deleteTeamUser(manulist)}
            style={{ gap: "10px" }}
          >
            <img src={Delete} alt="" />
            Delete
          </MenuItem>
        </Menu>
      </div>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Invite members
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="invite_members_modal_tabel">
            <thead>
              <tr>
                <th>Email</th>
                <th>Status</th>
              </tr>
            </thead>
            {modalData &&
              modalData.length &&
              modalData.map((ele, i) => (
                <tbody key={i}>
                  <tr>
                    <td>{ele.email}</td>
                    <td>{ele.status}</td>
                  </tr>
                </tbody>
              ))}
          </table>
        </Modal.Body>
        <Modal.Footer>
          <button
            style={{
              marginRight: 0,
              marginLeft: "auto",
            }}
            type="submit"
            className="authActionButton"
            onClick={handleCloseModal}
          >
            <div className="gradientBG">
              <div className="button">
                <span className="continueText">Ok</span>
              </div>
            </div>
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Team;

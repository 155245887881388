import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { ApiPost } from "helper/API/ApiData"
import { ReportBaseUrl } from "helper/API/BaseUrl"

export const getDashboard = createAsyncThunk(
    'dashbord/getDashboard',
    async (data, thunkAPI) => {
        return await ApiPost(`${ReportBaseUrl}reports/execute`, data).then((res) => {
            return res
        }).catch((err) => {
            err = { isError: true }
            return err
        })

    }
)

export const dashboardSlice = createSlice({
    name: "dashboardSlice",
    initialState: { data: [], loading: false },
    reducers: {
    },
    extraReducers: {
        [getDashboard.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.value += action.payload;
            state.getDashboard = JSON.parse(JSON.stringify(action.payload.response));
        },
        [getDashboard.reject]: (state, action) => {
            state.isLoading = false;
        },
        [getDashboard.pending]: (state, action) => {
            state.isLoading = true;
        },
    },

})


export default dashboardSlice.reducer;
import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import PromotionalCampaign from "../../assets/img/promotional-campaign.png";
import MyFirstCustomer from "../../assets/img/my-first-customer.png";
import EventOrganization from "../../assets/img/Event_Organization.png";
import { useTranslation } from "react-i18next";

const StartCampaign = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <>
      <div className="campaigns-heade">
        <h5>{`Campaigns->Start Campaign`}</h5>
      </div>
      <div className="campaigns-heade">
        <h1 className="page-header">Start Campaign</h1>
      </div>
      <div className="d-flex justify-content-around flex-wrap">
        <div className="start-campaign start-campaign-item">
          <img src={MyFirstCustomer} alt="" />
          <h5>My first customer</h5>
          <p className="boomio-gray">
            All offers automatically received by new customers when they join
            your place
          </p>
          <Button
            className="button-8559F3 mt-4"
            onClick={() =>
              navigate(
                "/campaigns/start-campaign/automatic-campaign/welcome-reward"
              )
            }
          >
            Get Started
          </Button>
        </div>
        <div className="start-campaign start-campaign-item">
          <div className="boomio-disabled">
            <img src={PromotionalCampaign} alt="" />
            <h5>Promotional campaign</h5>
            <p className="boomio-gray">
              Time limited offers, i.e. give your customers special offers for
              Sundays, their birthdays or for Christmas period
            </p>
            <Button className="button-8559F3 mt-4">Get Started</Button>
          </div>
          <Button className="coming-soon-btn">Coming Soon</Button>
        </div>
        <div className="start-campaign start-campaign-item">
          <div className="boomio-disabled">
            <img src={EventOrganization} alt="" />
            <h5>Event Organization</h5>
            <p className="boomio-gray">
              Give a limited amount of tickets for your loyal customers to
              participate in your event, i.e. your shop birthday
            </p>
            <Button className="button-8559F3 mt-4">Get Started</Button>
          </div>
          <Button className="coming-soon-btn">Coming Soon</Button>
        </div>
      </div>
    </>
  );
};

export default StartCampaign;

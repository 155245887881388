import { Divider, IconButton, Menu, MenuItem } from '@mui/material'
import { unwrapResult } from '@reduxjs/toolkit'
import React, { useEffect, useState } from 'react'
import { Button, Dropdown, } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { getAllCouponsData } from 'redux/features/couponsAndCards/couponsAndCardsSlice'
import CreateCoupons from '../componets/modals/CreateCoupons'
import ImageGallery from '../componets/modals/ImageGallery'
import { t } from 'i18next'

const Coupons = () => {
    const dispatch = useDispatch()
    const [type, setType] = useState("")
    const [filterbtn, setFilterbtn] = useState(false)
    const [modalShow, setModalShow] = useState(false);
    const [editModalShow, setEditModalShow] = useState(false);
    const [dropdownmanu, setdropdownmanu] = useState();
    const [selectImg, setSelectImg] = useState(false);
    const [selectedId, setSelectedId] = useState()
    const [couponsData, setCouponsData] = useState([])
    const [couponstotalCount, setCouponsTotalCount] = useState(0)
    const [couponsperPage, setCouponsPerPage] = useState(10)
    const [couponspageNumber, setCouponsPageNumber] = useState(0)
    const [couponstotalPages, setCouponsTotalPages] = useState(0)
    const [couponscurrentCount, setCouponsCurrentCount] = useState(0)
    const [cardstotalCount, setCardsTotalCount] = useState(0)
    const [cardsperPage, setCardsPerPage] = useState(10)
    const [cardspageNumber, setCardsPageNumber] = useState(0)
    const [cardstotalPages, setCardsTotalPages] = useState(0)
    const [cardscurrentCount, setCardsCurrentCount] = useState(0)
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [editModalData, seteditModalData] = useState()

    const getCouponsData = async () => {
        await dispatch(getAllCouponsData()).then(unwrapResult).then((result) => {
            setCouponsData(result?.data)
            return result?.data?.data
        }).catch((error) => {
            console.error(error);
        });
    }



    useEffect(() => {
        const totalPageRounded = Math.round(couponstotalCount / couponsperPage)
        const totalPage = couponstotalCount / couponsperPage
        let pages
        if (totalPage > totalPageRounded) {
            pages = totalPageRounded + 1
            setCouponsTotalPages(pages)
        } else {
            pages = totalPageRounded
            setCouponsTotalPages(pages)
        }
    }, [couponstotalCount]);

    useEffect(() => {
        const totalPageRounded = Math.round(cardstotalCount / cardsperPage)
        const totalPage = cardstotalCount / cardsperPage
        let pages
        if (totalPage > totalPageRounded) {
            pages = totalPageRounded + 1
            setCardsTotalPages(pages)
        } else {
            pages = totalPageRounded
            setCardsTotalPages(pages)
        }
    }, [cardstotalCount]);

    const nextPageCoupons = () => {
        const nextpage = couponspageNumber + 1
        if (nextpage < couponstotalPages) {
            setCouponsPageNumber(nextpage)
        } else {
            getCouponsData(couponspageNumber)
        }
    }

    const previousPageCoupons = () => {
        const nextpage = couponspageNumber - 1
        if (nextpage >= 0) {
            setCouponsPageNumber(nextpage)
        } else {
            getCouponsData(couponspageNumber)
        }
    }

    useEffect(() => {
        if (couponscurrentCount > couponstotalPages) {
            setCouponsCurrentCount(couponspageNumber)
        } else {
            setCouponsCurrentCount(couponspageNumber + 1)
        }
    }, [couponspageNumber]);


    const handleSelectAll = (e) => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(couponsData.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    const handleClick = (e) => {
        const { id, checked } = e.target;
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== parseInt(id)));
        } else {
            setIsCheck([...isCheck, parseInt(id)]);
        }
    };

    const openMenu = (i) => {
        if (i === dropdownmanu) {
            setdropdownmanu()
        } else {
            setdropdownmanu(i)
        }
    }

    const refreshList = () => {
        getCouponsData(0)
    }

    useEffect(() => {
        if (cardscurrentCount > cardstotalPages) {
            setCardsCurrentCount(cardspageNumber)
        } else {
            setCardsCurrentCount(cardspageNumber + 1)
        }
    }, [cardspageNumber]);

    useEffect(() => {
        getCouponsData()
    }, []);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClickK = (event, i) => {
        setAnchorEl(event.currentTarget);
        openMenu(i)
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <div className='campaigns-heade'>
                <h1 className='page-header'>{t("coupons.heder")}</h1>
                <Button disabled onClick={() => { setModalShow(true) }} className='button-8559F3'>{t("coupons.btn_create_new")}</Button>
            </div>
            <div className='loyalty-cards'>
                <div className='table-bg'>
                    <h2 className='my-coupons'>{t("coupons.my_coupons")}</h2>
                    <div className='d-flex mb-4 position-relative'>
                        <div className='search'>
                            <input
                                type="text"
                                value=""
                                name='Search'
                                onChange={() => { }}
                                className="search-input"
                                placeholder='Search'
                            />
                            <img src="./assets/img/search-icon.png" alt="" />
                        </div>
                        <Button className={filterbtn ? 'filterbtn-click' : 'filter-btn'} onClick={() => { setFilterbtn(!filterbtn) }}>
                            {filterbtn ?
                                <img src="./assets/img/Vector1.png" alt="" /> :
                                <img src="./assets/img/filter.png" alt="" />
                            }
                        </Button>
                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                {t("coupons.action.text")}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                    <img src="./assets/img/delete-icon.png" alt="" className='me-2' />
                                    {t("coupons.action.delete")}
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                    <img src="./assets/img/downlaod-icon.png" alt="" className='me-2' />
                                    {t("coupons.action.download")}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className='table-bg-inner'>
                        <div className="table-scroll">
                            <div style={{ minWidth: "1000px" }} >
                                <table className=" overflow-x-auto position-relative">
                                    <thead className='thead-bg'>
                                        <tr>
                                            <th scope="col">
                                                <div className='d-flex align-items-center'>
                                                    <input
                                                        type="checkbox"
                                                        name='checkbox'
                                                        onChange={(e) => { handleSelectAll(e) }}
                                                        className="checkbox-input"
                                                        checked={isCheckAll}
                                                    />
                                                    <span className='headr-text'>{t("coupons.table.card_image")}</span>
                                                    <img src="./assets/img/header-icon.png" alt="" />
                                                </div>
                                            </th>
                                            <th scope="col">
                                                <span className='headr-text'>{t("coupons.table.name")}</span>
                                                <img src="./assets/img/header-icon.png" alt="" />
                                            </th>
                                            <th scope="col">
                                                <span className='headr-text'>{t("coupons.table.sent_total")}</span>
                                                <img src="./assets/img/header-icon.png" alt="" />
                                            </th>
                                            <th scope="col">
                                                <span className='headr-text'>{t("coupons.table.used_total")}</span>
                                                <img src="./assets/img/header-icon.png" alt="" />
                                            </th>
                                            <th scope='col'>
                                                <span className='headr-text'>{t("coupons.table.percentage_of_used")}</span>
                                                <img src="./assets/img/header-icon.png" alt="" />
                                            </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            couponsData?.length > 0 ? couponsData.map((item, i) => (
                                                <tr>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <input
                                                                type="checkbox"
                                                                name='checkbox'
                                                                id={item.id}
                                                                onChange={(e) => { handleClick(e) }}
                                                                className="checkbox-input-body"
                                                                checked={isCheck.includes(item.id)}
                                                            />
                                                            {
                                                                item?.params?.large_coupon_picture ? <img src={item?.params?.large_coupon_picture} alt="" style={{ width: "116px", height: "48px", borderRadius: '3px' }} /> :
                                                                    <img src="./assets/img/br.png" alt="" style={{ width: "116px", height: "48px", opacity: '0.2', borderRadius: '3px' }} />
                                                            }
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span className='headr-text'>{item?.params?.top_description}</span>
                                                    </td>
                                                    <td>
                                                        <span className='headr-text'>{item?.coupons_count}</span>
                                                    </td>
                                                    <td>
                                                        <span className='headr-text'>{item?.used_coupons_count}</span>
                                                    </td>
                                                    <td>
                                                        <span className='headr-text'>{item?.used_coupons_count}</span>
                                                    </td>
                                                    <td>


                                                        <IconButton
                                                            disabled
                                                            onClick={(e) => handleClickK(e, i)}
                                                            size="small"
                                                            sx={{ ml: 2 }}
                                                            aria-controls={open ? 'account-menu' : undefined}
                                                            aria-haspopup="true"
                                                            aria-expanded={open ? 'true' : undefined}
                                                        >

                                                            <img src="./assets/img/three-dot.png" alt="" height="18px" />
                                                        </IconButton>
                                                        {dropdownmanu === i && <Menu
                                                            anchorEl={anchorEl}
                                                            id="account-menu"
                                                            open={open}
                                                            onClose={handleClose}
                                                            onClick={handleClose}
                                                            PaperProps={{
                                                                elevation: 0,
                                                                sx: {
                                                                    overflow: 'visible',
                                                                    filter: 'drop-shadow(0px 0px 1px #E1E1E1)',
                                                                    mt: 1.5,
                                                                    '& .MuiAvatar-root': {
                                                                        width: 32,
                                                                        height: 32,
                                                                        ml: -0.5,
                                                                        mr: 1,
                                                                    },
                                                                    '&:before': {
                                                                        content: '""',
                                                                        display: 'block',
                                                                        position: 'absolute',
                                                                        top: 0,
                                                                        right: 14,
                                                                        width: 10,
                                                                        height: 10,
                                                                        bgcolor: 'background.paper',
                                                                        transform: 'translateY(-50%) rotate(45deg)',
                                                                        zIndex: 0,
                                                                    },
                                                                },
                                                            }}
                                                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                                        >
                                                            <MenuItem onClick={() => {
                                                                setEditModalShow(true)
                                                                setSelectedId(item.id)
                                                                setType("Coupon Card")

                                                            }}>
                                                                <img src="./assets/img/Edit.png" alt="" className='me-2' />
                                                                {t("coupons.action.edit")}
                                                            </MenuItem>
                                                            <Divider />
                                                            <MenuItem>
                                                                <img src="./assets/img/delete-icon.png" alt="" className='me-2' />{t("coupons.action.delete")}
                                                            </MenuItem>
                                                            <Divider />
                                                            <MenuItem>
                                                                <img src="./assets/img/downlaod-icon.png" alt="" className='me-2' />{t("coupons.action.download")}
                                                            </MenuItem>

                                                        </Menu>}

                                                    </td>
                                                </tr>
                                            ))
                                                :
                                                <tr>
                                                    <td colSpan="7">
                                                        {t("noDataFound")}
                                                    </td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="pagination d-flex justify-content-between">
                        <div className="records-per-page">
                            {t("coupons.table.show")}
                            <select className='perpage' name="perpage" id="" value={couponsperPage} onChange={(e) => setCouponsPerPage(e.target.value)} disabled>
                                <option vlaue="10">{t("coupons.table.10")}</option>
                                <option vlaue="20">{t("coupons.table.20")}</option>
                                <option vlaue="50">{t("coupons.table.50")}</option>
                                <option vlaue="100">{t("coupons.table.100")}</option>
                            </select>
                            {t("coupons.table.entries")}
                        </div>
                        <div className="go-to-page">
                            {couponscurrentCount} {t("coupons.table.of")} {couponstotalPages}
                            <img src="./assets/img/table-left-arrow.png" alt="" onClick={previousPageCoupons} />
                            <img src="./assets/img/table-right-arrow.png" alt="" onClick={nextPageCoupons} />
                        </div>
                    </div>
                </div>
            </div>

            {
                modalShow && <CreateCoupons show={modalShow} onHideCoupons={() => setModalShow(false)} showData={true} showSelectImg={() => { setSelectImg(true) }} refreshList={refreshList} />
            }
            {
                editModalShow && <CreateCoupons type={type} selectedId={selectedId} show={editModalShow} defaultData={editModalData} showSelectImg={() => { setSelectImg(true) }} onHideCoupons={() => { setEditModalShow(false); refreshList() }} showData={false} refreshList={refreshList} />
            }
            {selectImg && <ImageGallery show={selectImg} onHide={() => setSelectImg(false)} />}
        </>
    )
}

export default Coupons

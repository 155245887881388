import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiPatch } from "helper/API/ApiData";
import { UserBaseUrl } from "helper/API/BaseUrl";
import { toast } from "react-toastify";

export const changePasswordAPI = createAsyncThunk(
    'authentication/loginUserAPI',
    async (editData) => {
        const body = {
            password: editData?.oldpassword,
            newPassword: editData?.newpassword,
            confirmNewPassword: editData?.confirmPassword
        }
        return await ApiPatch(`${UserBaseUrl}users/me`, body).then((res) => {
            toast.success("Password changed successfully", {
                closeOnClick: true,
                pauseOnHover: true,
            });
            return res;
        }).catch((err) => {
            toast.error("Please enter correct password", {
                closeOnClick: true,
                pauseOnHover: true,
            });
            console.log(err);
            return err;
        })

    }
)
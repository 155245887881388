import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { editUserDataAPI, getUserDataAPI } from 'redux/features/authentication/authSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import ChangePasswordModal from 'common/ChangePasswordModal';

const MyAccount = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [userData, setuserData] = useState()
  const [modalShow, setModalShow] = useState(false);

  const { t } = useTranslation();

  const EditSchema = yup.object({
      name: yup.string().required(t("SignUp.Errors.name")),
      surname: yup.string().required(t("SignUp.Errors.surName")),
      phone: yup.string().required(t("SignUp.Errors.phoneNumber")),
      job_title: yup.string().required(t("SignUp.Errors.jobTitle")),
  });

  const { register, handleSubmit, reset, formState: { errors } } = useForm({
      resolver: yupResolver(EditSchema),
      defaultValues: {
          name: userData?.name,
          surname: userData?.surname,
      }
  });

  const handleEditData = async (data) => {
      await dispatch(editUserDataAPI([userData, data, navigate])).then(unwrapResult).then((result) => {
          setuserData(result?.response)
      }).catch((error) => {
          console.error(error);
      });
  }

  const setUserProfileData = async () => {
      await dispatch(getUserDataAPI()).then(unwrapResult).then((result) => {
          setuserData(result?.response)
      }).catch((error) => {
          console.error(error);
      });
  }

  useEffect(() => {
      setUserProfileData()
  }, [])
  useEffect(() => {
      reset({
          name: userData?.name,
          surname: userData?.surname,
          phone: userData?.phone,
          email: userData?.email,
          job_title: userData?.job_title
      });
  }, [reset, userData])

  return (
      <>
          <div className='campaigns-heade'>
              <h1 className='page-header'>Edit Profile</h1>
          </div>
          <form action="">
              <div className='myaccount table-bg'>
                  <Row style={{ rowGap: '10px' }}>
                      <Col lg={6} md={6} sm={6}>
                          <p className='title-lable'>
                              {t("EditProfile.Fields.name")}
                          </p>
                          <input
                              type="text"
                              className='model-input'
                              name="name"
                              placeholder="name"
                              {...register("name")}
                              defaultValue={userData?.name}
                          />
                          {errors?.name && <Form.Text className="color">{errors?.name?.message}</Form.Text>}
                      </Col>
                      <Col lg={6} md={6} sm={6}>

                          <p className='title-lable'>
                              {t("EditProfile.Fields.surname")}
                          </p>
                          <input
                              type="text"
                              className='model-input'
                              name="surname"
                              placeholder="surname"
                              {...register("surname")}
                              defaultValue={userData?.surname}
                          />
                          {errors?.surname && <Form.Text className="color">{errors?.surname?.message}</Form.Text>}
                      </Col>
                      <Col lg={6} md={6} sm={6}>

                          <p className='title-lable'>
                              {t("EditProfile.Fields.phoneNumber")}
                          </p>
                          <input
                              type="text"
                              className='model-input'
                              name="phone"
                              placeholder="phone"
                              {...register("phone")}
                              defaultValue={userData?.phone}
                          />
                          {errors?.phone && <Form.Text className="color">{errors?.phone?.message}</Form.Text>}
                      </Col>
                      <Col lg={6} md={6} sm={6}>

                          <p className='title-lable'>
                              {t("EditProfile.Fields.email")}
                          </p>
                          <input
                              type="text"
                              className='model-input'
                              name="email"
                              placeholder="email"
                              {...register("email")}
                              defaultValue={userData?.email}
                              disabled
                          />
                          {errors?.email && <Form.Text className="color">{errors?.email?.message}</Form.Text>}
                      </Col>
                      <Col lg={6} md={6} sm={6}>

                          <p className='title-lable'>
                              {t("EditProfile.Fields.jobTitle")}
                          </p>
                          <input
                              type="text"
                              className='model-input'
                              name="job_title"
                              placeholder="add title"
                              {...register("job_title")}
                              defaultValue={userData?.job_title}
                          />
                          {errors.job_title && <Form.Text className="color">{errors?.job_title?.message}</Form.Text>}
                      </Col>
                  </Row>
                  <div className='start-promotional-campaign-footer mt-3'>
                      <button
                          type="button"
                          className='authActionButton'
                          onClick={() => setModalShow(true)}
                      >
                          <div className="gradientBG">
                              <div className="button">
                                  <span className="continueText">Change password</span>
                              </div>
                          </div>
                      </button>
                      <button
                          type="submit"
                          className='authActionButton'
                          onClick={handleSubmit((data) => {
                              handleEditData(data)
                          })}
                      >
                          <div className="gradientBG">
                              <div className="button">
                                  <span className="continueText">{t("EditProfile.Edit_btn")}</span>
                              </div>
                          </div>
                      </button>
                  </div>
              </div>
          </form>
          {
              modalShow &&
              <ChangePasswordModal show={modalShow} onHide={() => setModalShow(false)} />
          }
      </>
  )
};
export default MyAccount;
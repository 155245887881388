import React, { useRef, useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import ExamplePreview from "./ExamplePreview";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import logo from "../../assets/img/logoSmall.png";
import backgroundLogoSmall from "../../assets/img/backgroundLogoSmall.png";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  createTeaser,
  editTeaser,
  getTeaserById,
  getMyTeaser,
  deleteTeaser,
} from "redux/features/teser/teaserSlice";
import { ApiPost } from "helper/API/ApiData";
import { FileBaseUrl } from "helper/API/BaseUrl";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import ReactGA from "react-ga4";

const MobileApp = () => {
  const dispatch = useDispatch();
  const businessState = useSelector((state) => state.businessState.data);
  const [exampleData, setExampleData] = useState({
    logo: {
      type: "common",
      logoFormData: null,
      url: logo,
      id: "",
      title: "",
      description: "",
    },
    background: {
      type: "COUPON_BACKGROUND",
      logoFormData: null,
      url: backgroundLogoSmall,
      id: "",
      title: "background",
      description: "background",
    },
    banner: {
      type: "top",
      logoFormData: null,
      url: backgroundLogoSmall,
      id: "",
      title: "",
      description: "",
    },
    firstTime: true,
  });
  const inputrafLogo = useRef(null);
  const inputrafBackground = useRef(null);
  const inputrafBanner = useRef(null);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const [open, setOpen] = useState(false);

  const handleConfirm = () => {
    onDelete();
  };

  const onDelete = () => {
    if (exampleData.logo.id) {
      dispatch(deleteTeaser(exampleData.logo.id))
        .then(unwrapResult)
        .catch((error) => {
          console.error(error);
        });
    }

    if (exampleData.background.id) {
      dispatch(deleteTeaser(exampleData.background.id))
        .then(unwrapResult)
        .catch((error) => {
          console.error(error);
        });
    }

    if (exampleData.banner.id) {
      dispatch(deleteTeaser(exampleData.banner.id))
        .then(unwrapResult)
        .catch((error) => {
          console.error(error);
        });
    }
    setOpen(false);

    toast.success("You have removed data from Mobile App", {
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleFieldChange = (field) => {
    setExampleData((prevState) => {
      return {
        ...prevState,
        ...field,
        type: prevState.type,
      };
    });
  };

  const postUploadfile = (file, fieldTitle) => {
    const form_data = new FormData();
    form_data.append("file", file?.target.files[0]);
    form_data.append("group", "profile");
    form_data.append("subgroup", "");
    form_data.append("filename", new Date().getTime());
    form_data.append("url", URL.createObjectURL(file?.target.files[0]));

    const dataToUpdate = {
      [fieldTitle]: {
        ...exampleData[fieldTitle],
        logoFormData: form_data,
        url: URL.createObjectURL(file?.target.files[0]),
      },
    };
    handleFieldChange(dataToUpdate);
  };

  const handleTeaserData = async () => {
    ReactGA.event({
      action: "Submited for review",
      category: "Mobile App",
      value: "",
    });
    const imageKeys = ["logo", "background", "banner"];
    let successFlag = false;

    for (let i = 0; i < imageKeys.length; i++) {
      const image = exampleData[imageKeys[i]];
      let logoURL = undefined;
      let uploadRes;
      if (image?.id) {
        logoURL = {
          url: image.url,
          type: image.type,
        };
      }
      if (image?.logoFormData) {
        uploadRes = await ApiPost(
          `${FileBaseUrl}file/upload`,
          image?.logoFormData
        );
        logoURL = {
          url: uploadRes.response.url ?? image.url,
          type: image.type,
        };
      }

      const returnData = {
        title: image?.title,
        sort_order: 1,
        description: image?.description,
        select_status: "inactive",
        offer_type: logoURL?.type && logoURL?.type,
        img_url: logoURL?.url && logoURL.url,
        b_id: businessState.id,
      };
      const id = image.id;
      if (logoURL) {
        dispatch(
          id ? editTeaser({ id, ...returnData }) : createTeaser(returnData)
        )
          .then((res) => {
            if (res?.payload?.isError) {
              throw res;
            } else {
              successFlag = true;
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    }

    if (successFlag) {
      toast.success(
        `${
          exampleData.firstTime
            ? "Congratulations! Your info is sent for review. You can expect a response within 12 hours regarding the appearance of your e-shop on the app."
            : "Info is published on Boomio mobile app."
        }`,
        {
          closeOnClick: true,
          pauseOnHover: true,
        }
      );
    }

    setLoadingEdit(false);
  };

  const updateExampleData = (response) => {
    const updatedExampleData = { ...exampleData }; // Create a copy of exampleData

    const offerTypesToCheck = ["COUPON_BACKGROUND", "common", "top"]; // Offer types to check in order

    const filteredResponse = response.filter(
      (item) => item.status !== "deleted"
    );
    for (let i = filteredResponse.length - 1; i >= 0; i--) {
      const item = filteredResponse[i];
      updatedExampleData.firstTime = false;
      if (offerTypesToCheck.includes(item.offer_type)) {
        if (item.offer_type === "COUPON_BACKGROUND") {
          updatedExampleData.background.url = item.img_url;
          updatedExampleData.background.id = item.id;
        } else if (item.offer_type === "common") {
          updatedExampleData.logo.url = item.img_url;
          updatedExampleData.logo.id = item.id;
          updatedExampleData.logo.title = item.title;
          updatedExampleData.logo.description = item.description;
        } else if (item.offer_type === "top") {
          updatedExampleData.banner.url = item.img_url;
          updatedExampleData.banner.id = item.id;
          updatedExampleData.banner.title = item.title;
          updatedExampleData.banner.description = item.description;
        }
      }
    }
    setExampleData(updatedExampleData);
  };

  const gateTeaserId = async () => {
    try {
      const res = await dispatch(getMyTeaser());
      const unwrappedResult = unwrapResult(res);
      updateExampleData(unwrappedResult.response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    gateTeaserId();
  }, []);

  return (
    <>
      <Modal
        show={open}
        onHide={handleCancel}
        backdrop="static"
        centered
        aria-labelledby="example-custom-modal-styling-title"
        className="initial_add_store_modal"
        style={{ width: "100% !important" }}
      >
        <Modal.Body className="plansModal">
          <img
            onClick={handleCancel}
            src="./assets/img/closeIcon.png"
            className="closeIcon icon-size"
            alt="close"
          />
          <div
            className="initial_add_store_main"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <h1 className="header">Confirmation</h1>
            <p style={{ marginBottom: "40px", textAlign: "center" }}>
              Are you sure you want to Remove everything from App?
            </p>
            <div style={{ flexDirection: "row", width: "100%" }}>
              <Button
                onClick={handleCancel}
                className="button-8559F3 boomio_input boomio_input_main mx-3"
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirm}
                className="button-8559F3 boomio-white mx-3"
              >
                Confirm
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className="campaigns-heade">
        <div>
          <h1 className="page-header mb-0">Boomio Mobile App</h1>
          <p className="sub_title">
            Add details about e-shop and promotions that will appear on Boomio
            mobile app.
          </p>
        </div>
      </div>
      <Row className="mt-3">
        <Col xxl={8} xl={8} lg={12}>
          <div className="product-design-bg">
            <h2>E-shops details</h2>
            <div className="d-sm-flex flex-column justify-content-between">
              <div className="w-51"></div>
              <div className="w-100">
                <div className="mt-4 mb-2">
                  <label htmlFor="" className="input-bold">
                    Shop Details
                  </label>
                </div>
                <div className="boomio_input_main">
                  <input
                    maxLength={16}
                    required={
                      exampleData["logo"].logoFormData && exampleData["logo"].id
                    }
                    className="boomio_input"
                    type="text"
                    name="E-shop_name"
                    placeholder="E-shop name"
                    value={exampleData["logo"].title || ""}
                    onChange={(e) => {
                      handleFieldChange({
                        logo: {
                          ...exampleData.logo,
                          title: e.target.value,
                        },
                      });
                    }}
                    aria-label="default input example"
                  />
                </div>

                <div className="boomio_input_main mt-4">
                  <input
                    maxLength={40}
                    required={
                      exampleData["logo"].logoFormData && exampleData["logo"].id
                    }
                    className="boomio_input"
                    type="text"
                    name="description"
                    placeholder="Description"
                    value={exampleData["logo"].description || ""}
                    onChange={(e) => {
                      handleFieldChange({
                        logo: {
                          ...exampleData.logo,
                          description: e.target.value,
                        },
                      });
                    }}
                    aria-label="default input example"
                  />
                </div>
              </div>

              <div className="mt-4 mb-2">
                <label htmlFor="" className="input-bold">
                  Images
                </label>
              </div>

              <div className="import-images-container gap-1">
                <div className="import-images-size">
                  <div className="boomio_input_main flex-center p-1">
                    <div
                      onClick={(e) => {
                        inputrafLogo?.current?.click();
                      }}
                      className="import-images-tab d-flex cursor-pointer gap-1"
                    >
                      <AddPhotoAlternateOutlinedIcon fontSize="medium" />
                      <label className="cursor-pointer">Add E-shop Logo</label>
                      <img
                        className="import-photo"
                        style={{
                          border: "1px solid grey",
                        }}
                        src={exampleData["logo"]?.url}
                        alt=""
                      />
                      <input
                        type="file"
                        style={{ display: "none" }}
                        ref={inputrafLogo}
                        onChange={(e) => {
                          postUploadfile(e, "logo");
                        }}
                      />
                    </div>
                    <p
                      style={{
                        fontSize: "10px",
                        color: "#b0afbe !important",
                        textAlign: "center",
                        margin: "0px 10px 15px 10px",
                      }}
                    >
                      PNG format, 1:1 aspect ratio, max 1MB, preferred size:
                      1080x1080.
                    </p>
                  </div>
                </div>
                <div className="import-images-size">
                  <div className="boomio_input_main flex-center p-1">
                    <div
                      className="import-images-tab d-flex cursor-pointer gap-1"
                      onClick={(e) => {
                        inputrafBackground?.current?.click();
                      }}
                    >
                      <AddPhotoAlternateOutlinedIcon fontSize="medium" />
                      <label className="cursor-pointer">Add Background </label>
                      <img
                        style={{
                          border: "1px solid grey",
                        }}
                        className="import-photo"
                        src={exampleData["background"]?.url}
                        alt=""
                      />
                      <input
                        type="file"
                        style={{ display: "none" }}
                        ref={inputrafBackground}
                        onChange={(e) => {
                          postUploadfile(e, "background");
                        }}
                      />
                    </div>
                    <p
                      style={{
                        fontSize: "10px",
                        color: "#b0afbe !important",
                        textAlign: "center",
                        margin: "0px 10px 15px 10px",
                      }}
                    >
                      PNG format, 16:9 aspect ratio, max 1MB, preferred size:
                      1920x1080.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4 mb-2">
              <Accordion
                sx={{
                  border: "none !important",
                  boxShadow: "none",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    p: "0px 12px 0px 12px",
                    background:
                      "linear-gradient(90deg, #fee3e9 0%, #ffd7e8 21.87%,#f3dbf0 41.67%,#eadff7 62.5%,#eadff7 81.77%,#eed8ff 100%)",
                    borderRadius: "24px",
                    mb: "12px",
                  }}
                >
                  <label htmlFor="" className="input-bold">
                    Top Offers Banner Ad
                  </label>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <div className="boomio_input_main">
                    <input
                      maxLength={30}
                      className="boomio_input"
                      type="text"
                      name="Headline"
                      placeholder="Headline"
                      value={exampleData["banner"].title || ""}
                      onChange={(e) => {
                        handleFieldChange({
                          banner: {
                            ...exampleData.banner,
                            title: e.target.value,
                          },
                        });
                      }}
                      aria-label="default input example"
                    />
                  </div>

                  <div className="boomio_input_main mt-4">
                    <input
                      maxLength={40}
                      className="boomio_input"
                      type="text"
                      name="Offers_description"
                      placeholder="Offers description"
                      value={exampleData["banner"].description || ""}
                      onChange={(e) => {
                        handleFieldChange({
                          banner: {
                            ...exampleData.banner,
                            description: e.target.value,
                          },
                        });
                      }}
                      aria-label="default input example"
                    />
                  </div>

                  <div className="mt-4 mb-2">
                    <label htmlFor="" className="input-bold">
                      Images
                    </label>
                    <div className="import-images-container mt-4">
                      <div className="import-images-size">
                        <div className="boomio_input_main flex-center p-1">
                          <div
                            className="import-images-tab d-flex cursor-pointer gap-2"
                            style={{
                              alignItems: "center",
                            }}
                            onClick={(e) => {
                              inputrafBanner?.current?.click();
                            }}
                          >
                            <AddPhotoAlternateOutlinedIcon fontSize="medium" />
                            <label className="cursor-pointer">
                              Add E-Shop Banner
                            </label>
                            <img
                              className="import-photo"
                              src={exampleData["banner"]?.url}
                              alt=""
                            />
                            <input
                              type="file"
                              style={{ display: "none" }}
                              ref={inputrafBanner}
                              onChange={(e) => {
                                postUploadfile(e, "banner");
                              }}
                            />
                          </div>
                          <p
                            style={{
                              fontSize: "10px",
                              color: "#b0afbe !important",
                              textAlign: "center",
                              margin: "0px 10px 15px 10px",
                            }}
                          >
                            PNG format, 16:9 aspect ratio, max 1MB, preferred
                            size: 1920x1080.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="mt-5 d-flex flex-wrap gap-2 align-items-center justify-content-center">
              {!loadingEdit ? (
                <Button
                  disabled={
                    (exampleData["logo"]?.url &&
                      (exampleData["logo"]?.title == "" ||
                        exampleData["logo"]?.description == "")) ||
                    (exampleData["banner"]?.url &&
                      (exampleData["banner"]?.title == "" ||
                        exampleData["banner"]?.description == ""))
                  }
                  className="outline_btn_dark me-2"
                  onClick={() => {
                    handleTeaserData();
                    setLoadingEdit(true);
                  }}
                  style={{ minWidth: "200px" }}
                >
                  <span>
                    {exampleData.firstTime ? "Submit for review" : "Edit"}
                  </span>
                </Button>
              ) : (
                <Button
                  style={{ minWidth: "200px" }}
                  className="outline_btn_dark me-2"
                  disabled
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="sr-only">
                    {exampleData.firstTime ? "Submitting..." : "Editing..."}
                  </span>
                </Button>
              )}

              {!exampleData.firstTime && (
                <>
                  {!loadingDelete ? (
                    <Button
                      className="outline_btn_dark me-2"
                      onClick={() => setOpen(true)}
                      style={{ minWidth: "200px" }}
                    >
                      <span>{"Remove From Mobile App"}</span>
                    </Button>
                  ) : (
                    <Button
                      style={{ minWidth: "200px" }}
                      className="outline_btn_dark me-2"
                      disabled
                    >
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="sr-only">{"Removing..."}</span>
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
        </Col>
        <Col xxl={4} xl={4} lg={12}>
          <ExamplePreview exampleData={exampleData} />
        </Col>
      </Row>
    </>
  );
};

export default MobileApp;

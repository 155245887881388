import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router';

const AutomaticCampaign = () => {

    const navigate = useNavigate()
    const [activeRadioBtn, setActiveRadioBtn] = useState()
    const [activeRadioBtn2, setActiveRadioBtn2] = useState()
    const handleRadioBtn = (id) => {
        setActiveRadioBtn(id)
    }

    const handleRadioBtn2 = (id) => {
        setActiveRadioBtn2(id)
    }
    const radioBtn = ["Discount/Reward", "Visit My Shop", "Get a Feedback"]
    const radioBtn2 = ["First Timer Reward", "Create an Account", "Download an App", "Complete the Profile", "Congratulate 7 days before birthday"]

    const goto = () => {
        if (activeRadioBtn2 === "First Timer Reward") {
            navigate('/campaigns/start-campaign/automatic-campaign/first-timer-reward')
        }
        if (activeRadioBtn === "Visit My Shop") {
            navigate('/campaigns/start-campaign/automatic-campaign/visit-my-shop')
        }
        if (activeRadioBtn === "Get a Feedback") {
            navigate("/campaigns/start-campaign/automatic-campaign/get-feedback")
        }
    }

    return (
        <>
            <div className='campaigns-heade'>
                <h1 className='page-header'>Create Automatic Campaign</h1>
            </div>
            <div className='table-bg'>
                <div className="start-promotional-campaign">
                    <h2>Campaign Type</h2>
                    <h3>Select campaign type</h3>
                </div>
                <h4 className='radio-lable'>What kind of campaign you are looking to create?</h4>
                <div className='create-coupons-Select-Type'>

                    {radioBtn.map((item) => (
                        <label className={` ${activeRadioBtn === item && "bg-red"} form-check`} htmlFor={`rediobtn${item}`} id='test1' onClick={() => handleRadioBtn(item)}>
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id={`rediobtn${item}`} />
                            <label className="form-check-label" for={`rediobtn${item}`}>
                                {item}
                            </label>
                        </label>
                    ))}

                </div>
                {activeRadioBtn === "Discount/Reward" &&
                    <>
                        <h4 className='radio-lable'>Customers to send?</h4>
                        <div className='create-coupons-Select-Type'>
                            {radioBtn2.map((item) => (
                                <label className={` ${activeRadioBtn2 === item && "bg-red"} form-check`} htmlFor={`rediobtn2${item}`} id={item} onClick={() => handleRadioBtn2(item)}>
                                    <input className="form-check-input" type="radio" name="flexRadioDefault2" id={`rediobtn2${item}`} />
                                    <label className="form-check-label" for={`rediobtn2${item}`}>
                                        {item}
                                    </label>
                                </label>
                            ))}
                        </div>
                    </>
                }
                <div className='start-promotional-campaign-footer'>
                    <Button onClick={() => navigate('/campaigns/start-campaign')} className='cancel-btn'>Back</Button>
                    <Button onClick={() => goto()} className='submit-btn'>Next</Button>
                </div>
            </div>
        </>
    );
};

export default AutomaticCampaign;

import React from "react";
import BoostProduct from "../../assets/img/boost-product.png";
import LeadPurchase from "../../assets/img/lead-purchase.png";
import BoostSpecificProduct from "../../assets/img/boost-specific-product.png";
import IncreaseBrowsingTime from "../../assets/img/increase-browsing-time.png";
import CampaignCard from "./card/CampaignCard";
import { Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router";

const StartFirstCampaign = () => {
  const navigate = useNavigate();

  return (
    <>
      <Breadcrumbs className="breadcrumb" aria-label="breadcrumb">
        <Link onClick={() => navigate("/campaigns/boost-product")}>
          Campaigns
        </Link>
        <Typography color="">Start Campaigns</Typography>
      </Breadcrumbs>
      <div className="campaigns-heade">
        <div>
          <h1 className="page-header mb-0">Start Campaign</h1>
          <p className="sub_title">
            Achieve your goals by influencing and driving customer actions
            through dynamic, gamified, and incentivized engagements
          </p>
        </div>
      </div>
      <Row>
        <Col xxl={3} xl={4} lg={6} md={6} sm={6}>
          <CampaignCard
            header="Boost a product"
            text={`Draw user's attention to the specific product where they will play a mini-game and will get loyalty-boosting reward`}
            navigateTo={"/campaigns/boost-product"}
            img={BoostProduct}
          />
        </Col>
        <Col xxl={3} xl={4} lg={6} md={6} sm={6}>
          <CampaignCard
            header="Lead to a purchase"
            text={`Encourage users to purchase any product of their interest after engaging in a mini-game and getting a purchase-boosting reward`}
            navigateTo={
              "/campaigns/start-first-campaign/automatic-campaign/lead-to-purchase"
            }
            img={LeadPurchase}
          />
        </Col>
        <Col xxl={3} xl={4} lg={6} md={6} sm={6}>
          <CampaignCard
            header="Boost specific producs discovery"
            text={`Draw user's attention to 3 specific products where they will play an engaging game and will get a reward`}
            navigateTo={
              "/campaigns/start-first-campaign/automatic-campaign/specific-reward"
            }
            img={BoostSpecificProduct}
          />
        </Col>
        <Col xxl={3} xl={4} lg={6} md={6} sm={6}>
          <CampaignCard
            header="Increase browsing time"
            text={`Make users spend time on your e-shop where they will play a game and will get a reward after browsing for a certain time`}
            navigateTo={
              "/campaigns/start-first-campaign/automatic-campaign/increase-browsing-time"
            }
            img={IncreaseBrowsingTime}
          />
        </Col>
      </Row>
    </>
  );
};

export default StartFirstCampaign;

import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import { Button } from "react-bootstrap";
import Grid from "@mui/material/Grid";
import { Modal } from "react-bootstrap";
import Close_button from "../../../assets/img/close_button.png";

const GamePaper = ({
  gameSelect,
  setGameSelect,
  currentTabIndex,
  setGameId,
  gameId,
  filteredGameList,
}) => {
  const [open, setOpen] = useState(false);
  const [hoveredGame, setHoveredGame] = useState(null);
  const handleGameHover = (game) => {
    setHoveredGame(game);
  };

  const handleGameLeave = () => {
    setHoveredGame(null);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div style={{ width: "100%" }}>
      <Modal
        show={open}
        onHide={handleCancel}
        backdrop="static"
        centered
        aria-labelledby="example-custom-modal-styling-title"
        className="initial_add_store_modal"
        style={{
          width: "100% !important",
          zIndex: 9999,
        }}
      >
        <Modal.Body className="plansModal">
          <img
            onClick={handleCancel}
            src={Close_button}
            className="closeIcon icon-size"
            alt="close"
          />
          <div
            className="initial_add_store_main"
            style={{ flexDirection: "column" }}
          >
            <h1
              className="header"
              style={{
                marginBottom: "40px",
                textAlign: "center",
              }}
            >
              {open.label}
            </h1>
            <img src={open.gif} alt="Spin the Wheel GIF" />
            <div style={{ flexDirection: "row", width: "100%" }}>
              <Button
                onClick={handleCancel}
                className="button-8559F3 boomio_input boomio_input_main mx-3"
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Grid
        container
        spacing={1}
        columns={{
          xs: 2,
          sm: 8,
          md: 12,
          lg: 12,
        }}
        direction="row"
        alignItems="center"
        justifyContent="start"
      >
        {filteredGameList?.map((game, index) => (
          <Grid item xs={2} sm={4} md={4} lg={3} key={index}>
            <div key={index}>
              <Paper
                className={
                  game.widget_type === gameSelect.widget_type
                    ? "game-paper game-paper-color"
                    : "game-paper"
                }
                onClick={() => {
                  if (!game.disabled) {
                    setGameId(game.id);
                  }
                }}
                onMouseEnter={() => !game.disabled && handleGameHover(game)}
                onMouseLeave={handleGameLeave}
                style={{
                  cursor: game.disabled && "auto",
                }}
              >
                {" "}
                {!game.disabled ? (
                  <div
                    style={{
                      backgroundImage: `url(${
                        hoveredGame === game ? game.gif : game.params.icon_url
                      })`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      padding: "25px",
                      margin: "1px",
                      borderRadius: "16px",
                      width: "calc(100% - 2px)",
                      height: "180px",
                      justifyContent: "space-between",
                      display: "flex",
                      flexDirection: "column",
                      transition: "background-image 0.3s ease",
                    }}
                  >
                    <div>
                      <p
                        className="game-paper-header"
                        style={{
                          width: game.width ?? "100%",
                          display: game.width ? "Block" : "inline",
                        }}
                      >
                        {game.name}
                      </p>
                      {/* <Link
                      onClick={(event) => {
                        setOpen(game);
                        event.stopPropagation();
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <p className="game-paper-preview">Preview Game</p>
                    </Link> */}
                    </div>
                    <Button
                      className={`${
                        game.widget_type === gameSelect.widget_type
                          ? "outline_btn_dark_campagin"
                          : "outline_btn_dark"
                      }`}
                      style={{ width: "110px" }}
                      type="submit"
                      value={game.widget_type}
                    >
                      <span style={{ diplay: "block", width: "110px" }}>
                        {game.widget_type === gameSelect.widget_type
                          ? "Selected"
                          : "Select"}
                      </span>
                    </Button>
                  </div>
                ) : (
                  <div
                    style={{
                      backgroundImage: `url(${
                        hoveredGame === game ? game.gif : game.img
                      })`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      padding: "25px",
                      margin: "1px",
                      borderRadius: "16px",
                      width: "calc(100% - 2px)",
                      height: "180px",
                      justifyContent: "space-between",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <p className="game-paper-header">{game.name}</p>
                    <div className="coming-soon-box">
                      <p className="coming-soon">COMING SOON</p>
                    </div>
                  </div>
                )}
              </Paper>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default GamePaper;

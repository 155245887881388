import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { ApiGet } from "helper/API/ApiData";
import { InfoBaseUrl } from "helper/API/BaseUrl";

const PrivacyPolicy = () => {
  const [privacyData, setPrivacyData] = useState([]);

  useEffect(() => {
    ApiGet(`${InfoBaseUrl}api/static_info/privacyPortal`)
      .then(res => {
        if (res && res.response && res.response.json_body && res.response.json_body.data) {
          setPrivacyData(res.response.json_body.data)
        }
      });
    return () => setPrivacyData([])
  }, []);

  return (
    <>
      <Row>
        <Col lg={2}>
          <div className="some-title-here">
            <h1>Privacy Policy</h1>
            <div className="grediant-title">
              <span>Contents</span>
            </div>
            {
              privacyData.map((item, index) => {
                return <p key={index}>{index + 1}. {item?.chapter}</p>
              })
            }
          </div>
        </Col>
        <Col lg={10}>
          <div className="privacy-policy">
            {privacyData.map((item, i) => (
              <div className="some-title" key={i}>
                <h1 dangerouslySetInnerHTML={{ __html: item?.chapter }}></h1>
                <p dangerouslySetInnerHTML={{ __html: item?.chapter_data }}></p>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default PrivacyPolicy;

import "./pages/campaigns/widgets/Widget.css";
import Pages from "./pages/index";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AuthStorage from "helper/AuthStorage";
import STORAGEKEY from "config/APP/app.config";
import { useLocation } from "react-router";
import { appSettings } from "helper/appSettings";
import { useSearchParams } from "react-router-dom";
import "./App.css";
import "./Responsive.css";
import { getDashboardUserDataAPI } from "redux/features/authentication/authSlice";
import {
  getShopify,
  paymentSlice,
} from "redux/features/paymentmethods/PaymentSlice";
import { ReactGAinit } from "../src/pages/events";

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isAuth, setIsAuth] = useState(false);
  const { i18n } = useTranslation();
  const [searchBarParams] = useSearchParams();

  useEffect(() => {
    ReactGAinit();
    // getUserDashboardData();
    checkExternalTokenURL();
    checkAuthRedirect();

    const lang = AuthStorage.getStorageJsonData(STORAGEKEY.lang);
    if (lang) {
      i18n.changeLanguage(lang);
    } else {
      if (location.search === "?lng=hi") {
        AuthStorage.setStorageJsonData(STORAGEKEY.lang, "hi");
        i18n.changeLanguage("hi");
      } else if (location.search === "?lng=lt") {
        AuthStorage.setStorageJsonData(STORAGEKEY.lang, "lt");
        i18n.changeLanguage("lt");
      } else if (location.search === "?lng=nl") {
        AuthStorage.setStorageJsonData(STORAGEKEY.lang, "nl");
        i18n.changeLanguage("nl");
      } else {
        AuthStorage.setStorageJsonData(STORAGEKEY.lang, "en-us");
        i18n.changeLanguage("en-us");
      }
    }
  }, []);

  const getUserDashboardData = async () => {
    await dispatch(getDashboardUserDataAPI())
      .then((result) => {})
      .catch((error) => {
        console.error("error", error);
      });
    await dispatch(getShopify())
      .then((res) => {})
      .catch((error) => {
        console.error("error", error);
      });
  };

  const checkExternalTokenURL = async () => {
    const urlToken = searchBarParams.get(STORAGEKEY.token);
    if (urlToken) {
      AuthStorage.setToken("Bearer " + urlToken);
      setIsAuth(true);
    }
  };

  const checkAuthRedirect = () => {
    let authCookie = AuthStorage.getToken();
    if (!authCookie) {
      if (location.pathname !== "/getCouponCode") {
        window.location = appSettings.AUTH_APP_URL;
      } else {
        setIsAuth(true);
      }
    } else {
      setIsAuth(true);
    }
  };

  return (
    <div className="App">
      <ToastContainer position="top-right" autoClose={5000} />
      {isAuth ? <Pages /> : null}
    </div>
  );
}

export default App;

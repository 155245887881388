import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const ImportCustomer = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='create-coupons-modals'
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Import Customer
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className='upload-image'>
                    <img src="./assets/img/upload-image.png" alt="" />
                    <h1>Upload Image</h1>
                    <h2>or drop your image (PNG, JPEG,) here</h2>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <a className='templat-img'></a>
                <div className=''>
                    <Button onClick={props.onHide} className='cancel-btn'>Cancel</Button>
                    <Button onClick={() => { }} className='submit-btn'>Submit</Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default ImportCustomer;

import React from "react";
import { Button, Modal } from "react-bootstrap";
import Close_button from "../../../assets/img/close_button.png";

const HowItWorks = ({ showHowItWorks, setShowHowItWorks }) => {
  return (
    <Modal
      show={showHowItWorks}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-how-works"
      onHide={() => setShowHowItWorks(false)}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
          padding: "20px 20px 0px 20px",
        }}
      >
        <img
          style={{ cursor: "pointer" }}
          onClick={() => setShowHowItWorks(false)}
          src={Close_button}
          alt="close"
        />
      </div>

      <div
        style={{
          width: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          padding: "0px 40px",
        }}
        className="campaign-header"
      >
        HOW IT WORKS?
      </div>
      <Modal.Body
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          padding: "24px 40px 48px 40px",
        }}
      >
        <label className="modal-how-works-text">
          Fill campaign’s details and launch the campaign that will feature a
          game alongside your selected products on your e-shop. Users play the
          game and earn rewards, drawing their attention to the highlighted
          products.
        </label>
      </Modal.Body>
    </Modal>
  );
};

export default HowItWorks;

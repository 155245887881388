import React from 'react'

const Layout = ({ children, ...props }) => {
    return (
        <div >
            <div {...props}>{children}</div>
        </div>
    )
}

export default Layout

import { Button } from 'react-bootstrap';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';

const StartPromotionalCampaign = () => {

    const navigate = useNavigate()
    const [activeRadioBtn, setActiveRadioBtn] = useState()
    const handleRadioBtn = (id) => {
        setActiveRadioBtn(id)
    }
    const radioBtn = ["Product Discount", "Special Holidays Deal", "Sale"]
    return (
        <>
            <div className='campaigns-heade'>
                <h1 className='page-header'>Create Promotional Campaign</h1>
            </div>
            <div className='table-bg'>
                <div className="start-promotional-campaign">
                    <h2>Promotional Type</h2>
                    <h3>Select promotional type</h3>
                    <h4>What kind of annoucement you are looking to create?</h4>
                </div>

                <div className='create-coupons-Select-Type'>

                    {radioBtn.map((item) => (
                        <label className={` ${activeRadioBtn === item && "bg-red"} form-check`} htmlFor={`rediobtn${item}`} id='test1' onClick={() => handleRadioBtn(item)}>
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id={`rediobtn${item}`} />
                            <label className="form-check-label" for={`rediobtn${item}`}>
                                {item}
                            </label>
                        </label>
                    ))}

                </div>
                <div className='start-promotional-campaign-footer'>
                    <Button onClick={() => navigate('/campaigns/start-campaign')} className='cancel-btn'>Back</Button>
                    <Button onClick={() => navigate('/campaigns/start-campaign/start-promotional-campaign/product-design')} className='submit-btn'>Next</Button>
                </div>
            </div>
        </>
    );
};

export default StartPromotionalCampaign;

import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import PhoneSampleBG from "../../assets/img/PhoneSampleSecondBG.png";
import SwipeableViews from "react-swipeable-views";
import { virtualize } from "react-swipeable-views-utils";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { IconButton } from "@mui/material";

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

const ExamplePreview = (data) => {
  const [selectedScreen, setSelectedScreen] = useState(0);

  const handleRadioScreenChange = (event) => {
    setSelectedScreen(parseInt(event.target.value));
  };

  const handleScreenChange = (index) => {
    setSelectedScreen(index);
  };

  function cutText(text, maxLength) {
    if (text.length <= maxLength) {
      return text; // Return the original text if it's already within the maximum length
    } else {
      return text.substring(0, maxLength) + "..."; // Cut the text and add ellipsis at the end
    }
  }

  const slideRenderer = ({ index }) => {
    return (
      <div
        key={index}
        style={{
          ...styles.slide,
          overflow: "hidden",
        }}
      >
        {index === 0 ? (
          <div className="mobile-app-phone-img">
            {data.exampleData.banner.url && (
              <div
                style={{
                  width: "214px",
                  height: "105px",
                  position: "absolute",
                  top: "78px",
                  left: "53px",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  backgroundColor: "white",
                  backgroundImage: `url(${data.exampleData.banner.url})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  zIndex: 3,
                }}
              ></div>
            )}
            {data.exampleData["banner"].title && (
              <div
                style={{
                  backgroundColor: "white",
                  width: "210px",
                  height: "14px",
                  position: "absolute",
                  top: "186px",
                  left: "54px",
                  zIndex: 3,
                }}
              >
                <p
                  style={{
                    margin: "0px 12px",
                    fontSize: "10px",
                    fontWeight: "bold",
                    color: "#75717c",
                  }}
                  className="text-cut"
                >
                  {cutText(data.exampleData["banner"].title, 20)}
                </p>
              </div>
            )}
            {data.exampleData["banner"].description && (
              <div
                style={{
                  backgroundColor: "white",
                  width: "210px",
                  height: "17px",
                  position: "absolute",
                  top: "200px",
                  left: "54px",
                  borderBottomLeftRadius: "10px",
                  borderBottomRightRadius: "10px",
                  zIndex: 3,
                }}
              >
                <p
                  style={{
                    margin: "0px 12px",
                    fontSize: "9px",
                    color: "#76737b",
                  }}
                  className="text-cut"
                >
                  {cutText(data.exampleData["banner"].description, 32)}
                </p>
              </div>
            )}
            {data.exampleData["logo"]?.url && (
              <div
                style={{
                  backgroundColor: "white",
                  width: "38px",
                  height: "38px",
                  position: "absolute",
                  top: "300px",
                  left: "54px",
                  borderRadius: 100,
                  zIndex: 3,
                }}
              >
                <img
                  src={data.exampleData["logo"]?.url}
                  alt=""
                  width="38px"
                  height="38px"
                  style={{ borderRadius: "100px" }}
                />
              </div>
            )}
            {data.exampleData["logo"].title && (
              <div
                style={{
                  backgroundColor: "white",
                  width: "120px",
                  height: "16px",
                  position: "absolute",
                  top: "305px",
                  left: "100px",
                  color: "#756e7c",
                  zIndex: 3,
                }}
              >
                <p
                  style={{
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                  className="text-cut"
                >
                  {cutText(data.exampleData["logo"].title, 13)}
                </p>
              </div>
            )}
            {data.exampleData["logo"].description && (
              <div
                style={{
                  backgroundColor: "white",
                  width: "120px",
                  height: "20px",
                  position: "absolute",
                  top: "318px",
                  left: "100px",
                  zIndex: 3,
                }}
              >
                <p
                  style={{
                    fontSize: "7px",
                    color: "#a6a6a6",
                    fontWeight: "bold",
                    color: "#d5d4d8",
                    overflowWrap: "break-word",
                    wordWrap: "break-word",
                    hyphens: "auto",
                  }}
                  className="text-cut"
                >
                  {cutText(data.exampleData["logo"].description, 35)}
                </p>
              </div>
            )}
          </div>
        ) : (
          <div
            className="mobile-app-phone-img-second"
            style={Object.assign(styles.slide)}
          >
            {data.exampleData["logo"]?.url && (
              <div
                style={{
                  backgroundColor: "white",
                  width: "38px",
                  height: "38px",
                  position: "absolute",
                  top: "85px",
                  left: "50px",
                  borderRadius: 100,
                  zIndex: 3,
                }}
              >
                <img
                  src={data.exampleData["logo"]?.url}
                  alt=""
                  width="38px"
                  height="38px"
                  style={{ borderRadius: "100px" }}
                />
              </div>
            )}
            {data.exampleData["logo"].title && (
              <div
                style={{
                  backgroundColor: "#f9f9fa",
                  width: "110px",
                  height: "16px",
                  position: "absolute",
                  top: "92px",
                  left: "90px",
                  zIndex: 3,
                }}
              >
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: "black",
                  }}
                  className="text-cut"
                >
                  {cutText(data.exampleData["logo"].title, 13)}
                </p>
              </div>
            )}
            {data.exampleData["logo"].description && (
              <div
                style={{
                  backgroundColor: "#f9f9fa",
                  width: "190px",
                  height: "13px",
                  position: "absolute",
                  top: "125px",
                  left: "54px",
                  zIndex: 3,
                }}
              >
                <p
                  style={{
                    fontSize: "9px",
                    fontWeight: "bold",
                    color: "black",
                  }}
                  className="text-cut"
                >
                  {cutText(data.exampleData["logo"].description, 23)}
                </p>
              </div>
            )}
            {data.exampleData["logo"].title && (
              <div
                style={{
                  backgroundColor: "#f9f9fa",
                  width: "80px",
                  height: "16px",
                  position: "absolute",
                  top: "271px",
                  left: "167px",
                  zIndex: 3,
                }}
              >
                <p
                  style={{
                    fontSize: "8px",
                    fontWeight: "bold",
                    color: "black",
                  }}
                  className="text-cut"
                >
                  {cutText(data.exampleData["logo"].title, 13)}
                </p>
              </div>
            )}
            {data.exampleData["logo"]?.url && (
              <div
                style={{
                  backgroundColor: "white",
                  width: "25px",
                  height: "25px",
                  position: "absolute",
                  top: "244px",
                  left: "190px",
                  borderRadius: 100,
                  zIndex: 3,
                }}
              >
                <img
                  src={data.exampleData["logo"]?.url}
                  alt=""
                  width="25px"
                  height="25px"
                  style={{ borderRadius: "100px" }}
                />
              </div>
            )}
            {data.exampleData["background"]?.url && (
              <>
                <div
                  style={{
                    width: "234px",
                    height: "130px",
                    position: "absolute",
                    top: "20px",
                    left: "34px",
                    borderTopLeftRadius: "25px",
                    borderTopRightRadius: "25px",
                    backgroundColor: "white",

                    backgroundImage: `url(${data.exampleData["background"]?.url})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    zIndex: 1,
                    overflow: "hidden",
                  }}
                ></div>
              </>
            )}
            <div
              style={{
                width: "388px",
                height: "120px",
                position: "absolute",
                top: "72px",
                left: "-43px",
                clipPath: "inset(8.5% 23% 15% 23% round 10px)",

                backgroundImage: `url(${PhoneSampleBG})`,
                backgroundPosition: "0% 17%",
                backgroundSize: "cover",
                zIndex: 2,
                backgroundClip: "content-box",
              }}
            ></div>
          </div>
        )}
      </div>
    );
  };

  const styles = {
    root: {},
    slide: {
      padding: "24px 16px",
      color: "#fff",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
  };
  return (
    <div className="mobile-app-bg">
      <div className="mobile-app-header">
        <label htmlFor="" className="input-bold">
          Preview Campaign
        </label>
      </div>
      <div className="mobile-app-phone-img-bg">
        <div className="mobile-swipe-buttons">
          <IconButton
            onClick={() => handleScreenChange(selectedScreen - 1)}
            disabled={selectedScreen === 0}
            sx={{
              padding: "0px",
              "& .MuiSvgIcon-root": {
                fontSize: 30,
              },
            }}
          >
            <ChevronLeftIcon />
          </IconButton>{" "}
        </div>
        <VirtualizeSwipeableViews
          style={styles.root}
          index={selectedScreen}
          onChangeIndex={handleScreenChange}
          slideRenderer={slideRenderer}
          enableMouseEvents
          slideCount={2}
        />
        <div className="mobile-swipe-buttons">
          <IconButton
            onClick={() => handleScreenChange(selectedScreen + 1)}
            disabled={selectedScreen === 1}
            sx={{
              padding: "0px",
              "& .MuiSvgIcon-root": {
                fontSize: 30,
              },
            }}
          >
            <ChevronRightIcon />
          </IconButton>{" "}
        </div>
      </div>
      <div className="mobile-app-phone">
        <div style={{ textAlign: "center" }} className="mobile-radio-buttons">
          <Radio
            checked={selectedScreen === 0}
            onChange={handleRadioScreenChange}
            value={0}
            inputProps={{ "aria-label": "0" }}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 18,
              },
              "&, &.Mui-checked": {
                color: "#e1c0fa",
              },
            }}
          />
          <Radio
            checked={selectedScreen === 1}
            onChange={handleRadioScreenChange}
            value={1}
            inputProps={{ "aria-label": "1" }}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 18,
              },
              "&, &.Mui-checked": {
                color: "#e1c0fa",
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ExamplePreview;

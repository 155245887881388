import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { useNavigate } from 'react-router';
import select_arrow from '../../assets/img/boomio_select_icon.png'
import { useDispatch } from 'react-redux';
import { createTeaser, editTeaser, getTeaserById } from 'redux/features/teser/teaserSlice';
import queryString from 'query-string';
import { ApiPost } from 'helper/API/ApiData';
import { unwrapResult } from '@reduxjs/toolkit';
import { FileBaseUrl } from 'helper/API/BaseUrl';

const AddTeaser = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const inputraf = useRef(null)
    const query = queryString.parse(window.location.search);
    const id = query?.id
    const [image, setImage] = useState({ logoFormData: null, url: "" });
    const [status, setStatus] = useState('active');
    const [type, setType] = useState('');
    const [imgName, setImgName] = useState('');
    const [teaserData, setTeaserData] = useState();

    const productRewardSchema = yup.object({
        title: yup.string()
            .required('Title is required.'),
        sort_order: yup.string()
            .required('Sort order is required.'),
        offer_type: yup.string()
            .required('Offer type is required.'),
        select_status: yup.string()
            .required('Status is required.'),
        description: yup.string()
            .required('Description is required.'),
    });

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(productRewardSchema),
    });

    const handleTeaserData = async (payload) => {
        let logoURL = undefined;
        let uploadRes;
        if (image.logoFormData) {
            uploadRes = await ApiPost(
                `${FileBaseUrl}file/upload`,
                image?.logoFormData,
            );
            const { response: {
                url
            } } = uploadRes;
            logoURL = url
            setImage(url)
        }
        const returnData = {
            title: payload?.title && payload?.title,
            sort_order: payload?.sort_order && payload?.sort_order,
            offer_type: payload?.offer_type && payload?.offer_type,
            select_status: payload?.select_status && payload?.select_status,
            description: payload?.description && payload?.description,
            img_url: image.url ?? logoURL
        }
        dispatch(id ? editTeaser({ id, ...returnData }) : createTeaser(returnData)).then((res) => {
            if (res?.payload?.isError) {
                throw res
            }
            navigate('/teaser')
        }).catch((err) => {
            console.log('err', err)
        })
    }

    const postUploadfile = (file) => {
        setImgName(file.target.files[0].name)
        const form_data = new FormData()
        form_data.append('file', file?.target.files[0])
        form_data.append('group', 'teaser')
        form_data.append('subgroup', '')
        form_data.append('filename', new Date().getTime())
        form_data.append('url', URL.createObjectURL(file?.target.files[0]))
        setImage({ logoFormData: form_data, url: URL.createObjectURL(file?.target.files[0]) });
    }

    const gateTeaserData = () => {
        dispatch(getTeaserById(id)).then(unwrapResult).then((res) => {
            setTeaserData(res.response)
            setType(res.response.offer_type)
            setStatus(res.response.status)
            setImage({ ...image, url: res.response.img_url })
        })
    }

    useEffect(() => {
        var defaultResetValues = {}
        defaultResetValues = { ...defaultResetValues, title: teaserData?.params?.title }
        defaultResetValues = { ...defaultResetValues, sort_order: teaserData?.params?.sort_order }
        defaultResetValues = { ...defaultResetValues, status: teaserData?.params?.status }
        defaultResetValues = { ...defaultResetValues, offer_type: teaserData?.offer_type }
        defaultResetValues = { ...defaultResetValues, description: teaserData?.description }
        reset(defaultResetValues)
    }, [teaserData])

    useEffect(() => {
        if (id) {
            gateTeaserData()
        }
    }, [id])

    return (
        <>
            <div className="campaigns-heade">
                <div>
                    <h1 className="page-header">{`${id ? 'Edit' : 'Add'}`} treaser</h1>
                </div>
            </div>
            <Row className="mt-3">
                <Col xxl={12} xl={7} lg={12}>
                    <div className="product-design-bg">
                        <h2>Treaser details</h2>
                        <h3>Enter treaser details</h3>
                        <div className="d-sm-flex flex-column justify-content-between">

                            <div className="w-100 d-flex flex-wrap justify-content-between">
                                <div className="w-45">
                                    <div className="mt-4 mb-2">
                                        <label htmlFor="" className="input-bold">
                                            Title
                                        </label>
                                    </div>
                                    <div className="boomio_input_main">
                                        <input
                                            className="boomio_input"
                                            type="text"
                                            {...register('title')}
                                            placeholder="Title"
                                            onChange={(e) => {
                                                setValue('title', e.target.value)
                                            }}
                                            defaultValue={teaserData?.title}
                                            aria-label="default input example"
                                        />
                                    </div>
                                    {errors.title && <span className="campaings_error">{errors?.title?.message}</span>}
                                </div>
                                <div className="w-45">
                                    <div className="mt-4 mb-2">
                                        <label htmlFor="" className="input-bold">
                                            Sort order
                                        </label>
                                    </div>
                                    <div className="boomio_input_main">
                                        <input
                                            className="boomio_input"
                                            type="number"
                                            placeholder="Sort Order"
                                            aria-label="default input example"
                                            defaultValue={teaserData?.sort_order}
                                            {...register('sort_order')}
                                        />
                                    </div>
                                    {
                                        errors?.sort_order && <span className="campaings_error">{errors?.sort_order?.message}</span>
                                    }
                                </div>
                                <div className="w-45">
                                    <div className="mt-4 mb-2">
                                        <label htmlFor="" className="input-bold">
                                            Offer type
                                        </label>
                                    </div>
                                    <div className="boomio_input_main w-100">
                                        <img src={select_arrow} alt="" className="select_img" />
                                        <select
                                            className="boomio_input boomio_select"
                                            aria-label="Default select example"
                                            {...register('offer_type')}
                                            onChange={(e) => {
                                                setType(e.target.value)
                                                if (e.target.value)
                                                    setValue('offer_type', e.target.value)
                                            }}
                                            value={type}
                                        >
                                            <option value={'top'}>Top</option>
                                            <option value={'Common'}>Common</option>
                                        </select>
                                    </div>
                                    {errors.offer_type && <span className="campaings_error">{errors?.offer_type?.message}</span>}
                                </div>
                                <div className="w-45">
                                    <div className="mt-4 mb-2">
                                        <label htmlFor="" className="input-bold">
                                            Select status
                                        </label>
                                    </div>
                                    <div className="boomio_input_main w-100">
                                        <img src={select_arrow} alt="" className="select_img" />
                                        <select
                                            className="boomio_input boomio_select"
                                            aria-label="Default select example"
                                            {...register('select_status')}
                                            onChange={(e) => {
                                                setStatus(e.target.value)
                                                if (e.target.value)
                                                    setValue('select_status', e.target.value)
                                            }}
                                            value={status}
                                        >
                                            <option value={'active'}>Active</option>
                                            <option value={'inactive'}>InActive</option>
                                        </select>
                                    </div>
                                    {errors.select_status && <span className="campaings_error">{errors?.select_status?.message}</span>}
                                </div>
                                <div className="w-45">
                                    <div className="mt-4 mb-2">
                                        <label htmlFor="" className="input-bold">
                                            Description
                                        </label>
                                    </div>
                                    <div className="boomio_input_main">
                                        <input
                                            className="boomio_input"
                                            type="text"
                                            {...register('description')}
                                            placeholder="Description"
                                            onChange={(e) => {
                                                setValue('description', e.target.value)
                                            }}
                                            defaultValue={teaserData?.description}
                                            aria-label="default input example"
                                        />
                                    </div>
                                    {errors.description && <span className="campaings_error">{errors?.description?.message}</span>}
                                </div>
                                <div className="w-45">
                                    <div className="mt-4 mb-2">
                                        <label htmlFor="" className="input-bold">
                                            Select image
                                        </label>
                                    </div>
                                    <div className="set-profile d-flex boomio_input_main ">
                                        <input type='hidden' {...register('img_url')} />
                                        <span className='select_file_lable'>{`${imgName ? imgName : 'Select file'}`}</span>
                                        <input
                                            className="boomio_input"
                                            type="file"
                                            style={{ display: '' }}
                                            ref={inputraf}
                                            onChange={(e) => {
                                                postUploadfile(e)
                                            }}
                                            defaultValue={image.logoFormData}
                                        />
                                    </div>
                                    <img
                                        style={{ marginTop: '10px', display: `${image?.url || image.logoFormData ? 'block' : 'none'}` }}
                                        src={image?.url ? image?.url : image.logoFormData}
                                        alt=""
                                        width={100}
                                        height={100}
                                    />
                                </div>
                            </div>
                            <div className="mt-5 d-flex flex-wrap gap-2 align-items-center">
                                <Button
                                    className="outline_btn_dark me-2"
                                    onClick={handleSubmit((data) => {
                                        handleTeaserData(data)
                                    })}
                                >
                                    <span>
                                        {`${id ? 'Edit' : 'Add'}`}
                                    </span>
                                </Button>
                                <Button
                                    className="outline_btn_dark me-2"
                                    onClick={() => navigate('/teaser')}
                                >
                                    <span>
                                        Cancel
                                    </span>
                                </Button>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default AddTeaser
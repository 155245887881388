import React from "react";
import DOT from "../../../assets/img/dot.png";
import QRCode from "react-qr-code";
import { useState } from "react";
import Close_button from "../../../assets/img/close_button.png";

const DiscountModal = (props) => {
  const { couponData } = props;
  const [showQrView, setShowQrView] = useState(false);
  const [showDiscountModal, setshowDiscountModal] = useState(true);

  const viewQrCode = () => {
    setShowQrView(true);
    setshowDiscountModal(false);
  };

  const discountModalHide = () => {
    setShowQrView(false);
    setshowDiscountModal(true);
  };

  return (
    <div className="p-0 m-3 Preview-select box-show ">
      <div className="coupon__preview__body coupon_discount_modal">
        <div className="close_button">
          <img src={Close_button} alt="" />
        </div>
        <div className="coupon__preview__card__header text-center d-block">
          <h1 style={{ fontSize: "18px" }}>
            {couponData?.params?.topTitle
              ? couponData?.params?.topTitle
              : "You Got a 0% Discount"}{" "}
          </h1>
        </div>
        {showDiscountModal && (
          <div
            className="coupon_preview_card_info"
            onClick={(e) => {
              e.preventDefault();
              viewQrCode();
            }}
          >
            <div
              className={props.class ? props.class : "coupon__preview__card"}
            >
              <div className="coupon_info">
                <h3>
                  {couponData?.params?.couponCodeDiscount
                    ? couponData?.params?.couponCodeDiscount
                    : " 0%"}
                </h3>
                <h3>
                  {couponData?.params?.couponCodediscountText
                    ? couponData?.params?.couponCodediscountText
                    : " Discount"}
                </h3>
                <p>
                  Unique code:{" "}
                  {couponData?.params?.couponCode
                    ? couponData?.params?.couponCode
                    : " XXXXXXX"}
                </p>
              </div>
              {!props.class && (
                <>
                  <div className="coupon__preview__card__after"></div>
                  <div className="coupon__preview__card__befor"></div>
                </>
              )}
            </div>
          </div>
        )}
        {showQrView && (
          <div
            className="coupon_preview_card_info"
            onClick={(e) => {
              e.preventDefault();
              discountModalHide();
            }}
          >
            <QRCode
              size="100"
              value={
                couponData?.params?.couponCode
                  ? couponData?.params?.couponCode
                  : " XXXXXXX"
              }
            />
          </div>
        )}
        <div className="coupon_preview_card_footer">
          <p>
            To have immediate access for all your great rewards open{" "}
            <b> Boomio app</b>
          </p>
          <div className="btn-content d-flex align-items-center justify-content-center">
            <img src={DOT} alt="img not find" />
            <div className="d-flex flex-column btn-text-group ml-2">
              <small className="small-font">Open</small> <b>Boomio app</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DiscountModal;

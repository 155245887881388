import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";

const ButtonCard = ({
  img,
  title,
  bodytext,
  bodytext2,
  buttontext,
  cardmainclass,
  onBtnClick,
  link,
}) => {
  const navigate = useNavigate();

  return (
    <div className={`buttoncard__main ${cardmainclass}`}>
      <div>
        <div className="buttoncard__header">
          <img src={`./assets/img/${img}`} alt="" className="icon-size" />
          <h1>{title}</h1>
        </div>
        <div className="buttoncard__body">
          <p>{bodytext}</p>
          {cardmainclass === "connenct_store" && <p>{bodytext2}</p>}
        </div>
      </div>
      <div
        className="buttoncard__group"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div className="buttoncard__footer" style={{ minWidth: "155px" }}>
          <Button
            disabled={cardmainclass === "verify-email"}
            onClick={() => onBtnClick()}
            className=""
          >
            {buttontext}
          </Button>
        </div>
        {link && cardmainclass === "verify-email" && (
          <h4 className="add_store_bottom_text mb-0">
            Don’t forget to enable Boomio app in Shopify. <br />{" "}
            <u
              style={{ cursor: "pointer" }}
              className="add_store_bottom_text_link"
              onClick={() =>
                navigate("/settings", "_blank", { state: { id: "QA" } })
              }
            >
              A step by step guide is here
            </u>
          </h4>
        )}
      </div>
    </div>
  );
};

export default ButtonCard;

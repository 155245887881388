import React, { useState, useEffect } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Calendar, createStaticRanges } from "react-date-range";
import Modal from "@mui/material/Modal";
import { Button } from "react-bootstrap";

const DateRangeSelect = ({ selectedDay, open, setOpen, setSelectedDay }) => {
  const toggle = () => {
    setOpen(!open);
  };
  console.log(selectedDay);

  return (
    <Modal open={open} onClose={toggle}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          left: "50%",
          top: "50%",
          flexDirection: "column",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          padding: "32px",
          borderRadius: "16px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Calendar
          date={selectedDay}
          onChange={(item) => setSelectedDay(item)}
        />
        {open && (
          <div
            style={{
              gap: "12px",
              paddingTop: "2px",
              width: "100%",
              display: "flex",
              justifyContent: "start",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "330px",
                justifyContent: "center",
                margin: "0px 12px",
                gap: "20px",
              }}
            >
              {/* <Button
                className="outline_btn_dark me-2"
                onClick={() => setOpen(false)}
                style={{
                  marginTop: "24px",
                  width: "170px",
                  fontweight: "600 !important",
                  height: "42px",
                }}
              >
                <span style={{ fontWeight: "600 !important" }}>Cancel</span>
              </Button> */}
              <Button
                className="dateTimeButton"
                onClick={() => setOpen(false)}
                style={{
                  marginTop: "24px",
                  width: "170px",
                  fontweight: "600 !important",
                  height: "42px",
                }}
              >
                <span>Apply</span>
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default DateRangeSelect;

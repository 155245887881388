import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiGet } from "helper/API/ApiData";
import { BusinessBaseUrl } from "helper/API/BaseUrl";
import { saveAs } from "file-saver";

export const getCustomers = createAsyncThunk(
  "campaigns/getCampaigns",
  async (data, thunkAPI) => {
    const page = data?.[0];
    const limit = data?.[1];
    const order = data?.[2];
    const period = data?.[3];
    const startDate = data?.[4].startDate?.split("/").join("-");
    const endDate = data?.[4].endDate?.split("/").join("-");
    const search = data?.[5];

    return await ApiGet(
      `${BusinessBaseUrl}business/get/customers/?page=${page}&limit=${limit}&sort=${
        order.orderBy
      }+${order.orderDirection}&show_mode=${period ? "period" : "scoreboard"}${
        search && search != "" ? "&search=" + search : ""
      }${period ? "&date_start=" + startDate + "&date_end=" + endDate : ""}`
    )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        err = { isError: true };
        return err;
      });
  }
);

export const getDownloadCustomers = createAsyncThunk(
  "campaigns/getCampaigns",
  async (data, thunkAPI) => {
    const period = data?.[0];
    const startDate = data?.[1].startDate?.split("/").join("-");
    const endDate = data?.[1].endDate?.split("/").join("-");
    const search = data?.[2];
    const decrypt = data?.[3];

    const apiUrl = `${BusinessBaseUrl}business/get/customers/download/?show_mode=${
      period ? "period" : "scoreboard"
    }${search ? "&search=" + search : ""}${
      period ? "&date_start=" + startDate + "&date_end=" + endDate : ""
    }${decrypt ? `&decrypt=${decrypt}` : ""}`;

    try {
      const res = await ApiGet(apiUrl);
      if (res) {
        console.log(res);

        const csvData = res; // Assuming res.response contains the CSV data
        const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
        saveAs(blob, "customer_data.csv");
      }
    } catch (error) {
      console.error("Error downloading data:", error);
      throw error; // Rethrow the error to be caught by the caller
    }
  }
);

export const customersSlice = createSlice({
  name: "campaignSlice",
  initialState: { entities: [], loading: false },
  reducers: {},
  extraReducers: {
    [getCustomers.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.value += action.payload;
    },
    [getCustomers.reject]: (state, action) => {
      state.isLoading = false;
    },
    [getCustomers.pending]: (state, action) => {
      state.isLoading = true;
    },
  },
});

export default customersSlice.reducer;

import { configureStore } from '@reduxjs/toolkit';
import campaignReducer from './features/campaigns/campaignSlice';
import initialReducer from './features/intialSlice'
import couponsAndCardsReducer from './features/couponsAndCards/couponsAndCardsSlice'
import customersSlice from './features/customers/customersSlice';
import dashboardSlice from './features/dashboard/dashboardSlice';
import businessSlice from './features/business/businessSlice';
import teamSlice from './features/settings/teamSlice';
import authSlice from './features/authentication/authSlice';
import teaserSlice from './features/teser/teaserSlice';
import paymentSlice from './features/paymentmethods/PaymentSlice';

export default configureStore({
    reducer: {
        initialState: initialReducer,
        campaignState: campaignReducer,
        couponsAndCardsSlice: couponsAndCardsReducer,
        customersState: customersSlice,
        dshboardState: dashboardSlice,
        businessState: businessSlice,
        teamState: teamSlice,
        authState: authSlice,
        teaserState: teaserSlice,
        paymentSlice: paymentSlice,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
});
import React, { useState, useEffect, useRef } from "react";

const Slider = ({ setValue1, setSelectedDay, selectedDay }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const itemHeight = 34; // Adjust the height of each number item
  const dayList = [
    { name: "Custom", value: 0 },
    { name: "1 day", value: 1 },
    { name: "2 days", value: 2 },
    { name: "3 days", value: 3 },
    { name: "4 days", value: 4 },
    { name: "5 days", value: 5 },
    { name: "6 days", value: 6 },
    { name: "7 days", value: 7 },
  ];

  const itemCount = dayList.length;

  const containerRef = useRef(null);

  useEffect(() => {
    const containerElement = containerRef.current;
    if (containerElement) {
      const containerHeight = containerElement.clientHeight;
      const visibleItems = Math.floor(containerHeight / itemHeight);
      const middleIndex = Math.floor(visibleItems / 2);
      const selectedIndex = dayList.findIndex(
        (day) => day.value === selectedDay
      );
      const scrollToIndex = selectedIndex - middleIndex;

      scrollToPosition(containerElement, scrollToIndex * itemHeight, 300); // Smooth scroll to the position

      setScrollPosition(middleIndex); // Set the scrollPosition to the middle index
    }
  }, [selectedDay]);

  useEffect(() => {
    const containerElement = containerRef.current;
    if (containerElement) {
      containerElement.scrollTop = scrollPosition * itemHeight;
    }
  }, [scrollPosition]);

  const handleScroll = (e) => {
    const scrollTop = e.target.scrollTop;
    let newPosition = Math.floor(scrollTop / itemHeight);

    if (newPosition < 0) {
      newPosition = 0;
    } else if (newPosition >= itemCount) {
      newPosition = itemCount - 1;
    }

    setScrollPosition(newPosition);
  };

  const handleNumberClick = (number) => {
    setSelectedDay(number);
    // Perform any other action with the selected number here
  };

  const generateNumberItems = () => {
    const items = [];
    for (let i = 0; i < itemCount; i++) {
      const selectedIndex = dayList.findIndex(
        (day) => day.value === selectedDay
      );
      const distance = Math.abs(i - selectedIndex);

      items.push(
        <div
          key={i}
          className={`number ${
            selectedDay === dayList[i].value ? "selected" : ""
          }`}
          style={{ top: `${i * itemHeight}px` }}
          onClick={() => {
            setValue1("rewardExpirationDate", dayList[i].value);
            handleNumberClick(dayList[i].value);
          }}
        >
          <div
            className={selectedDay === dayList[i].value ? "selected-box" : ""}
          ></div>
          <div
            className={`number ${
              selectedDay === dayList[i].value ? "selected" : ""
            }`}
            style={{
              opacity: distance <= 2 ? 1 - 0.2 * distance : 0.4,
            }}
          >
            {dayList[i].name}
          </div>
        </div>
      );
    }
    return items;
  };

  const scrollToPosition = (element, to, duration) => {
    const start = element.scrollTop;
    const change = to - start;
    const increment = 20;
    let currentTime = 0;

    const animateScroll = function () {
      currentTime += increment;
      const val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;

      if (currentTime < duration) {
        requestAnimationFrame(animateScroll);
      }
    };

    animateScroll();
  };

  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  return (
    <div className="selector_border">
      <div className="selector" onScroll={handleScroll} ref={containerRef}>
        <div
          className="scroller"
          style={{ height: `${itemHeight * itemCount}px` }}
        >
          {generateNumberItems()}
        </div>
      </div>
    </div>
  );
};

export default Slider;

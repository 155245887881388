import React, { useEffect, useState, useMemo } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import moment from "moment/moment";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createCampaigns,
  editCampaigns,
  checkProductUrl,
  getCampaignsById,
} from "redux/features/campaigns/campaignSlice";
import queryString from "query-string";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import ReactGA from "react-ga4";
import Accordion from "./Accordion";
import HowItWorks from "./HowItWorks";
import { Modal } from "react-bootstrap";
import Close_button_Colored from "../../../assets/img/Close_button_Colored.png";
import UserJourney from "../userJourney/UserJourney";
import {
  getBusinessDetails,
  getAvailableGames,
} from "redux/features/business/businessSlice";
import GamePaper from "./GamePaper";
import PersonalizedGamePaper from "./PersonalizedGamePaper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import arrowLeft from "../../../assets/img/arrowLeft.png";
import arrowRightGray from "../../../assets/img/arrowRightGray.png";
import arrowRight from "../../../assets/img/arrowRight.png";
import RectangleBoomio from "../../../assets/img/RectangleBoomio.png";
import RectangleBoomioGray from "../../../assets/img/RectangleBoomioGray.png";
import copyIcon from "../../../assets/img/copy-icon.png";
import stepsCompleted from "../../../assets/img/Steps-Item-Icon.png";

const SpecificProductReward = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const handleCancel = () => {
    setOpen(false);
  };
  const [rewardType, setRewardType] = useState("FREE_SHIPPING");
  const [discountAmount, setDiscountAmount] = useState({
    value: 0,
    label: "Custom",
  });
  const [days, setDays] = useState("");
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [activatedBoomio, setActivatedBoomio] = useState(false);
  const [campaignName, setCampaignName] = useState("");
  const [showHowItWorks, setShowHowItWorks] = useState(false);
  const data = useSelector(
    (state) => state.authState.dashboardData?.shopify_params?.url
  );

  const shopName = useSelector(
    (state) => state.authState.dashboardData?.shopify_params?.type
  );
  const [emailCollectionRequired, setEmailCollectionRequired] = useState(true);
  const [shopConnected, setShopConnected] = useState(shopName);
  const [firstShopConnected, setFirstShopConnected] = useState(true);
  const [gameLoop, setGameLoop] = React.useState("time");
  const query = queryString.parse(window.location.search);
  const id = query?.id;
  const [errorMessage, setErrorMessage] = React.useState("");

  const [step, setStep] = React.useState(id ? 2 : 1);
  const [availableGames, setAvailableGames] = React.useState({});

  const [rulesData, setRulesData] = useState({
    rulesText: "Skaityti pilnas taisykles",
    rulesLink: "",
    gameRules_1: "SPAUSK kad skristum",
    gameRules_2: "KARTOK dėl geresnio rezultato",
    gameRules_3: "LAIMĖK 500 prizų kas savaitę!",
  });

  const [personalizationData, setPersonalizationData] = useState({
    intro_picture_url:
      "https://d16wkmeil0du4q.cloudfront.net/personalization/3b49e741-984b-403b-8fe1-1e0f7fb474c1.jpeg",
    game_background_url:
      "https://d16wkmeil0du4q.cloudfront.net/personalization/3b49e741-984b-403b-8fe1-1e0f7fb474c1.jpeg",
    scorebox_background_url:
      "https://d16wkmeil0du4q.cloudfront.net/personalization/3b49e741-984b-403b-8fe1-1e0f7fb474c1.jpeg",
  });

  const [privacyData, setPrivacyData] = useState({
    privacyPolicy: "Sutinku su įmonės privatumo politika.",
    websiteUrl: "",
    newsletter: "Sutinku gauti naujienlaiškius.",
  });

  const [scoreboardData, setScoreboardData] = useState({
    breakingPointText: 0,
    aboveBreakingPointText: "VALIO, tau puikiai sekasi!",
    aboveBreakingPointPrizes: "xx xd. net xx geriausių žaidėjų laimės prizus!",
    aboveBreakingPointEmail: "Apie laimėjimą sužinosi savo nurodytu el. paštu.",
    aboveBreakingPointDiscount: "O pirkdamas xxxxxx su nuolaidos kodu",
    discountCode: "",
    discountColor: "#b0afbe",
    discountText: "gauk papildomą nuolaidą!",
    belowBreakingPointText: "Tu gali!",
    belowBreakingPointPrizes:
      "Pagerink rezultatą nes mėnesio gale net xx žaidėjų laimės prizus",
    belowBreakingPointEmail: "Apie laimėjimą sužinosi savo nurodytu el. paštu.",
    belowBreakingPointDiscount: "O pirkdamas xxxxxx su nuolaidos kodu",
    belowDiscountCode: "",
    belowDiscountText: "gauk papildomą nuolaidą!",
  });

  const [campaignAccessibility, setCampaignAccessibility] = useState([
    "nickname",
    "email",
  ]);

  const [gameSelect, setGameSelect] = React.useState({});

  const [dateRange, setDateRange] = useState({
    startDate: moment(new Date()).format("YYYY/MM/DD"),
    endDate: undefined,
  });
  const [gameId, setGameId] = React.useState(7);

  const [selectUrl, setSelectUrl] = React.useState("");
  const [urlAvailable, setUrlAvailable] = React.useState(false);
  const [campaignData, setCampaignData] = useState();
  const [isActive, setIsActive] = useState(false);
  const [expanded, setExpanded] = React.useState("competition");
  const [currentTabIndex, setCurrentTabIndex] = useState({
    value: 0,
    label: "all",
  });
  const [campaignCreated, setCampaignCreated] = React.useState(false);
  const [outcome, setOutcome] = React.useState("points");
  const [campaignAcivated, setCampaignAcivated] = React.useState(false);

  const handleTabChange = (e, tabIndex) => {
    switch (tabIndex) {
      case 0:
        setCurrentTabIndex({ value: 0, label: "all" });
        break;
      case 1:
        setCurrentTabIndex({ value: 1, label: "reward" });
        break;
      case 2:
        setCurrentTabIndex({ value: 2, label: "points" });
        break;
      case 3:
        setCurrentTabIndex({ value: 3, label: "competition" });
        break;
      case 4:
        setCurrentTabIndex({ value: 4, label: "level" });
        break;
      default:
        break;
    }
  };

  const [selectedGameValue, setSlectedGameValue] =
    React.useState("competition");

  const handleSelectedGame = (event) => {
    setSlectedGameValue(event.target.value);
  };

  useEffect(() => {
    setShopConnected(shopName || "");
  }, [shopName]);

  const [couponData, setCouponData] = useState({
    params: {
      topTitle: "YOU GOT A FREE SHIPPING!",
      couponCodeDiscount: `Free`,
      couponCodediscountText: "SHIPPING",
    },
  });

  const navigate = useNavigate();
  const [urlFields, setUrlFields] = useState([
    { id: 1, value: "" }, // Initial URL field
  ]);

  const productRewardSchema = yup.object({
    Specific_product_URL: yup
      .array()
      .of(
        yup
          .string()
          .required("Product URL is required.")
          .matches(
            /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
            "Enter valid product URL"
          )
      )
      .required("Product URL is required."),
  });

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    setValue: setValue1,
    reset: reset1,
    formState: { errors },
    clearErrors: clearErrors1,
  } = useForm({
    resolver: yupResolver(productRewardSchema),
  });

  const getCampaignsData = async () => {
    await dispatch(getCampaignsById(id))
      .then(unwrapResult)
      .then((res) => {
        setIsActive(res?.response?.status === "inactive" ? false : true);

        setDateRange({
          startDate: res?.response?.campaign_start
            ? moment(res?.response?.campaign_start).format("YYYY/MM/DD")
            : moment(new Date()).format("YYYY/MM/DD"),
          endDate: res?.response?.campaign_end
            ? moment(res?.response?.campaign_end).format("YYYY/MM/DD")
            : undefined,
        });
        setSlectedGameValue(res?.response?.params?.game_type);
        setCampaignName(res?.response?.campaign_name);
        console.log(res?.response?.params?.productUrl);
        setSelectUrl((prevData) =>
          res?.response?.params?.productUrl !== undefined
            ? res.response.params.productUrl[0]
            : prevData
        );

        setGameId((prevData) =>
          res?.response?.params?.game_id !== undefined
            ? res.response.params.game_id
            : prevData
        );

        setRulesData((prevData) => ({
          rulesText:
            res?.response?.params?.rules?.full_text !== undefined
              ? res.response.params.rules.full_text
              : prevData.rulesText,
          rulesLink:
            res?.response?.params?.rules?.url !== undefined
              ? res.response.params.rules.url
              : prevData.rulesLink,
          gameRules_1:
            res?.response?.params?.rules?.short_list !== undefined
              ? res.response.params.rules.short_list[0]
              : prevData.gameRules_1,
          gameRules_2:
            res?.response?.params?.rules?.short_list !== undefined
              ? res.response.params.rules.short_list[1]
              : prevData.gameRules_2,
          gameRules_3:
            res?.response?.params?.rules?.short_list !== undefined
              ? res.response.params.rules.short_list[2]
              : prevData.gameRules_3,
        }));

        setScoreboardData((prevData) => ({
          breakingPointText:
            res?.response?.params?.game_outcome?.points_threshold !== undefined
              ? res.response.params.game_outcome.points_threshold
              : prevData.breakingPointText,
          aboveBreakingPointText:
            res?.response?.params?.game_outcome?.above_lines_list?.[0] !==
            undefined
              ? res.response.params.game_outcome.above_lines_list[0]
              : prevData.aboveBreakingPointText,
          aboveBreakingPointPrizes:
            res?.response?.params?.game_outcome?.above_lines_list?.[1] !==
            undefined
              ? res.response.params.game_outcome.above_lines_list[1]
              : prevData.aboveBreakingPointPrizes,
          aboveBreakingPointEmail:
            res?.response?.params?.game_outcome?.above_lines_list?.[2] !==
            undefined
              ? res.response.params.game_outcome.above_lines_list[2]
              : prevData.aboveBreakingPointEmail,
          aboveBreakingPointDiscount:
            res?.response?.params?.game_outcome?.above_lines_list?.[3] !==
            undefined
              ? res.response.params.game_outcome.above_lines_list[3]
              : prevData.aboveBreakingPointDiscount,
          discountCode:
            res?.response?.params?.game_outcome?.above_lines_list?.[4] !==
            undefined
              ? res.response.params.game_outcome.above_lines_list[4]
              : prevData.discountCode,
          discountColor:
            res?.response?.params?.game_outcome?.discountColor !== undefined
              ? res.response.params.game_outcome.discountColor
              : prevData.discountColor,
          discountText:
            res?.response?.params?.game_outcome?.above_lines_list?.[5] !==
            undefined
              ? res.response.params.game_outcome.above_lines_list[5]
              : prevData.discountText,
          belowBreakingPointText:
            res?.response?.params?.game_outcome?.below_lines_list?.[0] !==
            undefined
              ? res.response.params.game_outcome.below_lines_list[0]
              : prevData.belowBreakingPointText,
          belowBreakingPointPrizes:
            res?.response?.params?.game_outcome?.below_lines_list?.[1] !==
            undefined
              ? res.response.params.game_outcome.below_lines_list[1]
              : prevData.belowBreakingPointPrizes,
          belowBreakingPointEmail:
            res?.response?.params?.game_outcome?.below_lines_list?.[2] !==
            undefined
              ? res.response.params.game_outcome.below_lines_list[2]
              : prevData.belowBreakingPointEmail,
          belowBreakingPointDiscount:
            res?.response?.params?.game_outcome?.below_lines_list?.[3] !==
            undefined
              ? res.response.params.game_outcome.below_lines_list[3]
              : prevData.belowBreakingPointDiscount,
          belowDiscountCode:
            res?.response?.params?.game_outcome?.below_lines_list?.[4] !==
            undefined
              ? res.response.params.game_outcome.below_lines_list[4]
              : prevData.belowDiscountCode,
          belowDiscountText:
            res?.response?.params?.game_outcome?.below_lines_list?.[5] !==
            undefined
              ? res.response.params.game_outcome.below_lines_list[5]
              : prevData.belowDiscountText,
        }));

        setPrivacyData((prevState) => ({
          privacyPolicy: res?.response?.params?.terms_conditions
            ?.customers_confirm_text
            ? res?.response?.params?.terms_conditions?.customers_confirm_text
            : prevState.privacyPolicy,
          websiteUrl: res?.response?.params?.terms_conditions
            ?.privacy_policy_url
            ? res?.response?.params?.terms_conditions?.privacy_policy_url
            : prevState.websiteUrl,
          newsletter: res?.response?.params?.terms_conditions
            ?.privacy_policy_text
            ? res?.response?.params?.terms_conditions?.privacy_policy_text
            : prevState.newsletter,
        }));

        setPersonalizationData((prevState) => ({
          intro_picture_url: res?.response?.params?.customize_game
            ?.intro_picture_url
            ? res?.response?.params?.customize_game?.intro_picture_url
            : prevState.intro_picture_url,
          game_background_url: res?.response?.params?.customize_game
            ?.game_background_url
            ? res?.response?.params?.customize_game?.game_background_url
            : prevState.game_background_url,
          scorebox_background_url: res?.response?.params?.customize_game
            ?.scorebox_background_url
            ? res?.response?.params?.customize_game?.scorebox_background_url
            : prevState.scorebox_background_url,
        }));
      });
  };

  useEffect(() => {
    if (id) {
      getCampaignsData();
    }
  }, [id]);

  useEffect(() => {
    var defaultResetValues = {};
    defaultResetValues = {
      ...defaultResetValues,
      selectRewardType1: campaignData?.params?.selectRewardType1,
    };
    defaultResetValues = {
      ...defaultResetValues,
      rewardValue: campaignData?.params?.rewardValue,
    };
    defaultResetValues = {
      ...defaultResetValues,
      rewardValue: campaignData?.params?.rewardValue,
    };
    defaultResetValues = {
      ...defaultResetValues,
      campaignStartDate: campaignData?.campaign_start,
    };
    defaultResetValues = {
      ...defaultResetValues,
      campaignEndDate: campaignData?.campaign_end,
    };
    defaultResetValues = {
      ...defaultResetValues,
      Specific_product_URL: campaignData?.params?.productUrl,
    };
    defaultResetValues = {
      ...defaultResetValues,
      numberOfDays: campaignData?.params?.numberOfDays,
    };
    defaultResetValues = {
      ...defaultResetValues,
      email_collection_required: campaignData?.params?.emailCollectionRequired,
    };

    defaultResetValues = {
      ...defaultResetValues,
      rewardExpirationDate:
        campaignData?.params?.rewardExpirationDate ?? selectedDay,
    };
    defaultResetValues = {
      ...defaultResetValues,
      rewardExpirationDay: campaignData?.params?.rewardExpirationDay ?? days,
    };
    defaultResetValues = {
      ...defaultResetValues,
      campaignPeriod: campaignData?.params?.campaignPeriod,
    };
    defaultResetValues = {
      ...defaultResetValues,
      campaignLaunchDate: campaignData?.params?.campaignLaunchDate,
    };

    reset1(defaultResetValues);
  }, [campaignData]);

  useEffect(() => {
    urlFields.forEach((value, index) => {
      if (urlFields[index].value && urlFields[index].value !== "") {
        register1(`Specific_product_URL[${index}]`);
        setValue1(`Specific_product_URL[${index}]`, urlFields[index].value);
      }
    });
  }, [urlFields, register1, rewardType, discountAmount]);

  const handleStepChange = (step) => {
    startCampaigns();
    async function startCampaigns() {
      try {
        setErrorMessage("");
        const action = id
          ? editCampaigns({
              id,
              ...{
                status: "draft",
                params: {
                  game_id: gameId,
                  game_type: selectedGameValue,
                  ...(privacyData.websiteUrl
                    ? {
                        terms_conditions: {
                          privacy_policy_text: privacyData.newsletter,
                          privacy_policy_url: privacyData.websiteUrl,
                          customers_confirm_text: privacyData.privacyPolicy,
                        },
                      }
                    : {}),
                  user_fields: ["email", "nickname"],
                  ...(rulesData.rulesLink
                    ? {
                        rules: {
                          full_text: rulesData.rulesText,
                          url: rulesData.rulesLink,
                          short_list: [
                            rulesData.gameRules_1 ?? "",
                            rulesData.gameRules_2 ?? "",
                            rulesData.gameRules_3 ?? "",
                          ],
                        },
                      }
                    : {}),
                  customize_game: {
                    intro_picture_url: personalizationData.intro_picture_url,
                    game_background_url:
                      personalizationData.game_background_url,
                    scorebox_background_url:
                      personalizationData.scorebox_background_url,
                  },
                  game_outcome: {
                    points_threshold: scoreboardData.breakingPointText,
                    above_lines_list: [
                      scoreboardData.aboveBreakingPointText,
                      scoreboardData.aboveBreakingPointPrizes,
                      scoreboardData.aboveBreakingPointEmail,
                      scoreboardData.aboveBreakingPointDiscount,
                      scoreboardData.discountCode,
                      scoreboardData.discountText,
                    ],
                    above_colour: scoreboardData.discountColor,
                    below_lines_list: [
                      scoreboardData.belowBreakingPointText,
                      scoreboardData.belowBreakingPointPrizes,
                      scoreboardData.belowBreakingPointEmail,
                      scoreboardData.belowBreakingPointDiscount,
                      scoreboardData.belowDiscountCode,
                      scoreboardData.belowDiscountText,
                    ],
                    below_colour: scoreboardData.discountColor,
                  },
                  ...(dateRange.endDate && { timezone: "Europe/Kiev" }),
                  productUrl: [selectUrl],
                },
                ...(dateRange.endDate && {
                  campaign_start: dateRange.startDate,
                }),

                ...(dateRange.endDate && { campaign_end: dateRange.endDate }),

                campaign_type: "boost_product",
                campaign_name: campaignName,
              },
            })
          : createCampaigns({
              status: "draft",
              params: {
                game_id: gameId,
                game_type: "competition",
              },
              campaign_type: "boost_product",
              ...(campaignName && { campaign_name: campaignName }),
            });
        const response = await dispatch(action);
        if (response.payload?.isError) {
          throw response.payload;
        }
        ReactGA.event({
          action: "Launched campaign",
          category: "General, on selfservice",
          value: "",
        });
        if (response?.payload?.response?.id && !id) {
          window.location.href = `/campaigns/boost-product?id=${response.payload.response.id}`;
          setCampaignCreated(true);
        } else {
          setStep(step);
        }
      } catch (error) {
        setErrorMessage(error.isError);
        console.log("Error:", error);
      }
    }
  };

  const finishCampaign = () => {
    startCampaigns();
    async function startCampaigns() {
      const action = editCampaigns({
        id,
        ...{
          status: "active",
        },
      });
      const response = await dispatch(action);
      setCampaignAcivated(true);
      if (response.payload?.isError) {
        throw response.payload;
      }
    }
  };

  useEffect(() => {
    dispatch(getAvailableGames()).then((result) => {
      setAvailableGames(result.payload);
    });
  }, []);

  const filteredGameList = useMemo(() => {
    if (!availableGames) return [];
    if (currentTabIndex.label === "all") {
      return availableGames.game_mechanics;
    } else {
      return availableGames.game_mechanics.filter((game) =>
        game.params.types.includes(currentTabIndex.label)
      );
    }
  }, [availableGames, currentTabIndex.label]);

  useEffect(() => {
    console.log("filteredGameList", filteredGameList);
    console.log("gameId", gameId);
    if (filteredGameList) {
      const filteredGame = filteredGameList.find((game) => game.id === gameId);
      setGameSelect(filteredGame);
    }
  }, [filteredGameList, gameId]);

  console.log("setGameSelect", gameSelect);

  return (
    <>
      <Modal
        show={open}
        onHide={handleCancel}
        backdrop="static"
        centered
        aria-labelledby="example-custom-modal-styling-title"
        className="initial_add_store_modal"
        style={{
          width: "100% !important",
          zIndex: 9999,
        }}
      >
        <Modal.Body>
          <img
            onClick={handleCancel}
            src={Close_button_Colored}
            className="closeIcon "
            alt="close"
          />
          <div
            className="initial_add_store_main"
            style={{ flexDirection: "column" }}
          >
            <UserJourney
              gameSelect={gameSelect.widget_type}
              from="BoostProduct"
              title1="On your e-shop user gets notified about the mini game with a hidden reward."
              title2="The game appears on your e-shop. User plays a game by clicking on it."
              title3="After the game's completion a pop-up with a reward appears."
              couponData={couponData}
            />
          </div>
        </Modal.Body>
      </Modal>
      <HowItWorks
        showHowItWorks={showHowItWorks}
        setShowHowItWorks={setShowHowItWorks}
      />
      <div
        className="product-design-bg"
        style={{
          minHeight: "1000px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!campaignAcivated ? (
          <>
            <div style={{ marginBottom: "8px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h1
                  className="campaign-header"
                  style={{ marginBottom: "20px" }}
                >
                  NEW CAMPAIGN
                </h1>
              </div>
              {step > 1 && (
                <>
                  <div
                    style={{
                      border: "1px solid #D6D6D6",
                      padding: "16px 24px 16px 24px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      borderRadius: "16px",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p style={{ marginBottom: "0px", fontSize: "14px" }}>
                        Campaing Name
                      </p>
                      <p
                        style={{
                          marginBottom: "0px",
                          fontSize: "16px",
                          fontWeight: 700,
                        }}
                      >
                        {campaignName}
                      </p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p style={{ marginBottom: "0px", fontSize: "14px" }}>
                        Selected game
                      </p>
                      <p
                        style={{
                          marginBottom: "0px",
                          fontSize: "16px",
                          fontWeight: 700,
                        }}
                      >
                        {gameSelect.name}
                      </p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p style={{ marginBottom: "0px", fontSize: "14px" }}>
                        Game type
                      </p>
                      <p
                        style={{
                          marginBottom: "0px",
                          fontSize: "16px",
                          fontWeight: 700,
                        }}
                      >
                        {selectedGameValue}
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      marginTop: "52px",
                      marginBottom: "20px",
                      flexWrap: "wrap",
                    }}
                  >
                    <div
                      style={{
                        color: step === 2 ? "black" : "#473F4E73",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => setStep(2)}
                    >
                      <div style={{ position: "relative" }}>
                        <img
                          src={
                            step === 2
                              ? RectangleBoomio
                              : step > 2
                              ? stepsCompleted
                              : RectangleBoomioGray
                          }
                          style={{ marginRight: "8px" }}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: 5,
                            left: 12,
                            color: step === 2 ? "white" : "#473F4E73",
                          }}
                        >
                          {step < 3 && 1}
                        </div>
                      </div>
                      Registration
                    </div>
                    <img
                      src={arrowRightGray}
                      style={{
                        width: "12px",
                        height: "20px",
                        marginTop: "6px",
                        marginLeft: "4px",
                        marginRight: "4px",
                      }}
                    />
                    <div
                      style={{
                        color: step === 3 ? "black" : "#473F4E73",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => setStep(3)}
                    >
                      <div style={{ position: "relative" }}>
                        <img
                          src={
                            step === 3
                              ? RectangleBoomio
                              : step > 3
                              ? stepsCompleted
                              : RectangleBoomioGray
                          }
                          style={{ marginRight: "8px" }}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: 5,
                            left: 12,
                            color: step === 3 ? "white" : "#473F4E73",
                          }}
                        >
                          {step < 4 && 2}
                        </div>
                      </div>
                      Rules
                    </div>
                    <img
                      src={arrowRightGray}
                      style={{
                        width: "12px",
                        height: "20px",
                        marginTop: "6px",
                        marginLeft: "4px",
                        marginRight: "4px",
                      }}
                    />
                    <div
                      style={{
                        color: step === 4 ? "black" : "#473F4E73",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => setStep(4)}
                    >
                      <div style={{ position: "relative" }}>
                        <img
                          src={
                            step === 4
                              ? RectangleBoomio
                              : step > 4
                              ? stepsCompleted
                              : RectangleBoomioGray
                          }
                          style={{ marginRight: "8px" }}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: 5,
                            left: 12,
                            color: step === 4 ? "white" : "#473F4E73",
                          }}
                        >
                          {step < 5 && 3}
                        </div>
                      </div>
                      Personalization
                    </div>
                    <img
                      src={arrowRightGray}
                      style={{
                        width: "12px",
                        height: "20px",
                        marginTop: "6px",
                        marginLeft: "4px",
                        marginRight: "4px",
                      }}
                    />
                    <div
                      style={{
                        color: step === 5 ? "black" : "#473F4E73",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => setStep(5)}
                    >
                      <div style={{ position: "relative" }}>
                        <img
                          src={
                            step === 5
                              ? RectangleBoomio
                              : step > 5
                              ? stepsCompleted
                              : RectangleBoomioGray
                          }
                          style={{ marginRight: "8px" }}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: 5,
                            left: 12,
                            color: step === 5 ? "white" : "#473F4E73",
                          }}
                        >
                          {step < 6 && 4}
                        </div>
                      </div>
                      Outcome
                    </div>{" "}
                    <img
                      src={arrowRightGray}
                      style={{
                        width: "12px",
                        height: "20px",
                        marginTop: "6px",
                        marginLeft: "4px",
                        marginRight: "4px",
                      }}
                    />
                    <div
                      style={{
                        color: step === 6 ? "black" : "#473F4E73",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => setStep(6)}
                    >
                      <div style={{ position: "relative" }}>
                        <img
                          src={
                            step === 6
                              ? RectangleBoomio
                              : step > 6
                              ? stepsCompleted
                              : RectangleBoomioGray
                          }
                          style={{ marginRight: "8px" }}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: 5,
                            left: 12,
                            color: step === 6 ? "white" : "#473F4E73",
                          }}
                        >
                          {step < 7 && 5}
                        </div>
                      </div>
                      Duration
                    </div>
                    <img
                      src={arrowRightGray}
                      style={{
                        width: "12px",
                        height: "20px",
                        marginTop: "6px",
                        marginLeft: "4px",
                        marginRight: "4px",
                      }}
                    />
                    <div
                      style={{
                        color: step === 7 ? "black" : "#473F4E73",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => setStep(7)}
                    >
                      <div style={{ position: "relative" }}>
                        <img
                          src={
                            step === 7
                              ? RectangleBoomio
                              : step > 7
                              ? stepsCompleted
                              : RectangleBoomioGray
                          }
                          style={{ marginRight: "8px" }}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: 5,
                            left: 12,
                            color: step === 7 ? "white" : "#473F4E73",
                          }}
                        >
                          {step < 8 && 6}
                        </div>
                      </div>
                      Set Up
                    </div>
                    <img
                      src={arrowRightGray}
                      style={{
                        width: "12px",
                        height: "20px",
                        marginTop: "6px",
                        marginLeft: "4px",
                        marginRight: "4px",
                      }}
                    />
                    <div
                      style={{
                        color: step === 8 ? "black" : "#473F4E73",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => setStep(8)}
                    >
                      <div style={{ position: "relative" }}>
                        <img
                          src={
                            step === 8
                              ? RectangleBoomio
                              : step > 8
                              ? stepsCompleted
                              : RectangleBoomioGray
                          }
                          style={{ marginRight: "8px" }}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: 5,
                            left: 12,
                            color: step === 8 ? "white" : "#473F4E73",
                          }}
                        >
                          {step < 9 && 7}
                        </div>
                      </div>
                      Review
                    </div>
                  </div>
                </>
              )}
            </div>
            {step === 1 ? (
              <>
                <div style={{ marginTop: "40px" }}>
                  <h6
                    style={{
                      alignItems: "center",
                      fontWeight: "600",
                      display: "inline",
                      fontSize: "18px",
                    }}
                  >
                    What's your campaign name?
                  </h6>
                </div>
                <div
                  className="boomio_input_main"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "30px",
                  }}
                >
                  <input
                    className={` ${
                      campaignName ? "boomio_input" : "boomio_input_unfilled"
                    }`}
                    type="text"
                    name={`My campaign name....`}
                    placeholder="My campaign name...."
                    onChange={(e) => setCampaignName(e.target.value)}
                    value={campaignName}
                    aria-label="default input example"
                    style={{ fontStyle: "italic" }}
                    maxLength="64"
                  />
                  <div
                    style={{
                      position: "absolute",
                      right: "20px",
                      top: "10px",
                      color: "#7A7A7A",
                    }}
                  >
                    {campaignName.length} / 64
                  </div>
                </div>
                <div style={{ marginTop: "32px" }}>
                  <h6
                    style={{
                      alignItems: "center",
                      fontWeight: "600",
                      display: "inline",
                      fontSize: "18px",
                    }}
                  >
                    Select game mechanic
                  </h6>
                  {/* <div className="boomio_radio_spacing">
            <RadioButton
              width={"100%"}
              checked={emailCollectionRequired}
              text={"Collect more leads"}
              id={"option2"}
              value={true}
              textTwo={"Collect visitor's emails for your business."}
              onChange={() => {
                setEmailCollectionRequired(true);
              }}
            />
            <RadioButton
              width={"100%"}
              margin="0px"
              checked={!emailCollectionRequired}
              text={"Boost my products"}
              id={"option2"}
              value={false}
              textTwo={"Get more awareness for your boosted products."}
              onChange={() => {
                setEmailCollectionRequired(false);
              }}
            />
          </div> */}
                </div>
                <Tabs
                  value={currentTabIndex.value}
                  onChange={handleTabChange}
                  variant="fullWidth"
                  style={{
                    marginTop: "30px",
                    fontSize: "22px",
                    marginBottom: "20px",
                    borderBottom: "1px solid #ececec",
                  }}
                >
                  <Tab label="All games" sx={{ textTransform: "none" }} />
                  <Tab label="Rewards based" sx={{ textTransform: "none" }} />
                  <Tab
                    label="Points based game"
                    sx={{ textTransform: "none" }}
                  />
                  <Tab
                    label="Competition games"
                    sx={{ textTransform: "none" }}
                  />
                  <Tab label="Time based game" sx={{ textTransform: "none" }} />
                  <Tab
                    label="Level based game"
                    sx={{ textTransform: "none" }}
                  />
                </Tabs>

                <GamePaper
                  gameSelect={gameSelect}
                  setGameSelect={setGameSelect}
                  currentTabIndex={currentTabIndex}
                  setCurrentTabIndex={setCurrentTabIndex}
                  setGameId={setGameId}
                  gameId={gameId}
                  filteredGameList={filteredGameList}
                />
                <h6
                  style={{
                    alignItems: "center",
                    fontWeight: "600",
                    display: "inline",
                    fontSize: "18px",
                    marginTop: "30px",
                  }}
                >
                  Your personalized games
                </h6>
                <PersonalizedGamePaper
                  gameSelect={gameSelect}
                  setGameSelect={setGameSelect}
                  personalizedGames={availableGames?.personalized_games}
                  currentTabIndex={currentTabIndex}
                  setCurrentTabIndex={setCurrentTabIndex}
                />
                <div
                  style={{
                    marginTop: "40px",
                  }}
                >
                  <h6
                    style={{
                      alignItems: "center",
                      fontWeight: "600",
                      display: "inline",
                      fontSize: "18px",
                    }}
                  >
                    Select game type
                  </h6>
                </div>
                <FormControl style={{ marginLeft: "40px", marginTop: "12px" }}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      disabled
                      value="points"
                      control={
                        <Radio
                          style={{
                            color:
                              selectedGameValue === "points"
                                ? "#EB5894"
                                : "black",
                          }}
                        />
                      }
                      label={
                        <div style={{ marginTop: "20px" }}>
                          <div
                            style={{
                              fontWeight: "600",
                              fontSize: "16px",
                              color:
                                selectedGameValue === "points"
                                  ? "#EB5894"
                                  : "black",
                            }}
                          >
                            Points based
                          </div>
                          <div style={{ color: "#585666", fontSize: "14px" }}>
                            Players achieve the highest points score and convert
                            it to rewards.
                          </div>
                        </div>
                      }
                      onChange={handleSelectedGame}
                      checked={selectedGameValue === "points"}
                    />
                    <FormControlLabel
                      value="competition"
                      control={
                        <Radio
                          style={{
                            color:
                              selectedGameValue === "competition"
                                ? "#EB5894"
                                : "black",
                          }}
                        />
                      }
                      label={
                        <div style={{ marginTop: "20px" }}>
                          <div
                            style={{
                              fontWeight: "600",
                              fontSize: "16px",
                              color:
                                selectedGameValue === "competition"
                                  ? "#EB5894"
                                  : "black",
                            }}
                          >
                            Competition based
                          </div>
                          <div style={{ color: "#585666", fontSize: "14px" }}>
                            Players compete against other players with high
                            scores to get a reward.{" "}
                          </div>
                        </div>
                      }
                      onChange={handleSelectedGame}
                      checked={selectedGameValue === "competition"}
                    />
                    <FormControlLabel
                      value="rewards"
                      disabled
                      control={
                        <Radio
                          style={{
                            color:
                              selectedGameValue === "rewards"
                                ? "#EB5894"
                                : "black",
                          }}
                        />
                      }
                      label={
                        <div style={{ marginTop: "20px" }}>
                          <div
                            style={{
                              fontWeight: "600",
                              fontSize: "16px",
                              color:
                                selectedGameValue === "rewards"
                                  ? "#EB5894"
                                  : "black",
                            }}
                          >
                            Rewards based
                          </div>
                          <div style={{ color: "#585666", fontSize: "14px" }}>
                            A one-time playable game element designed to reward
                            visitors{" "}
                          </div>
                        </div>
                      }
                      onChange={handleSelectedGame}
                      checked={selectedGameValue === "rewards"}
                    />
                  </RadioGroup>
                </FormControl>
              </>
            ) : (
              <>
                <div>
                  <Accordion
                    urlFields={urlFields}
                    setUrlFields={setUrlFields}
                    rewardType={rewardType}
                    setRewardType={setRewardType}
                    couponData={couponData}
                    setCouponData={setCouponData}
                    discountAmount={discountAmount}
                    setDiscountAmount={setDiscountAmount}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    days={days}
                    setDays={setDays}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    shopName={shopName}
                    shopConnected={shopConnected}
                    setShopConnected={setShopConnected}
                    gameSelect={gameSelect}
                    setGameSelect={setGameSelect}
                    gameLoop={gameLoop}
                    setGameLoop={setGameLoop}
                    firstShopConnected={firstShopConnected}
                    campaignId={id}
                    expanded={expanded}
                    setExpanded={setExpanded}
                    step={step}
                    setStep={setStep}
                    campaignName={campaignName}
                    setCampaignName={setCampaignName}
                    selectedGameValue={selectedGameValue}
                    campaignAccessibility={campaignAccessibility}
                    setCampaignAccessibility={setCampaignAccessibility}
                    outcome={outcome}
                    setOutcome={setOutcome}
                    setSelectUrl={setSelectUrl}
                    selectUrl={selectUrl}
                    urlAvailable={urlAvailable}
                    setUrlAvailable={setUrlAvailable}
                    privacyData={privacyData}
                    setPrivacyData={setPrivacyData}
                    rulesData={rulesData}
                    setRulesData={setRulesData}
                    setScoreboardData={setScoreboardData}
                    scoreboardData={scoreboardData}
                    setPersonalizationData={setPersonalizationData}
                    personalizationData={personalizationData}
                  />
                </div>
              </>
            )}
          </>
        ) : (
          <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h1
                className="campaign-header"
                style={{ fontSize: step > 1 ? 14 : 32 }}
              >
                SUCCESS!
              </h1>
            </div>
            <div
              style={{ marginTop: "60px", fontWeight: "900", fontSize: "32px" }}
            >
              Campaign {campaignName} is created 🥳
            </div>
            <div style={{ fontSize: "14px", fontWeight: "700" }}>
              Bellow are the steps on how to get a game and place it on your
              website.
            </div>

            <div style={{ marginTop: "48px" }}>
              <div style={{ marginTop: "12px", marginBottom: "30px" }}>
                Go to your website page (
                <strong
                  onClick={() => window.open(selectUrl.toString(), "_blank")}
                >
                  {selectUrl}
                </strong>
                ), the game is live now!
              </div>
            </div>
          </div>
        )}

        <div
          style={{ alignSelf: "flex-end", marginTop: "auto", width: "100%" }}
        >
          <>
            {step > 1 && !campaignAcivated && (
              <Button
                style={{
                  minWidth: "120px",
                  marginBottom: "12px",
                  width: "120px",
                  marginTop: "20px",
                }}
                className="outline_btn_dark me-2 campaign_first_button"
                onClick={() => {
                  setStep(step + -1);
                }}
              >
                <img
                  src={arrowLeft}
                  alt=""
                  style={{
                    width: "8px",
                    height: "12px",
                    marginRight: "8px",
                    marginBottom: "2px",
                  }}
                />
                <span>Back</span>
              </Button>
            )}
            {step <
              (selectedGameValue === "competition"
                ? 8
                : selectedGameValue === "rewards" && 6) && (
              <>
                {errorMessage}
                <Button
                  style={{
                    minWidth: "120px",
                    marginBottom: "12px",
                    marginLeft: "24px",
                    width: "120px",
                    marginTop: "20px",
                  }}
                  className="outline_btn_dark me-2 campaign_first_button"
                  onClick={() => {
                    handleStepChange(step + 1);
                  }}
                >
                  <span>Next</span>
                  <img
                    src={arrowRight}
                    alt=""
                    style={{
                      width: "8px",
                      height: "12px",
                      marginLeft: "8px",
                      marginBottom: "2px",
                    }}
                  />
                </Button>
              </>
            )}
            {step ===
              (selectedGameValue === "competition"
                ? 8
                : selectedGameValue === "rewards" && 6) && (
              <Button
                style={{
                  minWidth: "140px",
                  marginBottom: "12px",
                  maxWidth: "360px",
                  marginLeft: "24px",
                  width: "190px",
                  marginTop: "20px",
                }}
                onClick={() => {
                  !campaignAcivated
                    ? finishCampaign()
                    : setCampaignAcivated(false);
                }}
                type="submit"
                className={`${"outline_btn_dark_campagin launch_btn"}`}
              >
                <span>{!campaignAcivated ? "Create campaign" : "Go back"}</span>
              </Button>
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default SpecificProductReward;

import React from "react";
import { Col, Row } from "react-bootstrap";

const BigCard = ({
  img,
  data,
  big,
  positionimg,
  boldtext,
  boldtext2,
  boldtext3,
  text,
  text2,
  text3,
}) => {
  return (
    <div
      className={big ? "dashboard-card dashboard-card-big" : "dashboard-card"}
    >
      <Row style={{ height: "100%" }}>
        <Col lg={2} md={2} sm={2} xs={2} className="p-0 text-center">
          <div className="tooltip_main">
            <img
              src={`./assets/img/${positionimg}`}
              alt=""
              className="position__img icon-size"
            />
            <div className="tooltip_detail">
              <p>
                {" "}
                <span>{boldtext}</span> {text}
              </p>
              <p>
                {" "}
                <span>{boldtext2}</span> {text2}
              </p>
              <p>
                {" "}
                <span>{boldtext3}</span> {text3}
              </p>
            </div>
          </div>
          <img src={`./assets/img/${img}`} alt="" className="icon-40" />
        </Col>
        <Col lg={10} md={10} sm={10} xs={10} className="">
          <div className="bigcard_body">
            {data?.length ? (
              data.map((item, i) => (
                <div key={i}>
                  <div className="card-title">
                    <h2>{item.text}</h2>
                  </div>
                  <div className="card-title mb-3">
                    <h1>{item.total}</h1>
                  </div>
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default BigCard;
